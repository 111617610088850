import { ChangeEvent } from 'react'

import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid2 as Grid,
  SxProps,
} from '@mui/material'
import theme from '@rfh-core/theme'

type LabelCheckboxProps = {
  disabled?: boolean
  fieldName: string
  flowDirection: 'row' | 'column' | 'column-reverse' | 'row-reverse'
  label: string
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  value: boolean
  width?: number
  sx?: SxProps
}

export default function LabelCheckbox({
  disabled,
  fieldName,
  flowDirection,
  label,
  onChange,
  value,
  width,
  sx,
}: Readonly<LabelCheckboxProps>): JSX.Element {
  return (
    <Grid
      container
      direction={flowDirection}
      justifyContent={'flex-start'}
      alignItems={'center'}
      width={width}
    >
      <FormControl fullWidth>
        <FormLabel
          htmlFor={`id-${fieldName}`}
          sx={{
            color: theme.rfhColors.black,
            whiteSpace: 'nowrap',
            minWidth: 170,
            textAlign: 'center',
            ...sx,
          }}
        >
          {label}
        </FormLabel>
      </FormControl>
      <FormControl fullWidth>
        <Checkbox
          id={`id-${fieldName}`}
          name={fieldName}
          disabled={disabled}
          onChange={onChange}
          checked={value}
        />
      </FormControl>
    </Grid>
  )
}
