import { Grid2 as Grid, Typography, useTheme } from '@mui/material'

import { gridSmallStyles, typographyStyles } from 'src/common/constants'

type ProductGroupRegularityDetailsItemProps = {
  label: string
  value: any
}

export default function ProductGroupRegularityDetailsItem({
  label,
  value,
}: Readonly<ProductGroupRegularityDetailsItemProps>): JSX.Element {
  const theme = useTheme()

  return (
    <Grid container sx={gridSmallStyles(theme)}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Typography variant='subtitle2'>{label}</Typography>
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <Typography variant='body2' sx={typographyStyles}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  )
}
