import { HttpCode } from 'src/common/constants'

import { IApiClientListFilter } from '../interfaces'
import { ListAndCount } from '../types'
import {
  AanvoerderProductgroepRegelmatigUitzondering,
  IAanvoerderProductgroepRegelmatigUitzondering,
  IAanvoerderProductgroepRegelmatigUitzonderingView,
  IClient,
} from './client'

export const getSupplierProductGroupRegularityExceptions = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<ListAndCount<IAanvoerderProductgroepRegelmatigUitzonderingView>> => {
  const safeFilter =
    !apiClientFilter?.filter || apiClientFilter.filter === ''
      ? undefined
      : apiClientFilter.filter

  const result =
    // eslint-disable-next-line max-len
    (await apiClient.aanvoerderProductgroepRegelmatigUitzonderingenViews_AanvoerderProductgroepRegelmatigUitzonderingView_ListAanvoerderProductgroepRegelmatigUitzonderingView(
      apiClientFilter.top,
      apiClientFilter.skip,
      undefined,
      safeFilter,
      apiClientFilter.count,
      apiClientFilter.orderby,
      undefined,
      undefined
    )) as any

  const records: IAanvoerderProductgroepRegelmatigUitzonderingView[] =
    result?.cleanedResponse?.value
  const count = result?.['@odata.count'] ?? 0

  return { records, count }
}

export const addSupplierProductGroupRegularityException = async (
  apiClient: IClient,
  newEntity: AanvoerderProductgroepRegelmatigUitzondering
): Promise<number> => {
  const result: any =
    // eslint-disable-next-line max-len
    await apiClient.aanvoerderProductgroepRegelmatigUitzonderingen_AanvoerderProductgroepRegelmatigUitzondering_CreateAanvoerderProductgroepRegelmatigUitzondering(
      newEntity
    )

  const cleanedResult: IAanvoerderProductgroepRegelmatigUitzondering =
    result?.cleanedResponse

  if (cleanedResult?.aanvoerderProductgroepRegelmatigUitzonderingId) {
    return HttpCode.CREATED
  }
}
