import { memo, useCallback, useEffect, useLayoutEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { LabelDate, LabelInputIconText } from 'src/common/components'
import { MAX_DATE } from 'src/common/constants'
import { usePathname } from 'src/common/hooks'
import { getStringValue } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'

import {
  CodeSoortFust,
  CodeSoortProduct,
  CodeSoortProductgroep,
  FEATURE_ROOT_PATH,
  Kind,
  validationErrors,
} from '../constants'
import { useReferenceData } from '../hooks'
import { getItemFromListByRegelNummer } from '../lib'
import { getActionGroupAsync } from '../services'
import { useRuleStore } from '../stores'
import { AuctionGroupDialog, AuctionGroupRulesDialog, LabelIconText } from './'

import startOfTomorrow from 'date-fns/startOfTomorrow'

export default memo(function RuleMutation() {
  const { apiClient } = useApiClient()
  const { t } = useTranslation()
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const { ensureReferenceData } = useReferenceData()
  const {
    rule,
    ruleCodes,
    mustRefetchReferenceData,
    oldRuleCodesToUpdate,
    setMustRefetchReferenceData,
    setRule,
    setRuleCodesToUpdate,
  } = useRuleStore()

  const updateAuctionGroup = useCallback(
    async (inputValue: string): Promise<void> => {
      if (!apiClient) {
        return Promise.resolve()
      }

      if (!inputValue?.trim()) {
        // clear the auction group data
        setRule({
          ...rule,
          veilgroepCode: null,
          veilgroepOmschrijving: '',
        })
        return Promise.resolve()
      }
      const item = await getActionGroupAsync(
        apiClient,
        Number(inputValue),
        rule.vestigingCode
      )
      setRule({
        ...rule,
        veilgroepCode: item.code,
        veilgroepOmschrijving: item.name,
      })
    },
    [apiClient, rule, setRule]
  )

  const updateAuctionGroupSupplier = useCallback(
    async (inputValue: string): Promise<void> => {
      if (!apiClient) {
        return Promise.resolve()
      }

      if (!inputValue.trim()) {
        // clear the auction group supplier data
        setRule({
          ...rule,
          veilgroepCodeAanvoerder: null,
          veilgroepOmschrijvingAanvoerder: '',
        })
        return Promise.resolve()
      }
      const item = await getActionGroupAsync(
        apiClient,
        Number(inputValue),
        rule.vestigingCode,
        true // ofSupplier
      )
      setRule({
        ...rule,
        veilgroepCodeAanvoerder: item.code,
        veilgroepOmschrijvingAanvoerder: item.name,
      })
    },
    [apiClient, rule, setRule]
  )

  const updateAuctionGroupRule = useCallback(
    function (inputValue: string): void {
      if (!inputValue.trim()) {
        // clear the auction group rule data
        setRule({
          ...rule,
          veilgroepRegelId: null,
          veilgroepRegelNummer: null,
          veilgroepRegelOmschrijving: '',
        })
        return
      }
      const item = getItemFromListByRegelNummer(
        ruleCodes.VEILGROEPREGEL,
        Number(inputValue)
      )
      setRule({
        ...rule,
        veilgroepRegelId: item?.id,
        veilgroepRegelNummer: item ? item?.code : Number(inputValue),
        veilgroepRegelOmschrijving: item
          ? item?.name
          : validationErrors.invalidAuctionGroupRule,
      })
    },
    [rule, ruleCodes.VEILGROEPREGEL, setRule]
  )

  const updateIngangsDatum = useCallback(
    (date: Date) => {
      setRule({ ...rule, ingangsDatum: date })
    },
    [rule, setRule]
  )

  const updateEindDatum = useCallback(
    (date: Date) => {
      setRule({ ...rule, eindDatum: date })
    },
    [rule, setRule]
  )

  useLayoutEffect(() => {
    const refresh = async () =>
      await ensureReferenceData(
        rule.productclusterID,
        rule.productclusterVeilgroepRegelId
      )

    if (
      mustRefetchReferenceData ||
      !rule.productclusterID ||
      !rule.productclusterVeilgroepRegelId
    ) {
      refresh()
    }
  }, [ensureReferenceData, mustRefetchReferenceData, rule])

  useEffect(() => {
    setRuleCodesToUpdate(oldRuleCodesToUpdate)
    setMustRefetchReferenceData(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {/* veilgroep */}
      <LabelInputIconText
        fieldName={'veilgroepCode'}
        inputValue={getStringValue(rule?.veilgroepCode)}
        label={t('auctionGroupAllocation.auctionGroup') + ':'}
        textValue={rule?.veilgroepOmschrijving || ''}
        onChange={updateAuctionGroup}
      >
        <AuctionGroupDialog />
      </LabelInputIconText>
      {/* ingangsdatum */}
      <LabelDate
        fieldName={'ingangsDatum'}
        label={t('auctionGroupAllocation.startDate') + ':'}
        value={
          ADDING ? new Date(startOfTomorrow()) : new Date(rule?.ingangsDatum)
        }
        onChange={updateIngangsDatum}
      />
      {/* datum t/m */}
      <LabelDate
        fieldName={'datumTtm'}
        label={t('auctionGroupAllocation.endDate') + ':'}
        value={ADDING ? new Date(MAX_DATE) : new Date(rule?.eindDatum)}
        onChange={updateEindDatum}
      />
      {/* veilgroepregel */}
      <LabelInputIconText
        fieldName={'veilgroepRegelNummer'}
        inputValue={getStringValue(rule?.veilgroepRegelNummer)}
        label={t('auctionGroupAllocation.auctionGroupRule') + ':'}
        textValue={rule?.veilgroepRegelOmschrijving || ''}
        onChange={updateAuctionGroupRule}
      >
        <AuctionGroupRulesDialog />
      </LabelInputIconText>
      {/* fustcodes */}
      <LabelIconText
        codeSoort={CodeSoortFust}
        fieldName={'fustCodes'}
        label={t('auctionGroupAllocation.trayCodes') + ':'}
      />
      {/* productgroepen */}
      <LabelIconText
        codeSoort={CodeSoortProductgroep}
        fieldName={'productgroepen'}
        label={t('auctionGroupAllocation.productGroups') + ':'}
      />
      {/* productcodes */}
      <LabelIconText
        codeSoort={CodeSoortProduct}
        fieldName={'productCodes'}
        label={t('auctionGroupAllocation.productCodes') + ':'}
      />
      {/* veilgroep aanvoerder */}
      <LabelInputIconText
        fieldName={'veilgroepCode'}
        inputValue={getStringValue(rule?.veilgroepCodeAanvoerder)}
        label={t('auctionGroupAllocation.auctionGroupSupplier') + ':'}
        textValue={rule?.veilgroepOmschrijvingAanvoerder || ''}
        onChange={updateAuctionGroupSupplier}
      >
        <AuctionGroupDialog kind={Kind.supplier} />
      </LabelInputIconText>
    </>
  )
})
