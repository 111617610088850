import {
  IProductclusterVeilgroepRegelCode,
  IVeilgroepIndelingRegelView,
} from 'src/common/services/client'
import { Item } from 'src/common/types'

export const byVolgordeNummer = (
  virv1: IVeilgroepIndelingRegelView,
  virv2: IVeilgroepIndelingRegelView
): number => {
  if (virv1.volgordeNummer > virv2.volgordeNummer) {
    return 1
  }
  if (virv1.volgordeNummer < virv2.volgordeNummer) {
    return -1
  }
  return 0
}

export const byGeselecteerd = (
  pcvgrc1: IProductclusterVeilgroepRegelCode,
  pcvgrc2: IProductclusterVeilgroepRegelCode
): number => {
  /* first by geselecteerd: true before false */
  if (pcvgrc1.geselecteerd > pcvgrc2.geselecteerd) {
    return -1
  }
  if (pcvgrc1.geselecteerd < pcvgrc2.geselecteerd) {
    return 1
  }
  /* then by code - asc */
  if (pcvgrc1.code > pcvgrc2.code) {
    return 1
  }
  if (pcvgrc1.code < pcvgrc2.code) {
    return -1
  }
  return 0
}

export function bySelected(item1: Item<number>, item2: Item<number>): number {
  /* first by geselecteerd: true before false */
  if (item1.selected > item2.selected) {
    return -1
  }
  if (item1.selected < item2.selected) {
    return 1
  }
  /* then by code - asc */
  if (item1.code > item2.code) {
    return 1
  }
  if (item1.code < item2.code) {
    return -1
  }
  return 0
}
