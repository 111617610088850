import { TableCell, TableRow } from '@mui/material'
import theme from '@rfh-core/theme'

import { ClockStatusData, getCellStyles, KLOK_STATUS } from './lib'

type ClocksStatusTableRowProps = {
  index: number
  row: ClockStatusData
  mainRow: boolean
}

export default function ClocksStatusTableRow({
  row,
  mainRow,
}: Readonly<ClocksStatusTableRowProps>): JSX.Element {
  function getStatusColor(statusData: ClockStatusData): {
    bgColor: string
    color?: string
  } {
    switch (statusData?.klokStatus) {
      case 'WAC':
        return { bgColor: KLOK_STATUS.WAC.color }
      case 'VEL':
        return { bgColor: KLOK_STATUS.VEL.color }
      case 'PAU':
        return { bgColor: KLOK_STATUS.PAU.color }
      case 'STO':
        return { bgColor: KLOK_STATUS.STO.color, color: theme.rfhColors.white }
      case 'END':
        return { bgColor: KLOK_STATUS.END.color }
      default:
        return { bgColor: theme.rfhColors.white }
    }
  }

  const mainBgColor = mainRow
    ? theme.rfhColors.grey[200]
    : theme.rfhColors.white

  return (
    <TableRow sx={{ bgcolor: mainBgColor }}>
      <TableCell
        sx={{
          ...getCellStyles('left'),
          bgcolor: mainRow ? mainBgColor : getStatusColor(row).bgColor,
          color: getStatusColor(row)?.color,
          fontWeight: 700,
          overflow: 'visible',
          width: '11.5%',
        }}
      >
        {row.klokNaam}
      </TableCell>
      <TableCell sx={{ ...getCellStyles('left'), width: '48.5%' }}>
        {row.huidigeVeilgroep}
      </TableCell>
      <TableCell sx={getCellStyles('right')}>{row.teVeilen}</TableCell>
      <TableCell sx={getCellStyles('right')}>{row.geveild}</TableCell>
    </TableRow>
  )
}
