import { TableCell, TableHead, TableRow } from '@mui/material'
import theme from '@rfh-core/theme'

import { getHeaderCellStyles } from './lib'

export default function ClocksStatusTableHeader(): JSX.Element {
  return (
    <TableHead>
      <TableRow sx={{ bgcolor: theme.rfhColors.grey[200] }}>
        <TableCell
          sx={{
            ...getHeaderCellStyles(),
            overflow: 'visible',
            width: '11.5%',
          }}
        >
          Klok
        </TableCell>
        <TableCell sx={{ ...getHeaderCellStyles(), width: '48.5%' }}>
          Veilgroep
        </TableCell>
        <TableCell sx={getHeaderCellStyles('right')}>#Pty</TableCell>
        <TableCell sx={{ ...getHeaderCellStyles(), pl: 0, pr: '1em' }}>
          #Geveiled
        </TableCell>
      </TableRow>
    </TableHead>
  )
}
