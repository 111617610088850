import { memo, useEffect, useState } from 'react'

import { Grid2 as Grid, IconButton, Typography } from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { snackbarUtils } from 'src/common/utils'

import { useRuleStore } from '../../stores'
import { CodeSoort } from '../../types'
import { MultiSelectDialog } from '../MultiSelectDialog'

type LabelIconTextProps = {
  codeSoort: CodeSoort
  fieldName?: string
  label: string
}

export default memo(function LabelIconText({
  codeSoort,
  fieldName,
  label = '',
}: Readonly<LabelIconTextProps>): JSX.Element {
  const { concatCodesText, initialCodesText } = useRuleStore()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isTimeoutReached, setIsTimeoutReached] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [text, setText] = useState<string>('')

  const openDialog = () => {
    setIsOpen(true)
    setIsLoading(true)
  }

  const closeDialog = () => {
    setIsOpen(false)
    setIsLoading(false)
    setText(concatCodesText(codeSoort))
  }

  let operationTimeout: NodeJS.Timeout

  function clearOperationTimeout() {
    if (operationTimeout) clearTimeout(operationTimeout)
    setIsTimeoutReached(false)
  }

  //! Research: Assignments to the 'operationTimeout' variable
  //! from inside React hook useEffect will be lost on each render.
  useEffect(() => {
    if (isOpen) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      operationTimeout = setTimeout(() => {
        setIsTimeoutReached(true)
        snackbarUtils.error('Operation timeout')
      }, 5000)
    }

    return clearOperationTimeout()
  }, [isOpen])

  useEffect(() => {
    const calculatedText = concatCodesText(codeSoort)
    if (calculatedText) {
      return setText(calculatedText)
    }
    const initialText = initialCodesText(codeSoort)
    setText(initialText)
  }, [codeSoort, concatCodesText, initialCodesText])

  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Grid>
        <Typography
          aria-label={`id-${fieldName}`}
          variant={'body2'}
          sx={{
            color: theme.rfhColors.black,
            whiteSpace: 'nowrap',
            pb: 0,
            my: '0.7em',
            minWidth: 170,
          }}
        >
          {label}
        </Typography>
      </Grid>
      {/* dialog trigger button */}
      <IconButton aria-label={'lookup icon button'} onClick={openDialog}>
        <SearchIcon
          sx={{
            color: theme.rfhColors.dutchOrange,
            height: 20,
            py: 0,
            ml: '-0.25em',
          }}
        />
      </IconButton>
      {isOpen && (
        <MultiSelectDialog
          codeSoort={codeSoort}
          dialogTitle={`Selecteer ${codeSoort}`}
          isLoading={isLoading}
          isOpen={isOpen}
          isTimeoutReached={isTimeoutReached}
          onClose={closeDialog}
        />
      )}
      <Grid size={8}>{text}</Grid>
    </Grid>
  )
})
