import { Box, type SxProps } from '@mui/material'
import theme from '@rfh-core/theme'

export const Divider = (props: SxProps): JSX.Element => {
  const hrStyle = {
    border: `1px solid ${theme.rfhColors.grey[200]}`,
    width: '100%',
    my: 4,
  }

  return <Box component={'hr'} sx={{ ...hrStyle, ...props }} />
}
