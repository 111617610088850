import { SearchStatsFilterType } from '../hooks'
import { Align } from '../types'
import { getLocalItem, getUTCDate } from '../utils'

import addDays from 'date-fns/addDays'
import differenceInDays from 'date-fns/differenceInDays'
import nextFriday from 'date-fns/nextFriday'
import nextMonday from 'date-fns/nextMonday'

export const getRandomInt = (between1: number, between2?: number): number => {
  const min = Math.min(between1, between2 ?? 0)
  const max = Math.max(between1, between2 ?? 0)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const getRandomArrayItem = <T>(array: T[]): T => {
  const length = array.length
  if (length === 0) {
    return null
  }

  return array[getRandomInt(length - 1)]
}

const combineAligns = (acc: any, align: Align, idx: number) => ({
  ...acc,
  [`& .MuiGrid-container[role="row"] .MuiGrid-item[role="cell"]:nth-child(${
    idx + 1
  })>.MuiBox-root`]: {
    textAlign: align,
  },
})

export const alignColumns = (aligns?: Align[]): Record<string, any> => {
  if (!aligns || aligns.length === 0) {
    return null
  }
  const alignedColumns = aligns.reduce(combineAligns, {})
  return alignedColumns
}

//! this kind of function better move into a hook so that they can be memoized
export const mayEditPeriod = (startDate?: Date, endDate?: Date): boolean => {
  if (!startDate || !endDate) {
    return false
  }

  const oneWeek = 7
  const minPeriod = 4 // ma t/m vr

  const currentDay = new Date()
  const startPeriod = getUTCDate(startDate)
  const endPeriod = getUTCDate(endDate)
  const minMonday = getUTCDate(nextMonday(currentDay))
  const minFriday = getUTCDate(nextFriday(minMonday))

  const mayOrMayNot =
    differenceInDays(endPeriod, startPeriod) >= minPeriod + oneWeek &&
    endPeriod >= addDays(minFriday, oneWeek)

  return mayOrMayNot
}

export const noOp = (): void => {
  // nothing to see - use it as a function placeholder
}

export const getStringValue = (inputValue: string | number): string =>
  String(inputValue ?? '')

export function getLocalFilter(
  cacheKey: string,
  initialFilter: SearchStatsFilterType
): SearchStatsFilterType {
  const localFilter: SearchStatsFilterType = getLocalItem(cacheKey)
  return { ...initialFilter, ...localFilter }
}

export const mergeArraysByKey = <T, K extends keyof T>(
  arr1: T[],
  arr2: T[],
  key: K
): T[] => {
  const mergedArray = [...arr1, ...arr2].reduce((result, obj) => {
    if (!result.some(item => item[key] === obj[key])) result.push(obj)
    return result
  }, [])

  return mergedArray
}
