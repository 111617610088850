/* eslint-disable max-len */
import { IApiClientGetFilter } from 'src/common/interfaces'
import {
  IClient,
  IVeilgroepRegelKenmerk,
  VeilgroepRegelKenmerk,
} from 'src/common/services/client'
import { ListAndCount } from 'src/common/types'
import { constructFilterRuleForKVP } from 'src/common/utils/FilterFunctions'

export const getByAuctionGroupRuleId = async (
  apiClient: IClient,
  { key }: Partial<IApiClientGetFilter>
): Promise<ListAndCount<IVeilgroepRegelKenmerk>> => {
  const filter = constructFilterRuleForKVP('veilgroepRegelId', Number(key))
  const result =
    (await apiClient.veilgroepRegelKenmerken_VeilgroepRegelKenmerk_ListVeilgroepRegelKenmerk(
      undefined,
      undefined,
      undefined,
      filter,
      true,
      undefined,
      undefined,
      undefined
    )) as any

  return {
    records: result.cleanedResponse.value ?? [],
    count: result?.['@odata.count'],
  }
}

export const addAuctionGroupRuleCharacteristic = async (
  apiClient: IClient,
  characteristic: IVeilgroepRegelKenmerk
): Promise<VeilgroepRegelKenmerk> => {
  const result =
    await apiClient.veilgroepRegelKenmerken_VeilgroepRegelKenmerk_CreateVeilgroepRegelKenmerk(
      new VeilgroepRegelKenmerk(characteristic)
    )

  return result
}

export const deleteAuctionGroupRuleCharacteristic = async (
  apiClient: IClient,
  id: number
): Promise<void> =>
  await apiClient.veilgroepRegelKenmerken_VeilgroepRegelKenmerk_DeleteVeilgroepRegelKenmerk(
    id,
    '*'
  )
