/* eslint-disable max-len */
import { IAanvoerderOnregelmatig, IClient } from 'src/common/services/client'
import {
  AanvoerderOnregelmatigBodyData,
  IClientExtra,
} from 'src/common/services/clientExtra'

/**
 * @param apiClient
 * @returns Promise<IAanvoerderOnregelmatig[]>
 */
export const getIrregularSuppliersAsync = async (
  apiClient: IClient
): Promise<IAanvoerderOnregelmatig[]> => {
  const result: any =
    await apiClient.aanvoerderOnregelmatig_AanvoerderOnregelmatig_ListAanvoerderOnregelmatig(
      1000,
      undefined,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      undefined
    )

  const records: IAanvoerderOnregelmatig[] =
    result?.cleanedResponse?.value || []

  return records || []
}

/**
 * @param apiClient
 * @param id
 * @returns Promise<IAanvoerderOnregelmatig>
 */
export const deleteIrregularSupplier = async (
  apiClient: IClientExtra,
  id: number
): Promise<void> => {
  const version = '1'
  await apiClient.aanvoerderOnregelmatigDELETE(id, version)
}

/**
 * @param apiClient
 * @param body
 * @returns Promise<IAanvoerderOnregelmatig>
 */
export const addIrregularSupplier = async (
  apiClient: IClientExtra,
  body: AanvoerderOnregelmatigBodyData
): Promise<void> => {
  const version = '1'
  await apiClient.addAanvoerderOnregelmatig(version, body)
}
