import { IClient, KenmerkWaarde } from 'src/common/services/client'
import { constructFilterRuleForKVP, getOrderBy } from 'src/common/utils'

export const getValueByCharacteristicCode = async (
  apiClient: IClient,
  kenmerkCode: string
): Promise<KenmerkWaarde[]> => {
  const filter = constructFilterRuleForKVP('KenmerkCode', kenmerkCode)
  const result =
    (await apiClient.kenmerkWaardes_KenmerkWaarde_ListKenmerkWaarde(
      undefined,
      undefined,
      undefined,
      filter,
      undefined,
      getOrderBy([
        {
          sort: 'asc',
          field: 'kenmerkWaardeCode',
        },
      ]),
      undefined,
      undefined
    )) as any

  return result.cleanedResponse.value ?? []
}

export const getPromotionCharacteristicValuesAsync = async (
  apiClient: IClient
): Promise<KenmerkWaarde[]> => {
  const filter = `KenmerkCode eq 'V22' and eindDatum ge ${new Date().toISOString()}`

  const result =
    (await apiClient.kenmerkWaardes_KenmerkWaarde_ListKenmerkWaarde(
      undefined,
      undefined,
      undefined,
      filter,
      undefined,
      undefined,
      undefined,
      undefined
    )) as any

  return result.cleanedResponse.value ?? []
}
