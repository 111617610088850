import { QueryClient, UseQueryResult } from '@tanstack/react-query'

import type { IClient } from 'src/common/services/client'
import type { IClientExtra } from 'src/common/services/clientExtra'
import { ListAndCount } from 'src/common/types'

import type { ListFilterType } from './lib.filterTypes'
import { FuncSort } from './lib.funcs'
import type { EntityType, ListFuncType } from './lib.funcTypes'

type ClientAndExtra = IClient & IClientExtra

export const prefetch = <T>(
  queryResult: UseQueryResult<ListAndCount<T>>,
  filter: ListFilterType,
  entity: EntityType,
  func: ListFuncType,
  client: IClient,
  queryClient: QueryClient
): void => {
  const tempFilter = {
    top: filter?.top ?? 100,
    skip: filter?.skip ?? 0,
    search: filter?.search ?? undefined,
    filter: filter?.filter ?? undefined,
    count: filter?.count ?? undefined,
    orderby: filter?.orderby ?? undefined,
    select: filter?.select ?? undefined,
    expand: filter?.expand ?? undefined,
  }
  if (filter.skip + filter.top < queryResult.data.count) {
    const newFilter = { ...tempFilter, skip: tempFilter.skip + tempFilter.top }
    const newQueryKey = [entity, FuncSort.list, newFilter]
    queryClient.prefetchQuery<any, any, ListAndCount<T>, typeof newQueryKey>(
      newQueryKey,
      {
        queryFn: () =>
          client[func](
            newFilter.top,
            newFilter.skip,
            newFilter.search,
            newFilter.filter,
            newFilter.count,
            newFilter.orderby,
            newFilter.select,
            newFilter.expand
          ) as Promise<any>,
      }
    )
  }
  if (filter.skip > filter.top) {
    const newFilter = { ...tempFilter, skip: tempFilter.skip - tempFilter.top }
    const newQueryKey = [entity, FuncSort.list, newFilter]
    queryClient.prefetchQuery<any, any, ListAndCount<T>, typeof newQueryKey>(
      newQueryKey,
      {
        queryFn: () =>
          client[func](
            newFilter.top,
            newFilter.skip,
            newFilter.search,
            newFilter.filter,
            newFilter.count,
            newFilter.orderby,
            newFilter.select,
            newFilter.expand
          ) as Promise<any>,
      }
    )
  }
}

export function hasPropertyAndNotNull(
  obj: ClientAndExtra,
  method: keyof ClientAndExtra
): boolean {
  // Controleer eerst of het object überhaupt aanwezig is
  if (obj === null || obj === undefined || typeof obj !== 'object') {
    return false
  }

  // Controleer vervolgens of de methode aanwezig is op het object
  if (!(method in obj) || typeof obj[method] !== 'function') {
    return false
  }

  // Als het object aanwezig is en de methode bestaat, retourneer true
  return true
}

export const isNumberDefined = (input?: number | string): boolean =>
  input && !isNaN(Number(input)) && Number(input) > 0
