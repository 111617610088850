import { ADD_PATH } from 'src/common/constants'
import { UpsertMode } from 'src/common/enums'

export function determineUpsertMode(
  rootPath: string,
  pathname: string
): UpsertMode {
  const paths = pathname.split('/')
  const length = paths?.length ?? 0

  if (length < 2 || paths[2] !== rootPath) return UpsertMode.void
  if (paths[length - 1] === ADD_PATH) return UpsertMode.add
  if (Number(paths[length - 1]) >= 0) return UpsertMode.edit

  return UpsertMode.void
}

export function getParam(
  rootPath: string,
  pathname: string
): number[] | undefined {
  const paths = pathname.split('/')
  const upsertMode = determineUpsertMode(rootPath, pathname)
  if (upsertMode === UpsertMode.add) return [0]
  if (upsertMode === UpsertMode.edit) {
    if (paths[4]) {
      return [Number(paths[3]), Number(paths[4])]
    } else {
      return [Number(paths[3])]
    }
  }
  return undefined
}
