import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { UpsertMode } from 'src/common/enums'

import { determineUpsertMode, getParam } from './lib'

type ReturnType = {
  ADDING: boolean
  upsertMode: UpsertMode
  urlParam: number[]
}

export const usePathname = (rootPath: string): ReturnType => {
  const location = useLocation()

  const upsertMode = useMemo(
    () => determineUpsertMode(rootPath, location.pathname),
    [location.pathname, rootPath]
  )

  const ADDING = useMemo(() => upsertMode === UpsertMode.add, [upsertMode])

  const urlParam = useMemo(() => {
    if (ADDING) {
      return [0] //! to be in sync with 0-id for new entities
    } else {
      return getParam(rootPath, location.pathname)
    }
  }, [location.pathname, rootPath, ADDING])

  return {
    ADDING,
    upsertMode,
    urlParam,
  }
}
