import { useTranslation } from 'react-i18next'
import {
  Route,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SecureRoute as OktaSecureRoute } from '@okta/okta-react'
import { LoadingPage } from '@rfh-core/components'

import { useUser } from 'src/common/providers/UserProvider'
import { NotFound } from 'src/views'

import type { RenderProps } from './utils'

type ConsulterRouteProps = RouteProps & { enabled?: boolean }

export default function ConsulterRoute({
  component: Component,
  enabled = true,
  ...rest
}: Readonly<ConsulterRouteProps>): JSX.Element {
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t } = useTranslation()

  const getContent = (props: RenderProps): JSX.Element => {
    if (!user.isAuthenticated) {
      return <LoadingPage label={t('common.loading')} />
    }

    // user is authenticated but the "enabled" prop is passed as "false"
    // => nothing to do
    if (enabled != null && !enabled) {
      return
    }

    if (!(user.isAuctionCoordinator || user.isConsulter)) {
      console.warn('Unauthorized: redirecting to read only environment')
      history.push('/dashboard')
      return
    }

    // "enabled" prop is not passed at all or passed as "true"
    // => show the Component
    return (
      <Component
        history={history}
        location={location}
        match={match}
        {...props}
      />
    )
  }

  return enabled ? (
    <OktaSecureRoute
      {...(rest as any)}
      render={(props: RenderProps) => getContent(props)}
    />
  ) : (
    <Route component={NotFound} />
  )
}
