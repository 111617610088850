import { Card, styled } from '@mui/material'

export const StyledCard = styled(Card)(({ theme }) => ({
  height: '160px !important',
  justifyContent: 'space-between',
  textDecoration: 'none',
  boxShadow: theme.shadows[7],
  transition: 'box-shadow 500ms ease',
  '&:hover': {
    boxShadow: theme.shadows[0],
  },
})) as typeof Card
