import {
  IApiClientGetFilter,
  IApiClientListFilter,
} from 'src/common/interfaces'
import {
  IClient,
  IVeilgroepRegel,
  VeilgroepRegel,
} from 'src/common/services/client'
import { ListAndCount } from 'src/common/types'

export const getAllAuctionGroupRulesAsync = async (
  apiClient: IClient,
  apiClientListFilter: Partial<IApiClientListFilter>
): Promise<ListAndCount<IVeilgroepRegel>> => {
  const result =
    (await apiClient.veilgroepRegels_VeilgroepRegel_ListVeilgroepRegel(
      apiClientListFilter.top,
      apiClientListFilter.skip,
      undefined,
      apiClientListFilter.filter,
      apiClientListFilter.count,
      apiClientListFilter.orderby,
      undefined,
      undefined
    )) as any

  return {
    records: result.cleanedResponse.value ?? [],
    count: result?.['@odata.count'],
  }
}

export const getAuctionGroupRuleByIdAsync = async (
  apiClient: IClient,
  { key }: Partial<IApiClientGetFilter>
): Promise<VeilgroepRegel> => {
  const result =
    (await apiClient.veilgroepRegels_VeilgroepRegel_GetVeilgroepRegel(
      key,
      undefined,
      undefined
    )) as any
  return result.cleanedResponse
}

export const addAuctionGroupRuleAsync = async (
  apiClient: IClient,
  rule: IVeilgroepRegel,
  userSub: string
): Promise<VeilgroepRegel> => {
  const newRule = new VeilgroepRegel({
    ...rule,
    aanmaakDatumTijd: new Date(),
    aanmaakGebruiker: userSub,
    mutatieDatumTijd: new Date(),
    mutatieGebruiker: userSub,
  })
  const result =
    (await apiClient.veilgroepRegels_VeilgroepRegel_CreateVeilgroepRegel(
      newRule
    )) as any
  return result.cleanedResponse
}

export const updateAuctionGroupRuleAsync = async (
  apiClient: IClient,
  key: number,
  rule: IVeilgroepRegel,
  userSub: string
): Promise<void> => {
  const updatedRule = new VeilgroepRegel({
    ...rule,
    mutatieDatumTijd: new Date(),
    mutatieGebruiker: userSub,
  })
  await apiClient.veilgroepRegels_VeilgroepRegel_UpdateVeilgroepRegel(
    key,
    updatedRule
  )
}

export const deleteAuctionGroupRuleAsync = async (
  apiClient: IClient,
  key: number
): Promise<void> => {
  await apiClient.veilgroepRegels_VeilgroepRegel_DeleteVeilgroepRegel(key, '*')
}
