import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { IClient } from 'src/common/services/client'
import {
  ClientExtra as Client,
  IClientExtra,
} from 'src/common/services/clientExtra'

import type { ChildrenType } from './types'
import { useUser } from './UserProvider'

type ApiClientContextType = {
  apiClient: IClient & IClientExtra
}

export const ApiClientContext = createContext<ApiClientContextType | null>(null)
ApiClientContext.displayName = 'ApiClientContext'

/**
 * Use this hook to get the apiClient as a singleton
 * @example const {apiClient} = useApiClient()
 */
export const useApiClient = (): ApiClientContextType => {
  const context = useContext(ApiClientContext)

  if (context === undefined) {
    throw new Error(
      'useApiClient hook was used outside of its context provider'
    )
  }

  return context
}

export const ApiClientProvider = ({ children }: ChildrenType): JSX.Element => {
  const user = useUser()
  const [apiClient, setApiClient] = useState<IClient & IClientExtra>()

  useEffect(() => {
    if (user?.accessToken) {
      console.log('apiClient renewed')
      const client = new Client(user?.accessToken)
      setApiClient(client)
    } else {
      console.log('apiClient destroyed')
      setApiClient(undefined)
    }
  }, [user.accessToken])

  const memoizedApiClient = useMemo(
    () => ({
      apiClient,
    }),
    [apiClient]
  )

  return (
    <ApiClientContext.Provider value={memoizedApiClient}>
      {children}
    </ApiClientContext.Provider>
  )
}
