import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BrowserRouter as Router } from 'react-router-dom'

import { Rainbow } from '@con/ui'
import { Box, Container, Typography } from '@mui/material'
import { Footer } from '@rfh-core/components'
import * as Sentry from '@sentry/react'

import { createBrowserHistory } from 'history'
import { SnackbarProvider } from 'notistack'

import Package from '../package.json'
import './App.css'
import { AppBar } from './common/components'
import GenericError from './common/components/GenericError'
import { Config } from './common/config'
import { GlobalProvider } from './common/providers/GlobalProvider'
import { useDevTools } from './dev-tools'
import type { SupportedLanguage } from './i18n'
import { Routes } from './routes/routes'

const history = createBrowserHistory()

export default function App(): JSX.Element {
  const { i18n, t } = useTranslation()
  const { version } = useDevTools()
  const ZINDEX = 1500

  useEffect(() => {
    document.title = `RFH - ${t('common.applicationName')}`
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Sentry.ErrorBoundary
      fallback={
        <GenericError error={new Error(t('common.genericErrorShort'))} />
      }
    >
      <SnackbarProvider
        autoHideDuration={7000}
        maxSnack={5}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Router>
          <GlobalProvider>
            <Box position={'fixed'} top={14} right={80} zIndex={ZINDEX}>
              <Typography variant='overline'>V {version}</Typography>
            </Box>
            <Box display={'flex'} flexDirection={'column'} minHeight={'100vh'}>
              <AppBar />
              <Box maxHeight={'calc(100vh - 64px)'} sx={{ overflowY: 'auto' }}>
                <Container maxWidth={'xl'}>
                  <Routes />
                </Container>
                <Container maxWidth={'lg'}>
                  <Footer
                    language={i18n.language.split('-')[0] as SupportedLanguage}
                  />
                </Container>
              </Box>
            </Box>
            <Box
              position={'fixed'}
              left={0}
              right={0}
              bottom={0}
              zIndex={ZINDEX}
            >
              <Rainbow />
            </Box>
          </GlobalProvider>
        </Router>
      </SnackbarProvider>
    </Sentry.ErrorBoundary>
  )
}

Sentry.init({
  dsn: 'https://24a0d0a25b88eb69a18b7d4f4672620e@o902103.ingest.sentry.io/4506716941975552',
  environment: Config.env,
  release: Package.version,
  enabled: process.env.NODE_ENV !== 'development',
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})
