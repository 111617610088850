import { LadingdragerType } from 'src/common/enums'
import { ILocationClock } from 'src/common/interfaces'
import {
  ITribuneKlok,
  IVeildagVeilgroepTellingReport,
} from 'src/common/services/client'

import type { AuctionGroupCountReport } from '../types'

export const toComboBoxOption = (clk: ILocationClock): JSX.Element => (
  <option key={clk.clockNumber} value={clk.clockNumber}>
    {clk.clockName}
  </option>
)

type KarAantallen = {
  aantalKarren?: number
  aantalOntvangenKarren?: number
  aantalNogTeOntvangenKarren?: number
  aantalInNeerzetvolgordeKarren?: number
  aantalNietInNeerzetvolgordeKarren?: number
}

const toKarAantallen = (
  report: IVeildagVeilgroepTellingReport
): KarAantallen => {
  let karAantallen: KarAantallen
  switch (report.ladingdragerType) {
    case LadingdragerType.Stapelwagens:
      karAantallen = {
        aantalKarren:
          report.aantalAangevoerdeTransportmiddelenStapelwagens || 0,
        aantalOntvangenKarren:
          report.aantalOntvangenTransportmiddelenStapelwagens || 0,
        aantalInNeerzetvolgordeKarren:
          report.aantalNeerzetscansTransportmiddelenStapelwagens || 0,
      }
      break
    case LadingdragerType.Denen:
      karAantallen = {
        aantalKarren: report.aantalAangevoerdeTransportmiddelenDenen || 0,
        aantalOntvangenKarren:
          report.aantalOntvangenTransportmiddelenDenen || 0,
        aantalInNeerzetvolgordeKarren:
          report.aantalNeerzetscansTransportmiddelenDenen || 0,
      }
      break
    default:
      karAantallen = {
        aantalKarren:
          (report.aantalAangevoerdeTransportmiddelenStapelwagens || 0) +
          (report.aantalAangevoerdeTransportmiddelenDenen || 0),
        aantalOntvangenKarren:
          (report.aantalOntvangenTransportmiddelenStapelwagens || 0) +
          (report.aantalOntvangenTransportmiddelenDenen || 0),
        aantalInNeerzetvolgordeKarren:
          (report.aantalNeerzetscansTransportmiddelenStapelwagens || 0) +
          (report.aantalNeerzetscansTransportmiddelenDenen || 0),
      }
      break
  }

  karAantallen.aantalNogTeOntvangenKarren =
    karAantallen.aantalKarren - karAantallen.aantalOntvangenKarren
  karAantallen.aantalNietInNeerzetvolgordeKarren = Math.max(
    karAantallen.aantalOntvangenKarren -
      karAantallen.aantalInNeerzetvolgordeKarren,
    0
  )

  return karAantallen
}

export const toAuctionGroupCount = (
  report: IVeildagVeilgroepTellingReport
): AuctionGroupCountReport => {
  const karAantallen = toKarAantallen(report)
  return {
    auctionDate: report.veildatum,
    clockName: report.klokNaam,
    auctionGroupCode: report.veilgroepCode,
    auctionGroupDescription: report.veilgroepOmschrijving,
    locationCode: report.vestigingCode,
    trolleyType: report.ladingdragerType,
    trolleysDeclared: karAantallen.aantalKarren,
    trolleysReceived: karAantallen.aantalOntvangenKarren,
    trolleysToReceive: karAantallen.aantalNogTeOntvangenKarren,
    trolleysPlacedInOrder: karAantallen.aantalInNeerzetvolgordeKarren,
    trolleysNotPlacedInOrder: karAantallen.aantalNietInNeerzetvolgordeKarren,
  }
}

export const toLocationClock = (apiData: ITribuneKlok): ILocationClock => ({
  clockNumber: apiData.klokNummer,
  clockName: apiData.klokNaam,
  locationCode: apiData.vestigingCode,
})
