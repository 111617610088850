import { ChangeEvent, useState } from 'react'

import { Checkbox, TableCell, TableRow } from '@mui/material'

import { IProductgroepenReport } from 'src/common/services/client'

type ProductGroupsItemProps = {
  changeChecked: (pg: IProductgroepenReport) => void
  productGroep: IProductgroepenReport
}

export default function ProductGroupsItem({
  changeChecked,
  productGroep,
}: Readonly<ProductGroupsItemProps>): JSX.Element {
  const [selected, setSelected] = useState<boolean>(
    productGroep?.productGroepGeselecteerd ?? false
  )

  const handleRowClick = () => {
    const checked = !selected
    handleClick(checked)
  }

  const toggleCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    handleClick(checked)
  }

  return (
    <TableRow
      key={productGroep.productGroepCode}
      hover={true}
      onClick={handleRowClick}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell sx={{ py: 0 }}>{productGroep.productGroepCode}</TableCell>
      <TableCell sx={{ py: 0 }}>
        {productGroep.productGroepOmschrijving}
      </TableCell>
      <TableCell sx={{ py: 0, textAlign: 'right' }}>
        <Checkbox checked={selected} onChange={toggleCheckbox} />
      </TableCell>
    </TableRow>
  )

  function handleClick(checked: boolean) {
    setSelected(checked)
    productGroep.productGroepGeselecteerd = checked
    changeChecked(productGroep)
  }
}
