import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { type Feature, type FeatureName } from '../config'

export type StoreState = {
  features: Record<FeatureName, Feature> | null
}

type Actions = {
  /* store */
  resetDevToolsStoreState: () => void
  /* features */
  setFeatures: (fts: Record<FeatureName, Feature>) => void
  toggleFeature: (name: string) => void
  shouldFeatureBeEnabled: (feature?: Feature) => boolean
}

const storeName = 'DevToolsStore'

const initialStoreState: StoreState = {
  features: null,
}

const useDevToolsStore = create<StoreState & Actions>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetDevToolsStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetDevToolsStoreState'
          ),
        /* features */
        setFeatures: (fts: Record<FeatureName, Feature>) =>
          set(() => ({ features: fts }), false, 'setFeatures'),
        toggleFeature: (name: FeatureName): void =>
          set(
            (state: StoreState) => {
              const newFeatures = { ...state.features }
              newFeatures[name].enabled = !state.features[name].enabled
              return { features: newFeatures }
            },
            false,
            'updateFeature'
          ),
        shouldFeatureBeEnabled: (feature?: Feature): boolean => {
          if (!feature) return false
          return (
            get().features?.[feature.name].enabled ||
            get().features?.[feature.name].alwaysEnabled
          )
        },
      }),
      {
        name: 'dev-tools-state',
        storage: createJSONStorage(() => localStorage),
      }
    ),
    { name: storeName }
  )
)

export default useDevToolsStore
