import { useTranslation } from 'react-i18next'

import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { deleteRegularityException } from 'src/common/services'
import type { RegularityExceptionView } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { ActionModal } from '../ActionModal'

type RegularityExceptionDeleteProps = {
  exceptionToBeDeleted: RegularityExceptionView
  open: boolean
  onCancel: () => any
  refresh: () => any
}

export default function RegularityExceptionDelete({
  exceptionToBeDeleted,
  open,
  onCancel,
  refresh,
}: Readonly<RegularityExceptionDeleteProps>): JSX.Element {
  const translationsKey = 'exceptions.deleteDialog'
  const { t } = useTranslation()
  const { apiClient } = useApiClient()

  const onConfirm = async () => {
    try {
      onCancel()
      await deleteRegularityException(apiClient, exceptionToBeDeleted)
      snackbarUtils.success(t('overviews.deleteSuccessMessage'))
      refresh()
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
  }

  return (
    <ActionModal
      onCancel={onCancel}
      onConfirm={onConfirm}
      open={open}
      translationsKey={translationsKey}
    />
  )
}
