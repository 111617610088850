import { IAllSearchFilterFields } from '../interfaces'
import { getUTCDate } from './DateFunctions'

//#region GLOBAL FUNCTIONS

export const pushNewFilterRule = <
  TKey extends keyof IAllSearchFilterFields,
  TValue extends IAllSearchFilterFields[TKey]
>(
  key: TKey,
  value: TValue,
  filterRules: string[]
): string[] => {
  if (key === 'vestigingCode') {
    const filterRule = constructFilterRuleForKVP(key, Number(value))
    if (filterRule) {
      filterRules.push(filterRule)
    }
  } else if (key !== 'perioden') {
    const filterRule = constructFilterRuleForKVP(key, value)
    if (filterRule) {
      filterRules.push(filterRule)
    }
  } else if (value !== 'allePerioden') {
    filterRules.push(constructFilterRuleForRadio(value))
  }
  return filterRules
}

export const constructFilterString = <T>(
  searchValues: T,
  addFilterRule: (key: keyof T, value: any, filterRules: string[]) => string[]
): string => {
  if (!searchValues) {
    return undefined
  }
  let filterRules: string[] = []
  // Loop over each value of the data and assign the correct filter rule
  for (const [key, value] of Object.entries(searchValues)) {
    if (!value) {
      continue
    }
    filterRules = addFilterRule(key as keyof T, value, filterRules)
  }
  if (filterRules.length === 0) {
    return undefined
  }
  return filterRules.join(' and ')
}

export const constructFilterRuleForKVP = (
  key: string,
  value: string | number
): string => {
  let filterRule = ''
  if (key) {
    filterRule =
      typeof value === 'number' ? `${key} eq ${value}` : `${key} eq '${value}'`
  }
  return filterRule
}

export const isFilterEmpty = <T>(filter: T): boolean =>
  !filter ||
  Object.keys(filter).length === 0 ||
  Object.keys(filter).every(key => !filter[key as keyof T])

//#endregion GLOBAL FUNCTIONS

//#region LOCAL FUNCTIONS

const constructFilterRuleForRadio = (value: string | number): string => {
  let filterRule
  if (value === 'periodenVerleden') {
    filterRule = `uitzonderingDatumTtm lt ${getUTCDate(
      new Date()
    ).toISOString()}`
  }
  if (value === 'periodenActueel') {
    filterRule = `uitzonderingDatumVanaf le ${getUTCDate(
      new Date()
    ).toISOString()} and uitzonderingDatumTtm ge ${getUTCDate(
      new Date()
    ).toISOString()}`
  }
  if (value === 'periodenToekomst') {
    filterRule = `uitzonderingDatumVanaf gt ${getUTCDate(
      new Date()
    ).toISOString()}`
  }
  return filterRule
}

//#endregion LOCAL FUNCTIONS
