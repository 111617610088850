import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Grid2 as Grid } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import {
  ActionButton,
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { ADD_PATH, GRID_MARGIN_TOP } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IAanvoerderclusterView } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { CLUSTERS_CACHE_KEY, FEATURE_ROOT_PATH } from '../constants'
import { calcMarginRight, getStaticClustersColumns } from '../lib'
import { deleteClusterAsync } from '../services'
import { useSupplierClustersStore, useSupplierClusterStore } from '../stores'

type ClustersGridProps = {
  loading: boolean
}

export const ClustersGrid: FC<ClustersGridProps> = ({ loading = false }) => {
  const translationsKey = 'supplierCluster.deleteDialog'
  const marginRight = calcMarginRight()
  const history = useHistory()
  const { apiClient } = useApiClient()
  const { i18n, t } = useTranslation()
  const {
    clusters: rows,
    removeCluster,
    setMustRefetchClusters,
  } = useSupplierClustersStore()
  const { cluster, setCluster } = useSupplierClusterStore()
  const [open, setOpen] = useState<boolean>(false)

  const getRowId = useCallback(
    (row: IAanvoerderclusterView) => row.aanvoerderclusterID,
    []
  )

  const openDeleteDialog = useCallback(
    (row: IAanvoerderclusterView) => {
      setCluster(row)
      setOpen(true)
    },
    [setCluster, setOpen]
  )

  const closeDeleteDialog = useCallback(() => {
    setCluster(undefined)
    setOpen(false)
  }, [setCluster, setOpen])

  const handleConfirm = useCallback(async () => {
    if (!apiClient) {
      Promise.resolve()
    }

    try {
      closeDeleteDialog()
      await deleteClusterAsync(apiClient, cluster.aanvoerderclusterID)
      snackbarUtils.success(t(`${translationsKey}.deleteSuccessMessage`))
      removeCluster(cluster.aanvoerderclusterID)
    } catch (error: any) {
      snackbarUtils.error(String(error))
      setMustRefetchClusters(true)
    }
  }, [
    apiClient,
    closeDeleteDialog,
    cluster?.aanvoerderclusterID,
    removeCluster,
    setMustRefetchClusters,
    t,
  ])

  const handleAddClick = useCallback(() => {
    history.push(`/dashboard/${FEATURE_ROOT_PATH}/${ADD_PATH}`)
  }, [history])

  const handleEditClick = useCallback(
    (row: IAanvoerderclusterView) => (): void =>
      row.aanvoerderclusterNummer &&
      history.push(
        `/dashboard/${FEATURE_ROOT_PATH}/${row.aanvoerderclusterNummer}`
      ),
    [history]
  )

  const handleDeleteClick = useCallback(
    (row: IAanvoerderclusterView) => (): void => {
      openDeleteDialog(row)
    },
    [openDeleteDialog]
  )

  const columns = useMemo((): GridColDef[] => {
    const columnsSet = getStaticClustersColumns()
    // EDIT icon
    columnsSet.push({
      field: 'edit',
      disableColumnMenu: true,
      type: 'actions',
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <EditIcon
          sx={{
            color: theme.rfhColors.grey[200],
            width: '20px',
            height: '20px',
          }}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditIcon
          onClick={handleEditClick(params.row)}
          sx={{
            cursor: 'pointer',
            color: theme.rfhColors.grey,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      flex: 1,
    })
    // DELETE icon
    columnsSet.push({
      field: 'delete',
      disableColumnMenu: true,
      type: 'actions',
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <DeleteIcon
          sx={{
            color: theme.rfhColors.grey[200],
            width: '20px',
            height: '20px',
          }}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <DeleteIcon
          onClick={handleDeleteClick(params.row)}
          sx={{
            cursor: 'pointer',
            color: theme.rfhColors.grey,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      flex: 1,
    })

    return columnsSet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleEditClick, handleDeleteClick, i18n.language])

  return (
    <Grid container marginTop={GRID_MARGIN_TOP}>
      <Grid size={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          <ActionButton onClick={handleAddClick}>
            {t('common.add')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
      <Grid size={12}>
        <CustomDataGrid
          cacheKey={CLUSTERS_CACHE_KEY}
          language={i18n.language}
          getRowId={getRowId}
          columns={columns}
          rows={rows}
          gridProps={{
            loading,
            onRowClick: (params: GridRowParams) =>
              handleEditClick(params.row)(),
          }}
          pagination={false}
          paginationMode={'client'}
          sortingMode={'client'}
        />
      </Grid>
      <Grid size={12}>
        <ActionModal
          onCancel={closeDeleteDialog}
          onConfirm={handleConfirm}
          open={open}
          translationsKey={translationsKey}
        />
      </Grid>
    </Grid>
  )
}
