import { useMemo } from 'react'

import { usePathname } from 'src/common/hooks/usePathname'
import { IVeilgroepIndelingRegelView } from 'src/common/services/client'

import { FEATURE_ROOT_PATH } from '../constants'
import { useAllocationStore } from '../stores'

type UseAllocationReturn = {
  isDataDirty: boolean
}

export default function useAllocation(): UseAllocationReturn {
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const { allocationRules, oldAllocationRules } = useAllocationStore()

  function reduceRules(rules: IVeilgroepIndelingRegelView[]): string {
    const result = rules.reduce(
      (json, rule) => json.concat(JSON.stringify(rule)),
      ''
    )
    return result
  }

  const isDataDirty = useMemo(() => {
    //! upsertMode: 'ADD'
    if (ADDING) {
      return allocationRules.length > 0
    }
    //! upsertMode: 'EDIT'
    return reduceRules(allocationRules) !== reduceRules(oldAllocationRules)
  }, [ADDING, allocationRules, oldAllocationRules])

  return {
    isDataDirty,
  }
}
