import { IApiClientGetFilter } from 'src/common/interfaces'
import { IClient, Kenmerk } from 'src/common/services/client'
import { constructFilterRuleForKVP } from 'src/common/utils/FilterFunctions'

export const getAllCharacteristics = async (
  apiClient: IClient
): Promise<Kenmerk[]> => {
  let skip = 0
  const characteristics: Kenmerk[] = []
  let finish = false

  while (!finish) {
    const result = (await apiClient.kenmerken_Kenmerk_ListKenmerk(
      undefined,
      skip,
      undefined,
      undefined,
      true,
      undefined,
      undefined,
      undefined
    )) as any

    if (result?.['@odata.count'] === 0 || !result?.cleanedResponse?.value) break
    characteristics.push(...result.cleanedResponse.value)
    skip = characteristics.length
    finish = result?.['@odata.count'] === characteristics.length
  }

  return characteristics
}

export const getByCharacteristicsCode = async (
  apiClient: IClient,
  { key }: IApiClientGetFilter
): Promise<Kenmerk[]> => {
  const filter = constructFilterRuleForKVP('kenmerkCode', Number(key))
  const result = (await apiClient.kenmerken_Kenmerk_ListKenmerk(
    undefined,
    undefined,
    undefined,
    filter,
    false,
    undefined,
    undefined,
    undefined
  )) as any

  return result.cleanedResponse.value ?? []
}
