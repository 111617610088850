import { useMemo } from 'react'

import { keyframes } from '@emotion/react'
import { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import theme from '@rfh-core/theme'

import { WSReadyState } from 'src/common/enums'

type ConnectivityIndicatorProps = {
  status?: WSReadyState
  sx?: SxProps
}

export default function ConnectivityIndicator({
  status,
  sx,
}: Readonly<ConnectivityIndicatorProps>): JSX.Element {
  const backgroundColor = useMemo(() => {
    if (status === WSReadyState.OPEN) {
      return theme.rfhColors.hydroGreen
    }
    if (status === WSReadyState.CLOSED) {
      return theme.rfhColors.alertRed
    }
    if (status === WSReadyState.CONNECTING) {
      return theme.rfhColors.dutchOrange
    }
    return theme.rfhColors.grey[200]
  }, [status])

  const switchValue = 33

  const colorChange = keyframes`
    0%, ${switchValue}% { background-color: ${theme.rfhColors.grey[200]}; }
    ${switchValue}%, 50% { background-color: ${backgroundColor} }
    50%, ${100 - switchValue}% { background-color: ${backgroundColor} }
    ${100 - switchValue}%, 100% { background-color: ${
    theme.rfhColors.grey[200]
  }; }
  `

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '24px',
        aspectRatio: '1 / 1',
        animation: `${colorChange} 2.5s ease-out infinite`,
        borderRadius: '50%',
        ...sx,
      }}
    />
  )
}
