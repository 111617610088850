import { IApiClientGetFilter } from '../interfaces'
import { IClient, IVeilgroepRegel } from './client'

export async function getAuctionGroupRulesAsync(
  client: IClient
): Promise<Partial<IVeilgroepRegel>[]> {
  const result = (await client.veilgroepRegels_ZoekVeilgroepRegels()) as any

  const records: Partial<IVeilgroepRegel>[] = result?.cleanedResponse?.value
  return records
}

export async function getAuctionGroupRuleAsync(
  client: IClient,
  apiClientFilter: Partial<IApiClientGetFilter>
): Promise<Partial<IVeilgroepRegel>> {
  const result = (await client.veilgroepRegels_VeilgroepRegel_GetVeilgroepRegel(
    apiClientFilter.key ?? 0,
    undefined,
    undefined
  )) as any

  const cleanedResult: Partial<IVeilgroepRegel> = result?.cleanedResponse

  return {
    veilgroepRegelId: cleanedResult.veilgroepRegelId,
    veilgroepRegelOmschrijving: cleanedResult.veilgroepRegelOmschrijving,
  } as Partial<IVeilgroepRegel>
}
