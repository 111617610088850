import { GridColDef } from '@mui/x-data-grid-premium'

import { SearchStatsFilterType } from 'src/common/hooks'
import i18n from 'src/i18n'

export const initialFilter: SearchStatsFilterType = {
  auctionDate: new Date(), // today
  locationCode: 1, // Aalsmeer
}

export const getStaticColumns = (): GridColDef[] => [
  {
    field: 'description',
    headerName: i18n.t('batchesStatus.description'),
    headerAlign: 'left',
    align: 'left',
    flex: 1.5,
  },
  {
    field: 'numberSourceReinspection',
    headerName: i18n.t('batchesStatus.source.reinspection'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'numberSourceEab',
    headerName: i18n.t('batchesStatus.source.eab'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'numberSourceManual',
    headerName: i18n.t('batchesStatus.source.manual'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'numberSourceQrCode',
    headerName: i18n.t('batchesStatus.source.qrCode'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'numberSourceArabicum',
    headerName: i18n.t('batchesStatus.source.arabicum'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'numberKvvOffered',
    headerName: i18n.t('batchesStatus.kvvOffered'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
  {
    field: 'numberKvvTransactions',
    headerName: i18n.t('batchesStatus.kvvTransactions'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
  },
]
