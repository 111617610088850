import { useEffect, useState } from 'react'

import { Box, Container, Typography } from '@mui/material'
import theme from '@rfh-core/theme'

import { ConnectivityIndicator } from 'src/common/components'
import { usePathname, useWebSocket } from 'src/common/hooks'

import type { LogisticsDashboardRowData } from './lib'
import { FEATURE_ROOT_PATH } from './lib'
import LogisticsDashboardGrid from './LogisticsDashboardGrid'
import rows from './mocks/data.json'

type Rows = LogisticsDashboardRowData[]

export default function LogisticsDashboard(): JSX.Element {
  const { urlParam } = usePathname(FEATURE_ROOT_PATH)
  const location = Number(urlParam?.[0] ?? 0)
  const [logisticData, setLogisticData] = useState<Rows>(rows as Rows)

  const { wsData, connectionStatus } = useWebSocket<Rows>('dashboard')

  useEffect(() => {
    if (wsData) {
      setLogisticData(wsData)
    }
  }, [wsData])

  return (
    <Container maxWidth={false}>
      <Box position={'relative'}>
        <Box textAlign='center' pt={0} mt={0}>
          <Typography
            aria-label={'Logistics Dashboard'}
            variant={'h2'}
            sx={{ color: theme.rfhColors.leafGreen, mb: '0.5em', mt: 0, p: 0 }}
          >
            {'Logistiek Dashboard'}
          </Typography>
        </Box>
        <LogisticsDashboardGrid rows={logisticData} location={location} />
        <ConnectivityIndicator
          status={connectionStatus}
          sx={{ top: '1.5em', right: '1.6em' }}
        />
      </Box>
    </Container>
  )
}
