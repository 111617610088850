/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
function loadDevTools(callbackToRun: () => void) {
  const shouldDevToolsBeEnabled = window.sessionStorage.getItem('dev-tools')
  // this allows you to explicitly disable it in development for example
  const explicitlyDisabled = shouldDevToolsBeEnabled === 'false'
  const explicitlyEnabled = shouldDevToolsBeEnabled === 'true'

  // we want it enabled by default everywhere but production and we also want
  // to support the dev tools in production (to make us more productive triaging production issues).
  // you can enable the DevTools via sessionStorage or the query string.
  if (
    (process.env.NODE_ENV !== 'test' && !explicitlyDisabled) ||
    explicitlyEnabled
  ) {
    // use a dynamic import so the dev-tools code isn't bundled with the regular
    // app code so we don't worry about bundle size.
    console.log('App DevTools enabled')
    import('./dev-tools.tsx')
      .then(devTools => devTools.install())
      .finally(callbackToRun)
  } else {
    // if we don't need the DevTools, call the callback immediately.
    callbackToRun()
  }
}

export default loadDevTools
