/* eslint-disable max-len */

/* eslint-disable id-blacklist */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'
import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { Divider } from 'src/common/components'
import { DEFAULT_PAGE_SIZE } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { getSupplierProductGroupRegularityViews } from 'src/common/services'
import {
  IAanvoerderProductgroepRegelmatigView,
  IClient,
} from 'src/common/services/client'
import { getOrderBy, snackbarUtils } from 'src/common/utils'
import {
  getDefaultPagination,
  getDefaultSortModel,
} from 'src/common/utils/DataGridFunctions'

import { OverviewsFilter, ProductGroupRegularityGrid } from '../components'

export default function ProductGroupRegularity(): JSX.Element {
  /*
  State
  */
  const defaultSortModel: GridSortModel = useMemo(
    () => [
      {
        sort: 'asc',
        field: 'aanvoerderNummer',
      },
      {
        sort: 'asc',
        field: 'productgroepCode',
      },
      {
        sort: 'asc',
        field: 'vestigingCode',
      },
    ],
    []
  )
  const CACHE_KEY = 'product-group-regularity-datagrid'
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [filterString, setFilterString] = useState<string>('')
  const [totalCount, setTotalCount] = useState<number>(0)
  const [cards, setCards] = useState<IAanvoerderProductgroepRegelmatigView[]>(
    []
  )
  const [paginationState, setPaginationState] =
    useState<GridPaginationInitialState>(() => getDefaultPagination(CACHE_KEY))
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getDefaultSortModel(CACHE_KEY, defaultSortModel)
  )
  const skip =
    paginationState?.paginationModel?.page &&
    paginationState?.paginationModel?.pageSize
      ? paginationState.paginationModel.page *
        paginationState.paginationModel.pageSize
      : 0
  const orderBy =
    sortModel.length > 0 ? getOrderBy(sortModel) : getOrderBy(defaultSortModel)
  const top = paginationState?.paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE
  /*
  Hooks
  */
  const { t } = useTranslation()
  const user = useUser()
  const { apiClient } = useApiClient()

  /*
  Methods
  */
  const changeSortModel = (value: GridSortModel) => {
    setSortModel(value?.length > 0 ? value : defaultSortModel)
  }

  const updateFilterString = useCallback(
    (value: string) => {
      setPaginationState({
        ...getDefaultPagination(CACHE_KEY),
        paginationModel: { page: 0 },
      })
      setFilterString(value)
    },
    [setFilterString, setPaginationState]
  )

  const fetchData = useCallback(
    async (client: IClient): Promise<void> => {
      if (!client || inProgress) {
        return Promise.resolve()
      }

      try {
        setInProgress(true)
        const filter =
          filterString && filterString.length > 0 ? filterString : undefined

        const { count, records } = await getSupplierProductGroupRegularityViews(
          client,
          {
            top,
            skip,
            filter,
            count: true,
            orderby: orderBy,
          }
        )

        setCards(records)
        setTotalCount(count)
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setInProgress(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filterString, inProgress]
  )
  const resetSearch = useCallback(() => {
    setFilterString('')
    setPaginationState({
      ...getDefaultPagination(CACHE_KEY),
      paginationModel: { page: 0 },
    })
    setSortModel(defaultSortModel)
  }, [defaultSortModel])

  /*
  Side Effects
  */
  useEffect(() => {
    setPaginationState(getDefaultPagination(CACHE_KEY))
  }, [])

  useEffect(() => {
    if (sortModel.length === 0) {
      return
    }
    fetchData(apiClient)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, filterString, paginationState, sortModel])

  /*
  Render
  */
  return (
    <>
      <Grid size={12} mb={2}>
        <Typography variant={'h2'}>
          {user.isAuctionCoordinator
            ? t('common.titles.overviewsProductGroupRegularity')
            : t('common.titles.overviewsProductGroupRegularityReadOnly')}
        </Typography>
      </Grid>
      <Grid size={{ sm: 12 }}>
        <OverviewsFilter
          changeFilterString={updateFilterString}
          resetSearch={resetSearch}
        />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={{ sm: 12 }} mt={4}>
        <ProductGroupRegularityGrid
          cacheKey={CACHE_KEY}
          rows={cards}
          isLoading={inProgress}
          paginationStateChange={setPaginationState}
          sortModelChange={changeSortModel}
          rowCount={totalCount ?? 0}
        />
      </Grid>
    </>
  )
}
