import { formLabelClasses, inputBaseClasses, type SxProps } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import theme from '@rfh-core/theme'

import { ELEMENT_HEIGHT } from 'src/common/constants'

import './CustomDatepicker.css'

type CustomDatePickerProps = {
  label?: string
  value?: Date
  onChange: (date: Date) => void
  shouldDisableDate?: (newValue: Date) => boolean
  disablePast?: boolean
  sx?: SxProps
}

export default function CustomDatePicker({
  label = undefined,
  value,
  onChange,
  shouldDisableDate,
  disablePast,
  sx,
}: Readonly<CustomDatePickerProps>): JSX.Element {
  return (
    <DatePicker
      disablePast={disablePast}
      label={label}
      onChange={onChange}
      shouldDisableDate={shouldDisableDate}
      value={value}
      slotProps={{
        textField: {
          sx: {
            ...sx,
            mt: '0.05rem',
            [`label.${formLabelClasses.root}`]: {
              fontFamily: 'halyard-text',
            },
            [`.${inputBaseClasses.root}`]: {
              bgcolor: theme.rfhColors.white,
              border: `1px solid ${theme.rfhColors.grey[200]}`,
              pl: '0.5rem',
              pr: '0.75rem',
              mt: '0.1rem',
              '& fieldset': { border: 'none' },
              maxHeight: ELEMENT_HEIGHT,
            },
          },
        },
      }}
    />
  )
}
