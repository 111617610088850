/* eslint-disable max-len */
import { IApiClientListFilter } from 'src/common/interfaces'
import {
  AanvoerderPromotieKenmerk,
  IAanvoerderPromotieKenmerk,
  IClient,
} from 'src/common/services/client'
import {
  IClientExtra,
  PromotieKenmerkBodyData,
} from 'src/common/services/clientExtra'
import { ListAndCount } from 'src/common/types'

export const getAllSupplierPromotionCharacteristicsAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<ListAndCount<IAanvoerderPromotieKenmerk>> => {
  const result =
    (await apiClient.aanvoerderPromotieKenmerk_AanvoerderPromotieKenmerk_ListAanvoerderPromotieKenmerk(
      apiClientFilter.top,
      apiClientFilter.skip,
      apiClientFilter.search,
      apiClientFilter.filter,
      apiClientFilter.count,
      apiClientFilter.orderby,
      apiClientFilter.select,
      apiClientFilter.expand
    )) as any
  const records: IAanvoerderPromotieKenmerk[] =
    result?.cleanedResponse?.value ?? []
  const count = result?.['@odata.count'] ?? 0
  return { records, count }
}

export const getSupplierPromotionCharacteristicAsync = async (
  apiClient: IClient,
  id: number
): Promise<AanvoerderPromotieKenmerk> => {
  const result =
    await apiClient.aanvoerderPromotieKenmerk_AanvoerderPromotieKenmerk_GetAanvoerderPromotieKenmerk(
      id,
      undefined,
      undefined
    )
  return result
}

export const deleteCharacteristic = async (
  apiClient: IClient,
  idToBeDeleted: number
): Promise<void> => {
  // eslint-disable-next-line max-len
  await apiClient.aanvoerderPromotieKenmerk_AanvoerderPromotieKenmerk_DeleteAanvoerderPromotieKenmerk(
    idToBeDeleted,
    '*'
  )
}

export const addCharacteristic = async (
  apiClient: IClientExtra,
  body: IAanvoerderPromotieKenmerk
): Promise<IAanvoerderPromotieKenmerk> =>
  await apiClient.addPromotieKenmerk('1', {
    aanvoerderPromotieKenmerk: body,
  } as PromotieKenmerkBodyData)

export const updateCharacteristic = async (
  apiClient: IClientExtra,
  body: IAanvoerderPromotieKenmerk
): Promise<void> => {
  await apiClient.updatePromotieKenmerk('1', {
    aanvoerderPromotieKenmerk: body,
  } as PromotieKenmerkBodyData)
}
