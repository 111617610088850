import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'
import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { Divider } from 'src/common/components'
import { Entity, useFetchMany } from 'src/common/hooks'
import { useUser } from 'src/common/providers/UserProvider'
import {
  getDefaultPagination,
  getDefaultSortModel,
  getOrderBy,
  getSkip,
  getTop,
} from 'src/common/utils'

import { OverviewsFilter, ProductRegularityExceptionsGrid } from '../components'

export default function ProductRegularityExceptions(): JSX.Element {
  const CACHE_KEY = 'product-regularity-exceptions-datagrid'
  const { t } = useTranslation()
  const user = useUser()
  const [filterString, setFilterString] = useState<string | undefined>(
    undefined
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultSortModel: GridSortModel = [
    {
      sort: 'asc',
      field: 'uitzonderingDatumVanaf',
    },
  ]
  const [paginationState, setPaginationState] =
    useState<GridPaginationInitialState>(() => getDefaultPagination(CACHE_KEY))
  const [sortModel, setSortModel] = useState<GridSortModel>(
    getDefaultSortModel(CACHE_KEY, defaultSortModel)
  )
  const changeSortModel = (value: GridSortModel) => {
    setSortModel(value?.length > 0 ? value : defaultSortModel)
  }

  const updateFilterString = useCallback(
    (value: string) => {
      setPaginationState({
        ...getDefaultPagination(CACHE_KEY),
        paginationModel: { page: 0 },
      })
      setFilterString(value ?? undefined)
    },
    [setFilterString, setPaginationState]
  )

  const {
    data: exceptionData,
    isLoading,
    refetch,
  } = useFetchMany<typeof Entity.ProductRegelmatigUitzondering>(
    {
      top: getTop(paginationState),
      skip: getSkip(paginationState),
      filter: filterString,
      count: true,
      orderby: getOrderBy(sortModel),
    },
    Entity.ProductRegelmatigUitzondering
  )

  const resetSearch = useCallback(() => {
    setFilterString(undefined)
    setPaginationState({
      ...getDefaultPagination(CACHE_KEY),
      paginationModel: { page: 0 },
    })
    setSortModel(defaultSortModel)
  }, [defaultSortModel])

  useEffect(() => {
    setPaginationState(getDefaultPagination(CACHE_KEY))
  }, [])

  return (
    <>
      <Grid size={12} mb={2}>
        <Typography variant={'h2'}>
          {user.isAuctionCoordinator
            ? t('common.titles.exceptionsProduct')
            : t('common.titles.exceptionsProductReadOnly')}
        </Typography>
      </Grid>
      <Grid size={{ sm: 12 }}>
        <OverviewsFilter
          changeFilterString={updateFilterString}
          resetSearch={resetSearch}
        />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={{ sm: 12 }} mt={4}>
        <ProductRegularityExceptionsGrid
          cacheKey={CACHE_KEY}
          rows={exceptionData?.records ?? []}
          isLoading={isLoading}
          paginationStateChange={setPaginationState}
          sortModelChange={changeSortModel}
          refresh={refetch}
          rowCount={exceptionData?.count ?? 0}
        />
      </Grid>
    </>
  )
}
