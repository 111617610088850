import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { useStore } from 'zustand'

import {
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
} from 'src/common/components'
import { ThemeConfig } from 'src/common/config'
import { ELEMENT_HEIGHT } from 'src/common/constants'
import { usePathname } from 'src/common/hooks/usePathname'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import {
  Aanvoerdercluster,
  AanvoerderclusterAanvoerder,
} from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH } from '../constants'
import { useSupplierClusters } from '../hooks'
import { byVolgordeNummer, getClusterSeed, shouldBeOrdered } from '../lib'
import {
  addClusterAsync,
  updateClusterAanvoerderAsync,
  updateClusterAsync,
} from '../services'
import { useSupplierClustersStore, useSupplierClusterStore } from '../stores'

export const SuppliersFilter: FC = ({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element => {
  const history = useHistory()
  const { t } = useTranslation()
  const userId = useUser().sub
  const { apiClient } = useApiClient()
  const { ADDING, urlParam } = usePathname(FEATURE_ROOT_PATH)
  const {
    isDataDirty,
    isClusterDescriptionInUse,
    isClusterNumberInUse,
    getClusterByClusterNumber,
  } = useSupplierClusters()
  const setMustRefetchClusters = useStore(
    useSupplierClustersStore,
    state => state.setMustRefetchClusters
  )
  const {
    cluster,
    clusterAanvoerders,
    ordered,
    orderedChanged,
    renumber,
    setCluster,
    setClusterAanvoerders,
    setMustRefetchCluster,
    setOldCluster,
    setOrdered,
    setOrderedChanged,
    updateCluster,
  } = useSupplierClusterStore()
  const [clusterNumber, setClusterNumber] = useState<number>(
    cluster?.aanvoerderclusterNummer ?? 0
  )

  const clusterFound = useMemo(
    () => getClusterByClusterNumber(urlParam[0]),
    [getClusterByClusterNumber, urlParam]
  )

  useEffect(() => {
    if (ADDING) {
      return
    }

    if (!clusterFound || clusterFound.aanvoerderclusterNummer === 0) {
      return
    }
    const aanvoerders = clusterFound?.aanvoerderclusterAanvoerders || []
    setClusterNumber(clusterFound.aanvoerderclusterNummer)
    if (shouldBeOrdered(aanvoerders)) {
      aanvoerders.sort(byVolgordeNummer)
    }
    setClusterAanvoerders(aanvoerders)
    setCluster(clusterFound)
    setOldCluster(clusterFound)
    setOrdered(shouldBeOrdered(aanvoerders))
  }, [
    ADDING,
    clusterFound,
    setCluster,
    setClusterAanvoerders,
    setOldCluster,
    setOrdered,
  ])

  const handleGoBack = useCallback(() => {
    history.push(`/dashboard/${FEATURE_ROOT_PATH}`)
  }, [history])

  const changeClusterNumber = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setClusterNumber(+event.target.value)
  }

  const submitClusterNumber = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newNumber = Number(event.target.value)
    if (ADDING && isClusterNumberInUse(newNumber)) {
      snackbarUtils.error(t('supplierCluster.numberInUse'))
      return
    }
    updateCluster({
      aanvoerderclusterNummer: newNumber,
    })
  }

  const submitClusterDescription = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    updateCluster({
      aanvoerderclusterOmschrijving: event.target.value,
    })
  }

  const toggleCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setOrderedChanged(true)
      setOrdered(checked)
      renumber(checked)
    },
    [renumber, setOrdered, setOrderedChanged]
  )

  const shouldSaveBeDisabled = useCallback(() => {
    if (ADDING) {
      return (
        !cluster?.aanvoerderclusterNummer ||
        cluster.aanvoerderclusterNummer === 0 ||
        isClusterNumberInUse(cluster.aanvoerderclusterNummer) ||
        !cluster?.aanvoerderclusterOmschrijving ||
        isClusterDescriptionInUse(cluster.aanvoerderclusterOmschrijving)
      )
    } else {
      return (
        !(isDataDirty || orderedChanged) ||
        !cluster?.aanvoerderclusterOmschrijving ||
        isClusterDescriptionInUse(cluster.aanvoerderclusterOmschrijving)
      )
    }
  }, [
    ADDING,
    cluster,
    isClusterDescriptionInUse,
    isDataDirty,
    isClusterNumberInUse,
    orderedChanged,
  ])

  const handleSaveCluster = useCallback(async () => {
    if (!apiClient) {
      return Promise.resolve()
    }

    if (!cluster) {
      snackbarUtils.error(t('supplierCluster.noCluster'))
      return Promise.resolve()
    }

    const clusterSeed = getClusterSeed(cluster, userId)
    const clusterEntity = new Aanvoerdercluster(clusterSeed)

    try {
      if (ADDING) {
        const addedAanvoerderCluster = await addClusterAsync(
          apiClient,
          clusterEntity
        )
        history.replace(
          `/dashboard/${FEATURE_ROOT_PATH}/${addedAanvoerderCluster.aanvoerderclusterID}`
        )
        setCluster(addedAanvoerderCluster)
        setOldCluster(addedAanvoerderCluster)
      } else {
        // clusterEntity.aanvoerderclusterAanvoerders = [...clusterAanvoerders]
        await updateClusterAsync(apiClient, clusterEntity)
        setCluster(clusterEntity)
        setOldCluster(clusterEntity)
        if (ordered && clusterAanvoerders?.length > 0) {
          const promises = clusterAanvoerders.map(ca =>
            updateClusterAanvoerderAsync(
              apiClient,
              new AanvoerderclusterAanvoerder({
                ...ca,
                aanmaakDatumTijd: new Date(ca.aanmaakDatumTijd),
                mutatieGebruiker: userId,
                mutatieDatumTijd: new Date(),
              })
            )
          )
          await Promise.all(promises)
          setOrderedChanged(false)
        } else {
          snackbarUtils.success(t('common.statusCodes.success'))
        }
      }
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setMustRefetchClusters(true)
      setMustRefetchCluster(true)
    }
  }, [
    ADDING,
    apiClient,
    cluster,
    clusterAanvoerders,
    history,
    ordered,
    setCluster,
    setMustRefetchCluster,
    setMustRefetchClusters,
    setOldCluster,
    setOrderedChanged,
    t,
    userId,
  ])

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography
          variant='h2'
          sx={{
            fontSize: '35px',
            marginTop: `${ThemeConfig.spacing.sm * 8}px`,
            marginBottom: `${ThemeConfig.spacing.sm * 8}px`,
          }}
        >
          {ADDING
            ? t('common.titles.supplierClusterAdd')
            : t('common.titles.supplierCluster')}
        </Typography>
      </Box>
      <FilterStack {...rest}>
        <FilterFieldsStack>
          <FormControl>
            <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
              {t('supplierCluster.clusterNumber')}
            </Typography>
            <TextField
              aria-label={'supplier cluster number'}
              disabled={!ADDING}
              error={isClusterNumberInUse(clusterNumber)}
              name={t('supplierCluster.clusterNumber')}
              onChange={changeClusterNumber}
              onBlur={submitClusterNumber}
              type={'number'}
              value={clusterNumber}
              sx={{
                mt: 1.5,
                bgcolor: theme.rfhColors.white,
                height: ELEMENT_HEIGHT,
                width: 140,
                maxWidth: 140,
                '.MuiInputBase-root': { paddingX: 1 },
              }}
            />
          </FormControl>
          <FormControl>
            <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
              {t('common.description')}
            </Typography>
            <TextField
              aria-label={'supplier cluster description'}
              name={t('common.description')}
              onChange={submitClusterDescription}
              onBlur={submitClusterDescription}
              required
              value={cluster?.aanvoerderclusterOmschrijving ?? ''}
              sx={{
                mt: 1.5,
                bgcolor: theme.rfhColors.white,
                height: ELEMENT_HEIGHT,
                width: 240,
                maxWidth: 240,
                '.MuiInputBase-root': { paddingLeft: 1, paddingRight: 1 },
              }}
            />
          </FormControl>
          <FormControl sx={{ ml: 0.5 }}>
            <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
              {t('supplierCluster.useSequenceNumbers')}
            </Typography>
            <FormControlLabel
              aria-label={'sequence numbers'}
              control={
                <Checkbox
                  checked={ordered ?? false}
                  onChange={toggleCheckbox}
                  sx={{
                    mt: 0.4,
                  }}
                />
              }
              label={''}
              name={t('supplierCluster.useSequenceNumbers')}
            />
          </FormControl>
        </FilterFieldsStack>
        <FilterButtonsStack>
          <Button
            variant='outlined'
            type='submit'
            sx={{
              height: ELEMENT_HEIGHT,
              width: ThemeConfig.spacing.xl * 16,
            }}
            onClick={handleGoBack}
          >
            {t('common.back')}
          </Button>
          <Button
            variant='contained'
            type='submit'
            disabled={shouldSaveBeDisabled()}
            sx={{
              height: ELEMENT_HEIGHT,
              width: ThemeConfig.spacing.xl * 16,
              marginRight: ThemeConfig.spacing.xs * 0.6,
            }}
            onClick={handleSaveCluster}
          >
            {ADDING ? t('common.create') : t('common.save')}
          </Button>
        </FilterButtonsStack>
      </FilterStack>
    </>
  )
}
