import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { FormControl, FormLabel, Grid2 as Grid, TextField } from '@mui/material'
import theme from '@rfh-core/theme'

import { getStringValue } from 'src/common/lib'

type LabelInputIconTextProps = {
  disabled?: boolean
  fieldName?: string
  inputValue?: string | number
  label?: string
  textValue?: string
  onChange?: (newInput: string) => void
  children?: ReactNode
}

export default memo(function LabelInputIconText({
  disabled = false,
  fieldName = '',
  inputValue = '',
  label = '',
  textValue = '',
  onChange,
  children,
}: Readonly<LabelInputIconTextProps>): JSX.Element {
  const [textInput, setTextInput] = useState<string>(getStringValue(inputValue))

  const changeTextInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.preventDefault()
      setTextInput(event.target.value)
    },
    []
  )

  const submitInputByLostFocus = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      onChange(event.target?.value ?? '')
    },
    [onChange]
  )

  const submitKeyboardInput = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onChange(textInput)
      }
    },
    [onChange, textInput]
  )

  useEffect(() => {
    setTextInput(getStringValue(inputValue))
  }, [inputValue])

  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Grid>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: theme.rfhColors.black,
              whiteSpace: 'nowrap',
              minWidth: 170,
              fontSize: '1rem',
              fontWeight: 100,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid>
        <FormControl fullWidth>
          <TextField
            id={`id-${fieldName}`}
            name={fieldName}
            value={textInput}
            disabled={disabled}
            onBlur={submitInputByLostFocus}
            onKeyUp={submitKeyboardInput}
            onChange={changeTextInput}
            sx={{ flex: 1, maxWidth: 160 }}
          />
        </FormControl>
      </Grid>
      <Grid>
        {/* dialog + trigger button */}
        {children}
      </Grid>
      <Grid size={4}>{textValue?.trim()}</Grid>
    </Grid>
  )
})
