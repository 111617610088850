import { useCallback, useEffect, useMemo, useState } from 'react'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import i18n, { SupportedLanguage } from 'src/i18n'

import { getLanguage, setLanguage } from '../services'
import type { ChildrenType } from './types'

import deLocale from 'date-fns/locale/de'
import enLocale from 'date-fns/locale/en-GB'
import nlLocale from 'date-fns/locale/nl'

export const LocalizationProvider = ({
  children,
}: ChildrenType): JSX.Element => {
  const localeMap = {
    de: deLocale,
    en: enLocale,
    nl: nlLocale,
  }
  const [locale, setLocale] = useState<SupportedLanguage>(
    () => getLanguage().slice(0, 2) as SupportedLanguage
  )
  const [localeInitialized, setLocaleInitialized] = useState<boolean>(false)

  const selectLocale = useCallback(
    (newLocale: SupportedLanguage) => {
      if (localeInitialized && newLocale !== locale) {
        setLocale(newLocale)
        setLanguage(newLocale)
      }
      i18n.changeLanguage(newLocale)
    },
    [locale, localeInitialized, setLocale]
  )

  useEffect(() => {
    const newLocale = !localeInitialized
      ? locale
      : (i18n.language.slice(0, 2) as SupportedLanguage)
    selectLocale(newLocale)
    if (!localeInitialized) setLocaleInitialized(true)
  }, [locale, localeInitialized, selectLocale])

  const memoized = useMemo(() => ({ locale }), [locale])

  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={localeMap[memoized.locale]}
    >
      {children}
    </MuiLocalizationProvider>
  )
}
