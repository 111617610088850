import {
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { DEFAULT_PAGE_SIZE } from '../constants'

/**
 * Formats MUI DataGrid GridSortModel to an correctly formatted string for OData to understand
 * @param sortingModel The GridSortModel that needs to be converted
 */
export const getOrderBy = (sortingModel: GridSortModel): string[] => {
  let orderBy = ''
  // Add for each item the string 'fieldName asc|desc' if it is not the last one add an ',' for the next row
  sortingModel?.forEach((item, index) => {
    orderBy += `${item.field} ${item.sort}${
      sortingModel.length - 1 !== index ? ', ' : ''
    }`
  })

  return [orderBy]
}

export const getTop = (input: GridPaginationInitialState): number =>
  input?.paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE

export const getSkip = (input: GridPaginationInitialState): number => {
  const page = input?.paginationModel?.page ?? 0
  const pageSize = input?.paginationModel?.pageSize ?? DEFAULT_PAGE_SIZE
  return page * pageSize
}
