import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { VestigingCode } from 'src/common/enums'
import { SearchStatsFilterType } from 'src/common/hooks'
import { ILocationClock } from 'src/common/interfaces'
import { getLocalItem, setLocalItem } from 'src/common/utils'

import { initialFilter } from '../constants'

export type StoreState = {
  filter: SearchStatsFilterType
  clockPool: ILocationClock[]
  locationConcernClocks: ILocationClock[]
  locationLocalClocks: ILocationClock[]
  locationTestClocks: ILocationClock[]
}

type Actions = {
  /* store */
  resetCountsStoreState: () => void
  /* counts */
  setFilter: (delta: Partial<SearchStatsFilterType>) => void
  setClockPool: (clocks: ILocationClock[]) => void
  setLocationConcernClocks: (clocks: ILocationClock[]) => void
  setLocationLocalClocks: (clocks: ILocationClock[]) => void
  setLocationTestClocks: (clocks: ILocationClock[]) => void
  /* computables */
  getLocationClocks: () => ILocationClock[]
}

const storeName = 'AuctionGroupCountsFilterStore'
const CACHE_KEY = 'auction-group-counts-filter-storage'

const initialStoreState: StoreState = {
  filter: {
    ...initialFilter,
    locationCode:
      getLocalItem<{ locationCode: VestigingCode }>(CACHE_KEY)?.locationCode ??
      initialFilter.locationCode,
  } as SearchStatsFilterType,
  clockPool: [] as ILocationClock[],
  locationConcernClocks: [] as ILocationClock[],
  locationLocalClocks: [] as ILocationClock[],
  locationTestClocks: [] as ILocationClock[],
}

const useFilterStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetCountsStoreState: () =>
          set(
            () => {
              get().setFilter(initialFilter)
              return initialStoreState
            },
            false, // replace or not replace - that is the question!
            'resetCountsStoreState'
          ),
        /* counts */
        setFilter: (delta: Partial<SearchStatsFilterType>) =>
          set(
            (state: StoreState) => {
              if (
                delta.locationCode &&
                delta.locationCode !== state.filter.locationCode
              ) {
                setLocalItem(CACHE_KEY, {
                  locationCode: delta.locationCode,
                })
              }
              const filter = mergePassedPropsToFilter(delta)
              const indexFrom = (
                get().getLocationClocks() as ILocationClock[]
              ).findIndex(lc => lc.clockName === filter.clockNameFrom)
              const indexTo = (
                get().getLocationClocks() as ILocationClock[]
              ).findIndex(lc => lc.clockName === filter.clockNameTo)
              if (indexFrom > indexTo) {
                filter.clockNameTo = filter.clockNameFrom
                filter.clockNumberTo = filter.clockNumberFrom
              }
              return { ...state, filter }
            },
            false,
            'setFilter'
          ),
        setClockPool: (clocks: ILocationClock[]) =>
          set(
            (state: StoreState) => ({ ...state, clockPool: [...clocks] }),
            false,
            'setClockPool'
          ),
        setLocationConcernClocks: (clocks: ILocationClock[]) =>
          set(
            (state: StoreState) => ({
              ...state,
              locationConcernClocks: [...clocks],
            }),
            false,
            'setLocationConcernClocks'
          ),
        setLocationLocalClocks: (clocks: ILocationClock[]) =>
          set(
            (state: StoreState) => ({
              ...state,
              locationLocalClocks: [...clocks],
            }),
            false,
            'setLocationLocalClocks'
          ),
        setLocationTestClocks: (clocks: ILocationClock[]) =>
          set(
            (state: StoreState) => ({
              ...state,
              locationTestClocks: [...clocks],
            }),
            false,
            'setLocationTestClocks'
          ),
        /* computables */
        getLocationClocks: () =>
          [
            ...get().locationLocalClocks,
            ...get().locationConcernClocks,
            ...get().locationTestClocks,
          ] as ILocationClock[],
      }),
      {
        name: CACHE_KEY,
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)

export default useFilterStore

export function mergePassedPropsToFilter(
  passedProps: Partial<SearchStatsFilterType>
): Partial<SearchStatsFilterType> {
  const filter = useFilterStore.getState().filter
  filter.auctionDate = Object.keys(passedProps).includes('auctionDate')
    ? passedProps.auctionDate
    : filter.auctionDate
  filter.clockNameFrom = Object.keys(passedProps).includes('clockNameFrom')
    ? passedProps.clockNameFrom
    : filter.clockNameFrom
  filter.clockNameTo = Object.keys(passedProps).includes('clockNameTo')
    ? passedProps.clockNameTo
    : filter.clockNameTo
  filter.clockNumberFrom = Object.keys(passedProps).includes('clockNumberFrom')
    ? passedProps.clockNumberFrom
    : filter.clockNumberFrom
  filter.clockNumberTo = Object.keys(passedProps).includes('clockNumberTo')
    ? passedProps.clockNumberTo
    : filter.clockNumberTo
  filter.locationCode = Object.keys(passedProps).includes('locationCode')
    ? passedProps.locationCode
    : filter.locationCode
  filter.trolleyType = Object.keys(passedProps).includes('trolleyType')
    ? passedProps.trolleyType
    : filter.trolleyType
  return filter
}
