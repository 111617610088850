import { memo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  paperClasses,
  Typography,
} from '@mui/material'

import { useReferenceData } from '../../hooks'
import { getDiffs } from '../../lib'
import { useRuleStore } from '../../stores'
import { CodeSoort } from '../../types'
import DeferredTable from './DeferredTable'

type MultiSelectDialogProps = {
  codeSoort: CodeSoort
  dialogTitle: string
  isLoading: boolean
  isOpen: boolean
  isTimeoutReached: boolean
  onClose?: () => void
}

export default memo(function MultiSelectDialog({
  codeSoort,
  dialogTitle,
  isLoading,
  isOpen,
  isTimeoutReached,
  onClose,
}: Readonly<MultiSelectDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const {
    getRuleCodesNumber,
    oldRuleCodesToUpdate,
    ruleCodesToUpdate,
    setOldRuleCodesToUpdate,
    setRuleCodesToUpdate,
    upsertRuleCodeToUpdate,
  } = useRuleStore()
  const { mergeSelected } = useReferenceData()

  const updateSelection = useCallback(() => {
    setOldRuleCodesToUpdate(ruleCodesToUpdate)
    mergeSelected(codeSoort) //! recalculate the text
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSoort, mergeSelected, onClose, ruleCodesToUpdate])

  const revertSelection = useCallback(() => {
    //! rollback all diffs
    const ruleCodesToRevert = getDiffs(ruleCodesToUpdate, oldRuleCodesToUpdate)
    ruleCodesToRevert.forEach(rcr => {
      rcr.geselecteerd = !rcr.geselecteerd
      upsertRuleCodeToUpdate(rcr)
    })
    setRuleCodesToUpdate(oldRuleCodesToUpdate)
    mergeSelected(codeSoort) //! recalculate the text
    onClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    codeSoort,
    mergeSelected,
    oldRuleCodesToUpdate,
    onClose,
    ruleCodesToUpdate,
  ])

  const HEADER_MR = getRuleCodesNumber(codeSoort) > 14 ? '2.3rem' : '1.4rem'

  return (
    <>
      {isOpen && !isTimeoutReached && (
        <Dialog
          open={isOpen}
          onClose={onClose}
          sx={{
            [`& .${paperClasses.root}`]: {
              overflowX: 'hidden',
            },
          }}
        >
          <Grid container direction={'column'} ml={'1.5rem'} mr={HEADER_MR}>
            <Typography variant={'h4'} mt={'1rem'}>
              {dialogTitle}
            </Typography>
            <Box
              display={'flex'}
              justifyContent={'flex-end'}
              alignItems={'center'}
              mt={'1rem'}
            >
              <Button variant='contained' onClick={updateSelection}>
                {t('common.select')}
              </Button>
            </Box>
          </Grid>
          <DialogContent
            sx={{
              width: '600px',
              maxWidth: '600px',
              maxHeight: '700px',
              mt: 0,
            }}
          >
            <DeferredTable codeSoort={codeSoort} />
          </DialogContent>
          <DialogActions sx={{ border: 'none', pt: '1rem' }}>
            <Button variant='outlined' onClick={revertSelection}>
              {t('common.back')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {isOpen && isTimeoutReached && isLoading && <></>}
    </>
  )
})
