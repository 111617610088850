import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid2 as Grid, Typography } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid-premium'

import { v4 as getRandomID } from 'uuid'

import { CustomDataGrid } from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { IAanvoerderProductgroepRegelmatigView } from 'src/common/services/client'

import {
  getHistoryGridColumns,
  PRODUCT_GROUP_REGULARITY_HISTORY_CACHE_KEY,
} from '../lib'

type ProductGroupRegularityHistoryGridProps = {
  regularityHistory: IAanvoerderProductgroepRegelmatigView[]
  isLoading: boolean
}

export default function ProductGroupRegularityHistoryGrid({
  regularityHistory,
  isLoading,
  ...rest
}: Readonly<ProductGroupRegularityHistoryGridProps>): JSX.Element {
  const { i18n, t } = useTranslation()

  const columns = useMemo((): GridColDef[] => getHistoryGridColumns(), [])

  return (
    <Grid
      container
      sx={{
        marginLeft: 0,
        marginTop: ThemeConfig.spacing.sm,
        width: '100%',
        '& .MuiGrid-root.MuiGrid-item': {
          flexGrow: 1,
          maxWidth: '100%',
          paddingLeft: 0,
          '& > .MuiBox-root': {
            paddingLeft: ThemeConfig.spacing.s,
          },
        },
      }}
      spacing={2}
    >
      <Grid
        size={12}
        sx={{
          padding: '0 !important',
          my: ThemeConfig.spacing.sm,
        }}
      >
        <Typography variant={'h2'}>
          {t('common.titles.regularityHistory')}
        </Typography>
      </Grid>
      <Box
        position={'relative'}
        marginTop={GRID_MARGIN_TOP}
        width={'100%'}
        {...rest}
      >
        <CustomDataGrid
          cacheKey={PRODUCT_GROUP_REGULARITY_HISTORY_CACHE_KEY}
          columns={columns}
          language={i18n.language}
          rows={regularityHistory}
          getRowId={getRandomID}
          gridProps={{
            loading: isLoading,
          }}
          pagination={false}
          paginationMode={'client'}
          sortingMode={'client'}
        />
      </Box>
    </Grid>
  )
}
