import { useCallback, useMemo } from 'react'

import { usePathname } from 'src/common/hooks/usePathname'
import { IAanvoerderclusterView } from 'src/common/services/client'

import { FEATURE_ROOT_PATH } from '../constants'
import { useSupplierClustersStore, useSupplierClusterStore } from '../stores'

type useSupplierClustersStoreReturn = {
  findAnotherCluster: (aanvoerderNummmer: number) => number
  getClusterByClusterNumber: (clusterNummer: number) => IAanvoerderclusterView
  isClusterDescriptionInUse: (clusterDescription: string) => boolean
  isClusterNumberInUse: (clusterNummer: number) => boolean
  isDataDirty: boolean
}

export const useSupplierClusters = (): useSupplierClustersStoreReturn => {
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const { cluster, oldCluster, orderedChanged } = useSupplierClusterStore(
    (state: any) => ({
      cluster: state.cluster,
      oldCluster: state.oldCluster,
      orderedChanged: state.orderedChanged,
    })
  )

  const { clusters } = useSupplierClustersStore((state: any) => ({
    clusters: state.clusters,
  }))

  const isDataDirty: boolean = useMemo(() => {
    const newOne: IAanvoerderclusterView = { ...cluster }
    const oldOne: IAanvoerderclusterView = { ...oldCluster }
    if (ADDING) {
      // upsertMode: 'ADD'
      return Boolean(cluster?.aanvoerderclusterOmschrijving)
    }
    // upsertMode: 'EDIT'
    return orderedChanged || JSON.stringify(newOne) !== JSON.stringify(oldOne)
  }, [cluster, oldCluster, ADDING, orderedChanged])

  const findAnotherCluster = useCallback(
    (aanvoerderNummer: number): number =>
      clusters.find((c: IAanvoerderclusterView) =>
        c.aanvoerderclusterAanvoerders.some(
          scs => scs.aanvoerderNummer === aanvoerderNummer
        )
      )?.aanvoerderclusterNummer ?? -1,
    [clusters]
  )

  const getClusterByClusterNumber = useCallback(
    (clusterNummer: number): IAanvoerderclusterView =>
      clusters.find(
        (c: IAanvoerderclusterView) =>
          c.aanvoerderclusterNummer === clusterNummer
      ),
    [clusters]
  )

  const isClusterNumberInUse = useCallback(
    (clusterNummer: number): boolean =>
      clusterNummer > 0 &&
      clusters.findIndex(
        (c: IAanvoerderclusterView) =>
          c.aanvoerderclusterNummer === clusterNummer
      ) !== -1,
    [clusters]
  )

  const isClusterDescriptionInUse = useCallback(
    (clusterDescription: string): boolean =>
      clusterDescription &&
      clusters.findIndex(
        (c: IAanvoerderclusterView) =>
          c.aanvoerderclusterOmschrijving === clusterDescription
      ) !== -1,
    [clusters]
  )

  return {
    findAnotherCluster,
    getClusterByClusterNumber,
    isClusterDescriptionInUse,
    isClusterNumberInUse,
    isDataDirty,
  }
}
