import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { VestigingCode } from 'src/common/enums'
import { SearchStatsFilterType } from 'src/common/hooks'
import { getLocalItem, setLocalItem } from 'src/common/utils'

import { initialFilter } from '../constants'

export type StoreState = {
  filter: SearchStatsFilterType
}

type Actions = {
  /* store */
  resetCountsStoreState: () => void
  /* counts */
  setFilter: (delta: Partial<SearchStatsFilterType>) => void
  /* computables */
}

const storeName = 'BatchesStatusFilterStore'
const CACHE_KEY = 'batches-status-filter-storage'

const initialStoreState: StoreState = {
  filter: {
    ...initialFilter,
    locationCode:
      getLocalItem<{ locationCode: VestigingCode }>(CACHE_KEY)?.locationCode ??
      initialFilter.locationCode,
  } as SearchStatsFilterType,
}

const useFilterStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetCountsStoreState: () =>
          set(
            () => {
              get().setFilter(initialFilter)
              return initialStoreState
            },
            false, // replace or not replace - that is the question!
            'resetCountsStoreState'
          ),
        /* counts */
        setFilter: (delta: Partial<SearchStatsFilterType>) =>
          set(
            (state: StoreState) => {
              if (
                delta.locationCode &&
                delta.locationCode !== state.filter.locationCode
              ) {
                setLocalItem(CACHE_KEY, {
                  locationCode: delta.locationCode,
                })
              }
              const filter = mergePassedPropsToFilter(delta)
              return { ...state, filter }
            },
            false,
            'setFilter'
          ),
        /* computables */
      }),
      {
        name: CACHE_KEY,
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)

export default useFilterStore

export function mergePassedPropsToFilter(
  passedProps: Partial<SearchStatsFilterType>
): Partial<SearchStatsFilterType> {
  const filter = useFilterStore.getState().filter
  filter.auctionDate = Object.keys(passedProps).includes('auctionDate')
    ? passedProps.auctionDate
    : filter.auctionDate
  filter.locationCode = Object.keys(passedProps).includes('locationCode')
    ? passedProps.locationCode
    : filter.locationCode
  return filter
}
