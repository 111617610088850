import { GridSortModel } from '@mui/x-data-grid-premium'

export const PRODUCT_GROUP_REGULARITY_HISTORY_CACHE_KEY =
  'product-group-regularity-history-datagrid'
export const defaultSortModel: GridSortModel = [
  {
    sort: 'asc',
    field: 'aanvoerderNummer',
  },
  {
    sort: 'asc',
    field: 'productgroepCode',
  },
  {
    sort: 'asc',
    field: 'vestigingCode',
  },
]
