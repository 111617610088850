import { IConfig } from 'src/common/interfaces'

type RuntimeEnv =
  | 'loc'
  | 'test'
  | 'dev'
  | 'sys'
  | 'acc'
  | 'prd'
  | '#{Omgeving}#'

// These values {#...#} are replaced in the release pipeline
// If they're not replaced then the local test values are used
let envValue: RuntimeEnv = '#{Omgeving}#'
if (envValue.startsWith('#{')) {
  envValue = 'sys'
  if (process.env.NODE_ENV !== 'test') {
    process.env.NODE_ENV = 'development'
  }
}
console.log('Env', envValue)
console.log('NODE_ENV', process.env.NODE_ENV)

disableDevToolsIf(envValue, ['prd'])

let apiHostValue = '#{ApiHost}#'
if (apiHostValue.startsWith('#{')) {
  // apiHostValue = 'https://localhost:44335/odata/v1'
  apiHostValue =
    'https://aax-tc-aanbodbeheerservice-api.commstaging.royalfloraholland.com/odata/v1'
}

let redirectUriValue = '#{OktaRedirectUri}#'
if (redirectUriValue.startsWith('#{')) {
  redirectUriValue = 'http://localhost:3000/callback'
}

let clientIdValue = '#{OktaClientId}#'
if (clientIdValue.startsWith('#{')) {
  clientIdValue = '0oa4aksx7ga62bA5q0x7' //aax-aanbodbeheer-sys
}

let domainValue = '#{OktaDomain}#'
if (domainValue.startsWith('#{')) {
  domainValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

let issuerValue = '#{OktaIssuer}#'
if (issuerValue.startsWith('#{')) {
  issuerValue = 'https://identity-test.royalfloraholland.com/oauth2/default'
}

let webSocketEndpoint = '#{WebSocketEndpoint}'
if (webSocketEndpoint.startsWith('#{')) {
  webSocketEndpoint =
    'wss://5dj21weza8.execute-api.eu-west-1.amazonaws.com/dev/'
}

export const Config: IConfig = {
  env: envValue,
  api: {
    host: apiHostValue,
    path: '',
    apiVersion: '/odata/v1',
  },
  okta: {
    clientId: clientIdValue,
    domain: domainValue,
    issuer: issuerValue,
    redirectUri: redirectUriValue,
    scopes: ['openid', 'profile', 'email', `aax-aanbodbeheer-api-${envValue}`],
    pkce: true,
  },
  webSocket: {
    endpoint: webSocketEndpoint,
  },
}

function disableDevToolsIf(
  currentEnv: RuntimeEnv,
  envsToDisable: RuntimeEnv[]
) {
  const shouldDevToolsBeEnabled = window.sessionStorage.getItem('dev-tools') // 'true' | 'false' | undefined
  const allowedInCurrentEnv = !envsToDisable.includes(currentEnv)
  if (
    process.env.NODE_ENV !== 'test' &&
    (allowedInCurrentEnv || shouldDevToolsBeEnabled === 'true')
  ) {
    console.log('App DevTools enabled')
  } else {
    window.sessionStorage.setItem('dev-tools', 'false')
    console.log('App DevTools disabled')
  }
}
