import { Typography } from '@mui/material'
import Box from '@mui/material/Box'
import theme from '@rfh-core/theme'

import { ELEMENT_HEIGHT } from 'src/common/constants'

type HeaderTextInfoFieldProps = { title: string; text: string }

export default function HeaderTextInfoFieldx({
  title,
  text,
}: Readonly<HeaderTextInfoFieldProps>): JSX.Element {
  return (
    <Box>
      <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
        {title}
      </Typography>
      <Box
        aria-label={title}
        sx={{
          p: 1,
          mt: 1.5,
          height: ELEMENT_HEIGHT,
          minWidth: 120,
          maxWidth: 240,
          bgcolor: theme.rfhColors.white,
        }}
      >
        {text}
      </Box>
    </Box>
  )
}
