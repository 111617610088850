import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import type { IProductclusterView } from 'src/common/services/client'
import { BloemenPlanten } from 'src/common/types'

export type StoreState = {
  bloemenPlanten: BloemenPlanten
  clusters: IProductclusterView[]
  mustRefetchClusters: boolean
}

type Actions = {
  /* store */
  resetProductClustersStoreState: () => void
  /* product clusters */
  addCluster: (c: IProductclusterView) => void
  removeCluster: (id: number) => void
  setBloemenPlanten: (bp: BloemenPlanten) => void
  setClusters: (cs: IProductclusterView[]) => void
  setMustRefetchClusters: (input: boolean) => void
}

const storeName = 'ProductClustersStore'

const initialStoreState: StoreState = {
  bloemenPlanten: 'B',
  clusters: [],
  mustRefetchClusters: false,
}

export const useProductClustersStore = create<StoreState & Actions>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetProductClustersStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetProductClustersStoreState'
          ),
        /* product clusters */
        addCluster: (c: IProductclusterView) =>
          set(
            (state: StoreState) => ({
              clusters: [...state.clusters, c],
            }),
            false,
            'addCluster'
          ),
        removeCluster: (id: number) =>
          set(
            (state: StoreState) => ({
              clusters: [
                ...state.clusters.filter(c => c?.productclusterID !== id),
              ],
            }),
            false,
            'removeCluster'
          ),
        setBloemenPlanten: (bp: BloemenPlanten) =>
          set(() => ({ bloemenPlanten: bp }), false, 'setBloemenPlanten'),
        setClusters: (cs: IProductclusterView[]) =>
          set(() => ({ clusters: [...cs] }), false, 'setClusters'),
        setMustRefetchClusters: (input: boolean) =>
          set(
            () => ({
              mustRefetchClusters: input,
            }),
            false,
            'setMustRefetchClusters'
          ),
      }),
      {
        name: 'product-clusters-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
