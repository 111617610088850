import { ChangeEvent, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl } from '@mui/material'

import {
  CustomDatePicker,
  FilterFieldsStack,
  FilterStack,
  LocationSelect,
} from 'src/common/components'
import { VestigingCode } from 'src/common/enums'

import useFilterStore from '../stores/FilterStore'

export default function BatchesStatusFilter(): JSX.Element {
  const { t } = useTranslation()
  const { filter, setFilter } = useFilterStore()
  const [locationCode, setLocationCode] = useState<VestigingCode>(
    filter.locationCode
  )

  const changeLocation = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const newLocationCode = +event.target.value
      setLocationCode(newLocationCode)
      setFilter({
        locationCode: newLocationCode,
      })
    },
    [setFilter]
  )

  return (
    <FilterStack>
      <FilterFieldsStack>
        <FormControl>
          <CustomDatePicker
            aria-label={'auction date'}
            label={t('common.auctionDate')}
            value={new Date(filter.auctionDate)}
            onChange={newDate => {
              setFilter({ auctionDate: new Date(newDate) })
            }}
            sx={{ maxWidth: 180 }}
          />
        </FormControl>
        <FormControl>
          <LocationSelect
            addCatchAll
            locationCode={locationCode}
            onChange={changeLocation}
          />
        </FormControl>
      </FilterFieldsStack>
    </FilterStack>
  )
}
