import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { Grid2 as Grid } from '@mui/material'
import {
  GridPaginationInitialState,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import {
  ActionButton,
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { getStringValue } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IVeilgroepRegel } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'
import i18n from 'src/i18n'

import { CACHE_KEY, columns, initialState } from '../constants'
import { calcMarginRight } from '../lib'
import { deleteAuctionGroupRuleAsync } from '../services'
import { useAuctionGroupRulesStore, useAuctionGroupRuleStore } from '../stores'

type RulesGridProps = {
  loading?: boolean
  setSortModel: (value: GridSortModel) => void
  setPagination: (value: GridPaginationInitialState) => void
  readonly?: boolean
}

export default function RulesGrid({
  loading = false,
  setSortModel,
  setPagination,
  readonly = true,
}: Readonly<RulesGridProps>): JSX.Element {
  const translationsKey = 'auctionRule.deleteDialog'
  const history = useHistory()
  const { apiClient } = useApiClient()
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)
  const {
    rules,
    count,
    setMustRefetchAuctionGroupRules,
    removeAuctionGroupRule,
  } = useAuctionGroupRulesStore()
  const {
    auctionGroupRule: rule,
    setRule,
    setMustRefetchRule,
  } = useAuctionGroupRuleStore()

  const getRowId = useCallback(
    (row: IVeilgroepRegel) => row.veilgroepRegelId,
    []
  )

  const openDeleteDialog = useCallback(
    (row: IVeilgroepRegel): void => {
      setOpen(true)
      setRule(row)
    },
    [setOpen, setRule]
  )

  const closeDeleteDialog = useCallback((): void => {
    setOpen(false)
    setRule(undefined)
  }, [setOpen, setRule])

  const openEditDialog = useCallback(
    (row: IVeilgroepRegel): void => {
      setMustRefetchRule(true)
      history.push(`/dashboard/auction-group-rules/${row.veilgroepRegelId}`)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history]
  )

  const handleAddAuctionGroupRule = useCallback(
    () => history.push('/dashboard/auction-group-rules/add'),
    [history]
  )

  const handleDelete = useCallback(async () => {
    if (!apiClient) {
      Promise.resolve()
    }

    try {
      closeDeleteDialog()
      removeAuctionGroupRule(rule.veilgroepRegelId)
      await deleteAuctionGroupRuleAsync(apiClient, rule.veilgroepRegelId)
    } catch (error: any) {
      snackbarUtils.error(getStringValue(error))
    } finally {
      setMustRefetchAuctionGroupRules(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, rule?.veilgroepRegelId])

  const generateColumns = useMemo(() => {
    const tempColumns = [...columns]
    if (readonly) return tempColumns

    tempColumns.push(
      {
        field: 'edit',
        headerName: i18n.t('common.edit'),
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: theme.rfhColors.grey[200],
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <EditIcon
            onClick={() => openEditDialog(params.row)}
            sx={{
              color: theme.rfhColors.grey,
              width: '20px',
              height: '20px',
            }}
          />
        ),
      },
      {
        field: 'delete',
        headerName: i18n.t('common.delete'),
        headerAlign: 'center',
        align: 'center',
        flex: 0.5,
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        disableExport: true,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: theme.rfhColors.grey[200],
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) => (
          <DeleteIcon
            onClick={() => openDeleteDialog(params.row)}
            sx={{
              cursor: 'pointer',
              color: theme.rfhColors.grey,
              width: '20px',
              height: '20px',
            }}
          />
        ),
      }
    )

    return tempColumns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, readonly, openEditDialog, openDeleteDialog])

  const marginRight = calcMarginRight()

  return (
    <Grid container marginTop={GRID_MARGIN_TOP}>
      <Grid size={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          <ActionButton onClick={handleAddAuctionGroupRule}>
            {t('common.add')}
          </ActionButton>
        </ButtonsBlock>
      </Grid>
      <Grid size={12}>
        <CustomDataGrid
          cacheKey={CACHE_KEY}
          changeGridPaginationInitialState={setPagination}
          changeSortModel={setSortModel}
          language={i18n.language}
          getRowId={getRowId}
          columns={generateColumns}
          rows={rules ?? []}
          initialState={initialState}
          gridProps={{
            rowCount: count,
            disablPagination: true,
            loading,
          }}
        />
      </Grid>
      <Grid size={12}>
        <ActionModal
          open={open}
          translationsKey={translationsKey}
          onCancel={closeDeleteDialog}
          onConfirm={handleDelete}
        />
      </Grid>
    </Grid>
  )
}
