import { IProductclusterProductgroep } from 'src/common/services/client'

export const byProductgroepIdSort = (
  pg1: IProductclusterProductgroep,
  pg2: IProductclusterProductgroep
): number => {
  // ID = 0 will get a new bigger ID after insert,
  // so should considered as a bigger of the two
  // unless (impossible) both IDs = 0
  if (pg1.productclusterProductgroepID === 0) {
    return pg2.productclusterProductgroepID
  } else {
    return pg1.productclusterProductgroepID - pg2.productclusterProductgroepID
  }
}
