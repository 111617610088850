import { GridColDef } from '@mui/x-data-grid-premium'

import { ThemeConfig } from 'src/common/config'
import {
  AanvoerderclusterAanvoerder,
  IAanvoerderclusterView,
} from 'src/common/services/client'
import { formatDate } from 'src/common/utils'
import i18n from 'src/i18n'

export const shouldBeOrdered = (
  aanvoerders: AanvoerderclusterAanvoerder[]
): boolean =>
  !!aanvoerders?.length && aanvoerders?.some(scs => scs.volgordeNummer > 0)

export const calcMarginRight = (): number => ThemeConfig.spacing.l * 4.64

export function getClusterSeed(
  cluster: IAanvoerderclusterView,
  userId: string
): IAanvoerderclusterView {
  const timestampNow = new Date()
  const clusterSeed: IAanvoerderclusterView = {
    ...cluster,
    aanmaakDatumTijd: cluster?.aanmaakDatumTijd ?? timestampNow,
    aanmaakGebruiker: cluster?.aanmaakGebruiker
      ? cluster.aanmaakGebruiker
      : userId,
    mutatieDatumTijd: timestampNow,
    mutatieGebruiker: userId,
  }
  return clusterSeed
}

export const getStaticClustersColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'aanvoerderclusterNummer',
    headerName: i18n.t('common.number'), // Nummer
    headerAlign: 'right',
    align: 'right',
    flex: 1,
  })
  columns.push({
    field: 'aanvoerderclusterOmschrijving',
    headerName: i18n.t('common.description'), // Omschrijving
    headerAlign: 'left',
    align: 'left',
    flex: 5,
  })
  columns.push({
    field: 'mutatieDatumTijd',
    headerName: i18n.t('common.mutationDateTime'), // Gemuteerd
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    valueFormatter: date => formatDate(date, 'dd-MM-yyyy HH:mm:ss'),
  })
  columns.push({
    field: 'mutatieGebruiker',
    headerName: i18n.t('common.mutationUser'), // Door
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  })
  return columns
}

export const getStaticSuppliersColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'aanvoerderNummer',
    headerName: i18n.t('supplierCluster.supplier.number'), // Nummer
    headerAlign: 'right',
    align: 'right',
    flex: 1.5,
  })
  columns.push({
    field: 'aanvoerderKlokNaam',
    headerName: i18n.t('supplierCluster.supplier.name'),
    headerAlign: 'left',
    align: 'left',
    flex: 4,
  })
  columns.push({
    field: 'volgordeNummer',
    headerName: i18n.t('supplierCluster.supplier.sequenceNumber'), // Omschrijving
    headerAlign: 'center',
    align: 'center',
    flex: 1.5,
    valueFormatter: params => (params?.value === 0 ? '' : params?.value),
  })
  columns.push({
    field: 'mutatieDatumTijd',
    headerName: i18n.t('common.mutationDateTime'), // Gemuteerd
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    valueFormatter: date => formatDate(date, 'dd-MM-yyyy HH:mm:ss'),
  })
  columns.push({
    field: 'mutatieGebruiker',
    headerName: i18n.t('common.mutationUser'), // Door
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  })
  return columns
}
