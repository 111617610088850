import type { IClient, IClusterEnRegelsView } from 'src/common/services/client'

export const getProductClusterInfo = async (
  apiClient: IClient,
  productCode?: number,
  productGroepCode?: number
): Promise<IClusterEnRegelsView> => {
  let result: IClusterEnRegelsView
  if (productCode) {
    result = await apiClient.veilgroepRegels_GetClusterEnRegelsDfe1(productCode)
  } else if (productGroepCode) {
    result = await apiClient.veilgroepRegels_GetClusterEnRegels4adc(
      productGroepCode
    )
  }
  const cluster = {
    clusterNaam: result?.clusterNaam,
    productgroep: result?.productgroep,
    veilgroepregels: result?.veilgroepregels,
  } as IClusterEnRegelsView
  return cluster
}
