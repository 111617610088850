import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  Box,
  Button,
  Container,
  FormControl,
  Grid2 as Grid,
  TextField,
  Typography,
} from '@mui/material'
import { LoadingPage } from '@rfh-core/components'
import theme from '@rfh-core/theme'

import {
  AvailableLocations,
  AvailableProductInput,
  AvailableReasonsInput,
  AvailableSupplierInput,
  CustomDatePicker,
} from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { HttpCode } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { addSupplierProductRegularityException } from 'src/common/services'
import {
  AanvoerderProductRegelmatigUitzondering,
  IAanvoerderProductRegelmatigUitzondering,
} from 'src/common/services/client'
import {
  disableNonFridays,
  disableNonMondays,
  getNextFriday,
  getNextMonday,
  getUTCDate,
  isUndefinedOrEmpty,
  isUndefinedOrNull,
  snackbarUtils,
} from 'src/common/utils'

import isEqual from 'lodash/isEqual'

export default function AddProductException(): JSX.Element {
  const MONDAY = 1
  const FRIDAY = 5
  const { t } = useTranslation()
  const defaultValues: IAanvoerderProductRegelmatigUitzondering = {
    aanvoerderNummer: undefined,
    productCode: undefined,
    vestigingCode: undefined,
    aanmaakDatumTijd: new Date(),
    uitzonderingDatumVanaf: getNextMonday(),
    uitzonderingDatumTtm: getNextFriday(getNextMonday()),
    uitzonderingRedenCode: undefined,
    uitzonderingRedenTekst: '',
  }

  /*
  State
  */
  const [inProgress, setInProgress] = useState(false)
  const [values, setValues] =
    useState<IAanvoerderProductRegelmatigUitzondering>(defaultValues)
  const [isValidSupplierNumber, setIsValidSupplierNumber] = useState(false)
  const [isValidProductNumber, setIsValidProductNumber] = useState(false)
  const [isValidLocation, setIsValidLocation] = useState(false)
  const [isValidReason, setIsValidReason] = useState(false)
  const [isValidReasonText, setIsValidReasonText] = useState(true)

  /*
 Hooks
 */
  const history = useHistory()
  const { apiClient } = useApiClient()
  const user = useUser()

  const updateEntity = (
    changedValues: Partial<IAanvoerderProductRegelmatigUitzondering>
  ): void => {
    const newValues = {
      ...values,
      ...changedValues,
    }
    if (!isEqual(values, newValues)) setValues(newValues)
  }

  const addException = async () => {
    try {
      if (inProgress) {
        return Promise.resolve()
      }

      setInProgress(true)

      const newEntity = new AanvoerderProductRegelmatigUitzondering({
        ...values,
        aanmaakGebruiker: user.sub,
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: user.sub,
        mutatieOmschrijving: '',
      })

      if (!validateInput(newEntity)) {
        setInProgress(false)
        return
      }

      const result =
        // eslint-disable-next-line max-len
        (await addSupplierProductRegularityException(
          apiClient,
          newEntity
        )) as any
      if (result === HttpCode.CREATED) {
        snackbarUtils.success('Success')
        setValues(defaultValues)
      }
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
    setInProgress(false)
  }

  const validateInput = useCallback(
    (input: AanvoerderProductRegelmatigUitzondering): boolean => {
      if (isUndefinedOrNull(input.vestigingCode)) {
        snackbarUtils.error(t('exceptions.addExceptions.locationEmptyError'))
        return false
      }
      if (isUndefinedOrNull(input.uitzonderingRedenCode)) {
        snackbarUtils.error(t('exceptions.addExceptions.reasonEmptyError'))
        return false
      }
      if (
        input.uitzonderingRedenCode === 1 &&
        isUndefinedOrEmpty(input.uitzonderingRedenTekst)
      ) {
        snackbarUtils.error(t('exceptions.addExceptions.reasonInputError'))
        return false
      }

      if (input.uitzonderingDatumVanaf.getDay() !== MONDAY) {
        snackbarUtils.error(t('exceptions.addExceptions.startdateDayError'))
        return false
      }
      if (input.uitzonderingDatumTtm.getDay() !== FRIDAY) {
        snackbarUtils.error(t('exceptions.addExceptions.enddateDayError'))
        return false
      }
      if (input.uitzonderingDatumTtm < input.uitzonderingDatumVanaf) {
        snackbarUtils.error(t('exceptions.addExceptions.daySwitch'))
        return false
      }
      return true
    },
    [t]
  )

  const disabled = useMemo(
    () =>
      inProgress ||
      !isValidSupplierNumber ||
      !isValidProductNumber ||
      !isValidLocation ||
      !isValidReason ||
      !isValidReasonText,
    [
      inProgress,
      isValidLocation,
      isValidProductNumber,
      isValidReason,
      isValidReasonText,
      isValidSupplierNumber,
    ]
  )

  if (inProgress) {
    return <LoadingPage />
  }

  return (
    <Container
      maxWidth='lg'
      sx={{
        padding: ThemeConfig.spacing.sm,
        minHeight: 'calc(100vh - 64px - 8px)',
      }}
    >
      <Grid container spacing={2} sx={{ width: '100%', m: '0' }}>
        <Grid size={12} sx={{ padding: '0 !important' }}>
          <Typography
            variant='h2'
            sx={{ m: `${ThemeConfig.spacing.sm * 8} 0` }}
          >
            {t('common.titles.addExceptions')}
          </Typography>
        </Grid>
        <Box component='form' onSubmit={addException} sx={{ width: '100%' }}>
          <Grid container spacing={2}>
            <Grid size={12}>
              <FormControl sx={{ m: 0, width: '100%' }}>
                <AvailableSupplierInput
                  changeValid={setIsValidSupplierNumber}
                  changeSupplierNumber={value =>
                    updateEntity({ aanvoerderNummer: value })
                  }
                  value={values.aanvoerderNummer}
                  required
                  sx={{ mt: ThemeConfig.spacing.s }}
                />
              </FormControl>
            </Grid>
            <Grid size={12}>
              <FormControl
                sx={{
                  m: 0,
                  width: '100%',
                }}
              >
                <AvailableProductInput
                  values={values}
                  value={values.productCode}
                  sx={{ mt: ThemeConfig.spacing.s }}
                  changeValid={setIsValidProductNumber}
                  onChange={(value: any) =>
                    updateEntity({ productCode: value })
                  }
                  required
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <AvailableLocations
                changeValid={setIsValidLocation}
                handleChange={(name, value) => {
                  updateEntity({ [name]: value })
                }}
                inputValue={values.vestigingCode}
                labelVisible
                required
                sx={{ width: '100%' }}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControl
                sx={{
                  m: 0,
                  width: '100%',
                }}
              >
                <CustomDatePicker
                  label={t('exceptions.startDate')}
                  value={values.uitzonderingDatumVanaf}
                  onChange={value => {
                    updateEntity({
                      uitzonderingDatumVanaf: getUTCDate(value),
                    })
                  }}
                  shouldDisableDate={disableNonMondays}
                  disablePast
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <Typography
                variant={'subtitle2'}
                sx={{
                  fontFamily: 'halyard-display',
                  fontWeight: 700,
                  fontSize: '1rem',
                  color: theme.rfhColors.black,
                }}
              >
                {`${t('overviews.reason')} *`}
              </Typography>
              <FormControl
                sx={{
                  m: 0,
                  width: '100%',
                  mt: 2,
                }}
              >
                <AvailableReasonsInput
                  input={values.uitzonderingRedenCode}
                  changeValid={setIsValidReason}
                  onChangeReason={(name, value) => {
                    if (value === 1) {
                      setIsValidReasonText(false)
                    } else {
                      setIsValidReasonText(true)
                    }
                    updateEntity({ [name]: value })
                  }}
                  required
                />
              </FormControl>
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormControl
                sx={{
                  m: 0,
                  width: '100%',
                }}
              >
                <CustomDatePicker
                  label={t('exceptions.endDate')}
                  value={values.uitzonderingDatumTtm}
                  onChange={value => {
                    updateEntity({
                      uitzonderingDatumTtm: getUTCDate(value),
                    })
                  }}
                  shouldDisableDate={disableNonFridays()}
                  disablePast
                />
              </FormControl>
            </Grid>
            <Grid size={12}>
              <FormControl
                sx={{
                  m: 0,
                  width: '100%',
                }}
              >
                <TextField
                  id='vrijeTekst'
                  label={
                    values.uitzonderingRedenCode === 1
                      ? `${t('exceptions.freeText')} *`
                      : t('exceptions.freeText')
                  }
                  name='uitzonderingRedenTekst'
                  type='text'
                  placeholder={t('exceptions.freeText')}
                  value={values.uitzonderingRedenTekst}
                  onChange={evnt => {
                    if (evnt.target.value !== '') {
                      setIsValidReasonText(true)
                    } else {
                      setIsValidReasonText(false)
                    }
                    updateEntity({
                      [evnt.target.name]: evnt.target.value,
                    })
                  }}
                  fullWidth
                ></TextField>
              </FormControl>
            </Grid>
            <Grid
              size={12}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                mt: ThemeConfig.spacing.s,
                marginBottom: ThemeConfig.spacing.sm,
              }}
            >
              <Button
                variant='outlined'
                onClick={() => history.goBack()}
                sx={{ mr: ThemeConfig.spacing.s }}
              >
                {t('common.back')}
              </Button>
              <Button
                variant='contained'
                disabled={disabled}
                onClick={addException}
              >
                {t('common.add')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Container>
  )
}
