import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  IAanvoerderPromotieKenmerk,
  IKenmerkWaarde,
} from 'src/common/services/client'

import { defaultCharacteristic } from '../constants'

type StoreState = {
  characteristic: IAanvoerderPromotieKenmerk
  oldCharacteristic: IAanvoerderPromotieKenmerk
  characteristicValues: IKenmerkWaarde[]
}

type Actions = {
  /* store */
  resetSupplierPromotionCharacteristicState: () => void
  /* Supplier Promotion Characteristic */
  setCharacteristic: (c: IAanvoerderPromotieKenmerk) => void
  setOldCharacteristic: (oc: IAanvoerderPromotieKenmerk) => void
  setcharacteristicValues: (cvs: IKenmerkWaarde[]) => void
  isDataDirty: () => boolean
}

const storeName = 'SupplierPromotionCharacteristicStore'

const initialStoreState: StoreState = {
  characteristic: defaultCharacteristic,
  oldCharacteristic: defaultCharacteristic,
  characteristicValues: null,
}

export const useSupplierPromotionCharacteristicStore = create<
  StoreState & Actions
>()(
  devtools(
    persist(
      (set, get: () => any): StoreState & Actions => ({
        ...initialStoreState,
        /* Store */
        resetSupplierPromotionCharacteristicState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetSupplierPromotionCharacteristicState'
          ),
        setCharacteristic: (c: IAanvoerderPromotieKenmerk) =>
          set(
            (state: StoreState) => ({
              characteristic: {
                ...state.characteristic,
                ...c,
                ingangsDatum: new Date(c.ingangsDatum),
                eindDatum: new Date(c.eindDatum),
              },
            }),
            false, // replace or not replace - that is the question!
            'setCharacteristic'
          ),
        setOldCharacteristic: (oc: IAanvoerderPromotieKenmerk) =>
          set(
            (state: StoreState) => ({
              oldCharacteristic: {
                ...state.oldCharacteristic,
                ...oc,
                ingangsDatum: new Date(oc.ingangsDatum),
                eindDatum: new Date(oc.eindDatum),
              },
            }),
            false, // replace or not replace - that is the question!
            'setOldCharacteristic'
          ),
        setcharacteristicValues: (cvs: IKenmerkWaarde[]) =>
          set(
            () => ({
              characteristicValues: cvs,
            }),
            false, // replace or not replace - that is the question!
            'setcharacteristicValues'
          ),
        isDataDirty: () =>
          JSON.stringify(get().oldCharacteristic) !==
          JSON.stringify(get().characteristic),
      }),
      {
        name: 'supplier-promotion-characteristic-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
