import { GridColDef } from '@mui/x-data-grid-premium'

import { SearchStatsFilterType } from 'src/common/hooks'
import i18n from 'src/i18n'

export const initialFilter: SearchStatsFilterType = {
  auctionDate: new Date(), // today
  locationCode: 1, // Aalsmeer
  trolleyType: 0, // stapelwagen
  clockNameFrom: undefined,
  clockNumberFrom: 0,
  clockNameTo: undefined,
  clockNumberTo: 0,
}

export const initialState = {
  aggregation: {
    model: {
      trolleysDeclared: 'sum',
      trolleysReceived: 'sum',
      trolleysToReceive: 'sum',
      trolleysPlacedInOrder: 'sum',
      trolleysNotPlacedInOrder: 'sum',
    },
  },
}

export const getStaticColumns = (): GridColDef[] => [
  {
    field: 'clockName',
    headerName: i18n.t('dayCounts.clockName'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  },
  {
    field: 'auctionGroupCode',
    headerName: i18n.t('dayCounts.auctionGroupCode'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  },
  {
    field: 'auctionGroupDescription',
    headerName: i18n.t('dayCounts.description'),
    headerAlign: 'left',
    align: 'left',
    flex: 4,
  },
  {
    field: 'trolleysDeclared',
    headerName: i18n.t('dayCounts.trolleysDeclared'),
    headerAlign: 'center',
    align: 'center',
    flex: 2,
  },
  {
    field: 'trolleysReceived',
    headerName: i18n.t('dayCounts.trolleysReceived'),
    headerAlign: 'center',
    align: 'center',
    flex: 2,
  },
  {
    field: 'trolleysToReceive',
    headerName: i18n.t('dayCounts.trolleysToReceive'),
    headerAlign: 'center',
    align: 'center',
    flex: 2,
  },
  {
    field: 'trolleysPlacedInOrder',
    headerName: i18n.t('dayCounts.trolleysPlacedInOrder'),
    headerAlign: 'center',
    align: 'center',
    flex: 2,
  },
  {
    field: 'trolleysNotPlacedInOrder',
    headerName: i18n.t('dayCounts.trolleysNotPlacedInOrder'),
    headerAlign: 'center',
    align: 'center',
    flex: 2,
  },
]
