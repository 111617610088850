import type {
  IAanvoerderProductgroepRegelmatigUitzonderingView,
  IAanvoerderProductRegelmatigUitzonderingView,
  IAanvoerderProductRegelmatigView,
  IPartijStatusReport,
  ITribuneKlok,
  IVeildagVeilgroepTellingReport,
} from 'src/common/services/client'

export const Entity = {
  AuctionGroupCount: {} as IVeildagVeilgroepTellingReport,
  BatchesStatus: {} as IPartijStatusReport,
  LocationClock: {} as ITribuneKlok,
  ProductRegelmatig: {} as IAanvoerderProductRegelmatigView,
  ProductgroepRegelmatigUitzondering:
    {} as IAanvoerderProductgroepRegelmatigUitzonderingView,
  ProductRegelmatigUitzondering:
    {} as IAanvoerderProductRegelmatigUitzonderingView,
} as const
