/* eslint-disable id-blacklist */
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import {
  Alert,
  AlertTitle,
  Box,
  capitalize,
  Grid2 as Grid,
} from '@mui/material'
import {
  GridColDef,
  GridInitialState,
  GridPaginationInitialState,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { DeleteIcon, EditIcon, InfoIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'
import type { QueryObserverResult } from '@tanstack/react-query'

import {
  ActionButton,
  ButtonsBlock,
  CustomDataGrid,
  RegularityExceptionDelete,
} from 'src/common/components'
import { GRID_MARGIN_TOP, MAX_NUMBER_COLS_SORTED } from 'src/common/constants'
import type { Entity } from 'src/common/hooks'
import { mayEditPeriod } from 'src/common/lib'
import { useUser } from 'src/common/providers/UserProvider'
import { IAanvoerderProductgroepRegelmatigUitzonderingView } from 'src/common/services/client'
import type { ListAndCount } from 'src/common/types'
import { formatDate, getUTCDate, snackbarUtils } from 'src/common/utils'

import { calcMarginRight } from '../lib'
import ProductGroupRegularityExceptionEdit from './ProductGroupRegularityExceptionEdit'

type ProductGroupRegularityExceptionsGridProps = {
  cacheKey: string
  rows: IAanvoerderProductgroepRegelmatigUitzonderingView[]
  isLoading: boolean
  paginationStateChange: (value: GridPaginationInitialState) => void
  sortModelChange: (value: GridSortModel) => void
  refresh: () => Promise<
    QueryObserverResult<
      ListAndCount<typeof Entity.ProductgroepRegelmatigUitzondering>,
      unknown
    >
  >
  rowCount: number
}

export default function ProductGroupRegularityExceptionsGrid({
  cacheKey,
  rows,
  isLoading,
  paginationStateChange,
  sortModelChange,
  refresh,
  rowCount,
  ...rest
}: Readonly<ProductGroupRegularityExceptionsGridProps>): JSX.Element {
  // Because we use server side sorting we only allow sorting when there are no more than 300.000 rows
  const maxSortedRows = 300000
  const sortingAllowed = rowCount <= maxSortedRows
  const { i18n, t } = useTranslation()
  const user = useUser()
  const history = useHistory()
  const marginRight = calcMarginRight()
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false)
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [selectedExceptionToBeEdited, setSelectedExceptionToBeEdited] =
    useState<IAanvoerderProductgroepRegelmatigUitzonderingView>()
  const [selectedExceptionToBeDeleted, setSelectedExceptionToBeDeleted] =
    useState<IAanvoerderProductgroepRegelmatigUitzonderingView>()
  const [hideAlertContainer, setHideAlertContainer] = useState(false)

  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel: {
        aanvoerderNummer: true,
        AanvoerderNaam: true,
        ProductgroepCode: true,
        ProductgroepNaam: true,
        vestigingCode: true,
        UitzonderingDatumVanaf: true,
        UitzonderingDatumTtm: true,
        UitzonderingRedenOmschrijving: true,
        UitzonderingRedenTekst: true,
        verwijderen: true,
        bewerken: true,
        __check__: true,
      },
    },
  }

  const openEditDialog = (
    value: IAanvoerderProductgroepRegelmatigUitzonderingView
  ) => {
    setSelectedExceptionToBeEdited(value)
    setEditOpen(true)
  }

  const closeEditDialog = () => {
    setSelectedExceptionToBeEdited(undefined)
    setEditOpen(false)
  }

  const openDeleteAlert = (
    value: IAanvoerderProductgroepRegelmatigUitzonderingView
  ) => {
    setSelectedExceptionToBeDeleted(value)
    setDeleteOpen(true)
  }

  const closeDeleteAlert = () => {
    setDeleteOpen(false)
    setSelectedExceptionToBeDeleted(undefined)
  }

  const changeLocalStorageSortingModel = useCallback(
    (value: GridSortModel) => {
      if (value?.length <= MAX_NUMBER_COLS_SORTED) {
        sortModelChange(value)
      } else {
        // To prevent abuse the maximum number of sorted columns is set to five in the backend
        // we catch this so we can show a friendlier message
        snackbarUtils.warning(t('overviews.maximumSorting'))
      }
    },
    [sortModelChange, t]
  )

  const changePaginationState = useCallback(
    (value: GridPaginationInitialState) => paginationStateChange(value),
    [paginationStateChange]
  )

  const getStaticColumns = useCallback((): GridColDef[] => {
    const newColumns: GridColDef[] = []
    newColumns.push({
      field: 'aanvoerderNummer',
      headerName: t('overviews.supplierNumber'),
      sortable: sortingAllowed,
      align: 'right',
      headerAlign: 'right',
      minWidth: 130,
    })
    newColumns.push({
      field: 'aanvoerderNaam',
      headerName: t('overviews.supplierName'),
      minWidth: 180,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productgroepCode',
      headerName: t('overviews.productGroupCode'),
      align: 'right',
      headerAlign: 'right',
      minWidth: 160,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'productgroepNaam',
      headerName: t('overviews.productGroupDescription'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 220,
      sortable: sortingAllowed,
    })
    newColumns.push({
      field: 'vestigingNaam',
      headerName: t('common.auctionLocation'),
      align: 'center',
      headerAlign: 'left',
      minWidth: 90,
      sortable: sortingAllowed,
      valueFormatter: item => capitalize(item.value ?? ''),
    })
    newColumns.push({
      field: 'uitzonderingDatumVanaf',
      headerName: t('overviews.startDate'),
      align: 'center',
      headerAlign: 'left',
      type: 'dateTime',
      sortable: sortingAllowed,
      minWidth: 115,
      valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
      groupingValueGetter: row => formatDate(row, t('common.dateFormatShort')),
    })
    newColumns.push({
      field: 'uitzonderingDatumTtm',
      headerName: t('overviews.endDate'),
      align: 'center',
      headerAlign: 'left',
      type: 'dateTime',
      sortable: sortingAllowed,
      minWidth: 115,
      valueFormatter: row => formatDate(row, t('common.dateFormatShort')),
      groupingValueGetter: row => formatDate(row, t('common.dateFormatShort')),
    })
    newColumns.push({
      field: 'uitzonderingRedenOmschrijving',
      headerName: t('overviews.reason'),
      sortable: sortingAllowed,
      minWidth: 150,
    })
    newColumns.push({
      field: 'uitzonderingRedenTekst',
      headerName: t('overviews.comments'),
      sortable: sortingAllowed,
      minWidth: 140,
    })
    if (user.isAuctionCoordinator) {
      newColumns.push({
        field: 'bewerken',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <EditIcon
            sx={{
              color: theme.rfhColors.grey[200],
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          mayEditPeriod(
            new Date(params.row.uitzonderingDatumVanaf),
            new Date(params.row.uitzonderingDatumTtm)
          ) ? (
            <EditIcon
              onClick={() => openEditDialog(params.row)}
              sx={{
                cursor: 'pointer',
                color: theme.rfhColors.grey,
                width: '20px',
                height: '20px',
              }}
            />
          ) : (
            <EditIcon
              sx={{
                color: theme.rfhColors.grey[200],
                width: '20px',
                height: '20px',
              }}
            />
          ),
        width: 50,
      })
      newColumns.push({
        field: 'verwijderen',
        disableColumnMenu: true,
        type: 'actions',
        hideable: false,
        sortable: false,
        renderHeader: () => (
          <DeleteIcon
            sx={{
              color: theme.rfhColors.grey[200],
              width: '20px',
              height: '20px',
            }}
          />
        ),
        renderCell: (params: GridRenderCellParams) =>
          getUTCDate(new Date(params.row.uitzonderingDatumVanaf)) >
          getUTCDate(new Date()) ? (
            <DeleteIcon
              onClick={() => openDeleteAlert(params.row)}
              sx={{
                cursor: 'pointer',
                color: theme.rfhColors.grey,
                width: '20px',
                height: '20px',
              }}
            />
          ) : (
            <DeleteIcon
              sx={{
                color: theme.rfhColors.grey[200],
                width: '20px',
                height: '20px',
              }}
            />
          ),
        width: 50,
      })
    }

    return newColumns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortingAllowed, t, i18n.language, user.isAuctionCoordinator])

  const columns = useMemo(
    () => getStaticColumns(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  const fields = useMemo(
    () =>
      getStaticColumns()
        .filter(
          column =>
            column.field !== 'bewerken' && column.field !== 'verwijderen'
        )
        .map(column => column.field),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getStaticColumns, i18n.language]
  )

  return (
    <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
      <Grid size={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          {user.isAuctionCoordinator ? (
            <ActionButton
              variant='contained'
              onClick={() =>
                history.push('/dashboard/add-exception-product-group')
              }
            >
              {t('common.add')}
            </ActionButton>
          ) : null}
        </ButtonsBlock>
      </Grid>
      {!sortingAllowed && !hideAlertContainer ? (
        <Box
          sx={{
            marginBottom: GRID_MARGIN_TOP * 2,
            width: '100%',
          }}
        >
          <Alert
            severity='warning'
            icon={<InfoIcon />}
            onClose={() => setHideAlertContainer(true)}
          >
            <AlertTitle>{t('overviews.sortingDisabledAlertTitle')}</AlertTitle>
            {t('overviews.sortingDisabledAlertMessage')}
          </Alert>
        </Box>
      ) : null}
      <Grid size={12}>
        <CustomDataGrid
          cacheKey={cacheKey}
          changeGridPaginationInitialState={changePaginationState}
          changeSortModel={changeLocalStorageSortingModel}
          columns={columns}
          rows={rows}
          initialState={initialState}
          language={i18n.language}
          getRowId={(row: IAanvoerderProductgroepRegelmatigUitzonderingView) =>
            row.aanvoerderProductgroepRegelmatigUitzonderingId
          }
          gridProps={{
            rowCount,
            loading: isLoading,
            fields,
          }}
        />
      </Grid>
      <ProductGroupRegularityExceptionEdit
        regularityException={selectedExceptionToBeEdited}
        open={editOpen}
        onClose={closeEditDialog}
        onCancel={closeEditDialog}
        refresh={refresh}
      />
      <RegularityExceptionDelete
        exceptionToBeDeleted={selectedExceptionToBeDeleted}
        open={deleteOpen}
        onCancel={closeDeleteAlert}
        refresh={refresh}
      />
    </Grid>
  )
}
