import { IPartijStatusReport } from 'src/common/services/client'

import type { BatchesStatusType } from '../types'

export const toBatchesStatus = (
  apiData: IPartijStatusReport
): BatchesStatusType => ({
  description: apiData.partijStatusOmschrijving,
  numberSourceReinspection: apiData.aantalBronHerkeur,
  numberSourceEab: apiData.aantalBronEab,
  numberSourceManual: apiData.aantalBronHandmatig,
  numberSourceQrCode: apiData.aantalBronQrCode,
  numberSourceArabicum: apiData.aantalBronArabicum,
  numberKvvOffered: apiData.aantalKvvAangeboden,
  numberKvvTransactions: apiData.aantalKvvTransacties,
})
