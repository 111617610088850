import {
  Route,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { useUser } from 'src/common/providers/UserProvider'

import type { RenderProps } from './utils'

/**
 * Route that is used for Okta to login, but it should redirect your if you are already logged in.
 */
export default function CallbackRoute({
  component: Component,
  ...rest
}: Readonly<RouteProps>): JSX.Element {
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()

  const getContent = (props: RenderProps): JSX.Element => {
    if (user.isAuthenticated) {
      console.warn('Redirected unneeded access to callback route')
      history.push('/dashboard')
    } else {
      return (
        <Component
          history={history}
          location={location}
          match={match}
          {...props}
        />
      )
    }
  }

  return <Route {...rest} render={(props: RenderProps) => getContent(props)} />
}
