// eslint-disable-next-line
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid2 as Grid,
  Typography,
} from '@mui/material'
import { GridViewIcon, LogoutIcon, UserIcon } from '@rfh-core/icons'

import { MenuCard } from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useAuthentication } from 'src/common/providers/AuthenticationProvider'
import { useUser } from 'src/common/providers/UserProvider'
import {
  getSupplierProductGroupRegularityExceptions,
  getSupplierProductRegularityExceptions,
} from 'src/common/services'
import { snackbarUtils } from 'src/common/utils'
import { useDevToolsStore } from 'src/dev-tools'

export default function HomePage(): JSX.Element {
  const { logout } = useAuthentication()
  const { t } = useTranslation()
  const user = useUser()
  const { apiClient } = useApiClient()

  const [countExceptionsPerProduct, setCountExceptionsPerProduct] = useState(0)
  const [countExceptionsPerProductGroup, setCountExceptionsPerProductGroup] =
    useState(0)
  const { features, shouldFeatureBeEnabled } = useDevToolsStore()

  const iconStyle = {
    fontSize: '0.9em !important',
    height: '20px',
    width: '20px',
  }

  const fetchExceptionsPerProduct = useCallback(async () => {
    try {
      const { count } = await getSupplierProductRegularityExceptions(
        apiClient,
        {
          top: 1,
          skip: 0,
          count: true,
        }
      )

      setCountExceptionsPerProduct(count ?? 0)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
  }, [apiClient, setCountExceptionsPerProduct])

  const fetchExceptionsPerProductGroup = useCallback(async () => {
    try {
      const { count } = await getSupplierProductGroupRegularityExceptions(
        apiClient,
        {
          top: 1,
          skip: 0,
          count: true,
        }
      )

      setCountExceptionsPerProductGroup(count ?? 0)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
  }, [apiClient, setCountExceptionsPerProductGroup])

  useEffect(() => {
    if (apiClient) {
      fetchExceptionsPerProduct()
      fetchExceptionsPerProductGroup()
    }
  }, [apiClient, fetchExceptionsPerProduct, fetchExceptionsPerProductGroup])

  return (
    <Container maxWidth='xl' sx={{ gap: ThemeConfig.spacing.sm }}>
      {/* TITLE HERO CARD */}
      <Card className='CardSecondary' sx={{ height: '100px !important' }}>
        <CardContent>
          <Box>
            <Typography className={'book'} variant='h6' data-testid='welcome'>
              {t('homePage.welcome')}
            </Typography>
            <Typography variant='h4' data-testid='welcomeName'>
              {user.name && ` ${user.name.split(' ')[0]}`}
            </Typography>
          </Box>
        </CardContent>
        <CardMedia
          component={'img'}
          image={
            new Date().getDate() % 2 === 0
              ? '/assets/images/flower.png'
              : '/assets/images/leaves.png'
          }
        />
      </Card>
      {/* MENU OPTIES */}
      <Grid container spacing={3} mt={4}>
        <Grid size={12}>
          <Typography variant={'h2'}>{t('homePage.actions')}</Typography>
        </Grid>
        {/* REGELMAAT OP PRODUCT*/}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'overviewsProductRegularityReadOnly'}
            subheader={'overviewsProductRegularity'}
            to={'supplier-product-regularity'}
          />
        </Grid>
        {/* REGELMAAT OP PRODUCTGROEP*/}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'overviewsProductGroupRegularityReadOnly'}
            subheader={'overviewsProductGroupRegularity'}
            to={'supplier-product-group-regularity'}
          />
        </Grid>
        {/* PRODUCT UITZONDERINGSPERIODEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={
              user.isAuctionCoordinator
                ? 'exceptionsProduct'
                : 'exceptionsProductReadOnly'
            }
            subheader={
              user.isAuctionCoordinator
                ? 'exceptionsProduct'
                : 'exceptionsProductReadOnly'
            }
            to={'exceptions-product'}
            bodyText={t('homePage.exceptions', {
              count: countExceptionsPerProduct,
            })}
          />
        </Grid>
        {/* PRODUCTGROEP UITZONDERINGSPERIODEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={
              user.isAuctionCoordinator
                ? 'exceptionsProductGroup'
                : 'exceptionsProductGroupReadOnly'
            }
            subheader={
              user.isAuctionCoordinator
                ? 'exceptionsProductGroup'
                : 'exceptionsProductGroupReadOnly'
            }
            to={'exceptions-product-group'}
            bodyText={t('homePage.exceptions', {
              count: countExceptionsPerProductGroup,
            })}
          />
        </Grid>
        {/* VEILDAGTELLINGEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'auctionDayCounts'}
            subheader={'auctionDayCounts'}
            to={'auction-group-counts'}
          />
        </Grid>
        {/* PARTIJENSTATUSSEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'searchBatchesStatus'}
            subheader={'searchBatchesStatus'}
            to={'batches-status'}
          />
        </Grid>
        {/* VEILGROEPREGELS */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={
              user.isAuctionCoordinator
                ? 'manageAuctionGroupRules'
                : 'searchAuctionGroupRules'
            }
            subheader={
              user.isAuctionCoordinator
                ? 'manageAuctionGroupRules'
                : 'searchAuctionGroupRules'
            }
            to={'auction-group-rules'}
          />
        </Grid>
        {/* AANVOERDERCLUSTERS */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'supplierClusters'}
            subheader={'supplierClusters'}
            to={'supplier-clusters'}
          />
        </Grid>
        {/* PRODUCTCLUSTERS */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'productClusters'}
            subheader={'productClusters'}
            to={'product-clusters'}
          />
        </Grid>
        {/* VEILGROEPINDELINGEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={
              user.isAuctionCoordinator
                ? 'manageAuctionGroupAllocations'
                : 'auctionGroupAllocations'
            }
            subheader={
              user.isAuctionCoordinator
                ? 'manageAuctionGroupAllocations'
                : 'auctionGroupAllocations'
            }
            to={'auction-group-allocations'}
          />
        </Grid>
        {/* ONREGELMATIGE AANVOERDERS */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={'irregularSuppliers'}
            subheader={'irregularSuppliers'}
            to={'irregular-suppliers'}
          />
        </Grid>
        {/* PROMOTIEKENMERKEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title={
              user.isAuctionCoordinator
                ? 'manageSupplierPromotionCharacteristics'
                : 'supplierPromotionCharacteristics'
            }
            subheader={
              user.isAuctionCoordinator
                ? 'manageSupplierPromotionCharacteristics'
                : 'supplierPromotionCharacteristics'
            }
            to={'supplier-promotion-characteristics'}
          />
        </Grid>
        {/* Zoek Product in Cluster/veilgroepregel */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<GridViewIcon sx={iconStyle} />}
            title='searchProduct'
            subheader='searchProduct'
            to='search-product'
          />
        </Grid>
        {/* Klokkenstatus*/}
        {shouldFeatureBeEnabled(features.KLOKSTATUS) && (
          <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
            <MenuCard
              icon={<GridViewIcon sx={iconStyle} />}
              title='clocksStatus'
              subheader='clocksStatus'
              to='clocks-status'
            />
          </Grid>
        )}
        {/* Logistiek dashboard*/}
        {shouldFeatureBeEnabled(features.DASHBOARD) && (
          <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
            <MenuCard
              icon={<GridViewIcon sx={iconStyle} />}
              title='logisticsDashboard'
              subheader='logisticsDashboard'
              to='logistics-dashboard'
            />
          </Grid>
        )}
        {/* WIP dummy card*/}
        {shouldFeatureBeEnabled(features.WIP) && (
          <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
            <MenuCard
              icon={<GridViewIcon sx={iconStyle} />}
              title='wip'
              subheader='wip'
              to='work-in-progress'
            />
          </Grid>
        )}
      </Grid>
      {/* BEHEER */}
      <Grid container spacing={3} mt={4}>
        <Grid size={12}>
          <Typography variant={'h2'}>{t('homePage.manage')}</Typography>
        </Grid>
        {/* MIJN ACCOUNT */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<UserIcon sx={iconStyle} />}
            title={'profilePage'}
            subheader={'profilePage'}
            to={'profile'}
          />
        </Grid>
        {/* UITLOGGEN */}
        <Grid size={{ xs: 12, sm: 6, lg: 4 }} sx={{ pb: '0 !important' }}>
          <MenuCard
            icon={<LogoutIcon sx={iconStyle} />}
            title={'logout'}
            subheader={'logout'}
            onClick={logout}
          />
        </Grid>
      </Grid>
    </Container>
  )
}
