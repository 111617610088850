import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { useApiClient } from 'src/common/providers'
import { ListAndCount } from 'src/common/types'
import { getUTCDate } from 'src/common/utils'

import type { SearchCountsFuncType, SearchStatsFilterType } from './lib'
import {
  Entity,
  FuncSort,
  getFuncByEntity,
  hasPropertyAndNotNull,
  placeholderData,
} from './lib'

export default function useSearchCounts(
  filter: SearchStatsFilterType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): Readonly<
  UseQueryResult<ListAndCount<typeof Entity.AuctionGroupCount>, unknown>
> {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(
    FuncSort.search,
    Entity.AuctionGroupCount
  ) as SearchCountsFuncType
  const queryResult = useQuery({
    queryKey: [filter.auctionDate, filter.locationCode, filter.trolleyType],
    enabled:
      enabled &&
      Boolean(apiClient) &&
      Boolean(filter) &&
      hasPropertyAndNotNull(apiClient, func),
    keepPreviousData: true,
    placeholderData,
    queryFn: () =>
      apiClient[func](
        getUTCDate(new Date(filter.auctionDate)),
        filter.locationCode,
        filter.trolleyType
      ) as Promise<typeof Entity.AuctionGroupCount>,
    select: (result: any) => ({
      records: result?.value || [],
      count: result['@odata.count'] ?? 0,
    }),
    ...extraOptions,
  }) as UseQueryResult<ListAndCount<typeof Entity.AuctionGroupCount>, unknown>

  return queryResult
}
