import type { ReactElement } from 'react'

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'

export type MenuItemType = {
  icon?: ReactElement
  onClick?: () => void
  text: string
}
export default function CustomListItem({
  item,
}: Readonly<{ item: MenuItemType }>): JSX.Element {
  const props = { onClick: item.onClick }
  return (
    <ListItem disablePadding>
      <ListItemButton {...props}>
        <ListItemIcon>{item.icon}</ListItemIcon>
        <ListItemText primary={item.text} />
      </ListItemButton>
    </ListItem>
  )
}
