import { IVeilgroepRegelKenmerk } from 'src/common/services/client'

export enum WeekDay {
  Maandag = 1,
  Dinsdag = 2,
  Woensdag = 3,
  Donderdag = 4,
  Vrijdag = 5,
}

export type DateInput = {
  dagVanaf: number | undefined
  dagTtm: number | undefined
  weekVanaf: number | undefined
  weekTtm: number | undefined
}

export type AuctionPeriodType = {
  dagVanaf: string | undefined
  dagTtm: string | undefined
  weekVanaf: string | undefined
  weekTtm: string | undefined
}

export type VeilgroepRegelKenmerkView = IVeilgroepRegelKenmerk & {
  kenmerkOmschrijving: string
}

export type LadingdragerTypen = {
  STW: boolean
  DC: boolean
}

export type Aanvoertypen = {
  MP: boolean
  oneP: boolean
  GP: boolean
  GPPlus: boolean
}

export type Kwaliteit = {
  A1: boolean
  A2: boolean
  B1: boolean
}
