/* eslint-disable id-blacklist */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import { Alert, AlertTitle, Box } from '@mui/material'
import {
  GridCellParams,
  GridInitialState,
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { InfoIcon } from '@rfh-core/icons'

import { CustomDataGrid } from 'src/common/components'
import { GRID_MARGIN_TOP, MAX_NUMBER_COLS_SORTED } from 'src/common/constants'
import { IAanvoerderProductgroepRegelmatigView } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { getRegularityGridColumns } from '../lib'
import ProductGroupRegularityDetails from './ProductGroupRegularityDetails'

type ProductGroupRegularityGridProps = {
  cacheKey: string
  rows: IAanvoerderProductgroepRegelmatigView[]
  isLoading: boolean
  paginationStateChange: (value: GridPaginationInitialState) => void
  sortModelChange: (value: GridSortModel) => void
  rowCount: number
}

export default function ProductGroupRegularityGrid({
  cacheKey,
  rows,
  isLoading,
  paginationStateChange,
  sortModelChange,
  rowCount,
  ...rest
}: Readonly<ProductGroupRegularityGridProps>): JSX.Element {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [hideAlertContainer, setHideAlertContainer] = useState(false)

  const initialState: GridInitialState = {
    columns: {
      columnVisibilityModel: {
        aanvoerderNummer: true,
        AanvoerderNaam: true,
        ProductgroepCode: true,
        ProductgroepNaam: true,
        vestigingCode: true,
        regelmatig: true,
        UitzonderingDatumVanaf: true,
        UitzonderingDatumTtm: true,
        UitzonderingRedenOmschrijving: true,
        UitzonderingRedenTekst: true,
      },
    },
  }

  const { i18n, t } = useTranslation()
  const history = useHistory()
  const { aanvoerderProductgroepRegelmatigId }: any = useParams()

  const changeLocalStorageSortingModel = (value: GridSortModel) => {
    if (value?.length <= MAX_NUMBER_COLS_SORTED) {
      sortModelChange(value)
    } else {
      // To prevent abuse the maximum number of sorted columns is set to five in the backend
      // we catch this so we can show a friendlier message
      snackbarUtils.warning(t('overviews.maximumSorting'))
    }
  }

  const changePaginationState = (value: GridPaginationInitialState) => {
    paginationStateChange(value)
  }

  const goToRegularityDetails = useCallback(
    (params: GridCellParams) => {
      if (params.row.aanvoerderProductgroepRegelmatigId === undefined) {
        return
      }

      history.push(
        `/dashboard/supplier-product-group-regularity/${params.row.aanvoerderProductgroepRegelmatigId}`
      )
      setDialogOpen(true)
    },
    [history]
  )

  const closeRegularityDetails = () => {
    history.push('/dashboard/supplier-product-group-regularity')
    setDialogOpen(false)
  }

  useEffect(() => {
    if (aanvoerderProductgroepRegelmatigId) {
      goToRegularityDetails({
        row: { aanvoerderProductgroepRegelmatigId },
      } as any)
    }
  }, [aanvoerderProductgroepRegelmatigId, goToRegularityDetails])

  // Because we use server side sorting we only allow sorting when there are no more than 300.000 rows
  const maxSortedRows = 300000
  const sortingAllowed = rowCount <= maxSortedRows

  const columns = useMemo(
    () => getRegularityGridColumns(sortingAllowed),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getRegularityGridColumns, i18n.language]
  )

  return (
    <>
      {!sortingAllowed && !hideAlertContainer && (
        <Box
          sx={{
            marginBottom: GRID_MARGIN_TOP * 2,
            width: '100%',
          }}
        >
          <Alert
            severity='warning'
            icon={<InfoIcon />}
            onClose={() => setHideAlertContainer(true)}
          >
            <AlertTitle>{t('overviews.sortingDisabledAlertTitle')}</AlertTitle>
            {t('overviews.sortingDisabledAlertMessage')}
          </Alert>
        </Box>
      )}
      <Box position={'relative'} marginTop={GRID_MARGIN_TOP} {...rest}>
        <CustomDataGrid
          cacheKey={cacheKey}
          changeGridPaginationInitialState={changePaginationState}
          changeSortModel={changeLocalStorageSortingModel}
          columns={columns}
          rows={rows}
          initialState={initialState}
          language={i18n.language}
          getRowId={(row: IAanvoerderProductgroepRegelmatigView) =>
            row.aanvoerderProductgroepRegelmatigId
          }
          gridProps={{
            rowCount,
            onCellClick: goToRegularityDetails,
            loading: isLoading,
          }}
        />
      </Box>
      <ProductGroupRegularityDetails
        open={dialogOpen}
        aanvoerderProductgroepRegelmatigId={aanvoerderProductgroepRegelmatigId}
        onClose={closeRegularityDetails}
      ></ProductGroupRegularityDetails>
    </>
  )
}
