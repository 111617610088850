import { BloemenPlanten } from 'src/common/types'

import { VestigingCode } from '../enums'
import { IApiClientGetFilter } from '../interfaces'
import { IClient, IProductVeilgroep } from './client'

export const getProductAuctionGroupByAisIdAsync = async (
  client: IClient,
  apiClientFilter: Partial<IApiClientGetFilter>
): Promise<Partial<IProductVeilgroep>> => {
  const result =
    (await client.productVeilgroepen_ProductVeilgroep_GetProductVeilgroep(
      apiClientFilter.key ?? 0,
      undefined,
      undefined
    )) as any

  const cleanedResult: Partial<IProductVeilgroep> = result?.cleanedResponse

  return {
    veilgroepCode: cleanedResult.veilgroepCode,
    veilgroepOmschrijving: cleanedResult.veilgroepOmschrijving,
  } as Partial<IProductVeilgroep>
}

export const getProductAuctionGroupByCodeAndLocationAsync = async (
  client: IClient,
  veilgroepCode: number,
  vestigingCode: number
): Promise<IProductVeilgroep> => {
  const result = (await client.productVeilgroepen_GetVeilgroep(
    veilgroepCode,
    vestigingCode
  )) as any

  return result?.cleanedResponse?.value?.[0] as IProductVeilgroep
}

export async function getProductAuctionGroupsByBloemenPlantenVestigingAsync(
  client: IClient,
  bloemenPlantenCode: BloemenPlanten,
  vestigingCode: VestigingCode
): Promise<Partial<IProductVeilgroep>[]> {
  const result = (await client.productVeilgroepen_ZoekProductVeilgroepen(
    `'${bloemenPlantenCode}'`,
    vestigingCode
  )) as any

  const records: Partial<IProductVeilgroep>[] =
    result?.cleanedResponse?.value ?? []
  return records
}
