import { CSSProperties } from 'react'

import theme from '@rfh-core/theme'

import { BloemenPlanten } from 'src/common/types'

type TextAlign = 'left' | 'right' | 'center'

export type LogisticsDashboardRowData = {
  vestigingCode: number
  klokSoort: string
  aantalStapelwagens: number
  aantalStapelwagensKowala: number
  aantalStapelwagensScannenBijOpstellen: number
}

export const FEATURE_ROOT_PATH = 'logistics-dashboard' as const

export const getCellStyles = (
  textAlign: TextAlign = 'right'
): CSSProperties => ({
  color: theme.rfhColors.leafGreen,
  fontSize: '3.2em',
  fontWeight: 500,
  textAlign,
  paddingRight: textAlign === 'right' ? '0.6em' : '0',
  width: '13%',
})

export const getHeaderCellStyles = (
  textAlign: TextAlign = 'center'
): CSSProperties => ({
  background: theme.rfhColors.leafGreen,
  color: theme.rfhColors.white,
  fontSize: '1.8em',
  fontWeight: 500,
  lineHeight: 1.25,
  paddingBottom: '0.5em',
  paddingTop: '0.25em',
  textAlign,
  width: '13%',
})

export function getClockSort(code?: BloemenPlanten): string {
  switch (code) {
    case 'B':
      return 'Bloemen'
    case 'P':
      return 'Planten'
    default:
      return 'Algemeen'
  }
}
