import { memo, useCallback, useLayoutEffect, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { usePathname } from 'src/common/hooks'
import {
  getStringValue,
  initialState,
  noOp,
  refreshReducer,
} from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IRegelCodesViewsBodyData } from 'src/common/services/clientExtra'
import { snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH } from '../constants'
import { useMutation } from '../hooks'
import { isDataConsistent } from '../lib'
import { upsertAuctionGroupRuleAsync } from '../services'
import { useAllocationStore, useRuleStore } from '../stores'
import CommonFilter from './CommonFilter'

export default memo(function RuleMutationFilter(): JSX.Element {
  const { apiClient } = useApiClient()
  const { t } = useTranslation()
  const history = useHistory()
  const [state, dispatch] = useReducer(refreshReducer, initialState)
  const { allocation, allocationRules, setMustRefetchAllocationRules } =
    useAllocationStore()
  const { setOldRuleCodesToUpdate, setRuleCodesToUpdate, setOldRule, setRule } =
    useRuleStore()
  const { getBodyData, isDataDirty, isFormValid } = useMutation()
  const { ADDING, urlParam } = usePathname(FEATURE_ROOT_PATH)

  const shouldSaveBeDisabled = useMemo(
    () => !isDataDirty || state.loading,
    [isDataDirty, state.loading]
  )

  const upsertProductClusterAuctionGroupRule = useCallback(
    async function () {
      if (!apiClient || !isFormValid(/*showErrors=*/ true) || state.loading) {
        return Promise.resolve()
      }

      try {
        dispatch({ type: 'PENDING' })
        setTimeout(noOp, 1)
        const bodyData: IRegelCodesViewsBodyData = getBodyData()
        const upsertedRule = await upsertAuctionGroupRuleAsync(
          apiClient,
          bodyData
        )
        setRule(upsertedRule)
        setOldRule(upsertedRule)
        setRuleCodesToUpdate([])
        setOldRuleCodesToUpdate([])
        snackbarUtils.success(t('common.statusCodes.success'))
        if (ADDING && upsertedRule?.productclusterVeilgroepRegelId) {
          history.replace(
            `/dashboard/${FEATURE_ROOT_PATH}` +
              `/${upsertedRule.productclusterID}` +
              `/${upsertedRule.productclusterVeilgroepRegelId}`
          )
        }
      } catch (error: any) {
        snackbarUtils.error(getStringValue(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
        setMustRefetchAllocationRules(true)
      }
    },
    [
      apiClient,
      isFormValid,
      state.loading,
      getBodyData,
      setRule,
      setOldRule,
      setRuleCodesToUpdate,
      setOldRuleCodesToUpdate,
      t,
      ADDING,
      history,
      setMustRefetchAllocationRules,
    ]
  )

  useLayoutEffect(() => {
    const isIdSameAsUri = allocation?.productclusterID === urlParam[0]
    if (
      isIdSameAsUri &&
      allocationRules.length > 0 &&
      isDataConsistent(allocation?.productclusterID, allocationRules)
    ) {
      return
    }
    //! else opnieuw ophalen - no hurry for now
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CommonFilter
      saveDisabled={shouldSaveBeDisabled}
      onClick={upsertProductClusterAuctionGroupRule}
    />
  )
})
