import {
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { useApiClient } from 'src/common/providers'
import type { ListAndCount } from 'src/common/types'

import type { EntityType, ListFilterType, ListFuncType } from './lib'
import {
  FuncSort,
  getFuncByEntity,
  hasPropertyAndNotNull,
  placeholderData,
  prefetch,
} from './lib'

export default function useFetchMany<T>(
  filter: ListFilterType,
  entity: EntityType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): Readonly<UseQueryResult<ListAndCount<T>, unknown>> {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(FuncSort.list, entity) as ListFuncType
  const queryClient = useQueryClient()
  const queryEnabled =
    enabled && Boolean(apiClient) && hasPropertyAndNotNull(apiClient, func)

  const queryResult = useQuery({
    queryKey: [
      entity,
      FuncSort.list,
      filter?.top,
      filter?.skip,
      filter?.search,
      filter?.filter,
      filter?.count,
      filter?.orderby,
      filter?.select,
      filter?.expand,
    ],
    queryFn: () =>
      apiClient[func](
        filter?.top ?? undefined,
        filter?.skip ?? undefined,
        filter?.search ?? undefined,
        filter?.filter ?? undefined,
        filter?.count ?? undefined,
        filter?.orderby ?? undefined,
        filter?.select ?? undefined,
        filter?.expand ?? undefined
      ) as Promise<any>,
    enabled: queryEnabled,
    keepPreviousData: true,
    placeholderData,
    select: (result: any) => ({
      records: result?.value ?? [],
      count: result?.['@odata.count'] ?? 0,
    }),
    ...extraOptions,
  }) as UseQueryResult<ListAndCount<T>, unknown>

  if (!apiClient) {
    return queryResult
  }

  if (filter && queryResult.isPreviousData && queryResult.isSuccess) {
    prefetch(queryResult, filter, entity, func, apiClient, queryClient)
  }

  return queryResult
}
