// @ts-nocheck
/**
 * **INSTRUCTIONS for Devs**
 * 1. generate an alternative client with NSwag Studio based on the blue link in your
 *     swagger overview: `https://localhost:44335/openapi/V1.0/schema.json`;
 * 2. copy/paste your CUD `/api/v1` function(s) into this file inside the `ClientExtra` class;
 *     **NOTE**: don't forget the `protected processFunctionName` part(s)!
 * 3. add `async` word to each function
 * 4. replace `let url_ = this.baseUrl` with `let url_ = this.stripOdataUri(this.baseUrl)`
 * 5. for every function, add its interface to `export interface IClientExtra` and export
 *     the corresponding `BodyData` interface;
 * 7. copy the evt. classes used by the functions and paste them at the end of this file
 * 8. make sure you don't miss any references (no red squigglies!), bear in mind that many of them
 *     sit in the standard `client.ts`, so they just have to be imported;
 *
 * **IMPORTANT**: don't check in the alternatively generated client; restore the classic one.
 */
import { Client, VeilgroepIndelingRegelView, VeilgroepIndelingRegelWithCodesView, throwException } from './client'

export interface IClientExtra {
  updateMultiple(version: string, body?: RegelViewsBodydata | undefined): Promise<void>
  updateProductcluster(version: string, body?: ProductclusterBodyData | undefined): Promise<void>
  /**
      * Updates of 1 ProductclusterVeilgroepRegel with related codes based on changes in the VeilgroepIndelingRegels
        related to the given productcluster.
      * @param body (optional) The body content of the call as regelCodeView.
      * @return Success
      */
  upsertSingleWithCodes(version: string, body?: RegelCodesViewsBodyData | undefined): Promise<VeilgroepIndelingRegelView>
  /**
      * Updates an AanvoerderPromotieKenmerk
      * @param body (optional) The value of the AanvoerderPromotieKenmerkId to be deleted.
      * @return No Content
      */
  updatePromotieKenmerk(version: string, body: PromotieKenmerkBodyData | undefined): Promise<void>
  /**
     * Adds an AanvoerderPromotieKenmerk .
     * @param body (optional) The body content of the call as aanvoerderPromotieKenmerkModel.
     * @return Success
     */
  addPromotieKenmerk(version: string, body: PromotieKenmerkBodyData | undefined): Promise<AanvoerderPromotieKenmerk>
  /**
     * Deletes a AanvoerderOnregelmatig by its AanvoerderOnregelmatigId.
     * @param key The value of the AanvoerderOnregelmatigId to be deleted.
     * @return No Content
     */
  aanvoerderOnregelmatigDELETE(key: number, version: string): Promise<void>
    /**
   * @param body (optional)
   * @return No Content
   */
  addAanvoerderOnregelmatig(version: string, body: AanvoerderOnregelmatigBodyData | undefined): Promise<void>
}

export interface IProblemDetails {
  type?: string
  title?: string
  status?: number
  detail?: string
  instance?: string
  [key: string]: any
}

export class ClientExtra extends Client {
  private stripOdataUri(url: string): string {
    return url.replace('/odata/v1', '')
  }

  /**
   * Updates the productcluster.
   * @param version: string The API version.
   * @param body (optional)
   * @return No Content
   */
  async updateProductcluster(version: string, body?: ProductclusterBodyData | undefined): Promise<void> {
    let url_ = this.stripOdataUri(this.baseUrl) + "/api/v{version}/Productclusters/UpdateProductcluster"
    if (version === undefined || version === null) {
      throw new Error("The parameter 'version' must be defined.")
    }
    url_ = url_.replace("{version}", encodeURIComponent("" + version))
    url_ = url_.replace(/[?&]$/, "")

    const content_ = JSON.stringify(body)

    let options_: RequestInit = {
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json;odata.metadata=minimal;odata.streaming=true",
      }
    }

    return this.transformOptions(options_).then(transformedOptions_ => {
      return this.http.fetch(url_, transformedOptions_)
    }).then((_response: Response) => {
      return this.transformResult(url_, _response, (_response: Response) => this.processUpdateProductcluster(_response))
    })
  }

  protected processUpdateProductcluster(response: Response): Promise<void> {
    const status = response.status
    let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v) };
    if (status === 204) {
      return response.text().then((_responseText) => {
        return
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetails.fromJS(resultData400)
        return throwException("Bad Request", status, _responseText, _headers, result400)
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers)
      })
    }
    return Promise.resolve<void>(null as any)
  }

  /**
   * Updates an array of ProductclusterVeilgroepRegels based on changes in the VeilgroepIndelingRegels
  related to the given productcluster.
  * @param version: string The API version.
  * @param body (optional) The body content of the call.
  * @return No Content
  */
  async updateMultiple(
    version: string,
    body?: RegelViewsBodydata | undefined
  ): Promise<void> {
    let url_ = this.stripOdataUri(this.baseUrl) + '/api/v{version}/VeilgroepIndelingRegelViews/UpdateMultiple'
    if (version === undefined || version === null) {
      throw new Error('The parameter "version" must be defined.')
    }
    url_ = url_.replace('{version}', encodeURIComponent('' + version))
    url_ = url_.replace(/[?&]$/, '')

    const content_ = JSON.stringify(body)

    let options_: RequestInit = {
      body: content_,
      method: 'POST',
      headers: {
        'Content-Type':
          'application/json;odata.metadata=minimal;odata.streaming=true',
      },
    }

    const transformedOptions_ = await this.transformOptions(options_)
    const _response = await this.http.fetch(url_, transformedOptions_)
    return await this.transformResult(url_, _response, (_response_1: Response) => this.processUpdateMultiple(_response_1))
  }

  protected processUpdateMultiple(response: Response): Promise<void> {
    const status = response.status
    let _headers: any = {}
    if (response.headers && response.headers.forEach) {
      response.headers.forEach((v: any, k: any) => (_headers[k] = v))
    }
    if (status === 204) {
      return response.text().then(_responseText => {
        return
      })
    } else if (status === 400) {
      return response.text().then(_responseText => {
        let result400: any = null
        let resultData400 =
          _responseText === ''
            ? null
            : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetails.fromJS(resultData400)
        return throwException(
          'Bad Request',
          status,
          _responseText,
          _headers,
          result400
        )
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then(_responseText => {
        return throwException(
          'An unexpected server error occurred.',
          status,
          _responseText,
          _headers
        )
      })
    }
    return Promise.resolve<void>(null as any)
  }

  /**
   * Updates of 1 ProductclusterVeilgroepRegel with related codes based on changes in the VeilgroepIndelingRegels
  related to the given productcluster.
   * @param body (optional) The body content of the call as regelCodeView.
   * @return Success
   */
  upsertSingleWithCodes(version: string, body?: RegelCodesViewsBodyData | undefined): Promise<VeilgroepIndelingRegelView> {
    let url_ = this.stripOdataUri(this.baseUrl) + "/api/v{version}/VeilgroepIndelingRegelViews/UpsertSingleWithCodes"
    if (version === undefined || version === null)
      throw new Error("The parameter 'version' must be defined.")
    url_ = url_.replace("{version}", encodeURIComponent("" + version))
    url_ = url_.replace(/[?&]$/, "")

    const content_ = JSON.stringify(body)

    let options_: RequestInit = {
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json;odata.metadata=minimal;odata.streaming=true",
        "Accept": "application/json"
      }
    }

    return this.transformOptions(options_).then(transformedOptions_ => {
      return this.http.fetch(url_, transformedOptions_)
    }).then((_response: Response) => {
      return this.transformResult(url_, _response, (_response: Response) => this.processUpsertSingleWithCodes(_response))
    })
  }

  protected processUpsertSingleWithCodes(response: Response): Promise<VeilgroepIndelingRegelView> {
    const status = response.status
    let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v) };
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = VeilgroepIndelingRegelView.fromJS(resultData200)
        return result200
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetails.fromJS(resultData400)
        return throwException("Bad Request", status, _responseText, _headers, result400)
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers)
      })
    }
    return Promise.resolve<VeilgroepIndelingRegelView>(null as any)
  }

  /**
    * Adds an AanvoerderPromotieKenmerk .
    * @param body (optional) The body content of the call as aanvoerderPromotieKenmerkModel.
    * @return Success
    */
  async addPromotieKenmerk(version: string, body: IPromotieKenmerkBodyData | undefined): Promise<AanvoerderPromotieKenmerk> {
    let url_ = this.stripOdataUri(this.baseUrl) + "/api/v{version}/AanvoerderPromotieKenmerk/AddPromotieKenmerk"
    if (version === undefined || version === null)
      throw new Error("The parameter 'version' must be defined.")
    url_ = url_.replace("{version}", encodeURIComponent("" + version))
    url_ = url_.replace(/[?&]$/, "")

    const content_ = JSON.stringify(body)

    let options_: RequestInit = {
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json;odata.metadata=minimal;odata.streaming=true",
        "Accept": "application/json"
      }
    }

    return this.transformOptions(options_).then(transformedOptions_ => {
      return this.http.fetch(url_, transformedOptions_)
    }).then((_response: Response) => {
      return this.transformResult(url_, _response, (_response: Response) => this.processAddPromotieKenmerk(_response))
    })
  }

  protected processAddPromotieKenmerk(response: Response): Promise<AanvoerderPromotieKenmerk> {
    const status = response.status
    let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v) };
    if (status === 200) {
      return response.text().then((_responseText) => {
        let result200: any = null
        let resultData200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result200 = AanvoerderPromotieKenmerk.fromJS(resultData200)
        return result200
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetails.fromJS(resultData400)
        return throwException("Bad Request", status, _responseText, _headers, result400)
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers)
      })
    }
    return Promise.resolve<AanvoerderPromotieKenmerk>(null as any)
  }
  /**
     * Updates an AanvoerderPromotieKenmerk
     * @param body (optional) The value of the AanvoerderPromotieKenmerkId to be deleted.
     * @return No Content
     */
  async updatePromotieKenmerk(version: string, body: PromotieKenmerkBodyData | undefined): Promise<void> {
    let url_ = this.stripOdataUri(this.baseUrl) + "/api/v{version}/AanvoerderPromotieKenmerk/UpdatePromotieKenmerk"
    if (version === undefined || version === null)
      throw new Error("The parameter 'version' must be defined.")
    url_ = url_.replace("{version}", encodeURIComponent("" + version))
    url_ = url_.replace(/[?&]$/, "")

    const content_ = JSON.stringify(body)

    let options_: RequestInit = {
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json;odata.metadata=minimal;odata.streaming=true",
      }
    }

    return this.transformOptions(options_).then(transformedOptions_ => {
      return this.http.fetch(url_, transformedOptions_)
    }).then((_response: Response) => {
      return this.transformResult(url_, _response, (_response: Response) => this.processUpdatePromotieKenmerk(_response))
    })
  }

  protected processUpdatePromotieKenmerk(response: Response): Promise<void> {
    const status = response.status
    let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v) };
    if (status === 204) {
      return response.text().then((_responseText) => {
        return
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetails.fromJS(resultData400)
        return throwException("Bad Request", status, _responseText, _headers, result400)
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers)
      })
    }
    return Promise.resolve<void>(null as any)
  }

  /**
   * Deletes a AanvoerderOnregelmatig by its AanvoerderOnregelmatigId.
   * @param key The value of the AanvoerderOnregelmatigId to be deleted.
   * @return No Content
   */
  async aanvoerderOnregelmatigDELETE(key: number, version: string): Promise<void> {
    let url_ = this.stripOdataUri(this.baseUrl) + "/api/v{version}/AanvoerderOnregelmatig/{key}"
    if (key === undefined || key === null)
      throw new Error("The parameter 'key' must be defined.")
    url_ = url_.replace("{key}", encodeURIComponent("" + key))
    if (version === undefined || version === null)
      throw new Error("The parameter 'version' must be defined.")
    url_ = url_.replace("{version}", encodeURIComponent("" + version))
    url_ = url_.replace(/[?&]$/, "")

    let options_: RequestInit = {
      method: "DELETE",
      headers: {
      }
    }

    return this.transformOptions(options_).then(transformedOptions_ => {
      return this.http.fetch(url_, transformedOptions_)
    }).then((_response: Response) => {
      return this.transformResult(url_, _response, (_response: Response) => this.processAanvoerderOnregelmatigDELETE(_response))
    })
  }

  protected processAanvoerderOnregelmatigDELETE(response: Response): Promise<void> {
    const status = response.status
    let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v) };
    if (status === 204) {
      return response.text().then((_responseText) => {
        return
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers)
      })
    }
    return Promise.resolve<void>(null as any)
  }

  /**
   * @param body (optional)
   * @return No Content
   */
  async addAanvoerderOnregelmatig(version: string, body: AanvoerderOnregelmatigBodyData | undefined): Promise<void> {
    let url_ = this.stripOdataUri(this.baseUrl) + "/api/v{version}/AanvoerderOnregelmatig/AddAanvoerderOnregelmatig"
    if(version === undefined || version === null)
      throw new Error("The parameter 'version' must be defined.")
    url_ = url_.replace("{version}", encodeURIComponent("" + version))
    url_ = url_.replace(/[?&]$/, "")

    const content_ = JSON.stringify(body)

    let options_: RequestInit = {
      body: content_,
      method: "POST",
      headers: {
        "Content-Type": "application/json;odata.metadata=minimal;odata.streaming=true",
      }
    }

    return this.transformOptions(options_).then(transformedOptions_ => {
      return this.http.fetch(url_, transformedOptions_)
    }).then((_response: Response) => {
      return this.transformResult(url_, _response, (_response: Response) => this.processAddAanvoerderOnregelmatig(_response))
    });
  }

  protected processAddAanvoerderOnregelmatig(response: Response): Promise<void> {
    const status = response.status
    let _headers: any = {}; if(response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v) };
    if (status === 204) {
      return response.text().then((_responseText) => {
        return
      })
    } else if (status === 400) {
      return response.text().then((_responseText) => {
        let result400: any = null
        let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver)
        result400 = ProblemDetails.fromJS(resultData400)
        return throwException("Bad Request", status, _responseText, _headers, result400)
      })
    } else if (status !== 200 && status !== 204) {
      return response.text().then((_responseText) => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers)
      })
    }
    return Promise.resolve<void>(null as any);
  }
}

export class ProblemDetails implements IProblemDetails {
  type?: string
  title?: string
  status?: number
  detail?: string
  instance?: string
  [key: string]: any

  constructor(data?: IProblemDetails) {
    if (data) {
      for (const property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(props: any) {
    if (!props) {
      return
    }

    for (const property in props) {
      if (props.hasOwnProperty(property))
        this[property] = props[property]
    }
    this.type = props['type']
    this.title = props['title']
    this.status = props['status']
    this.detail = props['detail']
    this.instance = props['instance']
  }

  static fromJS(data: any): ProblemDetails {
    data = typeof data === 'object' ? data : {}
    let result = new ProblemDetails()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    for (const property in this) {
      if (this.hasOwnProperty(property))
        data[property] = this[property]
    }
    data['type'] = this.type
    data['title'] = this.title
    data['status'] = this.status
    data['detail'] = this.detail
    data['instance'] = this.instance
    return data
  }

  clone(): ProblemDetails {
    const json = this.toJSON()
    let result = new ProblemDetails()
    result.init(json)
    return result
  }
}

export interface IRegelCodesViewsBodyData {
  regelCodeView?: VeilgroepIndelingRegelWithCodesView
}

export interface RegelViewsBodydata {
  regelViews?: IVeilgroepIndelingRegelView[]
}

export interface ProductclusterBodyData {
  productcluster?: Productcluster
}
/** Class to mirror the structure of the body content */
export interface IPromotieKenmerkBodyData {
  aanvoerderPromotieKenmerk?: AanvoerderPromotieKenmerk
}

export interface IAanvoerderPromotieKenmerk {
  aanvoerderPromotieKenmerkId?: number
  aanvoerderNummer?: number
  productCode?: number
  kenmerkCode?: string | undefined
  kenmerkWaarde?: string | undefined
  kenmerkWaardeOmschrijving?: string | undefined
  ingangsDatum?: Date
  eindDatum?: Date
  aanmaakDatumTijd?: Date
  aanmaakGebruiker?: string | undefined
  mutatieDatumTijd?: Date
  mutatieGebruiker?: string | undefined
}


export class PromotieKenmerkBodyData implements IPromotieKenmerkBodyData {
  aanvoerderPromotieKenmerk?: AanvoerderPromotieKenmerk

  constructor(data?: IPromotieKenmerkBodyData) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.aanvoerderPromotieKenmerk = _data["aanvoerderPromotieKenmerk"] ? AanvoerderPromotieKenmerk.fromJS(_data["aanvoerderPromotieKenmerk"]) : <any>undefined
    }
  }

  static fromJS(data: any): PromotieKenmerkBodyData {
    data = typeof data === 'object' ? data : {}
    let result = new PromotieKenmerkBodyData()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data["aanvoerderPromotieKenmerk"] = this.aanvoerderPromotieKenmerk ? this.aanvoerderPromotieKenmerk.toJSON() : <any>undefined
    return data
  }

  clone(): PromotieKenmerkBodyData {
    const json = this.toJSON()
    let result = new PromotieKenmerkBodyData()
    result.init(json)
    return result
  }
}

export class AanvoerderPromotieKenmerk implements IAanvoerderPromotieKenmerk {
  aanvoerderPromotieKenmerkId?: number
  aanvoerderNummer?: number
  productCode?: number
  kenmerkCode?: string | undefined
  kenmerkWaarde?: string | undefined
  kenmerkWaardeOmschrijving?: string | undefined
  ingangsDatum?: Date
  eindDatum?: Date
  aanmaakDatumTijd?: Date
  aanmaakGebruiker?: string | undefined
  mutatieDatumTijd?: Date
  mutatieGebruiker?: string | undefined

  constructor(data?: IAanvoerderPromotieKenmerk) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property]
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.aanvoerderPromotieKenmerkId = _data["aanvoerderPromotieKenmerkId"]
      this.aanvoerderNummer = _data["aanvoerderNummer"]
      this.productCode = _data["productCode"]
      this.kenmerkCode = _data["kenmerkCode"]
      this.kenmerkWaarde = _data["kenmerkWaarde"]
      this.kenmerkWaardeOmschrijving = _data["kenmerkWaardeOmschrijving"]
      this.ingangsDatum = _data["ingangsDatum"] ? new Date(_data["ingangsDatum"].toString()) : <any>undefined
      this.eindDatum = _data["eindDatum"] ? new Date(_data["eindDatum"].toString()) : <any>undefined
      this.aanmaakDatumTijd = _data["aanmaakDatumTijd"] ? new Date(_data["aanmaakDatumTijd"].toString()) : <any>undefined
      this.aanmaakGebruiker = _data["aanmaakGebruiker"]
      this.mutatieDatumTijd = _data["mutatieDatumTijd"] ? new Date(_data["mutatieDatumTijd"].toString()) : <any>undefined
      this.mutatieGebruiker = _data["mutatieGebruiker"]
    }
  }

  static fromJS(data: any): AanvoerderPromotieKenmerk {
    data = typeof data === 'object' ? data : {}
    let result = new AanvoerderPromotieKenmerk()
    result.init(data)
    return result
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {}
    data["aanvoerderPromotieKenmerkId"] = this.aanvoerderPromotieKenmerkId
    data["aanvoerderNummer"] = this.aanvoerderNummer
    data["productCode"] = this.productCode
    data["kenmerkCode"] = this.kenmerkCode
    data["kenmerkWaarde"] = this.kenmerkWaarde
    data["kenmerkWaardeOmschrijving"] = this.kenmerkWaardeOmschrijving
    data["ingangsDatum"] = this.ingangsDatum ? this.ingangsDatum.toISOString() : <any>undefined
    data["eindDatum"] = this.eindDatum ? this.eindDatum.toISOString() : <any>undefined
    data["aanmaakDatumTijd"] = this.aanmaakDatumTijd ? this.aanmaakDatumTijd.toISOString() : <any>undefined
    data["aanmaakGebruiker"] = this.aanmaakGebruiker
    data["mutatieDatumTijd"] = this.mutatieDatumTijd ? this.mutatieDatumTijd.toISOString() : <any>undefined
    data["mutatieGebruiker"] = this.mutatieGebruiker
    return data
  }

  clone(): AanvoerderPromotieKenmerk {
    const json = this.toJSON()
    let result = new AanvoerderPromotieKenmerk()
    result.init(json)
    return result
  }
}

/** BodyData to create AanvoerderOnregelmatig */
export interface AanvoerderOnregelmatigBodyData {
  /** VestigingCode */
  vestigingCode: number;
  /** AanvoerderNummer */
  aanvoerderNummer: number;
}

