import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import { CloseIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { ThemeConfig } from '../config/SpacingConfig'
import { LoadingIcon } from './LoadingIcon'

interface SpinnerProps {
  isError?: boolean
}

export default function Spinner({
  isError = false,
}: Readonly<SpinnerProps>): JSX.Element {
  const { t } = useTranslation()

  return isError ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <CloseIcon
        sx={{
          height: '100%',
          color: `${theme.rfhColors.alertRed}`,
          marginTop: ThemeConfig.spacing.s,
          paddingBottom: ThemeConfig.spacing.s,
        }}
      />
      <Typography
        variant='caption'
        sx={{
          color: `${theme.rfhColors.alertRed}`,
        }}
      >
        {t('common.genericErrorShort')}
      </Typography>
    </Box>
  ) : (
    <LoadingIcon />
  )
}
