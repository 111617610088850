import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Container, Grid2 as Grid, Typography } from '@mui/material'

import { Divider } from 'src/common/components'

import { RuleMutation, RuleMutationFilter } from '../components'
import { useRuleStore } from '../stores'

export default function ManageRuleMutation(): JSX.Element {
  const { t } = useTranslation()
  const { mustRefetchReferenceData, setMustRefetchReferenceData } =
    useRuleStore()

  useEffect(() => {
    if (mustRefetchReferenceData) {
      setMustRefetchReferenceData(false)
    }
  }, [mustRefetchReferenceData, setMustRefetchReferenceData])

  return (
    <Container maxWidth='md'>
      <Grid size={12} mb={2}>
        <Typography variant='h2' style={{ fontWeight: 'bold' }}>
          {t('common.titles.manageAuctionGroupRuleMutation')}
        </Typography>
      </Grid>
      <Grid size={12}>
        <RuleMutationFilter data-testid={'rule-mutation-filter'} />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12}>
        <RuleMutation data-testid={'rule-mutation'} />
      </Grid>
    </Container>
  )
}
