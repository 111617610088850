/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useOktaAuth } from '@okta/okta-react'

import jwt from 'jwt-decode'

import { Config } from 'src/common/config/Config'

import type { ChildrenType } from './types'

export interface IUser {
  name: string
  preferredUsername: string
  sub: string
  email: string
  accessToken: string
  isAuthenticated: boolean
  isConsulter: boolean
  isAuctionCoordinator: boolean
}

export const initialUser: IUser = {
  name: '',
  preferredUsername: '',
  sub: '',
  email: '',
  accessToken: '',
  isAuthenticated: false,
  isConsulter: false,
  isAuctionCoordinator: false,
}

export const UserContext = createContext<IUser>(initialUser)
UserContext.displayName = 'UserContext'

/**
 * Use this hook to get all the needed info about the user
 * @example const user = useUser()
 */
export const useUser = () => {
  const context = useContext(UserContext)

  if (context === undefined) {
    throw new Error('useUser hook was used outside of its context provider')
  }

  return context
}

/**
 * This provider is used to give all the child's access to the useUser hook that can be used
 * to access all information of an user. By using this pattern we are able to make the hook a singleton
 * what greatly improves performance and maintainability.
 */
export const UserProvider = ({ children }: ChildrenType): JSX.Element => {
  const { authState } = useOktaAuth()
  const [user, setUser] = useState<IUser>(initialUser)

  const hasRole = (token: string, propName: string): boolean => {
    const jsonToken = jwt<any>(token)
    const propNameWithEnv = `${propName}-${Config.env}`
    const propValue = jsonToken[propNameWithEnv]
      ? jsonToken[propNameWithEnv]
      : ''

    return propValue === 'true'
  }

  const update = useCallback((): void => {
    const accessToken = authState.accessToken.accessToken
    const idToken = authState.idToken.claims
    const isConsulter =
      hasRole(accessToken, 'aax-aanbodbeheer-israadpleger') ||
      hasRole(accessToken, 'aax-aanbodbeheer-isveilcoordinator')
    const isAuctionCoordinator = hasRole(
      accessToken,
      'aax-aanbodbeheer-isveilcoordinator'
    )
    const sub = jwt<any>(accessToken).sub

    const updatedUser: IUser = {
      name: idToken.name,
      preferredUsername: idToken.preferred_username,
      sub,
      email: idToken.email,
      accessToken,
      isAuthenticated: true,
      isConsulter,
      isAuctionCoordinator,
    }

    setUser(updatedUser)
  }, [authState?.accessToken?.accessToken, authState?.idToken?.claims])

  useEffect(() => {
    if (authState?.isAuthenticated) {
      update()
    } else {
      setUser(initialUser)
    }
  }, [authState?.accessToken?.accessToken, authState?.isAuthenticated, update])

  const memoizedUser = useMemo(() => user, [user])

  return (
    <UserContext.Provider value={memoizedUser}>{children}</UserContext.Provider>
  )
}
