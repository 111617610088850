import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  buttonBaseClasses,
  filledInputClasses,
  FormControl,
  formHelperTextClasses,
  InputAdornment,
  inputBaseClasses,
  TextField,
  Typography,
} from '@mui/material'

import debounce from 'lodash.debounce'

import { ELEMENT_HEIGHT, MAX_VALID_VALUE } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { type IProductgroep } from 'src/common/services/client'
import { getProductGroupAsync } from 'src/common/services/productGroupService'
import { type InputProps } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import AvailableProductGroupsDialog from './AvailableProductGroupsDialog'

export default function AvailableProductGroupInput({
  disabled,
  onChange,
  changeValid,
  value,
  sx,
  required,
}: Readonly<InputProps>): JSX.Element {
  /*
   * Hooks
   */
  const { t } = useTranslation()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
  const [productGroupCode, setProductGroupCode] = useState<number>(0)
  const [productGroupName, setProductGroupName] = useState<string>('')
  const { apiClient } = useApiClient()

  const fetchData = useCallback(
    async (newValue: number, emitUpdate = true) => {
      if (!apiClient || inProgress) {
        return Promise.resolve()
      }

      if (newValue > 0) {
        setProductGroupName('')
        setInProgress(true)
        try {
          const productGroup = await getProductGroupAsync(apiClient, {
            key: newValue,
          })
          setProductGroupCode(newValue)
          setProductGroupName(
            productGroup ? productGroup.productgroepNaam : undefined
          )
          setIsValid(Boolean(productGroup))
          if (emitUpdate) {
            onChange(newValue)
            changeValid(Boolean(productGroup))
          }
        } catch (error: any) {
          snackbarUtils.error(String(error))
        } finally {
          setInProgress(false)
        }
      } else {
        setProductGroupCode(0)
        setProductGroupName('')
        setIsValid(false)
        if (emitUpdate) {
          onChange(0)
          changeValid(false)
        }
      }
      return Promise.resolve()
    },
    [apiClient, changeValid, inProgress, onChange]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedNewInput = useCallback(debounce(fetchData, 1000), [fetchData])

  const changeTextInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = Number(event.target.value)
    if (newValue >= 0 && newValue < MAX_VALID_VALUE) {
      setProductGroupCode(newValue)
      debouncedNewInput(newValue)
    }
  }

  const selectProductGroup = useCallback(
    (productGroup: IProductgroep) => {
      setProductGroupCode(productGroup.productgroepCode)
      setProductGroupName(productGroup.productgroepNaam)
      onChange(productGroup.productgroepCode)
      setIsValid(true)
      changeValid(true)
    },
    [changeValid, onChange]
  )

  const getHelperText = (): string => {
    if (productGroupCode && !inProgress) {
      return productGroupName
    }
    return ''
  }

  const isError =
    typeof isValid === 'undefined' || !productGroupCode ? false : !isValid

  useEffect(() => {
    if (!(value in [0, productGroupCode])) {
      fetchData(value, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, value])

  return (
    <FormControl sx={sx} fullWidth>
      <Typography
        variant={'subtitle2'}
        sx={{ fontWeight: 'bold', mb: '0.5rem' }}
      >
        {t('exceptions.productGroupCode')}
      </Typography>
      <TextField
        placeholder={t('exceptions.addExceptions.selectProductGroup')}
        name='productGroupCode'
        data-testid='productGroup'
        required={required}
        disabled={disabled}
        error={isError}
        value={productGroupCode !== 0 ? productGroupCode : ''}
        onChange={changeTextInput}
        helperText={getHelperText()}
        slotProps={{
          input: {
            endAdornment: !disabled && (
              <InputAdornment position='end'>
                <AvailableProductGroupsDialog
                  client={apiClient}
                  onChange={selectProductGroup}
                />
              </InputAdornment>
            ),
          },
        }}
        sx={{
          [`& .${inputBaseClasses.root}`]: { pr: 1, height: ELEMENT_HEIGHT },
          [`& .${filledInputClasses.root}`]: { pr: 0 },
          [`& .${buttonBaseClasses.root}`]: { pr: 1 },
          [`& p.${formHelperTextClasses.root}`]: {
            position: 'absolute',
            bottom: '-2.5rem',
            left: '0.6rem',
            height: ELEMENT_HEIGHT,
          },
        }}
      />
    </FormControl>
  )
}
