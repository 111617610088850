import {
  IProductclusterProductgroep,
  IProductclusterView,
  IProductgroepenReport,
} from 'src/common/services/client'

import { emptyProductgroep } from '../constants'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const toProductclusterView = (rec?: any): IProductclusterView => {
  if (!rec) {
    return null
  }

  const pcv: IProductclusterView = {
    ...rec,
    aanmaakDatumTijd: rec.aanmaakDatumTijd
      ? new Date(rec.aanmaakDatumTijd)
      : null,
    mutatieDatumTijd: rec.mutatieDatumTijd
      ? new Date(rec.mutatieDatumTijd)
      : null,
  }

  return pcv
}

export function toIProductclusterProductgroep(
  pgr: IProductgroepenReport
): IProductclusterProductgroep {
  const pg: IProductclusterProductgroep = {
    ...emptyProductgroep,
    productgroepCode: pgr.productGroepCode,
    productgroepOmschrijving: pgr.productGroepOmschrijving,
  }
  return pg
}
