import { ChangeEvent, ChangeEventHandler, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import NativeSelect from '@mui/material/NativeSelect'
import theme from '@rfh-core/theme'

import { ELEMENT_HEIGHT } from 'src/common/constants'
import { noOp } from 'src/common/lib'
import type { BloemenPlanten } from 'src/common/types'

type BloemenPlantenSelectProps = {
  bloemenPlanten?: BloemenPlanten
  disabled?: boolean
  onChange?: ChangeEventHandler<HTMLSelectElement>
}

export default function BloemenPlantenSelect({
  bloemenPlanten: initialBloemenPlanten = 'B',
  disabled = false,
  onChange = noOp,
}: Readonly<BloemenPlantenSelectProps>): JSX.Element {
  const { t } = useTranslation()
  // B = bloemen, P = planten
  const [bloemenPlanten, setBloemenPlanten] = useState<BloemenPlanten>(
    initialBloemenPlanten
  )

  const selectOption = (event: ChangeEvent<HTMLSelectElement>) => {
    setBloemenPlanten(event.target.value as BloemenPlanten)
    onChange(event)
  }

  return (
    <FormControl>
      <Typography
        variant={'subtitle2'}
        sx={{ fontWeight: 'bold', mb: '0.5rem' }}
      >
        {t('productCluster.flowersPlants')}
      </Typography>
      <NativeSelect
        aria-label={'flowers or plants selector'}
        disabled={disabled}
        onChange={selectOption}
        readOnly={disabled}
        value={bloemenPlanten as string}
        sx={{
          px: 1,
          mt: 1.5,
          height: ELEMENT_HEIGHT,
          minWidth: 150,
          maxWidth: 180,
          bgcolor: theme.rfhColors.white,
          'svg.MuiNativeSelect-icon': {
            pointerEvents: 'none',
          },
        }}
      >
        <option value={'B'}>{t('productCluster.flowers')}</option>
        <option value={'P'}>{t('productCluster.plants')}</option>
      </NativeSelect>
    </FormControl>
  )
}
