import { Box, Grid2 as Grid, Skeleton, Stack } from '@mui/material'

import { v4 as getRandomID } from 'uuid'

export default function AllocationRulesGridSkeleton(): JSX.Element {
  return (
    <Grid container sx={{ width: '100%', marginTop: -1 }}>
      <Grid size={12}>
        <Grid container justifyContent={'flex-end'}>
          <Skeleton
            variant={'text'}
            sx={{ fontSize: '3em', width: '10%', marginRight: 1 }}
          />
          <Skeleton
            variant={'text'}
            sx={{ fontSize: '3em', width: '9%', marginRight: 1 }}
          />
          <Skeleton variant={'text'} sx={{ fontSize: '3em', width: '8%' }} />
        </Grid>
        <Stack spacing={1}>
          {Array(7)
            .fill(0)
            .map(_el => (
              <Box display={'flex'} gap={2} key={getRandomID()}>
                <Skeleton variant='rectangular' width={'100%'} height={40} />
              </Box>
            ))}
        </Stack>
      </Grid>
    </Grid>
  )
}
