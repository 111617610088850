export const FuncSort = {
  get: 'get',
  list: 'list',
  search: 'search',
  add: 'add',
  update: 'update',
  delete: 'delete',
} as const

export const GetFunc = {
  getProductRegelmatig:
    'aanvoerderProductRegelmatigViews_AanvoerderProductRegelmatigView_GetAanvoerderProductRegelmatigView',
} as const
export const ListFunc = {
  listProductRegelmatig:
    'aanvoerderProductRegelmatigViews_AanvoerderProductRegelmatigView_ListAanvoerderProductRegelmatigView',
  listProductgroepRegelmatigUitzondering:
    'aanvoerderProductgroepRegelmatigUitzonderingenViews_AanvoerderProductgroepRegelmatigUitzonderingView_ListAanvoerderProductgroepRegelmatigUitzonderingView',
  listProductRegelmatigUitzondering:
    'aanvoerderProductRegelmatigUitzonderingenViews_AanvoerderProductRegelmatigUitzonderingView_ListAanvoerderProductRegelmatigUitzonderingView',
  listTribuneKlok: 'tribuneKlokken_TribuneKlok_ListTribuneKlok',
} as const
export const SearchBatchesFunc = {
  zoekPartijStatussenPerVeildatumVestiging:
    'partijStatussen_GetVeildatumVestiging',
} as const
export const SearchCountsFunc = {
  zoekVeilgroepTellingenPerVeildatumVestiging:
    'veilgroepTellingen_GetPerVeildatumVestiging',
} as const
export const SearchHistoryFunc = {
  zoekHistorieRegelmatig: 'aanvoerderProductRegelmatigViews_ZoekHistorie',
} as const
