import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { LoginCallback } from '@okta/okta-react'
import { LoadingPage } from '@rfh-core/components'

import { GenericError } from 'src/common/components'

export const LoginRoute: FC<JSX.IntrinsicAttributes> = props => {
  const { t } = useTranslation()
  return (
    <LoginCallback
      {...props}
      errorComponent={GenericError}
      loadingElement={
        <LoadingPage label={t('common.loading')} />
        // <LoadingIconText loadingText={t('common.loading')} positionCenter />
      }
    />
  )
}
