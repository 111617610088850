import { LadingdragerType } from 'src/common/enums'
import { ILocationClock } from 'src/common/interfaces'
import { IVeildagVeilgroepTellingReport } from 'src/common/services/client'

const areThereAnyTrolleys = (
  report: IVeildagVeilgroepTellingReport,
  trolleyType: number
): boolean => {
  let thereAre = false
  switch (trolleyType) {
    case LadingdragerType.Stapelwagens:
      thereAre = report.aantalAangevoerdeTransportmiddelenStapelwagens > 0
      break
    case LadingdragerType.Denen:
      thereAre = report.aantalAangevoerdeTransportmiddelenDenen > 0
      break
    case 0: // all types
      thereAre =
        report.aantalAangevoerdeTransportmiddelenStapelwagens +
          report.aantalAangevoerdeTransportmiddelenDenen >
        0
      break
    default: // impossible in Production but possible in Development
      throw new Error(`Invalid ladingdragertype gebruikt: ${trolleyType} `)
  }
  return thereAre
}

export const byLadingdragerType =
  (trolleyType: number) =>
  (report: IVeildagVeilgroepTellingReport): boolean =>
    (trolleyType === 0 || report.ladingdragerType === trolleyType) &&
    areThereAnyTrolleys(report, trolleyType)

/**
 * Find reports that are in the given clock range
 * @param clockNumberFrom - technical clock number from
 * @param clockNumberTo  - technical clock number to
 * @param clocks - list of all location clocks (local, concern, test)
 * @returns true if the clockName of the report is in the clock range, false otherwise
 */
export const byClockSelection =
  (clockNumberFrom: number, clockNumberTo: number, clocks: ILocationClock[]) =>
  (report: IVeildagVeilgroepTellingReport): boolean => {
    if (clockNumberFrom === 0 && clockNumberTo === 0) {
      return true
    }
    if (!report.klokNaam) return false

    const indexFrom =
      clockNumberFrom === 0
        ? 0
        : clocks.findIndex(lc => lc.clockNumber === clockNumberFrom)
    const indexTo = clocks.findIndex(lc => lc.clockNumber === clockNumberTo)
    if (indexFrom === -1 || indexTo === -1) {
      return false
    }
    const indexReport = clocks.findIndex(lc => lc.clockName === report.klokNaam)
    return indexFrom <= indexReport && indexReport <= indexTo
  }
