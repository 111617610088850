import { memo, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import { DeferredLoad } from 'src/common/components'
import { IProductclusterVeilgroepRegelCode } from 'src/common/services/client'
import { Item } from 'src/common/types'

import {
  HEADER_CELL_STYLE_LEFT,
  HEADER_CELL_STYLE_RIGHT,
} from '../../constants'
import { useReferenceData } from '../../hooks'
import { bySelected } from '../../lib'
import { useRuleStore } from '../../stores'
import { CodeSoort } from '../../types'
import MultiSelectItem from './MultiSelectItem'

type DeferredTableProps = {
  codeSoort: CodeSoort
}

export default memo(function DeferredTable({
  codeSoort,
}: Readonly<DeferredTableProps>): JSX.Element {
  const { t } = useTranslation()
  const noResults = t('common.noResults')
  const { mergeSelected } = useReferenceData()
  const { rule, getRuleCodes, upsertRuleCodeToUpdate } = useRuleStore()
  const [items, setItems] = useState<Item<number>[]>(() => {
    const ruleCodes = getRuleCodes(codeSoort)
    ruleCodes.sort(bySelected)
    return ruleCodes
  })

  const mapItemToRegelCode = useCallback(
    (itm: Item<number>): IProductclusterVeilgroepRegelCode => ({
      code: itm.code,
      codeSoort,
      productclusterVeilgroepRegelId: rule.productclusterVeilgroepRegelId,
      geselecteerd: itm.selected,
    }),
    [codeSoort, rule.productclusterVeilgroepRegelId]
  )

  const changeChecked = useCallback(
    function (item: Item<number>) {
      const index = items.findIndex(i => i.code === item.code)
      const updatedItems = [
        ...items.slice(0, index),
        item,
        ...items.slice(index + 1),
      ]
      updatedItems.sort(bySelected)
      setItems(updatedItems)
      upsertRuleCodeToUpdate(mapItemToRegelCode(item))
      mergeSelected(codeSoort) //! recalculate the text
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mapItemToRegelCode, upsertRuleCodeToUpdate]
  )

  return (
    <Table sx={{ minHeight: 100, mt: '1rem' }}>
      <TableHead>
        <TableRow sx={{ my: 0, py: 0 }}>
          <TableCell sx={HEADER_CELL_STYLE_LEFT}>{t('common.code')}</TableCell>
          <TableCell sx={HEADER_CELL_STYLE_LEFT}>{t('common.name')}</TableCell>
          <TableCell sx={HEADER_CELL_STYLE_RIGHT}>
            {t('common.selected')}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.length === 0 && (
          <TableRow>
            <TableCell colSpan={3}>{noResults}</TableCell>
          </TableRow>
        )}
        {items.length > 0 && (
          <DeferredLoad>
            {items.map(item => (
              <MultiSelectItem
                changeChecked={changeChecked}
                item={item}
                key={item.code}
              />
            ))}
          </DeferredLoad>
        )}
      </TableBody>
    </Table>
  )
})
