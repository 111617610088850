import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormGroup,
  Grid2 as Grid,
  TextField,
  Typography,
} from '@mui/material'

import { AvailableReasonsInput, CustomDatePicker } from 'src/common/components'
import { ThemeConfig } from 'src/common/config'
import { gridSx, typographyStyles } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { updateRegularityException } from 'src/common/services'
import {
  AanvoerderProductRegelmatigUitzondering,
  IAanvoerderProductRegelmatigUitzonderingView,
} from 'src/common/services/client'
import {
  disableNonFridays,
  formatDate,
  getUTCDate,
  snackbarUtils,
} from 'src/common/utils'

type Values = {
  uitzonderingDatumVanaf: Date
  uitzonderingDatumTtm: Date
  uitzonderingRedenCode: number
  uitzonderingRedenTekst: string
}

type ProductRegularityExceptionEditProps = {
  regularityException: IAanvoerderProductRegelmatigUitzonderingView
  open: boolean
  onClose: () => void
  onCancel: () => void
  refresh: () => void
}

export default function ProductRegularityExceptionEdit({
  regularityException,
  open,
  onCancel,
  onClose,
  refresh,
}: Readonly<ProductRegularityExceptionEditProps>): JSX.Element {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues: Values = useMemo(
    (): Values => ({
      uitzonderingDatumVanaf: undefined,
      uitzonderingDatumTtm: undefined,
      uitzonderingRedenCode: undefined,
      uitzonderingRedenTekst: undefined,
    }),
    []
  )

  const [values, setValues] = useState<Values>(defaultValues)
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const [inProgress, setInProgress] = useState(false)
  const user = useUser()

  const cancelEdit = () => {
    setValues(defaultValues)
    onCancel()
  }

  const closeEditDialog = () => {
    setValues(defaultValues)
    refresh()
    onClose()
  }

  const handleChange = (
    changedValues: Partial<IAanvoerderProductRegelmatigUitzonderingView>
  ): void => {
    const newValues = {
      ...values,
      ...changedValues,
    }
    setValues(newValues)
  }

  const handleInputChangeSelect = (name: string, value: any): void => {
    const newValue = { ...values, [name]: value }
    setValues(newValue)
  }

  const onSubmit = async () => {
    try {
      setInProgress(true)

      const exception = { ...regularityException }

      const body = new AanvoerderProductRegelmatigUitzondering({
        ...exception,
        aanmaakDatumTijd: new Date(exception.aanmaakDatumTijd),
        uitzonderingDatumVanaf: new Date(values.uitzonderingDatumVanaf),
        uitzonderingDatumTtm: new Date(values.uitzonderingDatumTtm),
        uitzonderingRedenCode: values.uitzonderingRedenCode,
        uitzonderingRedenTekst: values.uitzonderingRedenTekst,
        mutatieDatumTijd: new Date(),
        mutatieGebruiker: user.sub,
      })

      await updateRegularityException(apiClient, body)

      closeEditDialog()
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
    setInProgress(false)
  }

  useEffect(() => {
    if (regularityException) {
      setValues({
        uitzonderingDatumVanaf: regularityException?.uitzonderingDatumVanaf
          ? new Date(regularityException.uitzonderingDatumVanaf)
          : undefined,
        uitzonderingDatumTtm: regularityException?.uitzonderingDatumTtm
          ? new Date(regularityException.uitzonderingDatumTtm)
          : undefined,
        uitzonderingRedenCode: regularityException?.uitzonderingRedenCode,
        uitzonderingRedenTekst: regularityException?.uitzonderingRedenTekst,
      })
    } else {
      setValues(defaultValues)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularityException])

  return (
    <Dialog open={open} onClose={cancelEdit}>
      <DialogContent>
        <Typography variant={'h3'} sx={{ mb: '1rem' }}>
          {t('common.titles.editReqularityException')}
        </Typography>
        <Grid container>
          <Grid container sx={gridSx}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                {t('exceptions.supplierNumber')}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant={'body2'} sx={typographyStyles}>
                {regularityException
                  ? `${regularityException.aanvoerderNummer} ${regularityException.aanvoerderNaam}`
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={gridSx}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                {t('common.auctionLocation')}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant={'body2'} sx={typographyStyles}>
                {regularityException ? regularityException.vestigingNaam : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={gridSx}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                {t('exceptions.productCode')}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant={'body2'} sx={typographyStyles}>
                {regularityException
                  ? `${regularityException.productCode} ${regularityException.productNaam}`
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={gridSx}>
            <Grid size={{ xs: 12, sm: 4 }}>
              <Typography variant={'subtitle2'} sx={{ fontWeight: 'bold' }}>
                {t('exceptions.startDate')}
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <Typography variant={'body2'} sx={typographyStyles}>
                {regularityException
                  ? formatDate(
                      regularityException.uitzonderingDatumVanaf,
                      t('common.dateFormatShort')
                    )
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
          <FormGroup
            sx={{
              width: '100%',
              marginTop: ThemeConfig.spacing.xs,
            }}
          >
            <Grid container sx={{ ...gridSx, margin: 0 }}>
              <Grid size={{ xs: 12, sm: 4 }} sx={{ verticalAlign: 'center' }}>
                <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                  {t('exceptions.endDate')}
                </Typography>
              </Grid>

              <Grid size={{ xs: 12, sm: 8 }}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <Grid sx={gridSx}>
                    <CustomDatePicker
                      value={values.uitzonderingDatumTtm}
                      onChange={value => {
                        if (value) {
                          handleChange({
                            uitzonderingDatumTtm: getUTCDate(value),
                          })
                        }
                      }}
                      shouldDisableDate={disableNonFridays(
                        values.uitzonderingDatumVanaf
                      )}
                      disablePast
                    />
                  </Grid>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={{ ...gridSx, margin: 0 }}>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography
                  variant='subtitle2'
                  sx={{ fontWeight: 'bold !important' }}
                >
                  {t('exceptions.reason')}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 8 }} sx={{ margin: 0, padding: 0 }}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <AvailableReasonsInput
                    input={values.uitzonderingRedenCode}
                    onChangeReason={handleInputChangeSelect}
                    marginTop={0}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container sx={gridSx}>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Typography variant='subtitle2' sx={{ fontWeight: 'bold' }}>
                  {t('exceptions.freeText')}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, sm: 8 }}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                    padding: 0,
                  }}
                >
                  <TextField
                    id='uitzonderingRedenTekst'
                    name='uitzonderingRedenTekst'
                    multiline
                    fullWidth
                    autoFocus
                    placeholder={t('exceptions.freeText')}
                    value={values.uitzonderingRedenTekst}
                    onChange={evnt => {
                      handleChange({
                        [evnt.target.name]: evnt.target.value,
                      })
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={cancelEdit}>
          {t('common.cancel')}
        </Button>
        <Button variant='contained' onClick={onSubmit} disabled={inProgress}>
          {t('common.save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
