import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, SelectChangeEvent } from '@mui/material'

import {
  AvailableProductsDialog,
  AvailableSuppliersDialog,
  LabelDate,
  LabelDropdownText,
  LabelInput,
  LabelInputIconText,
} from 'src/common/components'
import { ELEMENT_HEIGHT } from 'src/common/constants'
import { usePathname } from 'src/common/hooks'
import { useApiClient } from 'src/common/providers'
import { getAanvoerderAsync, getProductAsync } from 'src/common/services'
import type {
  IAanvoerder,
  IAanvoerderPromotieKenmerk,
  IProduct,
} from 'src/common/services/client'

import { FEATURE_ROOT_PATH } from '../constants'
import { fromIKenmerkWaardeToItem } from '../lib/lib.utils'
import { useSupplierPromotionCharacteristicStore } from '../stores'

import startOfTomorrow from 'date-fns/startOfTomorrow'

export default function CharacteristicMutation(): JSX.Element {
  const { characteristic, setCharacteristic, characteristicValues } =
    useSupplierPromotionCharacteristicStore()
  const { apiClient } = useApiClient()
  const [supplierName, setSupplierName] = useState('')
  const [productName, setProductName] = useState('')
  const { t } = useTranslation()
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)

  function changeSupplier(aanvoerder: IAanvoerder) {
    setSupplierName(aanvoerder?.aanvoerderNaam)
    setCharacteristic({
      ...characteristic,
      aanvoerderNummer: aanvoerder.aanvoerderNummer,
    })
  }

  function changeProduct(product: IProduct) {
    setProductName(product?.productNaam)
    setCharacteristic({
      ...characteristic,
      productCode: product.productCode,
    })
  }

  function selectItem({ target }: SelectChangeEvent<string>) {
    const { name, value } = target
    setCharacteristic({
      ...characteristic,
      [name]: value,
      kenmerkCode: 'V22',
      kenmerkWaardeOmschrijving: characteristicValues.filter(
        cv => cv.kenmerkWaardeCode !== target.value
      )[0].kenmerkWaardeOmschrijving,
    })
  }

  function selectDate(name: string, date: Date) {
    setCharacteristic({
      ...characteristic,
      [name]: date,
    })
  }

  const saveCharacteristic =
    (name: keyof IAanvoerderPromotieKenmerk) => (value: string) => {
      setCharacteristic({
        ...characteristic,
        [name]: Number(value),
      })
    }

  const ensureData = useCallback(async () => {
    if (!apiClient) {
      return Promise.resolve()
    }

    if (characteristic?.aanvoerderNummer) {
      const supplier = await getAanvoerderAsync(apiClient, {
        key: characteristic.aanvoerderNummer,
      })
      setSupplierName(supplier?.aanvoerderNaam)
    }
    if (characteristic?.productCode) {
      const product = await getProductAsync(apiClient, {
        key: characteristic.productCode,
      })
      setProductName(product?.productNaam)
    }
  }, [apiClient, characteristic?.aanvoerderNummer, characteristic?.productCode])

  useEffect(() => {
    ensureData()
  }, [
    apiClient,
    characteristic?.aanvoerderNummer,
    characteristic?.productCode,
    ensureData,
  ])

  return (
    <Grid
      container
      direction={'column'}
      justifyContent={'flex-start'}
      gap={'0.5rem'}
    >
      <Grid maxHeight={ELEMENT_HEIGHT} py={'0.25rem'}>
        <LabelInputIconText
          fieldName='aanvoerdernummer'
          label={t('exceptions.supplierNumber')}
          inputValue={
            characteristic?.aanvoerderNummer > 0
              ? characteristic.aanvoerderNummer
              : ''
          }
          textValue={supplierName}
          disabled={!ADDING}
          onChange={saveCharacteristic('aanvoerderNummer')}
        >
          <AvailableSuppliersDialog
            apiClient={apiClient}
            onChange={changeSupplier}
          />
        </LabelInputIconText>
      </Grid>
      <Grid maxHeight={ELEMENT_HEIGHT} py={'0.25rem'}>
        <LabelInputIconText
          fieldName='productCode'
          label={t('exceptions.productCode')}
          inputValue={
            characteristic?.productCode > 0 ? characteristic.productCode : ''
          }
          textValue={productName}
          disabled={!ADDING}
          onChange={saveCharacteristic('productCode')}
        >
          <AvailableProductsDialog
            apiClient={apiClient}
            onChange={changeProduct}
          />
        </LabelInputIconText>
      </Grid>
      <Grid maxHeight={ELEMENT_HEIGHT} py={'0.25rem'}>
        <LabelInput
          disabled
          fieldName='kenmerkCode'
          inputValue={'V22'}
          label={t('supplierPromotionCharacteristics.characteristic')}
        />
      </Grid>
      <Grid maxHeight={ELEMENT_HEIGHT} py={'0.25rem'}>
        <LabelDropdownText
          fieldName='kenmerkWaarde'
          label={t('supplierPromotionCharacteristics.characteristicValue')}
          values={characteristicValues?.map(fromIKenmerkWaardeToItem)}
          onChange={selectItem}
          value={characteristic?.kenmerkWaarde}
          disabled={!ADDING}
        />
      </Grid>
      <Grid maxHeight={ELEMENT_HEIGHT} py={'0.25rem'}>
        <LabelDate
          disabled={new Date(characteristic?.ingangsDatum) < startOfTomorrow()}
          fieldName='ingangsDatum'
          label={t('supplierPromotionCharacteristics.startdate')}
          value={new Date(characteristic?.ingangsDatum)}
          onChange={d => selectDate('ingangsDatum', d)}
        />
      </Grid>
      <Grid maxHeight={ELEMENT_HEIGHT} py={'0.25rem'}>
        <LabelDate
          fieldName='eindDatum'
          label={t('supplierPromotionCharacteristics.enddate')}
          value={new Date(characteristic?.eindDatum)}
          onChange={d => selectDate('eindDatum', d)}
        />
      </Grid>
    </Grid>
  )
}
