import { Children, ReactNode, useEffect, useMemo, useState } from 'react'

declare global {
  interface Window {
    requestIdleCallback: (
      // eslint-disable-next-line id-blacklist
      callback: IdleRequestCallback,
      options?: IdleRequestOptions
    ) => number
  }
}

type DeferredLoadProps = {
  children: ReactNode
  chunkSize?: number
  timeout?: number
}

export default function DeferredLoad({
  children,
  chunkSize = 100,
  timeout = 200,
}: Readonly<DeferredLoadProps>): JSX.Element {
  const [renderedItemsCount, setRenderedItemsCount] =
    useState<number>(chunkSize)

  const childrenArray = useMemo(() => Children.toArray(children), [children])

  useEffect(() => {
    if (renderedItemsCount < childrenArray.length) {
      window.requestIdleCallback(
        () => {
          setRenderedItemsCount(
            Math.min(renderedItemsCount + chunkSize, childrenArray.length)
          )
        },
        { timeout }
      )
    }
  }, [childrenArray.length, chunkSize, renderedItemsCount, timeout])

  return <>{childrenArray.slice(0, renderedItemsCount)}</>
}
