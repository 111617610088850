import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  IconButton,
  inputBaseClasses,
  outlinedInputClasses,
  paperClasses,
  TextField,
  Typography,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config'
import { MR } from 'src/common/constants'
import { fromProductToItem } from 'src/common/lib'
import { IClient, IProduct } from 'src/common/services/client'
import { getProductsAsync } from 'src/common/services/productService'
import { Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { Divider } from '../Divider'
import LookupGrid from '../LookupDialog/LookupGrid'

interface AvailableProductsDialogProps {
  apiClient: IClient
  buttonSx?: Record<string, unknown>
  disabled?: boolean
  inclExpired?: boolean
  onChange: (aanvoerder: IProduct) => void
  showNumber?: boolean
  trigger?: 'iconButton' | 'button'
}

export default function AvailableProductsDialog({
  apiClient,
  buttonSx,
  disabled = false,
  inclExpired = false,
  onChange,
  trigger = 'iconButton',
}: Readonly<AvailableProductsDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [inProgress, setInProgress] = useState(false)
  const [open, setOpen] = useState<boolean>(false)
  const [products, setProducts] = useState<IProduct[]>([])
  const [filterString, setFilterString] = useState<string>('')

  function handleClickOpen() {
    setFilterString('')
    setOpen(true)
    fetchData()
  }

  function handleClose() {
    setOpen(false)
  }

  const itemSelected = (evt: Item<number>) => {
    const selectedProductCode = evt.code
    const product: IProduct = {
      productCode: selectedProductCode,
      productNaam: products.find(p => p.productCode === selectedProductCode)
        .productNaam,
    }
    onChange(product)
    handleClose()
  }

  const submitKeyboardInput = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  function onSubmit() {
    fetchData()
  }

  function changeFilterProductName(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    setFilterString(event.target.value)
  }

  function getODataFilter() {
    const inclExpiredFilter = !inclExpired ? 'isVervallen eq false' : ''
    const and = inclExpiredFilter && filterString ? ' and ' : ''
    const productFilter = filterString
      ? `contains(productnaam, '${filterString}')`
      : ''
    const filter = inclExpiredFilter + and + productFilter
    return filter
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    try {
      setInProgress(true)

      // eslint-disable-next-line @typescript-eslint/quotes
      const filter = getODataFilter()
      const records = await getProductsAsync(apiClient, {
        filter: filter ?? undefined,
      })
      setProducts(records)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
      setFilterString('')
    }
  }

  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton aria-label={'lookup icon button'} onClick={handleClickOpen}>
          <SearchIcon sx={{ color: theme.rfhColors.dutchOrange, height: 20 }} />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={handleClickOpen}
          sx={buttonSx}
          type='submit'
          variant='contained'
        >
          {t('common.add')}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          [`& .${paperClasses.root}`]: {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogContent sx={{ width: '600px', maxWidth: '600px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '1.5rem',
              right: MR,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              bgcolor: 'white',
              pt: '2em',
              zIndex: 1,
            }}
          >
            <Typography variant={'h4'} mb={'16px'}>
              {t('exceptions.addExceptions.searchProducts')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container gap={1.5}>
                <Grid flexBasis={0} flexGrow={1}>
                  <TextField
                    autoFocus
                    id='productNaam'
                    name='productNaam'
                    fullWidth
                    placeholder={t('exceptions.addExceptions.productName')}
                    onChange={changeFilterProductName}
                    onKeyUp={submitKeyboardInput}
                    sx={{
                      [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                        {
                          bgcolor: theme.rfhColors.grey[100],
                        },
                    }}
                  />
                </Grid>
                <Grid>
                  <Button
                    variant='contained'
                    onClick={handleSubmit(onSubmit)}
                    disabled={inProgress}
                  >
                    {t('common.search')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Divider {...{ mr: MR, mb: 0 }} />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid
            items={products?.map(fromProductToItem) ?? []}
            selectItem={itemSelected}
          />
        </DialogContent>
        <DialogActions sx={{ mr: MR }}>
          <Button
            variant='outlined'
            onClick={handleClose}
            sx={{ mb: ThemeConfig.spacing.sm * 2 }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
