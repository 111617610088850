import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  buttonBaseClasses,
  filledInputClasses,
  FormControl,
  formHelperTextClasses,
  InputAdornment,
  inputBaseClasses,
  TextField,
  Typography,
} from '@mui/material'

import debounce from 'lodash.debounce'

import { ELEMENT_HEIGHT, MAX_VALID_VALUE } from 'src/common/constants'
import { useApiClient } from 'src/common/providers'
import { getProductAsync, type IProduct } from 'src/common/services'
import { type InputProps } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import AvailableProductsDialog from './AvailableProductsDialog'

export default function AvailableProductInput({
  changeValid,
  disabled,
  inclExpired = false,
  onChange,
  required,
  sx,
  value,
}: Readonly<InputProps>): JSX.Element {
  const { t } = useTranslation()
  const [inProgress, setInProgress] = useState(false)
  const [isValid, setIsValid] = useState<boolean | undefined>(undefined)
  const [productName, setProductName] = useState<string>(undefined)
  const [productCode, setProductCode] = useState<number>(0)
  const { apiClient } = useApiClient()

  const fetchData = useCallback(
    async (newValue: number, emitUpdate = true) => {
      if (!apiClient || inProgress) {
        return Promise.resolve()
      }

      if (newValue > 0) {
        setProductName('')
        setInProgress(true)
        try {
          const product = await getProductAsync(
            apiClient,
            {
              key: newValue,
            },
            inclExpired
          )
          setProductCode(newValue)
          setProductName(product ? product.productNaam : undefined)
          setIsValid(Boolean(product))
          if (emitUpdate) {
            onChange(newValue)
            changeValid(Boolean(product))
          }
        } catch (error: any) {
          snackbarUtils.error(String(error))
        } finally {
          setInProgress(false)
        }
      } else {
        setProductCode(0)
        setProductName('')
        setIsValid(false)
        if (emitUpdate) {
          onChange(0)
          changeValid(false)
        }
      }
    },
    [apiClient, changeValid, inProgress, inclExpired, onChange]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedNewInput = useCallback(debounce(fetchData, 1000), [fetchData])

  const changeTextInput = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = Number(event.target.value)
    if (newValue >= 0 && newValue < MAX_VALID_VALUE) {
      setProductCode(newValue)
      debouncedNewInput(newValue)
    }
  }

  const changeProductInput = useCallback(
    (product: IProduct) => {
      setProductCode(product.productCode)
      setProductName(product.productNaam)
      onChange(product.productCode)
      setIsValid(true)
      changeValid(true)
    },
    [changeValid, onChange]
  )

  const getHelperText = (): string => {
    if (productCode && !inProgress) {
      return productName
    }
    return ''
  }

  const isError =
    typeof isValid === 'undefined' || !productCode ? false : !isValid

  useEffect(() => {
    if (!(value in [0, productCode])) {
      fetchData(value, false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, value])

  return (
    <FormControl fullWidth sx={sx}>
      <Typography
        variant={'subtitle2'}
        sx={{ fontWeight: 'bold', mb: '0.5rem' }}
      >
        {t('exceptions.productCode')}
      </Typography>
      <TextField
        placeholder={t('exceptions.addExceptions.selectProduct')}
        name='productcode'
        data-testid='product'
        required={required}
        disabled={disabled}
        error={isError}
        value={productCode !== 0 ? productCode : ''}
        onChange={changeTextInput}
        helperText={getHelperText()}
        slotProps={{
          input: {
            endAdornment: !disabled && (
              <InputAdornment position='end'>
                <AvailableProductsDialog
                  apiClient={apiClient}
                  inclExpired={inclExpired}
                  onChange={changeProductInput}
                />
              </InputAdornment>
            ),
          },
        }}
        sx={{
          [`& .${inputBaseClasses.root}`]: { pr: 1, height: ELEMENT_HEIGHT },
          [`& .${filledInputClasses.root}`]: { pr: 0 },
          [`& .${buttonBaseClasses.root}`]: { pr: 1 },
          [`& p.${formHelperTextClasses.root}`]: {
            position: 'absolute',
            bottom: '-2.5rem',
            left: '0.6rem',
            height: ELEMENT_HEIGHT,
          },
        }}
      />
    </FormControl>
  )
}
