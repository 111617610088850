import { FC, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridSortModel,
} from '@mui/x-data-grid-premium'
import { DeleteIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import {
  ActionModal,
  ButtonsBlock,
  CustomDataGrid,
} from 'src/common/components'
import { AvailableSuppliersDialog } from 'src/common/components/SupplierInput'
import { ThemeConfig } from 'src/common/config'
import { ELEMENT_HEIGHT, GRID_MARGIN_TOP } from 'src/common/constants'
import { usePathname } from 'src/common/hooks'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import {
  AanvoerderclusterAanvoerder,
  IAanvoerder,
  IAanvoerderclusterAanvoerder,
} from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import {
  defaultSortModel,
  FEATURE_ROOT_PATH,
  SUPPLIERS_CACHE_KEY,
} from '../constants'
import { useSupplierClusters } from '../hooks'
import { calcMarginRight, getStaticSuppliersColumns } from '../lib'
import {
  addClusterAanvoerderAsync,
  deleteClusterAanvoerderAsync,
} from '../services'
import { useSupplierClusterStore } from '../stores'

export const SuppliersGrid: FC = ({ ...rest }) => {
  const translationsKey = 'supplierCluster.supplier.deleteDialog'
  const marginRight = calcMarginRight()
  const { i18n, t } = useTranslation()
  const userId = useUser().sub
  const { apiClient } = useApiClient()
  const { ADDING } = usePathname(FEATURE_ROOT_PATH)
  const { findAnotherCluster, isDataDirty } = useSupplierClusters()
  const {
    cluster,
    clusterAanvoerders,
    ordered,
    orderedChanged,
    removeClusterAanvoerder,
    setMustRefetchCluster,
  } = useSupplierClusterStore()
  const [open, setOpen] = useState<boolean>(false)
  const [idToBeDeleted, setIdToBeDeleted] = useState<number>(0)
  const [sortModel, setSortModel] = useState(() => defaultSortModel)

  const getRowId = useCallback(
    (row: IAanvoerderclusterAanvoerder) => row.aanvoerderclusterAanvoerderId,
    []
  )

  const handleChangeSortModel = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel)
  }

  const handleDeleteClick = useCallback(
    (row: IAanvoerderclusterAanvoerder) => () => {
      setOpen(true)
      setIdToBeDeleted(row.aanvoerderclusterAanvoerderId)
    },
    [setOpen, setIdToBeDeleted]
  )

  const closeDeleteDialog = useCallback(() => {
    setOpen(false)
    setIdToBeDeleted(0)
  }, [setOpen, setIdToBeDeleted])

  const handleConfirm = useCallback(async () => {
    if (!apiClient) {
      Promise.resolve()
    }

    try {
      closeDeleteDialog()
      await deleteClusterAanvoerderAsync(apiClient, idToBeDeleted)
      removeClusterAanvoerder(idToBeDeleted)
      snackbarUtils.success(t(`${translationsKey}.deleteSuccessMessage`))
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setMustRefetchCluster(true)
    }
  }, [
    apiClient,
    closeDeleteDialog,
    idToBeDeleted,
    removeClusterAanvoerder,
    setMustRefetchCluster,
    t,
  ])

  const shouldAddBeDisabled = useCallback(
    () => isDataDirty || orderedChanged,
    [isDataDirty, orderedChanged]
  )

  const addSupplier = async (aanvoerder: IAanvoerder) => {
    const aanvoerderNummer = Number(aanvoerder.aanvoerderNummer)
    const anotherClusterNumber = findAnotherCluster(aanvoerderNummer)
    if (
      (anotherClusterNumber !== -1 &&
        cluster.aanvoerderclusterNummer !== anotherClusterNumber) ||
      Boolean(
        clusterAanvoerders.find(
          (ca: IAanvoerderclusterAanvoerder) =>
            ca.aanvoerderNummer === aanvoerderNummer
        )
      )
    ) {
      snackbarUtils.error(
        t('supplierCluster.usedInCluster', { cluster: anotherClusterNumber })
      )
      return Promise.resolve()
    }
    const newSupplier = new AanvoerderclusterAanvoerder({
      aanvoerderclusterID: cluster.aanvoerderclusterID,
      aanvoerderNummer: aanvoerder.aanvoerderNummer,
      aanvoerderNaam: aanvoerder.aanvoerderNaam,
      volgordeNummer: ordered ? clusterAanvoerders.length + 1 : 0,
      aanmaakDatumTijd: new Date(),
      aanmaakGebruiker: userId,
      mutatieDatumTijd: new Date(),
      mutatieGebruiker: userId,
    })
    try {
      await addClusterAanvoerderAsync(apiClient, newSupplier)
      setMustRefetchCluster(true)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    }
  }

  const columns = useMemo((): GridColDef[] => {
    const columnsSet = getStaticSuppliersColumns()
    columnsSet.push({
      field: 'delete',
      disableColumnMenu: true,
      type: 'actions',
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <DeleteIcon
          sx={{
            color: theme.rfhColors.grey[200],
            width: '20px',
            height: '20px',
          }}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <DeleteIcon
          onClick={handleDeleteClick(params.row)}
          sx={{
            cursor: 'pointer',
            color: theme.rfhColors.grey,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      flex: 1,
    })
    return columnsSet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleDeleteClick, i18n.language])

  return (
    <Grid container marginTop={GRID_MARGIN_TOP} {...rest}>
      <Grid size={12} height={0} zIndex={1}>
        <ButtonsBlock marginRight={marginRight}>
          <AvailableSuppliersDialog
            apiClient={apiClient}
            disabled={ADDING || shouldAddBeDisabled()}
            onChange={addSupplier}
            trigger={'button'}
            buttonSx={{
              height: ELEMENT_HEIGHT,
              width: ThemeConfig.spacing.xl * 16,
            }}
            showNumber
          />
        </ButtonsBlock>
      </Grid>
      <Grid size={12}>
        <CustomDataGrid
          cacheKey={SUPPLIERS_CACHE_KEY}
          language={i18n.language}
          getRowId={getRowId}
          columns={columns}
          rows={clusterAanvoerders}
          changeSortModel={handleChangeSortModel}
          gridProps={{
            rowCount: clusterAanvoerders.length,
            sortModel,
          }}
          pagination={false}
          paginationMode={'client'}
          sortingMode={'client'}
        />
      </Grid>
      <Grid size={12}>
        <ActionModal
          onCancel={closeDeleteDialog}
          onConfirm={handleConfirm}
          open={open}
          translationsKey={translationsKey}
        />
      </Grid>
    </Grid>
  )
}
