import type { RefreshAction, RefreshState } from 'src/common/types'

export const initialState: RefreshState = {
  loading: false,
}

export const refreshReducer = (
  state: RefreshState,
  action: RefreshAction
): RefreshState => {
  switch (action.type) {
    case 'PENDING':
      return {
        ...state,
        loading: true,
      }
    case 'RESOLVED':
    case 'ERROR':
      //! temporary set loading = false for 'ERROR'
      return {
        ...state,
        loading: false,
      }
    default: {
      throw new Error(`Unsupported action type: ${action.type}`)
    }
  }
}
