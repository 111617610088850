import { v4 as getRandomID } from 'uuid'

import { VestigingCode } from 'src/common/enums'
import { Entity } from 'src/common/hooks'
import { ILocationClock } from 'src/common/interfaces'

import type { ObjectModelsArray } from '../types'
import { byLocationCodeClockName } from './lib.sort'

export const ensureIds = (elems: ObjectModelsArray): ObjectModelsArray =>
  [...elems].map(elem => ({ ...elem, id: elem.id || getRandomID() }))

export function getConcernLocationClocks(
  locationCode: number,
  records: (typeof Entity.AuctionGroupCount)[],
  clockPool: ILocationClock[]
): ILocationClock[] {
  if (records.length === 0 || locationCode === VestigingCode.Concern) {
    return [] as ILocationClock[]
  }

  /**
   * get a list of concern clocks that have stats for this location
   * they will be added to the klok filter list
   */
  const locationConcernClocks: ILocationClock[] = []
  records
    .filter(
      r =>
        Boolean(r.klokNaam) &&
        r.klokNaam[0].toUpperCase() === 'C' &&
        r.vestigingCode === locationCode
    )
    .forEach(r => {
      if (
        // the new location clock is not already in the list
        locationConcernClocks.findIndex(lc => lc.clockName === r.klokNaam) ===
        -1
      ) {
        const clockNumber =
          clockPool.find(cp => cp.clockName === r.klokNaam)?.clockNumber ?? 0
        locationConcernClocks.push({
          clockNumber,
          clockName: r.klokNaam,
          locationCode: r.vestigingCode,
        })
      }
    })
  locationConcernClocks.sort((a, b) => a.clockNumber - b.clockNumber)
  return locationConcernClocks
}

export function splitClocks(
  clockPool: ILocationClock[],
  locationCode: number
): { localClocks: ILocationClock[]; testClocks: ILocationClock[] } {
  const localClocks = clockPool.filter(
    c => c.locationCode === locationCode && c.clockName[0].toUpperCase() !== 'T'
  )
  localClocks.sort(byLocationCodeClockName)
  const testClocks = clockPool.filter(
    c => c.locationCode === locationCode && c.clockName[0].toUpperCase() === 'T'
  )
  testClocks.sort(byLocationCodeClockName)

  return { localClocks, testClocks }
}
