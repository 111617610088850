import type { GridColDef } from '@mui/x-data-grid-premium'

import { ThemeConfig } from 'src/common/config'
import { IVeilgroepRegel } from 'src/common/services/client'
import i18n from 'src/i18n'

import { DateInput } from '../types'

export const calcMarginRight = (): number => ThemeConfig.spacing.l * 4.64

export const validateAuctionPeriod = (input: DateInput): boolean => {
  if (Object.values(input).every(el => el == null)) {
    return true
  }
  const keys: (keyof DateInput)[] = [
    'dagTtm',
    'dagVanaf',
    'weekTtm',
    'weekVanaf',
  ]

  for (const key of keys) {
    if (input[key] === undefined || input[key] === null) {
      return false
    }
  }

  return true
}

export const validateDescription = (input: IVeilgroepRegel): boolean => {
  if (!input) return false
  return (
    input?.veilgroepRegelOmschrijving?.length > 0 &&
    input?.veilgroepRegelOmschrijving?.length <= 40
  )
}

export function getRulesGridColumns(): GridColDef[] {
  const newColumns: GridColDef[] = []
  newColumns.push({
    field: 'kenmerkCode',
    headerName: i18n.t('auctionRule.characteristicCode'),
    align: 'center',
    headerAlign: 'center',
    minWidth: 120,
  })
  newColumns.push({
    field: 'kenmerkOmschrijving',
    headerName: i18n.t('auctionRule.ruleDescription'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150,
  })
  newColumns.push({
    field: 'kenmerkWaardeVan',
    headerName: i18n.t('auctionRule.from'),
    align: 'center',
    headerAlign: 'center',
    minWidth: 90,
  })
  newColumns.push({
    field: 'kenmerkWaardeTtm',
    headerName: i18n.t('auctionRule.till'),
    align: 'center',
    headerAlign: 'center',
    minWidth: 90,
  })

  return newColumns
}
