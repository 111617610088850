import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { IVeilgroepRegel, VeilgroepRegel } from 'src/common/services/client'

export type StoreState = {
  rules: VeilgroepRegel[]
  mustRefetchRules: boolean
  count: number
}

type Actions = {
  /* Store */
  resetAuctionGroupRulesStoreState: () => void
  /* Auctiongroup Rules */
  addAuctionGroupRule: (r: IVeilgroepRegel) => void
  removeAuctionGroupRule: (id: number) => void
  setAuctionGroupRules: (rs: IVeilgroepRegel[]) => void
  setCount: (input: number) => void
  setMustRefetchAuctionGroupRules: (input: boolean) => void
}

const storeName = 'AuctionGroupRulesStore'

const initialState: StoreState = {
  mustRefetchRules: false,
  rules: [],
  count: 0,
}

export const useAuctionGroupRulesStore = create<StoreState & Actions>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any) => ({
        ...initialState,
        /* Store */
        resetAuctionGroupRulesStoreState: () =>
          set(() => initialState, false, 'resetAuctionGroupRulesStoreState'),
        /* Auctiongroup Rules */
        addAuctionGroupRule: (r: VeilgroepRegel) =>
          set(
            (state: StoreState) => ({
              rules: [...state.rules, r],
            }),
            false,
            'addAuctionGroupRule'
          ),
        removeAuctionGroupRule: (id: number) =>
          set(
            (state: StoreState) => ({
              rules: [...state.rules.filter(r => r?.veilgroepRegelId !== id)],
            }),
            false,
            'removeAuctionGroupRule'
          ),
        setAuctionGroupRules: (rs: VeilgroepRegel[]) =>
          set(() => ({ rules: [...rs] }), false, 'setAuctionGroupRules'),
        setCount: (input: number) =>
          set(
            () => ({
              count: input,
            }),
            false,
            'setCount'
          ),
        setMustRefetchAuctionGroupRules: (input: boolean) =>
          set(
            () => ({
              mustRefetchRules: input,
            }),
            false,
            'setMustRefetchAuctionGroupRules'
          ),
      }),
      {
        name: 'auctiongroup-rules-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
