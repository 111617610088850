import { useTranslation } from 'react-i18next'
import {
  Route,
  RouteProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'

import { SecureRoute as OktaSecureRoute } from '@okta/okta-react'
import { LoadingPage } from '@rfh-core/components'

import { useUser } from 'src/common/providers/UserProvider'
import { NotFound } from 'src/views'

import type { RenderProps } from './utils'

type AuctionCoordinatorRouteProps = RouteProps & { enabled?: boolean }

/**
 * Route that can only be accessed when a user is authenticated and a application manager, when
 * these conditions are not met you will be redirected to the base route.
 */
export default function AuctionCoordinatorRoute({
  component: Component,
  enabled = true,
  ...rest
}: Readonly<AuctionCoordinatorRouteProps>): JSX.Element {
  const user = useUser()
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t } = useTranslation()

  const getContent = (props: any): JSX.Element => {
    if (!user.isAuthenticated) {
      return <LoadingPage label={t('common.loading')} />
    }

    // user is authenticated but the "enabled" prop is passed as "false"
    // => nothing to do
    if (enabled != null && !enabled) {
      return
    }

    // "enabled" prop is not passed at all or passed as "true"
    // => the Component may be shown
    if (!user.isAuctionCoordinator) {
      console.warn('Unauthorized access to Auction Coordinator only route')
      history.push('/dashboard') // this will return
    }

    return (
      <Component
        history={history}
        location={location}
        match={match}
        {...props}
      />
    )
  }

  return enabled ? (
    <OktaSecureRoute
      {...(rest as any)}
      render={(props: RenderProps) => getContent(props)}
    />
  ) : (
    <Route component={NotFound} />
  )
}
