import { GridSortModel } from '@mui/x-data-grid-premium'

import {
  IProductclusterProductgroep,
  IProductclusterView,
} from 'src/common/services/client'

export const FEATURE_ROOT_PATH = 'product-clusters'
export const CLUSTERS_CACHE_KEY = 'product-clusters-datagrid'
export const PRODUCTGROEPEN_CACHE_KEY =
  'product-cluster-productgroepen-datagrid'
export const defaultSortModel: GridSortModel = [
  { field: 'productgroepCode', sort: 'asc' },
]

export const emptyCluster: IProductclusterView = {
  productclusterID: 0,
  productclusterOmschrijving: null,
  bloemenPlantenCode: null,
  productclusterProductgroepen: [],
  veilgroepRegels: null,
  eersteProductgroepen: '',
  eersteVeilgroepen: '',
  aanmaakDatumTijd: new Date('0001-01-01T00:00:00+01:00'),
  aanmaakGebruiker: null,
  mutatieDatumTijd: new Date('0001-01-01T00:00:00+01:00'),
  mutatieGebruiker: null,
}

export const emptyProductgroep: IProductclusterProductgroep = {
  productclusterProductgroepID: 0,
  productgroepCode: 0,
  productclusterID: 0,
  productgroepOmschrijving: null,
  volgordeNummer: null,
  aanmaakDatumTijd: new Date('0001-01-01T00:00:00+01:00'),
  aanmaakGebruiker: null,
  mutatieDatumTijd: new Date('0001-01-01T00:00:00+01:00'),
  mutatieGebruiker: null,
}
