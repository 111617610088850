import { GridSortModel } from '@mui/x-data-grid-premium'

import { IAanvoerderclusterView } from 'src/common/services/client'

export const FEATURE_ROOT_PATH = 'supplier-clusters'
export const CLUSTERS_CACHE_KEY = 'supplier-clusters-datagrid-state'
export const SUPPLIERS_CACHE_KEY = 'supplier-cluster-suppliers-datagrid-state'
export const defaultSortModel: GridSortModel = [
  { field: 'volgordeNummer', sort: 'asc' },
]

export const emptyCluster: IAanvoerderclusterView = {
  aanvoerderclusterNummer: 0,
  aanvoerderclusterOmschrijving: '',
}
