import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import { IVeilgroepIndelingOverview } from 'src/common/services/client'

import { SearchFilterInStore } from '../types'

export type StoreState = {
  allocations: IVeilgroepIndelingOverview[]
  filter: SearchFilterInStore
  mustRefetchAllocations: boolean
}

type Actions = {
  /* store */
  resetAllocationsStoreState: () => void
  /* allocations */
  setAllocations: (as: IVeilgroepIndelingOverview[]) => void
  setFilter: (delta: Partial<SearchFilterInStore>) => void
  setMustRefetchAllocations: (input: boolean) => void
}

const storeName = 'AllocationsStore'

const initialStoreState: StoreState = {
  allocations: [],
  filter: { bloemenPlantenCode: 'B', locationCode: 1 },
  mustRefetchAllocations: false,
}

const useAllocationsStore = create<StoreState & Actions>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetAllocationsStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetAllocationsStoreState'
          ),
        /* allocations */
        setAllocations: (as: IVeilgroepIndelingOverview[]) =>
          set(() => ({ allocations: [...as] }), false, 'setAllocations'),
        setFilter: (newFilter: SearchFilterInStore) =>
          set(
            (state: StoreState) => ({
              filter: { ...state.filter, ...newFilter },
            }),
            false,
            'setFilter'
          ),
        setMustRefetchAllocations: (input: boolean) =>
          set(
            () => ({ mustRefetchAllocations: input }),
            false,
            'setMustRefetchAllocations'
          ),
      }),
      {
        name: 'auction-group-allocations-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)

export default useAllocationsStore
