import {
  Grid2 as Grid,
  inputAdornmentClasses,
  inputBaseClasses,
  svgIconClasses,
  Typography,
} from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import theme from '@rfh-core/theme'

import { noOp } from 'src/common/lib'

type LabelDateProps = {
  fieldName: string
  label: string
  disabled?: boolean
  value?: Date
  onChange?: (date: Date) => void
}

export default function LabelDate({
  disabled = false,
  fieldName,
  label,
  value = null,
  onChange = noOp,
}: Readonly<LabelDateProps>): JSX.Element {
  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
    >
      <Grid>
        <Typography
          aria-label={`id-${fieldName}`}
          variant={'body2'}
          sx={{
            color: theme.rfhColors.black,
            whiteSpace: 'nowrap',
            minWidth: 170,
            py: 0,
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid>
        <DatePicker
          aria-label={`date picker for ${label.toLowerCase()}`}
          defaultValue={null}
          disabled={disabled}
          value={value}
          onChange={onChange}
          slotProps={{
            textField: {
              sx: {
                maxWidth: 160,
                [`& .${inputBaseClasses.root}`]: { pl: 2 },
                [`& .${inputAdornmentClasses.root}`]: {
                  pr: 1,
                  [`& svg.${svgIconClasses.root}`]: {
                    color: theme.rfhColors.dutchOrange,
                    fontSize: '1.25rem',
                  },
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  )
}
