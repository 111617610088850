import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'

import { Divider } from 'src/common/components'

import { BatchesStatusFilter, BatchesStatusGrid } from './components'

export default function BatchesStatus(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Grid size={12} mb={2}>
        <Typography variant='h2' style={{ fontWeight: 'bold' }}>
          {t('common.titles.searchBatchesStatus')}
        </Typography>
      </Grid>
      <Grid size={12}>
        <BatchesStatusFilter />
      </Grid>
      <Grid size={{ sm: 12 }}>
        <Divider />
      </Grid>
      <Grid size={{ sm: 12 }}>
        <BatchesStatusGrid />
      </Grid>
    </>
  )
}
