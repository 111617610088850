import { DEFAULT_TAKE } from '../constants'
import { IClient, IUitzonderingReden } from './client'

export const getExceptionReasons = async (
  apiClient: IClient
): Promise<Partial<IUitzonderingReden>[]> => {
  try {
    const result =
      (await apiClient.uitzonderingRedenen_UitzonderingReden_ListUitzonderingReden(
        DEFAULT_TAKE,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      )) as any

    const records: Partial<IUitzonderingReden>[] =
      result?.cleanedResponse?.value
    return records
  } catch (error: any) {
    console.log(error)
  }
}
