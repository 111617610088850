import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  TextField,
} from '@mui/material'

import { ThemeConfig } from 'src/common/config'

import { useAuctionGroupRuleStore } from '../stores'
import { WeekDay } from '../types'
import WeekDaySelector from './WeekDaySelector'

export default function AuctionPeriod(): JSX.Element {
  const { t } = useTranslation()
  const { auctionPeriod, setAuctionPeriod } = useAuctionGroupRuleStore()

  const getdays = useCallback(() => {
    const days: JSX.Element[] = [
      <option key='' value={undefined}>
        {''}
      </option>,
    ]
    const dayData: JSX.Element[] = Object.keys(WeekDay)
      .filter(item => !Number(item))
      .map(day => (
        <option key={day} value={WeekDay[day as keyof typeof WeekDay]}>
          {t<any>(`auctionRule.${day.toLowerCase()}`)}
        </option>
      ))
    return <>{[days, ...dayData]}</>
  }, [t])

  const changeAuctionPeriod = useCallback(
    (
      event: ChangeEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >
    ) => {
      const { name, value } = event.target
      setAuctionPeriod({
        ...auctionPeriod,
        [name]: Number(value) && Number(value) > 0 ? Number(value) : null,
      })
    },
    [setAuctionPeriod, auctionPeriod]
  )

  const clearAuctionPeriod = (name: string) => {
    setAuctionPeriod({
      ...auctionPeriod,
      [name]: null,
    })
  }

  return (
    <Grid
      container
      width={'100%'}
      justifyContent={'space-between'}
      wrap={'nowrap'}
    >
      <Grid>
        <FormControl
          sx={{
            m: 0,
            width: '100%',
            '& label span': {
              fontWeight: 100,
            },
          }}
        >
          <FormControlLabel
            label={t('auctionRule.weekFrom')}
            sx={{ width: '100%' }}
            control={
              <TextField
                slotProps={{
                  htmlInput: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  },
                }}
                value={
                  Number(auctionPeriod?.weekVanaf) &&
                  auctionPeriod.weekVanaf > 0
                    ? auctionPeriod.weekVanaf
                    : ''
                }
                name='weekVanaf'
                onChange={changeAuctionPeriod}
                sx={{ mr: ThemeConfig.spacing.sm }}
              />
            }
          />
        </FormControl>
      </Grid>
      <Grid mr={2}>
        <WeekDaySelector
          label={t('auctionRule.dayFrom')}
          name={'dagVanaf'}
          Options={getdays()}
          value={auctionPeriod?.dagVanaf}
          onChange={changeAuctionPeriod}
          onClick={() => clearAuctionPeriod('dagVanaf')}
        />
      </Grid>
      <Grid>
        <FormControl
          sx={{
            m: 0,
            width: '100%',
            '& label span': {
              fontWeight: 100,
            },
          }}
        >
          <FormControlLabel
            label={t('auctionRule.weekTill')}
            sx={{ width: '100%' }}
            control={
              <TextField
                slotProps={{
                  htmlInput: {
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  },
                }}
                name='weekTtm'
                value={
                  auctionPeriod?.weekTtm !== 0 &&
                  auctionPeriod?.weekTtm !== undefined &&
                  auctionPeriod.weekTtm !== null &&
                  !isNaN(auctionPeriod.weekTtm)
                    ? `${auctionPeriod.weekTtm}`
                    : ''
                }
                onChange={changeAuctionPeriod}
                sx={{ mr: ThemeConfig.spacing.sm }}
              />
            }
          />
        </FormControl>
      </Grid>
      <Grid>
        <WeekDaySelector
          label={t('auctionRule.dayTill')}
          name={'dagTtm'}
          Options={getdays()}
          value={auctionPeriod?.dagTtm}
          onChange={changeAuctionPeriod}
          onClick={() => clearAuctionPeriod('dagVanaf')}
        />
      </Grid>
    </Grid>
  )
}
