import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  IVeilgroepIndelingOverview,
  IVeilgroepIndelingRegelView,
  Kenmerk,
} from 'src/common/services/client'

import { byVolgordeNummer } from '../lib'

export type StoreState = {
  allocation: IVeilgroepIndelingOverview
  allocationRules: IVeilgroepIndelingRegelView[]
  characteristics: Kenmerk[]
  mustRefetchAllocationRules: boolean
  oldAllocationRules: IVeilgroepIndelingRegelView[]
}

type Actions = {
  /* store */
  resetAllocationStoreState: () => void
  /* allocation */
  removeAllocationRule: (id: number) => void
  setAllocation: (a: IVeilgroepIndelingOverview) => void
  setAllocationRules: (ars: IVeilgroepIndelingRegelView[]) => void
  setCharacteristics: (cs: Kenmerk[]) => void
  setIsTouched: (input?: boolean) => void
  setMustRefetchAllocationRules: (input: boolean) => void
  setOldAllocationRules: (oars: IVeilgroepIndelingRegelView[]) => void
  /* computables */
  getNewOrderNumber: () => number
}

const storeName = 'AllocationStore'

const initialStoreState: StoreState = {
  allocation: null as any as IVeilgroepIndelingOverview,
  allocationRules: [] as IVeilgroepIndelingRegelView[],
  characteristics: [] as Kenmerk[],
  mustRefetchAllocationRules: false,
  oldAllocationRules: [] as IVeilgroepIndelingRegelView[],
}

const useAllocationStore = create<StoreState & Actions>()(
  devtools(
    persist(
      (set, get: () => any) => ({
        ...initialStoreState,
        /* store */
        resetAllocationStoreState: () =>
          set(
            () => initialStoreState,
            false, // replace or not replace - that is the question!
            'resetAllocationStoreState'
          ),
        /* allocation */
        removeAllocationRule: (id: number) =>
          set(
            (state: StoreState) => ({
              ...state,
              clusterAanvoerders: [
                ...state.allocationRules.filter(
                  cs => cs?.productclusterVeilgroepRegelId !== id
                ),
              ],
            }),
            false,
            'removeAllocationRule'
          ),
        setAllocation: (a: IVeilgroepIndelingOverview) =>
          set(() => ({ allocation: a }), false, 'setAllocation'),
        setAllocationRules: (ars: IVeilgroepIndelingRegelView[]) =>
          set(
            () => {
              const rules = [...ars]
              rules.sort(byVolgordeNummer)
              return { allocationRules: rules }
            },
            false,
            'setAllocationRules'
          ),
        setCharacteristics: (cs: Kenmerk[]) =>
          set(
            () => ({ characteristics: [...cs] }),
            false,
            'setCharacteristics'
          ),
        setMustRefetchAllocationRules: (input: boolean) =>
          set(
            () => ({ mustRefetchAllocationRules: input }),
            false,
            'setMustRefetchAllocationRules'
          ),
        setOldAllocationRules: (oars: IVeilgroepIndelingRegelView[]) =>
          set(
            (state: StoreState) => {
              const rules = [...oars]
              rules.sort(byVolgordeNummer)
              return { ...state, oldAllocationRules: rules }
            },
            false,
            'setOldAllocationRules'
          ),
        /* computables */
        getNewOrderNumber: () =>
          Math.max(
            0,
            ...get().allocationRules.map(
              (ar: IVeilgroepIndelingRegelView) => ar.volgordeNummer
            )
          ) + 1,
      }),
      {
        name: 'auction-group-allocation-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)

export default useAllocationStore
