import { ReactNode } from 'react'

import {
  FormControl,
  FormLabel,
  Grid2 as Grid,
  type SxProps,
} from '@mui/material'

import { ThemeConfig } from 'src/common/config'

type FormInputProps = {
  label: string
  id: string
  input: ReactNode
  sx?: SxProps
}

export default function FormInput({
  label,
  input,
  id,
  sx,
}: Readonly<FormInputProps>): JSX.Element {
  return (
    <Grid
      container
      alignItems={'center'}
      sx={{ my: ThemeConfig.spacing.xs, maxHeight: '3rem', ...sx }}
    >
      <Grid size={{ xs: 12, sm: 4 }}>
        <FormLabel htmlFor={id} sx={{ fontSize: '1rem', lineHeight: '1rem' }}>
          {label}
        </FormLabel>
      </Grid>
      <Grid size={{ xs: 12, sm: 8 }}>
        <FormControl
          id={id}
          sx={{
            m: 0,
            width: '100%',
          }}
        >
          {input}
        </FormControl>
      </Grid>
    </Grid>
  )
}
