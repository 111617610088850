import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, capitalize, Grid2 as Grid, Typography } from '@mui/material'
import theme from '@rfh-core/theme'

import FullscreenDialog from 'src/common/components/FullscreenDialog'
import Spinner from 'src/common/components/Spinner'
import { StyledGridCard } from 'src/common/components/StyledGridCard'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { boxLoaderStyles } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import {
  getProductGroupRegularityHistory,
  getSupplierProductGroupRegularityView,
} from 'src/common/services'
import type { IAanvoerderProductgroepRegelmatigView } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import ProductGroupRegularityDetailsItem from './ProductGroupRegularityDetailsItem'
import ProductGroupRegularityHistoryGrid from './ProductGroupRegularityHistoryGrid'

type ProductGroupRegularityDetailsProps = {
  open: boolean
  aanvoerderProductgroepRegelmatigId: string
  onClose: () => void
}

export default function ProductGroupRegularityDetails({
  aanvoerderProductgroepRegelmatigId,
  open,
  onClose,
}: Readonly<ProductGroupRegularityDetailsProps>): JSX.Element {
  /**
   * State
   */
  const [regularity, setRegularity] =
    useState<IAanvoerderProductgroepRegelmatigView>(undefined)
  const [regularityHistory, setRegularityHistory] = useState<
    IAanvoerderProductgroepRegelmatigView[]
  >([])
  const [loadingError, setLoadingError] = useState<boolean>(false)
  const [loadingData, setLoadingData] = useState<boolean>(false)
  const [loadingHistory, setLoadingHistory] = useState<boolean>(false)

  const { t } = useTranslation()
  const { apiClient } = useApiClient()

  const fetchData = async () => {
    if (loadingData) {
      return Promise.resolve()
    }

    try {
      setLoadingData(true)
      const result = await getSupplierProductGroupRegularityView(apiClient, {
        key: Number(aanvoerderProductgroepRegelmatigId),
      })

      if (result) {
        setRegularity(result)
        setLoadingError(false)
      } else {
        setLoadingError(true)
      }
    } catch (error: any) {
      snackbarUtils.error(`FetchData: ${error}`)
      setLoadingError(true)
    } finally {
      setLoadingData(false)
    }
  }

  const fetchHistory = async () => {
    if (loadingHistory) {
      return Promise.resolve()
    }

    try {
      setLoadingHistory(true)
      const records = (await getProductGroupRegularityHistory(apiClient, {
        vestigingCode: regularity.vestigingCode,
        aanvoerderNummer: regularity.aanvoerderNummer,
        productgroepCode: regularity.productgroepCode,
      })) as any

      if (records) {
        setRegularityHistory(records)
        setLoadingError(false)
      } else {
        setLoadingError(true)
      }
    } catch (error: any) {
      snackbarUtils.error(`FetchHistory: ${error}`)
      setLoadingError(true)
    } finally {
      setLoadingHistory(false)
    }
  }

  /*
  Side Effects
  */
  useEffect(() => {
    if (aanvoerderProductgroepRegelmatigId) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aanvoerderProductgroepRegelmatigId])

  useEffect(() => {
    if (regularity) {
      fetchHistory()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regularity])

  return (
    <FullscreenDialog
      title={t('common.titles.regularityDetails')}
      open={open}
      handleClose={onClose}
    >
      <Grid
        container
        sx={{
          width: '100%',
          marginLeft: 0,
          '& > .MuiGrid-root.MuiGrid-item': {
            paddingLeft: 0,
            width: '100%',
            '& .MuiBox-root': {
              bgcolor: theme.rfhColors.grey[200],
              minHeight: 0,
              paddingLeft: 0,
            },
          },
        }}
        spacing={2}
      >
        <Grid
          size={12}
          sx={{
            padding: '0 !important',
            my: ThemeConfig.spacing.sm,
          }}
        >
          <Typography variant={'h2'} style={{ fontSize: '34px' }}>
            {t('common.titles.regularityDetails')}
          </Typography>
        </Grid>
        {regularity ? (
          <StyledGridCard lg={6} xs={12}>
            <Box sx={boxLoaderStyles(!!regularity)}>
              <ProductGroupRegularityDetailsItem
                label={t('overviews.supplierName')}
                value={`${regularity.aanvoerderNummer} ${
                  regularity.aanvoerderNaam ?? ''
                }`}
              />
              <ProductGroupRegularityDetailsItem
                label={t('common.auctionLocation')}
                value={capitalize(regularity.vestigingNaam)}
              />
              <ProductGroupRegularityDetailsItem
                label={t('overviews.productGroupDescription')}
                value={` ${regularity.productgroepCode} ${
                  regularity.productgroepNaam ?? ''
                }`}
              />
            </Box>
          </StyledGridCard>
        ) : (
          <Spinner isError={loadingError} />
        )}
        <ProductGroupRegularityHistoryGrid
          regularityHistory={regularityHistory}
          isLoading={loadingHistory}
        />
      </Grid>
    </FullscreenDialog>
  )
}
