import { memo, useCallback, useLayoutEffect, useMemo, useReducer } from 'react'

import { useStore } from 'zustand'

import { usePathname } from 'src/common/hooks'
import { getStringValue, initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH } from '../constants'
import { useAllocation } from '../hooks'
import { isDataConsistent } from '../lib'
import { updateAllocationRulesAsync } from '../services'
import { useAllocationsStore, useAllocationStore } from '../stores'
import CommonFilter from './CommonFilter'

export default memo(function AllocationRulesFilter(): JSX.Element {
  const { apiClient } = useApiClient()
  const { urlParam } = usePathname(FEATURE_ROOT_PATH)
  const { isDataDirty } = useAllocation()
  const allocations = useStore(useAllocationsStore, state => state.allocations)
  const {
    allocation,
    allocationRules,
    setAllocation,
    setIsTouched,
    setMustRefetchAllocationRules,
  } = useAllocationStore()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const updateVeilgroepIndeling = useCallback(async () => {
    if (!apiClient || state.loading) {
      return Promise.resolve()
    }

    try {
      dispatch({ type: 'PENDING' })
      await updateAllocationRulesAsync(apiClient, [...allocationRules])
    } catch (error: any) {
      snackbarUtils.error(getStringValue(error))
    } finally {
      dispatch({ type: 'RESOLVED' })
      setMustRefetchAllocationRules(true)
      setIsTouched(false)
    }
  }, [
    apiClient,
    state.loading,
    allocationRules,
    setMustRefetchAllocationRules,
    setIsTouched,
  ])

  const shouldSaveBeDisabled = useMemo(
    () => !isDataDirty || state.loading,
    [isDataDirty, state.loading]
  )
  useLayoutEffect(() => {
    const isIdSameAsUri = allocation?.productclusterID === urlParam[0]
    if (
      isIdSameAsUri &&
      allocationRules.length > 0 &&
      isDataConsistent(allocation?.productclusterID, allocationRules)
    ) {
      return
    }
    // get productclusterID from uri and find its allocation
    const productclusterID = urlParam[0]
    const allocationFound = allocations?.find(
      a => a.productclusterID === productclusterID
    )
    // precaution in case there is no allocation in the store
    // or the allocation does not match the uri
    if (!isIdSameAsUri && allocationFound) {
      setAllocation(allocationFound)
      setMustRefetchAllocationRules(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CommonFilter
      saveDisabled={shouldSaveBeDisabled}
      onClick={updateVeilgroepIndeling}
    />
  )
})
