import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'

import { useStore } from 'zustand'

import { Divider } from 'src/common/components'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { IClient } from 'src/common/services/client'
import {
  getDefaultPagination,
  getDefaultSortModel,
  getOrderBy,
  getSkip,
  getTop,
  snackbarUtils,
} from 'src/common/utils'

import CharacteristicsFilter from '../components/CharacteristicsFilter'
import CharacteristicsGrid from '../components/CharacteristicsGrid'
import { CHARACTERISTICS_CACHE_KEY, defaultSortModel } from '../constants'
import { getAllSupplierPromotionCharacteristicsAsync } from '../services/supplierPromotionCharacteristicsServices'
import {
  useSupplierPromotionCharacteristicsStore,
  useSupplierPromotionCharacteristicStore,
} from '../stores'

export default function SupplierPromotionCharacteristics(): JSX.Element {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const user = useUser()

  const {
    mustRefetchCharacteristics,
    setCharacteristics,
    setMustRefetchCharacteristics,
    setNumberOfCharacticeristics,
    pagination,
    setPagination,
    sorting,
    setSorting,
    filter,
  } = useSupplierPromotionCharacteristicsStore()
  const resetSupplierPromotionCharacteristicState = useStore(
    useSupplierPromotionCharacteristicStore,
    state => state.resetSupplierPromotionCharacteristicState
  )
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const fetchData = useCallback(
    async (client: IClient): Promise<void> => {
      if (!client || state.loading) {
        return Promise.resolve()
      }
      try {
        dispatch({ type: 'PENDING' })
        const promotionCharacteristics =
          await getAllSupplierPromotionCharacteristicsAsync(client, {
            top: getTop(pagination),
            skip: getSkip(pagination),
            filter: filter?.length > 0 ? filter : undefined,
            orderby: sorting?.length
              ? getOrderBy(sorting)
              : getOrderBy(defaultSortModel),
            count: true,
          })
        setCharacteristics(promotionCharacteristics.records)
        setNumberOfCharacticeristics(promotionCharacteristics.count)
      } catch (error) {
        snackbarUtils.error(String(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [
      state.loading,
      pagination,
      filter,
      sorting,
      setCharacteristics,
      setNumberOfCharacticeristics,
    ]
  )

  useEffect(() => {
    if (apiClient && mustRefetchCharacteristics) {
      fetchData(apiClient)
      setMustRefetchCharacteristics(false)
    }
  }, [
    apiClient,
    fetchData,
    mustRefetchCharacteristics,
    setMustRefetchCharacteristics,
  ])

  useEffect(() => {
    setMustRefetchCharacteristics(true)
  }, [
    pagination?.paginationModel?.page,
    pagination?.paginationModel?.pageSize,
    sorting,
    filter,
    setMustRefetchCharacteristics,
  ])

  useEffect(() => {
    resetSupplierPromotionCharacteristicState()
    setPagination(getDefaultPagination(CHARACTERISTICS_CACHE_KEY))
    setSorting(getDefaultSortModel(CHARACTERISTICS_CACHE_KEY, defaultSortModel))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid size={12} mb={2}>
        <Typography variant='h2' sx={{ fontWeight: 'bold' }}>
          {user.isAuctionCoordinator
            ? t('common.titles.manageSupplierPromotionCharacteristics')
            : t('common.titles.supplierPromotionCharacteristics')}
        </Typography>
      </Grid>
      <Grid size={12}>
        <CharacteristicsFilter />
      </Grid>
      <Divider />
      <Grid size={12}>
        <CharacteristicsGrid
          data-testid={'characteristics-grid'}
          loading={state.loading}
        />
      </Grid>
    </>
  )
}
