import { GridColDef } from '@mui/x-data-grid-premium'

import { ThemeConfig } from 'src/common/config'
import type { IKenmerkWaarde } from 'src/common/services/client'
import type { Item } from 'src/common/types'
import { constructFilterRuleForKVP, formatDate } from 'src/common/utils'
import i18n from 'src/i18n'

import { FilterInput } from '../types'

export const calcMarginRight = (): number => ThemeConfig.spacing.l * 4.64

export const getStaticColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'aanvoerderNummer',
    headerName: i18n.t('overviews.supplierNumber'),
    headerAlign: 'left',
    align: 'right',
    flex: 1.75,
  })
  columns.push({
    field: 'productCode',
    headerName: i18n.t('overviews.productCode'),
    headerAlign: 'left',
    align: 'right',
    flex: 1.75,
  })
  columns.push({
    field: 'kenmerkWaardeOmschrijving',
    headerName: i18n.t(
      'supplierPromotionCharacteristics.characteristicDescription'
    ),
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  })
  columns.push({
    field: 'ingangsDatum',
    headerName: i18n.t('supplierPromotionCharacteristics.startdate'),
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    valueFormatter: row =>
      row.value && formatDate(row, i18n.t('common.dateFormatShort')),
    flex: 3,
  })
  columns.push({
    field: 'eindDatum',
    headerName: i18n.t('supplierPromotionCharacteristics.enddate'),
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    valueFormatter: row =>
      row.value && formatDate(row, i18n.t('common.dateFormatShort')),
    flex: 3,
  })
  columns.push({
    field: 'mutatieDatumTijd',
    headerName: i18n.t('supplierPromotionCharacteristics.mutadionDate'),
    headerAlign: 'left',
    align: 'left',
    type: 'date',
    valueFormatter: row =>
      row.value && formatDate(row, i18n.t('common.dateFormatShort')),
    flex: 3,
  })
  columns.push({
    field: 'mutatieGebruiker',
    headerName: i18n.t('supplierPromotionCharacteristics.mutationUser'),
    headerAlign: 'left',
    align: 'left',
    flex: 3,
  })

  return columns
}

export const createFilter = <
  TKey extends keyof FilterInput,
  TValue extends FilterInput[TKey]
>(
  key: TKey,
  value: TValue,
  filterRules: string[]
): string[] => {
  let filterRule = ''

  if (key !== 'toonVervallen') {
    filterRule = constructFilterRuleForKVP(key, value as string | number)
    filterRules.push(filterRule)
  } else if (!value) {
    filterRule = `eindDatum ge ${new Date().toISOString()}`
    filterRules.push(filterRule)
  }

  return filterRules
}

export const fromIKenmerkWaardeToItem = (kw: IKenmerkWaarde): Item<string> => ({
  code: kw.kenmerkWaardeCode,
  name: kw.kenmerkWaardeOmschrijving,
})
