// eslint-disable-next-line
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '@mui/material'
import theme from '@rfh-core/theme'

import { ThemeConfig } from '../config/SpacingConfig'

type BooleanTagProps = {
  value: boolean
  inverseColors?: boolean
}

export default function BooleanTag({
  value,
  inverseColors = false,
}: Readonly<BooleanTagProps>): JSX.Element {
  const { t } = useTranslation()

  const tagColor =
    (value && inverseColors) || (!value && !inverseColors)
      ? theme.rfhColors.alertRed
      : theme.rfhColors.hydroGreen

  return (
    <Box
      data-testid='card-tag'
      px={ThemeConfig.spacing.xs}
      py={0}
      sx={{
        minWidth: '50px',
        bgcolor: tagColor,
        borderRadius: '12px',
      }}
    >
      <Typography
        sx={{
          color: theme.rfhColors.white,
          lineHeight: '1.5rem !important',
          fontSize: '0.6rem !important',
          fontWeight: 'bold !important',
          textAlign: 'center',
          textTransform: 'uppercase',
        }}
      >
        {value ? t('common.yes') : t('common.no')}
      </Typography>
    </Box>
  )
}
