/* eslint-disable id-blacklist */
import { ChangeEvent, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControlLabel, Radio, RadioGroup } from '@mui/material'

import {
  ActionButton,
  AvailableLocations,
  AvailableProductGroupInput,
  AvailableSupplierInput,
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
} from 'src/common/components'
import { useFilter } from 'src/common/hooks'
import { IAllSearchFilterFields, IOverviewsFilter } from 'src/common/interfaces'
import { noOp } from 'src/common/lib'
import {
  constructFilterString,
  isFilterEmpty,
  pushNewFilterRule,
} from 'src/common/utils'

export default function OverviewsFilter({
  changeFilterString,
  resetSearch: resetFilterString,
  ...rest
}: Readonly<IOverviewsFilter>): JSX.Element {
  const CACHE_KEY = 'overviews-product-group-exceptions-filter'
  const defaultValues: IAllSearchFilterFields = {
    perioden: 'allePerioden',
  }
  const { t } = useTranslation()

  const setFilterString = (newValues: IAllSearchFilterFields) => {
    if (isFilterEmpty(newValues)) {
      changeFilterString(undefined)
      return
    }

    const newFilterString = constructFilterString(newValues, pushNewFilterRule)
    changeFilterString(newFilterString)
  }

  const {
    filter,
    clear,
    handleChange,
    handleRequest: search,
  } = useFilter<IAllSearchFilterFields>({
    cacheKey: CACHE_KEY,
    defaultFilter: defaultValues,
    resetFilter: resetFilterString,
    setFilterString,
  })

  useEffect(() => {
    clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeRadioButton = (evt: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = evt.target
    handleChange(name as keyof IAllSearchFilterFields, value)
  }

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <AvailableSupplierInput
          changeSupplierNumber={value =>
            handleChange('aanvoerderNummer', value > 0 ? value : undefined)
          }
          value={filter.aanvoerderNummer ? filter.aanvoerderNummer : undefined}
          changeValid={noOp}
          sx={{ maxWidth: 180 }}
        />
        <AvailableProductGroupInput
          onChange={value =>
            handleChange('productgroepCode', value > 0 ? value : undefined)
          }
          value={filter.productgroepCode ? filter.productgroepCode : undefined}
          values={filter}
          changeValid={noOp}
          sx={{ maxWidth: 200 }}
        />
        <AvailableLocations
          handleChange={handleChange}
          inputValue={filter.vestigingCode}
          labelVisible
          sx={{ ml: 0.2, width: 160 }}
        />
        <RadioGroup
          defaultValue={'allePerioden'}
          name='perioden'
          row
          value={filter?.perioden}
        >
          <FormControlLabel
            control={<Radio onChange={changeRadioButton} />}
            label={t('common.allPeriods')}
            labelPlacement={'top'}
            value='allePerioden'
            slotProps={{
              typography: { sx: { pb: 2, fontWeight: 'bold !important' } },
            }}
            sx={{ ml: 0, mr: 0.5, mb: 0.2 }}
          />
          <FormControlLabel
            control={<Radio onChange={changeRadioButton} />}
            label={t('common.past')}
            labelPlacement={'top'}
            value='periodenVerleden'
            slotProps={{
              typography: { sx: { pb: 2, fontWeight: 'bold !important' } },
            }}
            sx={{ mx: 0.5, mb: 0.2 }}
          />
          <FormControlLabel
            control={<Radio onChange={changeRadioButton} />}
            label={t('common.present')}
            labelPlacement={'top'}
            value='periodenActueel'
            slotProps={{
              typography: { sx: { pb: 2, fontWeight: 'bold !important' } },
            }}
            sx={{ mx: 0.5, mb: 0.2 }}
          />
          <FormControlLabel
            control={<Radio onChange={changeRadioButton} />}
            label={t('common.future')}
            labelPlacement={'top'}
            value='periodenToekomst'
            slotProps={{
              typography: { sx: { pb: 2, fontWeight: 'bold !important' } },
            }}
            sx={{ mx: 0.5, mb: 0.2 }}
          />
        </RadioGroup>
      </FilterFieldsStack>
      <FilterButtonsStack>
        <ActionButton variant='outlined' onClick={clear}>
          {t('common.clear')}
        </ActionButton>
        <ActionButton type='submit' onClick={search}>
          {t('common.search')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
