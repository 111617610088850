import {
  createTheme,
  CssBaseline,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material'
import rfhTheme from '@rfh-core/theme'
import '@rfh-core/theme/dist/fonts.css'

import type { ChildrenType } from './types'

const theme = createTheme({
  ...rfhTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
})
export const ThemeProvider = ({ children }: ChildrenType): JSX.Element => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    {children}
  </MuiThemeProvider>
)
