import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useEffect,
  useState,
} from 'react'

import {
  FormControl,
  FormLabel,
  Grid2 as Grid,
  SxProps,
  TextField,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { getStringValue } from 'src/common/lib'

type LabelInputProps = {
  disabled?: boolean
  fieldName?: string
  inputValue?: string | number
  label?: string
  onSave?: (newInput: string) => void
  sx?: SxProps
}

export default function LabelInput({
  onSave,
  inputValue,
  label,
  disabled = false,
  fieldName,
  sx,
}: Readonly<LabelInputProps>): JSX.Element {
  const [value, setValue] = useState<string>('')
  const changeTextInput = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setValue(event.target.value)
  }

  const submitInputByLooseFocus = (event: FocusEvent<HTMLInputElement>) => {
    onSave(event.target?.value ?? '')
  }

  const submitKeyboardInput = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSave(value)
    }
  }

  useEffect(() => {
    setValue(getStringValue(inputValue))
  }, [inputValue])
  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={sx}
    >
      <Grid>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: theme.rfhColors.black,
              whiteSpace: 'nowrap',
              minWidth: 170,
              fontSize: '1rem',
              fontWeight: 100,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid>
        <FormControl fullWidth>
          <TextField
            id={`id-${fieldName}`}
            name={fieldName}
            value={value}
            disabled={disabled}
            onBlur={submitInputByLooseFocus}
            onKeyUp={submitKeyboardInput}
            onChange={changeTextInput}
            sx={{ flex: 1, maxWidth: 160 }}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}
