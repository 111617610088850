import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useStore } from 'zustand'

import { usePathname } from 'src/common/hooks'
import { SnackbarMessage, snackbarUtils } from 'src/common/utils'

import { FEATURE_ROOT_PATH } from '../constants'
import { useSupplierPromotionCharacteristicStore } from '../stores'

import startOfTomorrow from 'date-fns/startOfTomorrow'

type UseSupplierPromotionCharacteristicReturn = {
  isFormValid: (showErrors?: boolean) => boolean
}
const useSupplierPromotionCharacteristic =
  (): UseSupplierPromotionCharacteristicReturn => {
    const { t } = useTranslation()
    const characteristic = useStore(
      useSupplierPromotionCharacteristicStore,
      state => state.characteristic
    )
    const { ADDING } = usePathname(FEATURE_ROOT_PATH)

    const isFormValid = useCallback(
      (showErrors = false): boolean => {
        let result = true
        const errors: string[] = []
        if (!characteristic.ingangsDatum || !characteristic.eindDatum) {
          errors.push(t('supplierPromotionCharacteristics.noDates'))
          result = false
        }
        if (characteristic.ingangsDatum > characteristic.eindDatum) {
          errors.push(t('supplierPromotionCharacteristics.invalidDates'))
          result = false
        }
        if (ADDING && characteristic.ingangsDatum < startOfTomorrow()) {
          errors.push(t('supplierPromotionCharacteristics.invalidStartDate'))
          result = false
        }
        if (
          !characteristic.aanvoerderNummer ||
          characteristic.aanvoerderNummer <= 0
        ) {
          errors.push(t('supplierPromotionCharacteristics.invalidSupplier'))
          result = false
        }
        if (!characteristic.productCode || characteristic.productCode <= 0) {
          errors.push(t('supplierPromotionCharacteristics.invalidProduct'))
          result = false
        }
        if (!characteristic.kenmerkWaarde) {
          errors.push(
            t('supplierPromotionCharacteristics.invalidCharacteristicValue')
          )
          result = false
        }
        /* FINALLY */
        if (!result && showErrors) {
          snackbarUtils.error(SnackbarMessage(errors, 'Invoer niet correct'))
        }
        return result
      },
      [
        ADDING,
        characteristic.aanvoerderNummer,
        characteristic.eindDatum,
        characteristic.ingangsDatum,
        characteristic.kenmerkWaarde,
        characteristic.productCode,
        t,
      ]
    )

    return {
      isFormValid,
    }
  }

export default useSupplierPromotionCharacteristic
