import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Box, Button } from '@mui/material'
import { Cookiebar, OnlineOfflineAlert } from '@rfh-core/components'
import theme from '@rfh-core/theme'

import { useSnackbar } from 'notistack'

import { snackbarUtils } from 'src/common/utils'
import NotFound from 'src/views/notfound/NotFound'

import { CallbackRoute } from '../guards'
import { InternalRoutes } from '../internalRoutes'
import { LoginRoute } from './LoginRoute'

export default function Routes(): JSX.Element {
  const { t } = useTranslation()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    snackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar)
  }, [closeSnackbar, enqueueSnackbar])

  return (
    <>
      <Box
        display={'flex'}
        flexDirection={'column'}
        flexGrow={1}
        minHeight={'calc(100vh - 140px)'}
        overflow={'auto'}
        pt={10}
      >
        <OnlineOfflineAlert
          offlineMessage={t('onlineOfflineAlert.offline')}
          onlineMessage={t('onlineOfflineAlert.online')}
        />
        <Switch>
          {/* Workaround for Navigate while keeping the query params intact */}
          <Route
            path='/'
            exact
            component={({ location }: any) => (
              <Redirect
                to={{
                  ...location,
                  pathname: location.pathname.replace('/', '/dashboard'),
                }}
              />
            )}
          />
          <Route path='/logout' />
          <CallbackRoute path='/callback' component={LoginRoute} />
          <Route path='/dashboard' component={InternalRoutes} />
          <Route component={NotFound} />
        </Switch>
      </Box>
      <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
        <Cookiebar
          title={t('cookieBar.title')}
          messageText={t('cookieBar.text')}
          LinkComponent={
            <a
              href={t('cookieBar.link')}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Button variant='text' sx={{ color: theme.rfhColors.white }}>
                {t('cookieBar.linkText')}
              </Button>
            </a>
          }
        />
      </Box>
    </>
  )
}
