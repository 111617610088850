/* eslint-disable no-unused-expressions */

/* eslint-disable @typescript-eslint/unbound-method */
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory, useLocation } from 'react-router-dom'

import {
  Box,
  Button,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material'
import {
  ArrowDownIcon,
  BackgroundFlowerIcon,
  GlobeFilledIcon,
  GridViewIcon,
  LogoutIcon,
  MenuIcon,
  UserIcon,
} from '@rfh-core/icons'
import { RfhLogo } from '@rfh-core/illustrations'

import { useAuthentication } from 'src/common/providers/AuthenticationProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { useDevToolsStore } from 'src/dev-tools'
import { fallbackLng, locales, supportedLanguages } from 'src/i18n'

import CustomListItem, { type MenuItemType } from './CustomListItem'
import FlagDE from './icons/FlagDE'
import FlagGB from './icons/FlagGB'
import FlagNL from './icons/FlagNL'

export default function AppBar(): JSX.Element {
  const { i18n, t } = useTranslation()
  const history = useHistory()
  const user = useUser()
  const { login, logout } = useAuthentication()
  const [menuOpen, setMenuOpen] = useState(false)
  const { features, shouldFeatureBeEnabled } = useDevToolsStore()

  const [openLang, setOpenLang] = useState(false)
  const theme = useTheme()
  const location = useLocation()
  const urlPart = new URLSearchParams(location.search).get('language')
  const queryStringLanguage = urlPart
    ? urlPart.toLowerCase().slice(0, 2)
    : fallbackLng

  useEffect(() => {
    if (locales.map(l => l.toString()).includes(queryStringLanguage)) {
      i18n.changeLanguage(queryStringLanguage)
    } else if (urlPart && supportedLanguages.includes(urlPart)) {
      i18n.changeLanguage(urlPart)
    } else if (queryStringLanguage) {
      console.warn(
        `Could not detect language from url, queryStringLanguage: ${queryStringLanguage}`
      )
    }
  }, [queryStringLanguage, i18n, urlPart])

  const onClickRedirectHandler = (path: string): void => {
    setMenuOpen(false)
    history.push(path)
  }

  const getLanguages = [
    {
      value: 'de',
      label: t('appBar.languageNameDE'),
      icon: <FlagDE />,
    },
    {
      value: 'en',
      label: t('appBar.languageNameEN'),
      icon: <FlagGB />,
    },
    {
      value: 'nl',
      label: t('appBar.languageNameNL'),
      icon: <FlagNL />,
    },
  ] as const

  const getPrimaryMenuItems = (): MenuItemType[] => {
    const menu: MenuItemType[] = []
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard'),
      text: t('common.titles.homePage'),
      icon: <BackgroundFlowerIcon />,
    })

    if (!user.isAuthenticated) return menu

    menu.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/supplier-product-regularity'),
      text: t('common.titles.overviewsProductRegularityReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/supplier-product-group-regularity'),
      text: t('common.titles.overviewsProductGroupRegularityReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/exceptions-product'),
      text: user.isAuctionCoordinator
        ? t('common.titles.exceptionsProduct')
        : t('common.titles.exceptionsProductReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/exceptions-product-group'),
      text: user.isAuctionCoordinator
        ? t('common.titles.exceptionsProductGroup')
        : t('common.titles.exceptionsProductGroupReadOnly'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/auction-group-counts'),
      text: t('common.titles.auctionDayCounts'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/batches-status'),
      text: t('common.titles.searchBatchesStatus'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/auction-group-rules'),
      text: user.isAuctionCoordinator
        ? t('auctionRule.title.manage')
        : t('common.titles.searchAuctionGroupRules'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/supplier-clusters'),
      text: t('common.titles.supplierClusters'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/product-clusters'),
      text: t('common.titles.productClusters'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/auction-group-allocations'),
      text: user.isAuctionCoordinator
        ? t('common.titles.manageAuctionGroupAllocations')
        : t('common.titles.auctionGroupAllocations'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/irregular-suppliers'),
      text: t('common.titles.irregularSuppliers'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () =>
        onClickRedirectHandler('/dashboard/supplier-promotion-characteristics'),
      text: user.isAuctionCoordinator
        ? t('common.titles.manageSupplierPromotionCharacteristics')
        : t('common.titles.supplierPromotionCharacteristics'),
      icon: <GridViewIcon />,
    })
    menu.push({
      onClick: () => onClickRedirectHandler('/dashboard/search-product'),
      text: t('common.titles.searchProduct'),
      icon: <GridViewIcon />,
    })
    if (shouldFeatureBeEnabled(features?.KLOKSTATUS)) {
      menu.push({
        onClick: () => onClickRedirectHandler('/dashboard/clocks-status'),
        text: t('common.titles.clocksStatus'),
        icon: <GridViewIcon />,
      })
    }
    if (shouldFeatureBeEnabled(features?.DASHBOARD)) {
      menu.push({
        onClick: () => onClickRedirectHandler('/dashboard/logistics-dashboard'),
        text: t('common.titles.logisticsDashboard'),
        icon: <GridViewIcon />,
      })
    }
    if (shouldFeatureBeEnabled(features?.WIP)) {
      menu.push({
        onClick: () => onClickRedirectHandler('/dashboard/work-in-progress'),
        text: t('common.titles.wip'),
        icon: <GridViewIcon />,
      })
    }

    return menu
  }

  const getSecondaryMenuItems = (): MenuItemType[] => {
    const menu: MenuItemType[] = []
    if (user.isAuthenticated) {
      menu.push({
        onClick: () => onClickRedirectHandler('/dashboard/profile'),
        text: t('common.titles.profilePage'),
        icon: <UserIcon />,
      })
      menu.push({
        onClick: () => logout(),
        text: t('common.titles.logout'),
        icon: <LogoutIcon />,
      })
    } else {
      menu.push({
        onClick: () => login(),
        text: t('common.titles.login'),
        icon: <LogoutIcon />,
      })
    }
    return menu
  }

  return (
    <>
      <MuiAppBar elevation={2} position='sticky' color='inherit'>
        <Toolbar disableGutters variant='dense' sx={{ m: theme.spacing(0, 4) }}>
          <Button
            component={Link}
            to='/dashboard'
            sx={{
              p: 0,
              m: 0,
              color: 'common.black',
              '&:focus': { outline: 'none' },
            }}
            variant='text'
          >
            <RfhLogo sx={{ width: '85px', height: 'inherit' }} />
          </Button>
          <Box
            zIndex={-1}
            justifyContent='center'
            textAlign='center'
            display='flex'
          >
            <Typography
              variant='h1'
              sx={{
                [theme.breakpoints.up('xs')]: {
                  fontSize: theme.typography.pxToRem(14),
                  textTransform: 'uppercase',
                },
              }}
            >
              {t('common.applicationName')}
            </Typography>
          </Box>
          <IconButton
            aria-label={'open menu'}
            onClick={() => {
              setMenuOpen(!menuOpen)
            }}
            sx={{ mr: 0 }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </MuiAppBar>
      <Drawer
        anchor='right'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        PaperProps={{ sx: { minWidth: '400px' } }}
      >
        <List>
          {getPrimaryMenuItems().map(item => (
            <CustomListItem key={item.text} item={item} />
          ))}
        </List>
        <Divider />
        <List>
          {getSecondaryMenuItems().map(item => (
            <CustomListItem key={item.text} item={item} />
          ))}
          <ListItem disablePadding>
            <ListItemButton onClick={() => setOpenLang(!openLang)}>
              <ListItemIcon>
                <GlobeFilledIcon />
              </ListItemIcon>
              <ListItemText primary={t('appBar.changeLanguage')} />
            </ListItemButton>
            <ArrowDownIcon
              sx={{
                position: 'absolute',
                right: theme.spacing(3),
                fontSize: theme.typography.fontSize,
                transform: openLang ? 'rotate(0deg)' : 'rotate(270deg)',
              }}
            />
          </ListItem>
        </List>
        <Collapse in={openLang}>
          <List>
            {getLanguages.map(lang => (
              <ListItem key={lang.value} disablePadding>
                <ListItemButton onClick={() => i18n.changeLanguage(lang.value)}>
                  <ListItemIcon>{lang.icon}</ListItemIcon>
                  <ListItemText>{lang.label}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
      </Drawer>
    </>
  )
}
