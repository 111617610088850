import { useState } from 'react'

import { TableCell, TableRow } from '@mui/material'
import theme from '@rfh-core/theme'

import { VestigingCode } from 'src/common/enums'
import { usePrevProps } from 'src/common/hooks'
import { BloemenPlanten } from 'src/common/types'

import type { LogisticsDashboardRowData } from './lib'
import { getCellStyles, getClockSort } from './lib'

import isEqual from 'lodash/isEqual'

type LogisticsDashboardGridRowProps = {
  row: LogisticsDashboardRowData
  index: number
}

export default function LogisticsDashboardGridRow({
  row,
  index,
}: Readonly<LogisticsDashboardGridRowProps>): JSX.Element {
  const prevRow = usePrevProps(row)
  const [updated, setUpdated] = useState(false)

  if (prevRow && !isEqual(prevRow, row) && !updated) {
    setUpdated(true)
    setTimeout(() => {
      setUpdated(false)
    }, 1000)
  }

  return (
    <TableRow
      key={index}
      sx={{
        bgcolor:
          index % 2 === 0 ? theme.rfhColors.white : theme.rfhColors.grey[200],
        paddingRight: '-0.5em',
      }}
    >
      <TableCell sx={getCellStyles('left')}>
        {VestigingCode[row.vestigingCode]}
      </TableCell>
      <TableCell sx={getCellStyles('left')}>
        {getClockSort(row.klokSoort as BloemenPlanten)}
      </TableCell>
      <TableCell sx={getCellStyles()}>{row.aantalStapelwagens}</TableCell>
      <TableCell sx={getCellStyles()}>{row.aantalStapelwagensKowala}</TableCell>
      <TableCell sx={getCellStyles()}>
        {Math.max(row.aantalStapelwagens - row.aantalStapelwagensKowala, 0)}
      </TableCell>
      <TableCell sx={getCellStyles()}>
        {row.aantalStapelwagensScannenBijOpstellen}
      </TableCell>
      <TableCell sx={getCellStyles()}>
        {Math.max(
          row.aantalStapelwagensKowala -
            row.aantalStapelwagensScannenBijOpstellen,
          0
        )}
      </TableCell>
    </TableRow>
  )
}
