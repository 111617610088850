import { GridSortModel } from '@mui/x-data-grid-premium'

import { startOfTomorrow } from 'date-fns'

import type { IAanvoerderPromotieKenmerk } from 'src/common/services/client'

import type { FilterInput } from '../types'

export const FEATURE_ROOT_PATH = 'supplier-promotion-characteristics'
export const CHARACTERISTICS_CACHE_KEY =
  'aanvoerder-promotie-kenmerken-datagrid'
export const CHARACTERISTICS_FILTER_CACHE =
  'aanvoerder-promotie-kenmerken-filter'
export const defaultSortModel: GridSortModel = [
  {
    sort: 'asc',
    field: 'aanvoerderNummer',
  },
  {
    sort: 'asc',
    field: 'productCode',
  },
]
export const defaultFilter: FilterInput = {
  aanvoerdernummer: undefined,
  productCode: undefined,
  toonVervallen: false,
}
export const DELETE_TRANSLATION_KEY =
  'supplierPromotionCharacteristics.deleteDialog'

export const defaultCharacteristic: IAanvoerderPromotieKenmerk = {
  aanmaakDatumTijd: new Date(),
  aanmaakGebruiker: null,
  aanvoerderNummer: null,
  aanvoerderPromotieKenmerkId: 0,
  eindDatum: new Date('2099-12-31T00:00:00Z'),
  ingangsDatum: startOfTomorrow(),
  kenmerkCode: 'V22',
  kenmerkWaarde: null,
  kenmerkWaardeOmschrijving: null,
  mutatieDatumTijd: new Date(),
  mutatieGebruiker: null,
  productCode: null,
}
