// eslint-disable-next-line
import { useTranslation } from 'react-i18next'

import { Chip, Container, Grid2 as Grid, Typography } from '@mui/material'

import BooleanTag from 'src/common/components/BooleanTag'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { typographyStyles } from 'src/common/constants'
import { useUser } from 'src/common/providers/UserProvider'

export default function ProfilePage(): JSX.Element {
  const user = useUser()
  const { t } = useTranslation()

  const showLoginAccount = () => {
    if (user.preferredUsername && user.sub) {
      return user.preferredUsername === user.sub ? (
        user.preferredUsername
      ) : (
        <>
          {user.preferredUsername}
          <Chip color='info' label={user.sub} />
        </>
      )
    }
    return t('common.loading')
  }

  /*
   * Render
   */
  return (
    <Container maxWidth='lg' sx={{ p: ThemeConfig.spacing.sm }}>
      <Grid size={12}>
        <Typography variant={'h2'} sx={{ fontSize: '34px' }}>
          {t('common.titles.profilePage')}
        </Typography>
      </Grid>

      <Grid container sx={{ my: ThemeConfig.spacing.sm }}>
        <Grid size={12} sx={{ bgcolor: 'white', p: ThemeConfig.spacing.sm }}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant={'h4'}>{t('profilePage.name')}</Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography
                data-testid='name'
                variant={'subtitle1'}
                sx={typographyStyles}
              >
                {user.name ? user.name : t('common.loading')}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant={'h4'}>{t('profilePage.account')}</Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography
                data-testid='account'
                variant={'subtitle1'}
                sx={typographyStyles}
              >
                {showLoginAccount()}
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography variant={'h4'}>{t('profilePage.email')}</Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <Typography
                data-testid='email'
                variant={'subtitle1'}
                sx={typographyStyles}
              >
                {user.email ? user.email : t('common.loading')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container flexDirection={'column'} width='100%'>
        <Grid size={12}>
          <Typography variant={'h2'} sx={{ fontSize: '34px' }}>
            {t('profilePage.permissions')}
          </Typography>
        </Grid>

        <Grid container sx={{ my: ThemeConfig.spacing.sm }}>
          <Grid size={12} sx={{ bgcolor: 'white', p: ThemeConfig.spacing.sm }}>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant={'h4'}>
                  {t('profilePage.consulterRole')}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography
                  data-testid='consulter'
                  variant={'subtitle1'}
                  sx={typographyStyles}
                >
                  <BooleanTag value={user.isConsulter} />
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography variant={'h4'}>
                  {t('profilePage.auctionCoordinatorRole')}
                </Typography>
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <Typography
                  data-testid='auctionCoordinator'
                  variant={'subtitle1'}
                  sx={typographyStyles}
                >
                  <BooleanTag value={user.isAuctionCoordinator} />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
