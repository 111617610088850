import { useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'

import { Box } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium'
import { EditIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { CustomDataGrid } from 'src/common/components'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import {
  byBloemenPlantenFilter,
  byLocationCodeFilter,
  byProductclusterDescriptionSort,
} from 'src/common/lib'
import { IVeilgroepIndelingOverview } from 'src/common/services/client'
import i18n from 'src/i18n'

import { ALLOCATIONS_CACHE_KEY, FEATURE_ROOT_PATH } from '../constants'
import { getMainAllocationsGridColumns } from '../lib'
import { useAllocationsStore } from '../stores'

type AllocationsGridProps = {
  loading: boolean
}

export default function AllocationsGrid({
  loading = false,
  ...rest
}: Readonly<AllocationsGridProps>): JSX.Element {
  const history = useHistory()
  const { allocations, filter } = useAllocationsStore()
  const getRowId = useCallback(
    (row: IVeilgroepIndelingOverview) => row.productclusterID,
    []
  )

  const editAllocation = useCallback(
    (row: IVeilgroepIndelingOverview) => (): void => {
      history.push(`/dashboard/${FEATURE_ROOT_PATH}/${row.productclusterID}`)
    },
    [history]
  )

  const columns = useMemo((): GridColDef[] => {
    const columnsSet = getMainAllocationsGridColumns()
    // EDIT icon
    columnsSet.push({
      field: 'edit',
      disableColumnMenu: true,
      type: 'actions',
      hideable: false,
      sortable: false,
      renderHeader: () => (
        <EditIcon
          sx={{
            color: theme.rfhColors.grey[200],
            width: '20px',
            height: '20px',
          }}
        />
      ),
      renderCell: (params: GridRenderCellParams) => (
        <EditIcon
          onClick={editAllocation(params.row)}
          sx={{
            cursor: 'pointer',
            color: theme.rfhColors.grey,
            width: '20px',
            height: '20px',
          }}
        />
      ),
      flex: 0.5,
    })
    return columnsSet
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAllocation, i18n.language])

  return (
    <Box position={'relative'} marginTop={GRID_MARGIN_TOP} {...rest}>
      <CustomDataGrid
        cacheKey={ALLOCATIONS_CACHE_KEY}
        language={i18n.language}
        getRowId={getRowId}
        columns={columns}
        rows={[
          ...allocations
            .filter(byLocationCodeFilter(filter.locationCode))
            .filter(byBloemenPlantenFilter(filter.bloemenPlantenCode))
            .sort(byProductclusterDescriptionSort),
        ]}
        gridProps={{
          loading,
          onRowClick: (params: GridRowParams) => editAllocation(params.row)(),
        }}
        pagination={false}
        paginationMode={'client'}
        sortingMode={'client'}
      />
    </Box>
  )
}
