import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import {
  ActionButton,
  AvailableProductGroupInput,
  AvailableProductInput,
  FilterButtonsStack,
  FilterFieldsStack,
  FilterStack,
} from 'src/common/components'
import { noOp } from 'src/common/lib'

import { useClusterRulesStore } from '../stores'

export default function SearchFilter({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element {
  const { t } = useTranslation()
  const {
    clusterView,
    productCode,
    productGroepCode,
    setClusterView,
    setProductCode,
    setProductGroepCode,
    setMustRefetch,
    resetState,
  } = useClusterRulesStore()

  function changeProduct(newNumber: number) {
    setProductCode(newNumber)
    setProductGroepCode(undefined)
  }

  function changeProductGroup(newNumber: number) {
    setProductGroepCode(newNumber)
    setProductCode(undefined)
  }

  function triggerSearchAction() {
    setClusterView({
      ...clusterView,
      clusterNaam: undefined,
      productgroep: undefined,
      veilgroepregels: [],
    })
    setMustRefetch(true)
  }

  const buttonDisabled = useMemo(
    () => !productCode && !productGroepCode,
    [productCode, productGroepCode]
  )

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <AvailableProductInput
          changeValid={noOp}
          disabled={!!productGroepCode}
          inclExpired={true}
          onChange={changeProduct}
          value={productCode}
        />
        <AvailableProductGroupInput
          changeValid={noOp}
          disabled={!!productCode}
          onChange={changeProductGroup}
          value={productGroepCode}
        />
      </FilterFieldsStack>
      <FilterButtonsStack>
        <ActionButton variant='outlined' onClick={resetState}>
          {t('common.clear')}
        </ActionButton>
        <ActionButton
          type='submit'
          onClick={triggerSearchAction}
          disabled={buttonDisabled}
        >
          {t('common.search')}
        </ActionButton>
      </FilterButtonsStack>
    </FilterStack>
  )
}
