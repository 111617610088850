import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import type { IClusterEnRegelsView } from 'src/common/services/client'

type StoreState = {
  productGroepCode: number | undefined
  productCode: number | undefined
  clusterView: IClusterEnRegelsView
  mustRefetch: boolean
}

type Actions = {
  setProductGroepCode: (pgc: number) => void
  setProductCode: (pc: number) => void
  setClusterView: (crvs: IClusterEnRegelsView) => void
  resetState: () => void
  setMustRefetch: (input: boolean) => void
}

const storeName = 'SearchClusterRulesStore'

const iniitialState: StoreState = {
  clusterView: undefined,
  productCode: undefined,
  productGroepCode: undefined,
  mustRefetch: false,
}

export const useClusterRulesStore = create<StoreState & Actions>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any) => ({
        ...iniitialState,
        /* store */
        resetState: () => set(() => iniitialState, false, 'resetState'),
        /* ClusterEnVielgroepregels */
        setProductGroepCode: (pgc: number) => {
          set(() => ({ productGroepCode: pgc }), false, 'setProductGroepCode')
        },
        setProductCode: (pc: number) => {
          set(() => ({ productCode: pc }), false, 'setProductCode')
        },
        setClusterView: (crv: IClusterEnRegelsView) => {
          set(() => ({ clusterView: crv }), false, 'setClusterView')
        },
        setMustRefetch: (input: boolean) => {
          set(() => ({ mustRefetch: input }), false, 'setMustRefetch')
        },
      }),
      {
        name: 'cluster-rules-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
