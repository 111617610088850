import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  IconButton,
  inputBaseClasses,
  outlinedInputClasses,
  paperClasses,
  TextField,
  Typography,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config'
import { MR } from 'src/common/constants'
import { fromProductGroupToItem } from 'src/common/lib'
import { IClient, IProductgroep } from 'src/common/services/client'
import { getProductGroupsAsync } from 'src/common/services/productGroupService'
import { Item } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { Divider } from '../Divider'
import LookupGrid from '../LookupDialog/LookupGrid'

type AvailableProductGroupsDialogProps = {
  client: IClient
  onChange: (productGroup: IProductgroep) => void
}

export default function AvailableProductGroupsDialog({
  client,
  onChange,
}: Readonly<AvailableProductGroupsDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [productGroups, setProductGroups] = useState<IProductgroep[]>([])
  const [filterString, setFilterString] = useState<string>('')

  const openDialog = () => {
    setFilterString('')
    setOpen(true)
    fetchData()
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const itemSelected = (evt: Item<number>) => {
    const selectedProductgroepCode = evt.code
    const productGroup: IProductgroep = {
      productgroepCode: selectedProductgroepCode,
      productgroepNaam: productGroups.find(
        pg => pg.productgroepCode === selectedProductgroepCode
      ).productgroepNaam,
    }
    onChange(productGroup)
    closeDialog()
  }

  const submitKeyboardInput = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = () => {
    fetchData()
  }

  const changeFilterProductGroupName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFilterString(event.target.value)
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    try {
      setInProgress(true)

      const filter = filterString
        ? `contains(productgroepnaam, '${filterString}')`
        : undefined

      const records = await getProductGroupsAsync(client, {
        filter,
      })
      setProductGroups(records)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
    }
  }

  return (
    <>
      <IconButton aria-label={'lookup icon button'} onClick={openDialog}>
        <SearchIcon sx={{ color: theme.rfhColors.dutchOrange, height: 20 }} />
      </IconButton>
      <Dialog
        open={open}
        onClose={closeDialog}
        sx={{
          [`& .${paperClasses.root}`]: {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogContent sx={{ width: '600px', maxWidth: '600px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '1.5rem',
              right: MR,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              bgcolor: 'white',
              paddingTop: '2rem',
              zIndex: 1,
            }}
          >
            <Typography sx={{ mb: '16px' }} variant={'h4'}>
              {t('exceptions.addExceptions.searchProductGroups')}
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container gap={1.5}>
                <Grid flexBasis={0} flexGrow={1}>
                  <TextField
                    autoFocus
                    fullWidth
                    id='productGroupName'
                    name='productGroupName'
                    placeholder={t('exceptions.addExceptions.productGroupName')}
                    onChange={changeFilterProductGroupName}
                    onKeyUp={submitKeyboardInput}
                    sx={{
                      [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                        {
                          bgcolor: theme.rfhColors.grey[100],
                        },
                    }}
                  />
                </Grid>
                <Grid>
                  <Button
                    variant='contained'
                    onClick={handleSubmit(onSubmit)}
                    disabled={inProgress}
                  >
                    {t('common.search')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Divider {...{ mr: MR, mb: 0 }} />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid
            items={productGroups.map(fromProductGroupToItem)}
            selectItem={itemSelected}
          />
        </DialogContent>
        <DialogActions sx={{ mr: MR }}>
          <Button
            variant='outlined'
            onClick={closeDialog}
            sx={{ mb: ThemeConfig.spacing.sm * 2 }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
