import {
  IKenmerk,
  IVeilgroepRegel,
  IVeilgroepRegelKenmerk,
} from 'src/common/services/client'

import {
  defaultAuctionPeriod,
  defaultLoadCarrier,
  defaultQuality,
  defaultSupplier,
} from '../constants'
import {
  Aanvoertypen,
  DateInput,
  Kwaliteit,
  LadingdragerTypen,
  VeilgroepRegelKenmerkView,
} from '../types'

type Input = string | undefined | null
export const qualityToString = (quality: Kwaliteit): string => {
  if (quality === defaultQuality) return null
  let content = ''
  if (quality.A1) {
    content += 'A1,'
  }
  if (quality.A2) {
    content += 'A2,'
  }
  if (quality.B1) {
    content += 'B1,'
  }

  if (content.length) {
    return content.replace(/,\s*$/, '')
  }
  return null
}

export const stringToQuality = (input: Input): Kwaliteit => {
  if (input == null) return defaultQuality
  const elements = input.split(',').map(el => el.trim())
  return {
    A1: elements.includes('A1'),
    A2: elements.includes('A2'),
    B1: elements.includes('B1'),
  }
}

export const suplierTypesToString = (input: Aanvoertypen): string => {
  if (input == null || input === defaultSupplier) return null
  let content = ''
  if (input.oneP) {
    content += '1P,'
  }
  if (input.GP) {
    content += 'GP,'
  }
  if (input.GPPlus) {
    content += 'GP+,'
  }
  if (input.MP) {
    content += 'MP,'
  }

  if (content.length) {
    return content.replace(/,\s*$/, '')
  }
  return null
}

export const stringToSupplierTypes = (input: Input): Aanvoertypen => {
  if (input == null) return defaultSupplier
  const elements = input.split(',').map(el => el.trim())
  return {
    GP: elements.includes('GP'),
    GPPlus: elements.includes('GP+'),
    MP: elements.includes('MP'),
    oneP: elements.includes('1P'),
  }
}

export const loadCarierTypesToString = (input: LadingdragerTypen): string => {
  if (input == null || input === defaultLoadCarrier) return null
  let content = ''
  if (input.STW) {
    content += 'STW,'
  }
  if (input.DC) {
    content += 'DC,'
  }

  if (content.length) {
    return content.replace(/,\s*$/, '')
  }
  return null
}

export const stringToLoadCarierTypes = (input: Input): LadingdragerTypen => {
  if (input === null) return defaultLoadCarrier
  const elements = input.split(',').map(el => el.trim())
  return { DC: elements.includes('DC'), STW: elements.includes('STW') }
}

export const ruleCharacteristicsToString = (
  values: VeilgroepRegelKenmerkView[],
  characteristics: IKenmerk[]
): string => {
  if (!values || !characteristics) return ''
  let content = ''

  const newValues = values.map(el => ({
    ...el,
    kenmerkOmschrijving: characteristics.find(
      acr => el?.kenmerkCode === acr?.kenmerkCode
    )?.kenmerkOmschrijving,
  }))

  newValues.forEach((element, index) => {
    content += `${element.kenmerkCode} ${
      element.kenmerkOmschrijving
    } (waarde ${element?.kenmerkWaardeVan?.trim()} `
    content += element?.kenmerkWaardeTtm?.trim()
      ? `t/m ${element.kenmerkWaardeTtm})`
      : ')'
    if (values.length > 1 && index < values.length - 1) {
      content += ' en \n'
    }
  })

  return content
}

export const ruleCharacteristicToView = (
  value: IVeilgroepRegelKenmerk,
  characteristics: IKenmerk[]
): VeilgroepRegelKenmerkView => ({
  ...value,
  kenmerkOmschrijving: characteristics?.find(
    c => c.kenmerkCode === value.kenmerkCode
  )?.kenmerkOmschrijving,
})

export const extractAuctionPeriod = (input: IVeilgroepRegel): DateInput => {
  if (!input) return defaultAuctionPeriod
  return {
    dagTtm: input?.dagTtm,
    dagVanaf: input?.dagVanaf,
    weekTtm: input?.weekTtm,
    weekVanaf: input?.weekVanaf,
  }
}
