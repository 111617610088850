import { VeilgroepRegelKenmerkView } from '../types'

export const byKenmerkcode = (
  k1: VeilgroepRegelKenmerkView,
  k2: VeilgroepRegelKenmerkView
): number => {
  if (k1.kenmerkCode > k2.kenmerkCode) {
    return 1
  }
  if (k1.kenmerkCode < k2.kenmerkCode) {
    return -1
  }
  return 0
}
