export enum ApiError {
  notFound = 'notFound',
  badRequest = 'badRequest',
  generalFailure = 'generalFailure',
}

export enum PageSize {
  sm = 10,
  md = 25,
  lg = 50,
  xl = 100,
}

export enum Location {
  Aalsmeer = 1,
  Naaldwijk = 2,
  Rijnsburg = 3,
  Eelde = 7,
}

export enum Vestiging {
  Aalsmeer = 'Aalsmeer',
  Naaldwijk = 'Naaldwijk',
  Rijnsburg = 'Rijnsburg',
  Eelde = 'Eelde',
  VRM = 'Rhein-Maas',
  Concern = 'RFH Concern',
}

export enum VestigingCode {
  All = 0,
  Aalsmeer = 1,
  Naaldwijk = 2,
  Rijnsburg = 3,
  Eelde = 7,
  VRM = 24,
  Concern = 25,
}

export enum LadingdragerType {
  Stapelwagens = 1,
  Denen = 2,
}

export enum UpsertMode {
  add = 'ADD',
  edit = 'EDIT',
  void = 'VOID',
}

/**
 * See https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/readyState
 */
export enum WSReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
}
