import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  IconButton,
  inputBaseClasses,
  outlinedInputClasses,
  paperClasses,
  SxProps,
  TextField,
  Typography,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { Divider } from 'src/common/components'
import { ThemeConfig } from 'src/common/config'
import { MR } from 'src/common/constants'
import type { Item } from 'src/common/types'

import LookupGrid from './LookupGrid'

type LookupDialogProps = {
  getItems: () => Promise<Item<number>[]>
  buttonName?: string
  buttonSx?: SxProps
  disabled?: boolean
  searchTitle?: string
  trigger?: 'iconButton' | 'button'
  onChange?: (item: Item<number>) => void
}

export default function LookupDialog({
  getItems,
  buttonName = 'ADD',
  buttonSx,
  disabled = false,
  searchTitle,
  trigger = 'iconButton',
  onChange,
}: Readonly<LookupDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const [lookup, setLookup] = useState<string>('')
  const [items, setItems] = useState<Item<number>[]>([])
  const [filteredItems, setFilteredItems] = useState<Item<number>[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const openDialog = useCallback(async () => {
    const fetchedItems = await getItems()
    setItems(fetchedItems)
    setFilteredItems(fetchedItems)
    setIsOpen(true)
  }, [getItems])

  const closeDialog = useCallback(() => {
    setLookup('')
    setFilteredItems([] as Item<number>[])
    setIsOpen(false)
  }, [])

  const onChangeFilter = useCallback(() => {
    setFilteredItems(
      items.filter(item => {
        const testValue = isNaN(Number(lookup))
          ? item.name
          : item.code.toString()
        return testValue.toLowerCase().includes(lookup.toLowerCase())
      })
    )
  }, [items, lookup])

  function changeLookup(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void {
    setLookup(event.target.value)
  }

  const submitKeyboardInput = useCallback(
    (event: KeyboardEvent<HTMLDivElement>): void => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault()
        handleSubmit(onChangeFilter)()
      }
    },
    [handleSubmit, onChangeFilter]
  )

  const selectItem = useCallback(
    (item: Item<number>): void => {
      onChange?.(item)
      closeDialog()
    },
    [closeDialog, onChange]
  )

  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton
          aria-label={'lookup icon button'}
          onClick={openDialog}
          sx={{ px: 0.5 }}
        >
          <SearchIcon
            sx={{ color: theme.rfhColors.dutchOrange, height: 20, py: 0 }}
          />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={openDialog}
          sx={buttonSx}
          variant='contained'
        >
          {buttonName}
        </Button>
      )}
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        sx={{
          [`& .${paperClasses.root}`]: {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogContent sx={{ width: '600px', maxWidth: '600px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '1.5rem',
              right: MR,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              bgcolor: 'white',
              pt: '2rem',
              zIndex: 1,
            }}
          >
            {/*TITLE*/}
            <Typography variant={'h4'} mb={'16px'}>
              {searchTitle}
            </Typography>
            {/*SEARCH BAR*/}
            <form onSubmit={handleSubmit(onChangeFilter)}>
              <Grid container gap={1.5}>
                <Grid flexBasis={0} flexGrow={1}>
                  <TextField
                    autoFocus
                    id='lookup-field'
                    name='item-name'
                    fullWidth
                    placeholder={'code of naam'}
                    onChange={changeLookup}
                    onKeyUp={submitKeyboardInput}
                    sx={{
                      [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                        {
                          bgcolor: theme.rfhColors.grey[100],
                        },
                    }}
                  />
                </Grid>
                <Grid>
                  <Button
                    variant='contained'
                    onClick={handleSubmit(onChangeFilter)}
                    disabled={false}
                  >
                    {t('common.search')}
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Divider {...{ mb: 0, mr: MR }} />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid items={filteredItems} selectItem={selectItem} />
        </DialogContent>
        {/*BACK BUTTON*/}
        <DialogActions sx={{ mr: MR }}>
          <Button
            variant='outlined'
            onClick={closeDialog}
            sx={{ mb: ThemeConfig.spacing.sm * 2 }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
