/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const isOfType = <T>(item: any, type: T): boolean =>
  item?.constructor === type

/**
 * Check if an item is an `Array`
 * @param item The item to be checked
 */
export const isArray = (item: any): boolean => isOfType(item, Array)

/**
 * Check if an item is an `Object`
 * @param item The item to be checked
 */
export const isObject = (item: any): boolean => isOfType(item, Object)

/**
 * Check if an object implements a specific interface and has a specific key
 * @param obj The object to be checked
 * @param key A key name that must be present in that interface
 */
export const hasInterfaceKey = <T>(obj: any, key: string): obj is T =>
  key in obj
