import { ILocationClock } from '../interfaces'
import {
  IProductclusterProductgroep,
  IProductclusterView,
  IProductgroepenReport,
  IVeilgroepIndelingOverview,
} from '../services/client'
import { BloemenPlanten } from '../types'

export const byNumberFilter = (entry: [string, unknown]): boolean =>
  !Number.isNaN(Number(entry[0]))

type LocationTypesUnion = ILocationClock | IVeilgroepIndelingOverview
export const byLocationCodeFilter =
  (locationCode: number) =>
  (entry: LocationTypesUnion): boolean => {
    if ('clockNumber' in entry) {
      return locationCode === 0 || entry.locationCode === locationCode
    } else if ('productclusterID' in entry) {
      return entry.vestigingCode === locationCode
    }
  }

export const byBloemenPlantenFilter =
  <
    T extends
      | IVeilgroepIndelingOverview
      | IProductclusterView
      | IProductclusterProductgroep
      | IProductgroepenReport
  >(
    bloemenPlantenCode?: BloemenPlanten
  ) =>
  (entry: T): boolean => {
    if (!bloemenPlantenCode) return true

    let firstChar = '1'
    // Check if T is IProductclusterView
    if ('bloemenPlantenCode' in entry) {
      return entry.bloemenPlantenCode === bloemenPlantenCode
    } else if ('productgroepCode' in entry) {
      // else if T is IProductclusterProductgroep
      firstChar = String(entry.productgroepCode)[0]
    } else if ('productGroepCode' in entry) {
      // else T is IProductgroepenReport
      firstChar = String(entry.productGroepCode)[0]
    }

    switch (bloemenPlantenCode) {
      case 'B':
        return firstChar === '1'
      case 'P':
        return firstChar !== '1'
    }
  }
