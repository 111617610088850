import { FC, useCallback, useEffect, useState } from 'react'

import { SxProps, Theme } from '@mui/material/styles'
import {
  DataGridPremium,
  GridColDef,
  GridEventListener,
  GridInitialState,
  GridPaginationInitialState,
  GridPaginationModel,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { DataGridPremiumProps } from '@mui/x-data-grid-premium/models/dataGridPremiumProps'

import { gridHeaderStyles, rowsPerPageOptions } from 'src/common/constants'
import { noOp } from 'src/common/lib'
import { getLocale } from 'src/common/utils/i18nHelpers'
import { getLocalItem, updateLocalItem } from 'src/common/utils/OfflineManager'

import GridToolbar from '../GridToolbar'

type CustomDataGridProps = {
  cacheKey: string
  changeGridPaginationInitialState?: (value: GridPaginationInitialState) => void
  changeSortModel?: (value: GridSortModel) => void
  columns: GridColDef[]
  disableAggregation?: boolean
  filterMode?: 'server' | 'client'
  getRowId: (row: any) => any
  gridProps?: any
  hideHorizontalScrollbar?: boolean
  initialState?: GridInitialState
  language: string
  pagination?: boolean
  paginationMode?: 'server' | 'client'
  rows: any[]
  sortingMode?: 'server' | 'client'
}

const CustomDataGrid: FC<CustomDataGridProps> = ({
  cacheKey,
  changeGridPaginationInitialState,
  changeSortModel,
  columns,
  disableAggregation = true,
  filterMode = 'server',
  getRowId,
  gridProps,
  hideHorizontalScrollbar = false,
  initialState,
  language,
  pagination = true,
  paginationMode = 'server',
  rows,
  sortingMode = 'server',
}) => {
  const apiRef = useGridApiRef()
  const gridSx: SxProps<Theme> = hideHorizontalScrollbar && {
    '& .MuiDataGrid-virtualScroller': {
      overflowX: 'hidden !important',
    },
  }
  const [paginationState, setPaginationState] =
    useState<GridPaginationInitialState>(() => {
      const cachedPagination =
        getLocalItem<GridInitialState>(cacheKey)?.pagination
      return (
        cachedPagination ??
        ({
          paginationModel: {
            pageSize: gridProps?.pageSize
              ? gridProps.pageSize
              : rowsPerPageOptions[0],
            page: gridProps?.page ? gridProps.page : 0,
          },
        } as GridPaginationInitialState)
      )
    })

  /**
   * Methods
   */
  const changeLocalStoragePagination = useCallback(
    (page?: number, pageSize?: number) => {
      page = page ?? paginationState.paginationModel.page
      pageSize = pageSize ?? paginationState.paginationModel.pageSize

      setPaginationState(prevState => {
        const nextState: GridPaginationInitialState = {
          paginationModel: {
            pageSize,
            page,
          },
        }
        if (
          prevState?.paginationModel?.pageSize !==
          nextState?.paginationModel?.pageSize
        ) {
          const startCurrentPage =
            prevState.paginationModel.page * prevState.paginationModel.pageSize
          const newPage = Math.floor(
            startCurrentPage / nextState.paginationModel.pageSize
          )
          nextState.paginationModel.page = newPage
        } else if (
          prevState.paginationModel.page === nextState.paginationModel.page
        ) {
          return prevState
        }
        const newGridState: GridInitialState = {
          ...getLocalItem<GridInitialState>(cacheKey),
          pagination: nextState,
        }
        updateLocalItem<GridInitialState>(cacheKey, {
          pagination: newGridState?.pagination
            ? {
                paginationModel: newGridState.pagination?.paginationModel
                  ? {
                      page: newGridState.pagination.paginationModel?.page ?? 0,
                      pageSize:
                        newGridState.pagination.paginationModel?.pageSize ??
                        rowsPerPageOptions[0],
                    }
                  : undefined,
              }
            : undefined,
        })
        return nextState
      })
    },
    [
      cacheKey,
      paginationState.paginationModel?.page,
      paginationState.paginationModel?.pageSize,
    ]
  )

  // Remember: don't change the defaultGridProps here unless you know what you are doing!
  const defaultGridProps: Partial<DataGridPremiumProps> = {
    apiRef,
    columns,
    components: { Toolbar: GridToolbar },
    slotProps: {
      pagination: {
        showFirstButton: true,
        showLastButton: true,
      },
      toolbar: { cacheKey },
    },
    disableRowGrouping: true,
    getRowId,
    initialState: {
      ...getLocalItem<GridInitialState>(cacheKey),
      pagination: paginationState,
      ...initialState,
    },
    localeText: getLocale(language),
    onPaginationModelChange: (paginationModel: GridPaginationModel) =>
      changeLocalStoragePagination(
        paginationModel.page,
        paginationModel.pageSize
      ),
    onSortModelChange: changeSortModel,
    rowCount: gridProps?.rowCount ?? 0,
    rows,
  }
  const defaultSx = {
    ...gridHeaderStyles,
    width: '100%',
  }

  useEffect(() => {
    if (changeGridPaginationInitialState) {
      changeGridPaginationInitialState(paginationState)
    }
  }, [paginationState, changeGridPaginationInitialState])

  useEffect(() => {
    const handleRowDragStart: GridEventListener<'rowDragStart'> = _params => {
      // AR: not sure yet what (else) to do here as doing nothing works 100%
      noOp()
    }
    // This will manage proper unsubscribe logic to prevent duplicate event handlers
    return apiRef.current.subscribeEvent('rowDragStart', handleRowDragStart)
  }, [apiRef])

  return (
    <DataGridPremium
      autoHeight
      disableAggregation={disableAggregation}
      disableColumnFilter // We have not yet implemented the filter with the OData client so disabled for now
      disableRowSelectionOnClick
      disableVirtualization
      filterMode={filterMode}
      onRowOrderChange={gridProps?.onRowOrderChange}
      pagination={pagination}
      paginationMode={paginationMode}
      pageSize={
        paginationState?.paginationModel?.pageSize ?? rowsPerPageOptions[0]
      }
      pageSizeOptions={rowsPerPageOptions}
      rowReordering={gridProps?.rowReordering}
      sortingMode={sortingMode}
      {...defaultGridProps}
      {...gridProps}
      sx={{
        ...defaultSx,
        ...gridSx,
      }}
    />
  )
}

export default CustomDataGrid
