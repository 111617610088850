import type { ChangeEventHandler, MouseEventHandler, ReactNode } from 'react'

import {
  FormControl,
  FormControlLabel,
  IconButton,
  inputBaseClasses,
  NativeSelect,
  nativeSelectClasses,
  svgIconClasses,
} from '@mui/material'
import { ClearIcon } from '@mui/x-date-pickers'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config'

type WeekDaySelectorProps = {
  label: string
  name: string
  Options: ReactNode
  value?: number
  onChange?: ChangeEventHandler<HTMLSelectElement>
  onClick?: MouseEventHandler<HTMLButtonElement>
}

export default function WeekDaySelector({
  label,
  name,
  Options,
  value,
  onChange,
  onClick,
}: Readonly<WeekDaySelectorProps>): JSX.Element {
  return (
    <FormControl
      sx={{
        margin: 0,
        width: '100%',
        '& label span': {
          fontWeight: 100,
        },
      }}
    >
      <FormControlLabel
        control={
          <NativeSelect
            name={name}
            value={value > 0 ? value : ''}
            onChange={onChange}
            endAdornment={
              <IconButton
                onClick={onClick}
                sx={{
                  [`& svg.${svgIconClasses.root}`]: {
                    color: theme.rfhColors.alertRed,
                    display: value > 0 ? '' : 'none',
                    top: -4,
                    right: 16,
                    pointerEvents: 'all',
                  },
                }}
              >
                <ClearIcon />
              </IconButton>
            }
            sx={{
              minWidth: 160,
              mr: ThemeConfig.spacing.sm,
              [`& .${nativeSelectClasses.select}`]: {
                minWidth: 110,
                ml: 1,
              },
            }}
          >
            {Options}
          </NativeSelect>
        }
        label={label}
        sx={{
          [`& .${inputBaseClasses.root} > svg.${svgIconClasses.root}`]: {
            display: value > 0 ? 'none' : '',
          },
        }}
      />
    </FormControl>
  )
}
