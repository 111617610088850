export type FeatureName = 'KLOKSTATUS' | 'DASHBOARD' | 'WIP'

export type Feature = {
  name: FeatureName
  enabled: boolean
  alwaysEnabled?: boolean
}

export const FEATURES: Record<FeatureName, Feature> = {
  KLOKSTATUS: {
    name: 'KLOKSTATUS',
    enabled: false,
    alwaysEnabled: false,
  },
  DASHBOARD: {
    name: 'DASHBOARD',
    enabled: false,
    alwaysEnabled: false,
  },
  WIP: {
    name: 'WIP',
    enabled: false,
    alwaysEnabled: false,
  },
} as const

export const featureList: Feature[] = Object.values(FEATURES)
export const featureNames = Object.keys(FEATURES) as FeatureName[]
