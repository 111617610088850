import Box from '@mui/material/Box'

import { v4 as getRandomID } from 'uuid'

const DELTA = '0.4rem'

export default function SnackbarMessage(
  messages: string[],
  title?: string
): JSX.Element {
  const nonEmptyMessages = messages.filter(m => m.trim().length > 0)
  const onlyOne = nonEmptyMessages.length === 1
  let withTitle = true

  if (nonEmptyMessages.length === 0) {
    if (title) {
      nonEmptyMessages.push(title)
      withTitle = false
    } else {
      return null // no title and no messages
    }
  }

  return (
    <Box
      flexDirection={'column'}
      justifyContent={'flex-start'}
      alignItems={'flex-start'}
    >
      {withTitle && (
        <h3 style={{ margin: 0, marginLeft: DELTA, fontSize: '1.2rem' }}>
          {title}
        </h3>
      )}
      {nonEmptyMessages.map(msg => (
        <p
          key={getRandomID()}
          style={{ margin: 0, padding: 0, paddingLeft: onlyOne ? 0 : DELTA }}
        >
          {msg.endsWith(':') ? (
            <span style={{ fontSize: '1rem' }}>{msg}</span>
          ) : (
            <span
              style={{
                paddingLeft: onlyOne && !withTitle ? 0 : 16,
                fontSize: onlyOne ? '1rem' : null,
              }}
            >
              {`${withTitle && !onlyOne ? '- ' : ''}${msg}`}
            </span>
          )}
        </p>
      ))}
    </Box>
  )
}
