import { useCallback, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { Divider } from 'src/common/components'
import { initialState, refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IClient } from 'src/common/services/client'
import snackbarUtils from 'src/common/utils/snackbar/SnackbarUtils'

import {
  ProductGroupsFilter,
  ProductGroupsGrid,
  ProductGroupsGridSkeleton,
} from '../components'
import { getClusterAsync } from '../services'
import { useProductClusterStore } from '../stores/ProductClusterStore'

export default function ManageProductCluster(): JSX.Element {
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const {
    cluster,
    mustRefetchCluster,
    setCluster,
    setMustRefetchCluster,
    setOldCluster,
    setProductgroepen,
    setProductgroepenHaveChanged,
  } = useProductClusterStore()
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const refetchCluster = useCallback(
    async (client: IClient) => {
      if (!cluster || state.loading) {
        return Promise.resolve()
      }

      try {
        dispatch({ type: 'PENDING' })
        const refetchedCluster = await getClusterAsync(
          client,
          cluster.productclusterID
        )
        setProductgroepen(refetchedCluster.productclusterProductgroepen)
        setCluster(refetchedCluster)
        setOldCluster(refetchedCluster)
        setProductgroepenHaveChanged(false)
      } catch (error: any) {
        dispatch({ type: 'ERROR' })
        snackbarUtils.error(String(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cluster?.productclusterID, state?.loading]
  )

  useEffect(() => {
    if (apiClient && mustRefetchCluster) {
      refetchCluster(apiClient)
      setMustRefetchCluster(false)
    }
  }, [apiClient, mustRefetchCluster, refetchCluster, setMustRefetchCluster])

  return (
    <>
      <Box mt={4} mb={2}>
        <Typography variant='h2' style={{ fontWeight: 'bold' }}>
          {t('common.titles.productCluster')}
        </Typography>
      </Box>
      <Grid size={12}>
        <ProductGroupsFilter />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={12}>
        {state.loading ? <ProductGroupsGridSkeleton /> : <ProductGroupsGrid />}
      </Grid>
    </>
  )
}
