import {
  GridInitialState,
  GridPaginationInitialState,
  GridSortModel,
} from '@mui/x-data-grid-premium'

import { PageSize } from '../enums'
import { getLocalItem } from './OfflineManager'

export const getDefaultPagination = (
  key: string
): GridPaginationInitialState => ({
  paginationModel: {
    pageSize: PageSize.sm,
    page: 0,
  },
  ...getLocalItem<GridInitialState>(key)?.pagination,
})

export const getDefaultSortModel = (
  key: string,
  defaultSortModel: GridSortModel
): GridSortModel =>
  getLocalItem<GridInitialState>(key)?.sorting?.sortModel || defaultSortModel
