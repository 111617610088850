import { LookupDialog } from 'src/common/components'
import type { Item } from 'src/common/types'

import { useRuleStore } from '../../stores'

export default function AuctionGroupRulesDialog(): JSX.Element {
  const { rule, ruleCodes, setRule } = useRuleStore()

  const getItems = async () => await Promise.resolve(ruleCodes.VEILGROEPREGEL)

  function selectItem(item: Item<number>): void {
    setRule({
      ...rule,
      veilgroepRegelId: item.id,
      veilgroepRegelNummer: item.code,
      veilgroepRegelOmschrijving: item.name,
    })
  }

  return (
    <LookupDialog
      getItems={getItems}
      searchTitle={'Zoek veilgroepregel'}
      onChange={selectItem}
    />
  )
}
