import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { FormControl, NativeSelect, SxProps, Typography } from '@mui/material'
import theme from '@rfh-core/theme'

import { ELEMENT_HEIGHT } from '../constants'
import { Location } from '../enums'
import { IAllSearchFilterFields } from '../interfaces'
import { LocationsListByEnum } from './LocationsListByEnum'

type AvailableLocationsProps = {
  handleChange: (
    name: keyof IAllSearchFilterFields,
    value: string,
    storeLocal?: boolean
  ) => void
  changeValid?: (value: boolean) => void
  labelVisible?: boolean
  required?: boolean
  inputValue: number
  sx?: SxProps
}

export default function AvailableLocations({
  handleChange,
  changeValid,
  labelVisible,
  required,
  inputValue,
  sx,
}: Readonly<AvailableLocationsProps>): JSX.Element {
  const { t } = useTranslation()

  const selectItemOrClear = (event: ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target
    if (!value || value === '') {
      handleChange('vestigingCode', undefined)
      if (changeValid) {
        changeValid(false)
      }
    } else {
      handleChange('vestigingCode', `${value}`)
      if (changeValid) {
        changeValid(true)
      }
    }
  }

  return (
    <FormControl sx={sx}>
      {labelVisible && (
        <Typography
          variant={'subtitle2'}
          sx={{ fontWeight: 'bold', mb: '0.5rem' }}
        >
          {required
            ? `${t('common.auctionLocation')} *`
            : t('common.auctionLocation')}
        </Typography>
      )}
      <NativeSelect
        required={required}
        data-testid='location'
        value={inputValue ? `${inputValue}` : ''}
        onChange={selectItemOrClear}
        sx={{
          marginTop: 2.1,
          minHeight: ELEMENT_HEIGHT,
          bgcolor: theme.rfhColors.white,
          'svg.MuiNativeSelect-icon': { pointerEvents: 'none' },
        }}
      >
        <LocationsListByEnum enumType={Location} />
      </NativeSelect>
    </FormControl>
  )
}
