import { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config'
import { noOp } from 'src/common/lib'

type ActionModalProps = {
  onCancel?: () => void
  onConfirm?: () => void
  open?: boolean
  translationsKey: string
  customDialogElement?: ReactElement
}

export default function ActionModal({
  onCancel = noOp,
  onConfirm = noOp,
  open = false,
  translationsKey,
  customDialogElement = null,
}: Readonly<ActionModalProps>): JSX.Element {
  const { t } = useTranslation()

  if (customDialogElement) return customDialogElement

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>
        <Typography variant={'subtitle1'} sx={{ fontWeight: 'bold' }}>
          {t(`${translationsKey}.title` as any)}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          mt: ThemeConfig.spacing.sm,
          px: 0,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          mx={6}
          pb={3}
        >
          {t(`${translationsKey}.description` as any)}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancel}
          variant={'text'}
          sx={{
            border: `1px solid ${theme.rfhColors.dutchOrange}`,
            textDecoration: 'none',
            minWidth: '80px',
          }}
        >
          {t('common.no')}
        </Button>
        <Button
          onClick={onConfirm}
          variant='contained'
          sx={{ minWidth: '80px' }}
        >
          {t('common.yes')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
