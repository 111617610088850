import { useCallback, useEffect, useRef, useState } from 'react'

import { Config } from 'src/common/config'
import { WSReadyState } from 'src/common/enums'

type UseWebSocketReturn<T> = {
  connectionStatus: WSReadyState
  wsData: T
}

export const useWebSocket = <T>(topicName: string): UseWebSocketReturn<T> => {
  const isMounted = useRef<boolean>(false)
  const webSocket = useRef<WebSocket>()
  const reconnectTimeout = useRef<NodeJS.Timeout>()
  const [connectionStatus, setConnectionStatus] = useState(
    WSReadyState.CONNECTING
  )
  const [wsData, setWsData] = useState<T>()

  const connectWebSocket = useCallback(() => {
    isMounted.current = true
    // eslint-disable-next-line react-hooks/exhaustive-deps
    webSocket.current = new WebSocket(Config.webSocket.endpoint)

    webSocket.current.onopen = () => {
      console.log('WebSocket connected')
      setConnectionStatus(WSReadyState.OPEN)
      webSocket.current.send(
        JSON.stringify({
          action: 'default',
          // eslint-disable-next-line id-blacklist
          data: { topicName, action: 'subscribe' },
        })
      )
    }

    webSocket.current.onmessage = event => {
      let message: T
      try {
        message = JSON.parse(event.data)
      } catch (err: any) {
        message = event.data
      }

      if (Array.isArray(message)) {
        setWsData(message)
      }
    }

    webSocket.current.onclose = () => {
      console.log('WebSocket disconnected')
      if (isMounted.current) {
        setConnectionStatus(WSReadyState.CLOSED)
        reconnectTimeout.current = setTimeout(connectWebSocket, 3000)
      }
    }

    webSocket.current.onerror = () => {
      setConnectionStatus(WSReadyState.CLOSED)
      reconnectTimeout.current = setTimeout(connectWebSocket, 3000)
    }

    return () => {
      webSocket.current.close()
      isMounted.current = false
    }
  }, [topicName])

  useEffect(() => {
    connectWebSocket()

    return () => {
      if (webSocket) {
        webSocket.current.close()
      }
      if (reconnectTimeout?.current) {
        clearTimeout(reconnectTimeout.current)
      }
    }
  }, [connectWebSocket, reconnectTimeout, webSocket])

  useEffect(() => {
    const handleNetworkChange = () =>
      setConnectionStatus(
        navigator.onLine ? WSReadyState.OPEN : WSReadyState.CLOSED
      )

    window.addEventListener('online', handleNetworkChange)
    window.addEventListener('offline', handleNetworkChange)

    return () => {
      window.removeEventListener('online', handleNetworkChange)
      window.removeEventListener('offline', handleNetworkChange)
    }
  }, [])

  return { wsData, connectionStatus }
}
