type EnumAsProp<T extends Record<string, string | number>> = {
  enumType: T
}

export function LocationsListByEnum<T extends Record<string, string | number>>({
  enumType,
}: Readonly<EnumAsProp<T>>): JSX.Element {
  const enumPropertyNames = getEnumPropertyNames<T>(enumType)
  const locationData = enumPropertyNames.map(name => (
    <option key={String(name)} value={enumType[name]}>
      {String(name)}
    </option>
  ))
  locationData.unshift(<option key='' value=''></option>)

  return <>{locationData}</>
}

const getEnumPropertyNames = <T extends Record<string, string | number>>(
  enumObject: T
): (keyof T)[] =>
  Object.keys(enumObject).filter(
    key => !isNaN(Number(enumObject[key]))
  ) as (keyof T)[]
