import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  IKenmerk,
  IKenmerkWaarde,
  IVeilgroepRegel,
  IVeilgroepRegelKenmerk,
} from 'src/common/services/client'

import {
  defaultAuctionPeriod,
  defaultLoadCarrier,
  defaultQuality,
  defaultSupplier,
  defaultValues,
} from '../constants'
import {
  loadCarierTypesToString,
  qualityToString,
  suplierTypesToString,
} from '../lib'
import { Aanvoertypen, DateInput, Kwaliteit, LadingdragerTypen } from '../types'

type StoreState = {
  auctionGroupRule: IVeilgroepRegel
  auctionGroupRuleCharacteristics: IVeilgroepRegelKenmerk[]
  mustRefetchAuctionGroupRule: boolean
  oldAuctionGroupRule: IVeilgroepRegel
  characteristics: IKenmerk[]
  characteristicValues: IKenmerkWaarde[]
  quality: Kwaliteit
  supplier: Aanvoertypen
  loadCarriers: LadingdragerTypen
  mustRefetchAuctionGroupRuleCharacteristics: boolean
  auctionPeriod: DateInput
}

type Actions = {
  /* store */
  resetAuctionGroupRuleStoreState: () => void
  /* Auction group auctionGroupRule? */
  setRule: (c: IVeilgroepRegel) => void
  setMustRefetchRule: (input: boolean) => void
  setOldRule: (oc: IVeilgroepRegel) => void
  /* and their characteristics */
  addCharacteristic: (arc: IVeilgroepRegelKenmerk) => void
  removeCharacteristic: (id: number) => void
  setRuleCharacteristics: (arcs: IVeilgroepRegelKenmerk[]) => void
  setCharacteristics: (cs: IKenmerk[]) => void
  setCharacteristicValues: (cvs: IKenmerkWaarde[]) => void
  setQuality: (q: Kwaliteit) => void
  setLoadCarriers: (lc: LadingdragerTypen) => void
  setSupplier: (s: Aanvoertypen) => void
  setMustRefetchAuctionGroupRuleCharacteristics: (input: boolean) => void
  setAuctionPeriod: (input: DateInput) => void
}

const storeName = 'AuctionGroupRuleStore'

const initialState: StoreState = {
  auctionGroupRuleCharacteristics: [],
  mustRefetchAuctionGroupRule: false,
  oldAuctionGroupRule: defaultValues,
  auctionGroupRule: defaultValues,
  characteristics: [],
  characteristicValues: [],
  quality: defaultQuality,
  loadCarriers: defaultLoadCarrier,
  supplier: defaultSupplier,
  mustRefetchAuctionGroupRuleCharacteristics: false,
  auctionPeriod: defaultAuctionPeriod,
}

type Store = StoreState & Actions

export const useAuctionGroupRuleStore = create<Store>()(
  devtools(
    persist(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (set, get: () => any): Store => ({
        ...initialState,
        /* Store */
        resetAuctionGroupRuleStoreState: () =>
          set(() => initialState, false, 'resetAuctionGroupRuleStoreState'),
        /* Auction group auctionGroupRule? */
        setRule: (vgr: IVeilgroepRegel) =>
          set(
            () => ({
              auctionGroupRule: {
                ...vgr,
              },
            }),
            false,
            'setRule'
          ),
        setMustRefetchRule: (input: boolean) =>
          set(
            () => ({ mustRefetchAuctionGroupRule: input }),
            false,
            'setMustRefetchRule'
          ),
        setOldRule: (vgr: IVeilgroepRegel) =>
          set(
            () => ({
              oldAuctionGroupRule: {
                ...vgr,
              },
            }),
            false,
            'setOldRule'
          ),
        /*their characteristics */
        addCharacteristic: (vgrk: IVeilgroepRegelKenmerk) =>
          set(
            (state: StoreState) => ({
              ...state,
              auctionGroupRuleCharacteristics: [
                ...state.auctionGroupRuleCharacteristics,
                vgrk,
              ],
            }),
            false,
            'addCharacteristic'
          ),
        removeCharacteristic: (id: number) =>
          set(
            (state: StoreState) => ({
              ...state,
              auctionGroupRuleCharacteristics: [
                ...state.auctionGroupRuleCharacteristics.filter(
                  c => c?.veilgroepRegelKenmerkId !== id
                ),
              ],
            }),
            false,
            'removeCharacteristic'
          ),
        setRuleCharacteristics: (vgrk: IVeilgroepRegelKenmerk[]) =>
          set(
            () => ({ auctionGroupRuleCharacteristics: [...vgrk] }),
            false,
            'setRuleCharacteristics'
          ),
        setCharacteristics: (cs: IKenmerk[]) =>
          set(
            () => ({ characteristics: [...cs] }),
            false,
            'setCharacteristics'
          ),
        setCharacteristicValues: (cvs: IKenmerkWaarde[]) =>
          set(
            () => ({ characteristicValues: [...cvs] }),
            false,
            'setCharacteristicValues'
          ),
        setQuality: (q: Kwaliteit) =>
          set(
            (state: StoreState) => ({
              ...state,
              auctionGroupRule: {
                ...state.auctionGroupRule,
                kwaliteit: qualityToString(q),
              },
              quality: q,
            }),
            false,
            'setQuality'
          ),
        setLoadCarriers: (lc: LadingdragerTypen) =>
          set(
            (state: StoreState) => ({
              ...state,
              auctionGroupRule: {
                ...state.auctionGroupRule,
                ladingdragerTypen: loadCarierTypesToString(lc),
              },
              loadCarriers: lc,
            }),
            false,
            'setLoadCarriers'
          ),
        setSupplier: (s: Aanvoertypen) =>
          set(
            (state: StoreState) => ({
              ...state,
              auctionGroupRule: {
                ...state.auctionGroupRule,
                aanvoerTypen: suplierTypesToString(s),
              },
              supplier: s,
            }),
            false,
            'setSupplier'
          ),
        setMustRefetchAuctionGroupRuleCharacteristics: (input: boolean) =>
          set(
            () => ({ mustRefetchAuctionGroupRuleCharacteristics: input }),
            false,
            'setMustRefetchAuctionGroupRuleCharacteristics'
          ),
        setAuctionPeriod: (input: DateInput) =>
          set(
            (state: StoreState) => ({
              ...state,
              auctionPeriod: {
                ...state.auctionPeriod,
                ...input,
              },
              auctionGroupRule: {
                ...state.auctionGroupRule,
                ...input,
              },
            }),
            false,
            'setAuctionPeriod'
          ),
      }),
      {
        name: 'auctiongroup-m-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { name: storeName }
  )
)
