import { useCallback, useEffect, useMemo, useReducer } from 'react'
import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'

import { useStore } from 'zustand'

import { Divider } from 'src/common/components/Divider'
import type { IApiClientListFilter } from 'src/common/interfaces'
import { refreshReducer } from 'src/common/lib'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { IClient } from 'src/common/services/client'
import type { RefreshState } from 'src/common/types'
import { snackbarUtils } from 'src/common/utils'

import { ClustersGrid } from '../components/ClustersGrid'
import { getClustersAsync } from '../services'
import { useSupplierClustersStore, useSupplierClusterStore } from '../stores'

export default function SupplierClusters(): JSX.Element {
  const { mustRefetchClusters, setClusters, setMustRefetchClusters } =
    useSupplierClustersStore()
  const resetSupplierClusterStoreState = useStore(
    useSupplierClusterStore,
    state => state.resetSupplierClusterStoreState
  )
  const { t } = useTranslation()
  const { apiClient } = useApiClient()
  const apiClientFilter: Partial<IApiClientListFilter> = useMemo(
    () => ({
      expand: ['aanvoerderclusterAanvoerders'],
    }),
    []
  )

  const initialState: RefreshState = {
    loading: false,
  }
  const [state, dispatch] = useReducer(refreshReducer, initialState)

  const fetchData = useCallback(
    async (client: IClient): Promise<void> => {
      if (!client || state.loading) {
        return Promise.resolve()
      }

      try {
        dispatch({ type: 'PENDING' })
        const supplierClusters = await getClustersAsync(client, apiClientFilter)
        setClusters(supplierClusters)
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        dispatch({ type: 'RESOLVED' })
      }
    },
    [apiClientFilter, setClusters, state.loading]
  )

  useEffect(() => {
    if (apiClient && mustRefetchClusters) {
      fetchData(apiClient)
      setMustRefetchClusters(false)
    }
  }, [apiClient, fetchData, mustRefetchClusters, setMustRefetchClusters])

  useEffect(() => {
    resetSupplierClusterStoreState()
    setMustRefetchClusters(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Grid size={12} mb={2}>
        <Typography variant={'h2'}>
          {t('common.titles.supplierClusters')}
        </Typography>
      </Grid>
      <Divider />
      <Grid size={{ sm: 12 }} mt={4}>
        <ClustersGrid data-testid={'clusters-grid'} loading={state.loading} />
      </Grid>
    </>
  )
}
