import { IUitzonderingReden } from 'src/common/services/client'

type ReasonOptionsProps = {
  reasons: IUitzonderingReden[]
}

export default function ReasonOptions({
  reasons,
}: Readonly<ReasonOptionsProps>): JSX.Element {
  const reasonsList = reasons ?? []
  const reasonData = reasonsList.map((reason: IUitzonderingReden) => (
    <option
      key={reason.uitzonderingRedenCode}
      value={reason.uitzonderingRedenCode}
    >
      {reason.uitzonderingRedenOmschrijving}
    </option>
  ))
  reasonData.unshift(<option key='' value=''></option>)

  return <>{reasonData}</>
}
