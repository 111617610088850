import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { useApiClient } from 'src/common/providers'

import type { GetFilterType, GetFuncType } from './lib'
import {
  EntityType,
  FuncSort,
  getFuncByEntity,
  hasPropertyAndNotNull,
  isNumberDefined,
} from './lib'

export default function useFetchOne<T>(
  filter: GetFilterType,
  entity: EntityType,
  enabled = true,
  extraOptions?: Partial<UseQueryOptions>
): UseQueryResult<T> {
  const { apiClient } = useApiClient()
  const func = getFuncByEntity(FuncSort.get, entity) as GetFuncType
  const queryEnabled = Boolean(
    enabled &&
      Boolean(apiClient) &&
      Boolean(filter?.key) &&
      isNumberDefined(filter.key) &&
      hasPropertyAndNotNull(apiClient, func)
  )
  const queryResult = useQuery({
    queryKey: [entity, FuncSort.get, filter],
    queryFn: () =>
      apiClient[func](Number(filter.key), undefined, undefined) as any,
    enabled: queryEnabled,
    keepPreviousData: true,
    ...extraOptions,
  }) as UseQueryResult<T>

  return queryResult
}
