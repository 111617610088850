/* eslint-disable import/no-unresolved */
import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  inputBaseClasses,
  outlinedInputClasses,
  paperClasses,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { useStore } from 'zustand'

import { Divider } from 'src/common/components'
import { ThemeConfig } from 'src/common/config'
import { MR, TABLE_CELL_STYLE } from 'src/common/constants'
import { byBloemenPlantenFilter } from 'src/common/lib'
import { IClient, IProductgroepenReport } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import { byExistingCodeFilter, getExtraReportsFromDeleted } from '../../lib'
import { getAllowedProductGroupsAsync } from '../../services'
import { useProductClustersStore, useProductClusterStore } from '../../stores'
import ProductGroupsItem from './ProductGroupsItem'

type ProductGroupsAddDialogProps = {
  apiClient: IClient
  buttonSx?: SxProps
  disabled?: boolean
  onChange: (productgroepen: IProductgroepenReport[]) => void
  showNumber?: boolean
  trigger?: 'iconButton' | 'button'
}

export default function ProductGroupsAddDialog({
  apiClient,
  buttonSx,
  disabled = false,
  onChange,
  showNumber = false,
  trigger = 'iconButton',
}: Readonly<ProductGroupsAddDialogProps>): JSX.Element {
  const { t } = useTranslation()
  const { handleSubmit } = useForm()
  const bloemenPlanten = useStore(
    useProductClustersStore,
    state => state.bloemenPlanten
  )
  const { deletedProductgroepen, productclusterProductgroepen } =
    useProductClusterStore()
  const [inProgress, setInProgress] = useState<boolean>(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [allowedFromApi, setAllowedFromApi] = useState<IProductgroepenReport[]>(
    []
  )
  const [allowedProductgroepen, setAllowedProductgroepen] = useState<
    IProductgroepenReport[]
  >([])
  const [searchByCodePattern, setSearchByCodePattern] = useState<string>('')
  const [searchByNamePattern, setSearchByNamePattern] = useState<string>('')

  const openDialog = () => {
    setIsOpen(true)
    fetchData()
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  const changeChecked = (clickedProductGroep: IProductgroepenReport) => {
    const index = allowedProductgroepen.findIndex(
      pg => pg.productGroepCode === clickedProductGroep.productGroepCode
    )
    const updatedProductgroepen = [
      ...allowedProductgroepen.slice(0, index),
      clickedProductGroep,
      ...allowedProductgroepen.slice(index + 1),
    ]
    setAllowedProductgroepen(updatedProductgroepen)
  }

  const resetAllowedProductgroepenFromApi = () =>
    setAllowedProductgroepen([...allowedFromApi])

  const handleSearch =
    (searchPattern?: string, searchType?: 'code' | 'name') => () => {
      if (!searchPattern) {
        resetAllowedProductgroepenFromApi()
        return
      }

      const pattern = new RegExp(searchPattern, 'i')
      if (searchType === 'code') {
        setAllowedProductgroepen([
          ...allowedProductgroepen.filter(pg =>
            pattern.exec(pg.productGroepCode.toString())
          ),
        ])
      } else if (searchType === 'name') {
        setAllowedProductgroepen([
          ...allowedProductgroepen.filter(pg =>
            pattern.exec(pg.productGroepOmschrijving)
          ),
        ])
      } else {
        resetAllowedProductgroepenFromApi()
      }
    }

  const handleChange = () => {
    const selected = allowedProductgroepen.filter(
      pg => pg.productGroepGeselecteerd
    )
    if (selected.length > 0) {
      onChange(selected)
    }
    closeDialog()
  }

  const changeFilterProductGroupCode = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const searchPattern = event.target.value
    setSearchByCodePattern(searchPattern)
    setSearchByNamePattern('')
    handleSearch(searchPattern, 'code')()
  }

  const changeFilterProductGroupName = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    const searchPattern = event.target.value
    setSearchByNamePattern(event.target.value)
    setSearchByCodePattern('')
    handleSearch(searchPattern, 'name')()
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    try {
      setInProgress(true)
      const allowed = await getAllowedProductGroupsAsync(
        apiClient,
        `${bloemenPlanten}`
      )
      setAllowedFromApi(allowed)
      const extraReportsFromDeleted: IProductgroepenReport[] =
        getExtraReportsFromDeleted(allowed, deletedProductgroepen)
      const allAllowed = [...allowed, ...extraReportsFromDeleted]
      const filteredAllowed = allAllowed.filter(
        byExistingCodeFilter(productclusterProductgroepen)
      )
      setAllowedProductgroepen(filteredAllowed)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
    }
  }

  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton aria-label={'lookup icon button'} onClick={openDialog}>
          <SearchIcon sx={{ color: theme.rfhColors.dutchOrange, height: 20 }} />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={openDialog}
          sx={{ pointerEvents: 'auto', ...buttonSx }}
          type='submit'
          variant='contained'
        >
          {t('common.add')}
        </Button>
      )}
      <Dialog
        open={isOpen}
        onClose={closeDialog}
        sx={{
          [`& .${paperClasses.root}`]: {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogContent
          sx={{
            width: '600px',
            maxWidth: '600px',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '0',
              left: '1.5em',
              right: MR,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              bgcolor: 'white',
              pt: '2rem',
              zIndex: 1,
            }}
          >
            <Typography sx={{ mb: '16px' }} variant={'h4'}>
              {t('productCluster.searchProductGroups')}
            </Typography>
            <form
              onSubmit={handleSubmit(handleSearch())}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: ThemeConfig.spacing.l,
              }}
            >
              {showNumber && (
                <TextField
                  id='productGroupCode'
                  name='productGroupCode'
                  placeholder={t('common.code')}
                  value={searchByCodePattern}
                  onChange={changeFilterProductGroupCode}
                  sx={{
                    [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                      {
                        bgcolor: theme.rfhColors.grey[100],
                      },
                  }}
                />
              )}
              <TextField
                fullWidth
                id='productGroupName'
                name='productGroupName'
                placeholder={t('productCluster.productGroupDescription')}
                value={searchByNamePattern}
                onChange={changeFilterProductGroupName}
                sx={{
                  [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                    {
                      bgcolor: theme.rfhColors.grey[100],
                    },
                }}
              />
              <Button variant='contained' onClick={handleSubmit(handleChange)}>
                {t('common.ok')}
              </Button>
            </form>
            <Divider {...{ mt: '1rem', mb: 0, mr: MR }} />
          </Box>
          <Table sx={{ minHeight: '100px', mt: '7rem' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={TABLE_CELL_STYLE}>
                  {t('productCluster.productGroupCode')}
                </TableCell>
                <TableCell sx={TABLE_CELL_STYLE}>
                  {t('productCluster.productGroupDescription')}
                </TableCell>
                <TableCell sx={{ ...TABLE_CELL_STYLE, textAlign: 'right' }}>
                  {t('productCluster.productGroupSelected')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!allowedProductgroepen.length && (
                <TableRow>
                  <TableCell colSpan={3}>{t('common.noResults')}</TableCell>
                </TableRow>
              )}
              {allowedProductgroepen
                .filter(byBloemenPlantenFilter(bloemenPlanten))
                .map((pg: IProductgroepenReport) => (
                  <ProductGroupsItem
                    changeChecked={changeChecked}
                    key={pg.productGroepCode}
                    productGroep={pg}
                  />
                ))}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions sx={{ mr: MR }}>
          <Button
            variant='outlined'
            autoFocus
            onClick={closeDialog}
            sx={{ mb: ThemeConfig.spacing.xs }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
