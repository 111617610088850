import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  inputBaseClasses,
  outlinedInputClasses,
  paperClasses,
  TextField,
  Typography,
} from '@mui/material'
import { SearchIcon } from '@rfh-core/icons'
import theme from '@rfh-core/theme'

import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { MR } from 'src/common/constants'
import { fromAanvoerderToItem } from 'src/common/lib'
import { getAanvoerderAsync, getAanvoerdersAsync } from 'src/common/services'
import { IAanvoerder, IClient } from 'src/common/services/client'
import { Item } from 'src/common/types'

import { Divider } from '../Divider'
import LookupGrid from '../LookupDialog/LookupGrid'

type AvailableSuppliersDialogProps = {
  apiClient: IClient
  buttonSx?: Record<string, unknown>
  disabled?: boolean
  onChange: (aanvoerder: IAanvoerder) => void
  showNumber?: boolean
  trigger?: 'iconButton' | 'button'
}

const AvailableSuppliersDialog: FC<AvailableSuppliersDialogProps> = ({
  apiClient,
  buttonSx,
  disabled = false,
  onChange,
  showNumber = false,
  trigger = 'iconButton',
}) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit } = useForm()
  const [inProgress, setInProgress] = useState(false)
  const [openSupplier, setOpenSupplier] = useState(false)
  const [suppliers, setSuppliers] = useState<IAanvoerder[]>([])
  const [searchStringSupplierName, setSearchStringSupplierName] =
    useState<string>('')
  const [searchSupplierNumber, setSearchSupplierNumber] = useState<
    number | undefined
  >(undefined)

  const handleClickOpen = () => {
    setOpenSupplier(true)
    fetchData()
  }

  const handleClose = () => {
    setOpenSupplier(false)
  }

  const itemSelected = (evt: Item<number>) => {
    const selectedAanvoerderNummer = evt.code
    const supplier: IAanvoerder = {
      aanvoerderNummer: selectedAanvoerderNummer,
      aanvoerderNaam: suppliers.find(
        a => a.aanvoerderNummer === selectedAanvoerderNummer
      ).aanvoerderNaam,
    }
    onChange(supplier)
    handleClose()
  }

  const submitKeyboardInput = (evt: any) => {
    if (evt.key === 'Enter' && !evt.shiftKey) {
      evt.preventDefault()
      handleSubmit(onSubmit)()
    }
  }

  const onSubmit = async () => {
    await fetchData()
    setSearchStringSupplierName('')
    setSearchSupplierNumber(undefined)
  }

  const changeFilterSupplierName = (evt: any): void => {
    setSearchStringSupplierName(evt.target.value)
    setSearchSupplierNumber(undefined)
  }

  const changeFilterSupplierNumber = (evt: any): void => {
    setSearchSupplierNumber(evt.target.value)
    setSearchStringSupplierName('')
  }

  const fetchData = async () => {
    if (inProgress) {
      return Promise.resolve()
    }

    setInProgress(true)
    const filterString = searchStringSupplierName
      ? `contains(aanvoerdernaam, '${searchStringSupplierName}')`
      : undefined
    const key = searchSupplierNumber
    let records: Partial<IAanvoerder>[]
    if (key) {
      records = [
        await getAanvoerderAsync(apiClient, {
          key,
        }),
      ]
    } else {
      records = await getAanvoerdersAsync(apiClient, {
        filter: filterString,
      })
    }
    setSuppliers(records)
    setInProgress(false)
  }

  return (
    <>
      {trigger === 'iconButton' ? (
        <IconButton aria-label={'lookup icon button'} onClick={handleClickOpen}>
          <SearchIcon sx={{ color: theme.rfhColors.dutchOrange, height: 20 }} />
        </IconButton>
      ) : (
        <Button
          disabled={disabled}
          onClick={handleClickOpen}
          sx={{ pointerEvents: 'auto', ...buttonSx }}
          type='submit'
          variant='contained'
        >
          {t('common.add')}
        </Button>
      )}
      <Dialog
        open={openSupplier}
        onClose={handleClose}
        sx={{
          [`& .${paperClasses.root}`]: {
            overflowX: 'hidden',
          },
        }}
      >
        <DialogContent sx={{ width: '600px', maxWidth: '600px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: '1.5rem',
              right: MR,
              clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0% 100%)',
              bgcolor: 'white',
              pt: '2rem',
              zIndex: 1,
            }}
          >
            <Typography sx={{ mb: '16px' }} variant={'h4'}>
              {t('exceptions.addExceptions.searchSuppliers')}
            </Typography>
            <form
              onSubmit={handleSubmit(onSubmit)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: ThemeConfig.spacing.l,
              }}
            >
              {showNumber && (
                <TextField
                  id='aanvoerderNummer'
                  name='aanvoerderNummer'
                  placeholder={t('common.number')}
                  onChange={changeFilterSupplierNumber}
                  onKeyUp={submitKeyboardInput}
                  sx={{
                    [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                      {
                        bgcolor: theme.rfhColors.grey[100],
                      },
                  }}
                />
              )}
              <TextField
                id='aaanvoerderNaam'
                name='aaanvoerderNaam'
                placeholder={t('exceptions.addExceptions.suplierName')}
                fullWidth
                onChange={changeFilterSupplierName}
                onKeyUp={submitKeyboardInput}
                sx={{
                  [`& .${inputBaseClasses.root}.${outlinedInputClasses.root}`]:
                    {
                      bgcolor: theme.rfhColors.grey[100],
                    },
                }}
              />
              <Button
                variant='contained'
                onClick={handleSubmit(onSubmit)}
                disabled={inProgress}
              >
                {t('common.search')}
              </Button>
            </form>
            <Divider {...{ ml: 0, mr: MR, mb: 0 }} />
          </Box>
          {/*GRID WITH LOOKUP DATA*/}
          <LookupGrid
            items={suppliers.map(fromAanvoerderToItem)}
            selectItem={itemSelected}
          />
        </DialogContent>
        <DialogActions sx={{ mr: MR }}>
          <Button
            variant={'outlined'}
            autoFocus
            onClick={handleClose}
            sx={{ mb: ThemeConfig.spacing.sm * 2 }}
          >
            {t('common.back')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AvailableSuppliersDialog
