import { ChangeEvent, useMemo } from 'react'

import {
  BloemenPlantenSelect,
  FilterFieldsStack,
  FilterStack,
  LocationSelect,
} from 'src/common/components'
import type { BloemenPlanten } from 'src/common/types'

import { getAvailableLocations } from '../lib'
import { useAllocationsStore } from '../stores'

/**
 * AllocationsFilter is the title + search filter widget
 * @param rest (optional) any extra (e.g. styling) properties
 * @returns AllocationsFilter JSX
 */
export default function AllocationsFilter({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element {
  const { filter, setFilter, setMustRefetchAllocations } = useAllocationsStore()
  const locations = useMemo(
    () => getAvailableLocations(filter?.bloemenPlantenCode),
    [filter?.bloemenPlantenCode]
  )

  const changeLocation = (event: ChangeEvent<HTMLSelectElement>) => {
    setFilter({ locationCode: +event.target.value })
    setMustRefetchAllocations(true)
  }
  const changeBloemenPlanten = (event: ChangeEvent<HTMLSelectElement>) => {
    const newBloemenPlantenKeuze = event.target.value as BloemenPlanten
    const availableLocations = getAvailableLocations(newBloemenPlantenKeuze)
    if (newBloemenPlantenKeuze === 'P') {
      setFilter({
        locationCode:
          availableLocations.find(l => l.code === filter.locationCode)?.code ??
          availableLocations[0].code,
        bloemenPlantenCode: newBloemenPlantenKeuze,
      })
    } else {
      setFilter({
        bloemenPlantenCode: newBloemenPlantenKeuze,
      })
    }
    setMustRefetchAllocations(true)
  }

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <BloemenPlantenSelect
          bloemenPlanten={filter?.bloemenPlantenCode}
          onChange={changeBloemenPlanten}
        />
        <LocationSelect
          locations={locations}
          locationCode={filter.locationCode}
          onChange={changeLocation}
        />
      </FilterFieldsStack>
    </FilterStack>
  )
}
