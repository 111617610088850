import type { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium'

import { v4 as getRandomID } from 'uuid'

import { BooleanTagCentered as BooleanTag } from 'src/common/components'
import { type IAanvoerderProductRegelmatigView } from 'src/common/services/client'
import { formatDate } from 'src/common/utils'
import i18n from 'src/i18n'

export const isRegularityDefined = (
  regularity: IAanvoerderProductRegelmatigView
): boolean =>
  regularity &&
  regularity?.vestigingCode > 0 &&
  regularity?.aanvoerderNummer > 0 &&
  regularity?.productCode > 0

export function getRegularityGridColumns(
  sortingAllowed: boolean
): GridColDef[] {
  const newColumns: GridColDef[] = []
  newColumns.push({
    field: 'vestigingNaam',
    headerName: i18n.t('common.auctionLocation'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 90,
    sortable: sortingAllowed,
    valueFormatter: ({ value }: { value: string }) =>
      value ? value.charAt(0).toUpperCase() + value.slice(1) : '',
  })
  newColumns.push({
    field: 'aanvoerderNummer',
    headerName: i18n.t('overviews.supplierNumber'),
    sortable: sortingAllowed,
    align: 'right',
    headerAlign: 'right',
    minWidth: 130,
  })
  newColumns.push({
    field: 'aanvoerderNaam',
    headerName: i18n.t('overviews.supplierName'),
    minWidth: 160,
    align: 'left',
    headerAlign: 'left',
    sortable: sortingAllowed,
  })
  newColumns.push({
    field: 'productCode',
    headerName: i18n.t('overviews.productCode'),
    align: 'right',
    headerAlign: 'right',
    minWidth: 120,
    sortable: sortingAllowed,
  })
  newColumns.push({
    field: 'productNaam',
    headerName: i18n.t('overviews.productDescription'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 180,
    sortable: sortingAllowed,
  })
  newColumns.push({
    field: 'regelmatig',
    headerName: i18n.t('overviews.regularity'),
    sortable: sortingAllowed,
    align: 'center',
    headerAlign: 'left',
    minWidth: 110,
    aggregable: false,
    renderCell: (params: GridRenderCellParams) => (
      <BooleanTag value={params.row.regelmatig} />
    ),
  })
  newColumns.push({
    field: 'aantalWekenLimiet',
    headerName: i18n.t('overviews.weekLimit'),
    type: 'number',
    align: 'center',
    headerAlign: 'left',
    minWidth: 160,
    sortable: sortingAllowed,
  })
  newColumns.push({
    field: 'aantalDagenGeveild',
    headerName: i18n.t('overviews.auctionDays'),
    type: 'number',
    align: 'center',
    headerAlign: 'left',
    minWidth: 130,
    sortable: sortingAllowed,
  })
  newColumns.push({
    field: 'aantalDagenGeveildWeek',
    headerName: i18n.t('overviews.auctionDaysWeek'),
    type: 'number',
    align: 'center',
    headerAlign: 'left',
    minWidth: 140,
    sortable: sortingAllowed,
  })
  newColumns.push({
    field: 'aantalWekenUitzondering',
    headerName: i18n.t('overviews.exceptionWeeks'),
    type: 'number',
    align: 'center',
    headerAlign: 'left',
    minWidth: 190,
    sortable: sortingAllowed,
    valueFormatter: ({ value }) => value ?? 0,
  })
  newColumns.push({
    field: 'datumEindeWeek',
    headerName: i18n.t('overviews.dateEndOfWeek'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 160,
    sortable: sortingAllowed,
    valueFormatter: row =>
      row.value && formatDate(row, i18n.t('common.dateFormatShort')),
    groupingValueGetter: row =>
      row.value && formatDate(row, i18n.t('common.dateFormatShort')),
  })

  return newColumns
}

export const getHistoryGridColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []

  columns.push({
    field: 'datumEindeWeek',
    headerName: i18n.t('regularity.datumEindeWeek'),
    headerAlign: 'center',
    align: 'right',
    flex: 1,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
  })
  columns.push({
    field: 'aantalDagenGeveild',
    headerName: i18n.t('overviews.auctionDays'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: item => item?.value ?? 0,
  })
  columns.push({
    field: 'aantalDagenGeveildWeek',
    headerName: i18n.t('overviews.auctionDaysWeek'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: item => item?.value ?? 0,
  })
  columns.push({
    field: 'aantalWekenLimiet',
    headerName: i18n.t('overviews.weekLimit'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: item => item?.value ?? 0,
  })
  columns.push({
    field: 'regelmatig',
    headerName: i18n.t('overviews.regularity'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: params => (
      <BooleanTag value={params.row.regelmatig} key={getRandomID()} />
    ),
  })
  columns.push({
    field: 'aantalWekenUitzondering',
    headerName: i18n.t('overviews.exceptionWeeks'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    valueFormatter: item => item?.value ?? 0,
  })
  columns.push({
    field: 'uitzondering',
    headerName: i18n.t('regularity.exceptionWeek'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: params => (
      <BooleanTag value={params.row.regelmatig} key={getRandomID()} />
    ),
  })
  columns.push({
    field: 'uitzonderingRedenOmschrijving',
    headerName: i18n.t('regularity.uitzonderingRedenOmschrijving'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  })
  columns.push({
    field: 'uitzonderingRedenTekst',
    headerName: i18n.t('regularity.uitzonderingRedenTekst'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
  })

  return columns
}
