import type { RegularityException, RegularityExceptionView } from '../types'
import { hasInterfaceKey } from '../utils'
import {
  AanvoerderProductgroepRegelmatigUitzondering,
  AanvoerderProductRegelmatigUitzondering,
  IAanvoerderProductgroepRegelmatigUitzonderingView,
  IAanvoerderProductRegelmatigUitzonderingView,
  IClient,
} from './client'

export const deleteRegularityException = async (
  apiClient: IClient,
  exceptionToBeDeleted: RegularityExceptionView
): Promise<void> => {
  if (
    hasInterfaceKey<IAanvoerderProductRegelmatigUitzonderingView>(
      exceptionToBeDeleted,
      'aanvoerderProductRegelmatigUitzonderingId'
    )
  ) {
    // eslint-disable-next-line max-len
    await apiClient.aanvoerderProductRegelmatigUitzonderingen_AanvoerderProductRegelmatigUitzondering_DeleteAanvoerderProductRegelmatigUitzondering(
      exceptionToBeDeleted.aanvoerderProductRegelmatigUitzonderingId,
      '*' //Fix: IF-Match header Bug, alle resources mogen nu matchen.
    )
  }
  if (
    hasInterfaceKey<IAanvoerderProductgroepRegelmatigUitzonderingView>(
      exceptionToBeDeleted,
      'aanvoerderProductgroepRegelmatigUitzonderingId'
    )
  ) {
    // DeleteSupplierProductGroupRegularityException
    // eslint-disable-next-line max-len
    await apiClient.aanvoerderProductgroepRegelmatigUitzonderingen_AanvoerderProductgroepRegelmatigUitzondering_DeleteAanvoerderProductgroepRegelmatigUitzondering(
      exceptionToBeDeleted.aanvoerderProductgroepRegelmatigUitzonderingId,
      '*' //Fix: IF-Match header Bug, alle resources mogen nu matchen.
    )
  }
}

export const updateRegularityException = async (
  apiClient: IClient,
  body: RegularityException
): Promise<void> => {
  if (
    hasInterfaceKey<AanvoerderProductRegelmatigUitzondering>(
      body,
      'aanvoerderProductRegelmatigUitzonderingId'
    )
  ) {
    // eslint-disable-next-line max-len
    await apiClient.aanvoerderProductRegelmatigUitzonderingen_AanvoerderProductRegelmatigUitzondering_UpdateAanvoerderProductRegelmatigUitzondering(
      body.aanvoerderProductRegelmatigUitzonderingId,
      body
    )
  }

  if (
    hasInterfaceKey<AanvoerderProductgroepRegelmatigUitzondering>(
      body,
      'aanvoerderProductgroepRegelmatigUitzonderingId'
    )
  ) {
    // eslint-disable-next-line max-len
    await apiClient.aanvoerderProductgroepRegelmatigUitzonderingen_AanvoerderProductgroepRegelmatigUitzondering_UpdateAanvoerderProductgroepRegelmatigUitzondering(
      body.aanvoerderProductgroepRegelmatigUitzonderingId,
      body
    )
  }
}
