import { allLocations } from 'src/common/constants'
import type { LocationType } from 'src/common/types'

type LocationsListProps = {
  locations?: LocationType[]
}

export default function LocationsList({
  locations = allLocations,
}: Readonly<LocationsListProps>): JSX.Element {
  return (
    <>
      {locations.map(loc => (
        <option key={loc.code} value={loc.code}>
          {loc.name}
        </option>
      ))}
    </>
  )
}
