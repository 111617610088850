import {
  IAanvoerderclusterAanvoerder,
  IAanvoerderclusterView,
} from 'src/common/services/client'

export const byAanvoerderclusterNummer = (
  sc1: IAanvoerderclusterView,
  sc2: IAanvoerderclusterView
): number => sc1.aanvoerderclusterNummer - sc2.aanvoerderclusterNummer

export const byVolgordeNummer = (
  scs1: IAanvoerderclusterAanvoerder,
  scs2: IAanvoerderclusterAanvoerder
): number => scs1.volgordeNummer - scs2.volgordeNummer
