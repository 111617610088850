import { MouseEventHandler, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import {
  Box,
  CardContent,
  CardMedia,
  svgIconClasses,
  Typography,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { StyledCard } from './MenuCard.styles'

type MenuCardProps = {
  bodyText?: string
  icon?: ReactNode
  onClick?: MouseEventHandler<HTMLAnchorElement> | (() => void)
  subheader?: string
  title: string
  to?: string
  visible?: boolean
}

export default function MenuCard({
  bodyText,
  icon,
  onClick,
  subheader = '',
  title,
  to = '',
}: Readonly<MenuCardProps>): JSX.Element {
  const { t } = useTranslation()

  return (
    <StyledCard
      component={Link}
      to={`/dashboard/${to}`}
      className='CardSecondary'
      data-testid='card'
      onClick={onClick}
    >
      <CardContent>
        <Box>
          <Typography className='book' variant='h4'>
            {t(`common.titles.${title}` as any)}
          </Typography>
          <Typography variant='subtitle2'>
            {t(`common.titleDescriptions.${subheader}` as any)}
          </Typography>
          <Typography variant='body1'>{bodyText}</Typography>
        </Box>
      </CardContent>
      <CardMedia
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [`& .${svgIconClasses.root}`]: {
            fontSize: '2.5em',
            marginLeft: '1rem',
            color: theme.rfhColors.white,
          },
        }}
      >
        {icon}
      </CardMedia>
    </StyledCard>
  )
}
