import { SxProps } from '@mui/material'
import { GridSortModel } from '@mui/x-data-grid-premium'

import { CELL_PADDING, MAX_DATE } from 'src/common/constants'
import type { IVeilgroepIndelingRegelView } from 'src/common/services/client'
import type { Item } from 'src/common/types'

import type { CodeSoort } from '../types'

import startOfTomorrow from 'date-fns/startOfTomorrow'

export const FEATURE_ROOT_PATH = 'auction-group-allocations' as const
export const GAP = 0.5 as const
export const ALLOCATIONS_CACHE_KEY = 'allocations-datagrid'
export const ALLOCATION_VEILGROEP_REGELS_CACHE_KEY =
  'allocation-veilgroep-regels-datagrid'
export const defaultSortModel: GridSortModel = [
  { field: 'productgroepCode', sort: 'asc' },
]
export const CodeSoortFust = 'FUST' as const
export const CodeSoortProduct = 'PRODUCT' as const
export const CodeSoortProductgroep = 'PRODUCTGROEP' as const
export const HEADER_CELL_STYLE_LEFT: SxProps = {
  textAlign: 'left',
  verticalAlign: 'top',
  py: CELL_PADDING,
  fontWeight: 700,
} as const

export const HEADER_CELL_STYLE_RIGHT: SxProps = {
  textAlign: 'right',
  verticalAlign: 'top',
  paddingY: CELL_PADDING,
  fontWeight: 700,
}

export const initialRule: IVeilgroepIndelingRegelView = {
  productclusterVeilgroepRegelId: 0,
  productclusterID: null,
  vestigingCode: null,
  veilgroepCode: null,
  volgordeNummer: null,
  actiefIndicatie: false,
  veilgroepOmschrijving: '',
  veilgroepRegelId: null,
  veilgroepRegelNummer: null,
  veilgroepRegelOmschrijving: '',
  veilgroepRegelTekst: '',
  veilgroepCodeAanvoerder: null,
  veilgroepOmschrijvingAanvoerder: null,
  codeBeperkingIndicatie: false,
  ingangsDatum: startOfTomorrow(),
  eindDatum: MAX_DATE,
  fustTekst: '',
  productTekst: '',
  productgroepTekst: '',
}

export const initialRuleCodes: Record<
  CodeSoort | 'VEILGROEPREGEL',
  Item<number>[]
> = {
  FUST: [],
  PRODUCT: [],
  PRODUCTGROEP: [],
  VEILGROEPREGEL: [],
}

export enum Kind {
  regular = 'regular',
  supplier = 'supplier',
}

export const validationErrors = {
  invalidAuctionGroup: 'Onbekende of ongeldige veilgroep',
  invalidAuctionGroupSupplier: 'Onbekende of ongeldige veilgroepaanvoerder',
  invalidAuctionGroupRule: 'Onbekende of ongeldige veilgroepregel',
  startdateNotInFuture: 'Ingangsdatum moet in de toekomst liggen',
  enddateNotInFuture: 'Einddatum moet in de toekomst liggen',
  enddateBeforeStartdate: 'Einddatum moet na de ingangsdatum liggen',
} as const
