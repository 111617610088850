import { ChangeEvent, useCallback, useState } from 'react'

import { Checkbox, TableCell, TableRow } from '@mui/material'

import { Item } from 'src/common/types'

type MultiSelectItemProps = {
  item: Item<number>
  changeChecked: (item: Item<number>) => void
}

export default function MultiSelectItem({
  item,
  changeChecked,
}: Readonly<MultiSelectItemProps>): JSX.Element {
  const MAX_HEIGHT = '2rem'
  const [selected, setSelected] = useState<boolean>(item.selected)

  const handleChecked = useCallback(
    (checked: boolean) => {
      setSelected(checked)
      item.selected = checked
      changeChecked(item)
    },
    [changeChecked, item]
  )

  const selectRow = useCallback(() => {
    const checked = !selected
    handleChecked(checked)
  }, [handleChecked, selected])

  const toggleCheckbox = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const checked = event.target.checked
      handleChecked(checked)
    },
    [handleChecked]
  )

  return (
    <TableRow
      key={item.code}
      hover={true}
      onClick={selectRow}
      sx={{ cursor: 'pointer' }}
    >
      <TableCell sx={{ py: 1, maxHeight: MAX_HEIGHT }}>{item.code}</TableCell>
      <TableCell sx={{ py: 1, maxHeight: MAX_HEIGHT }}>{item.name}</TableCell>
      <TableCell sx={{ py: 1, maxHeight: MAX_HEIGHT, textAlign: 'right' }}>
        <Checkbox
          checked={selected}
          onChange={toggleCheckbox}
          sx={{ mr: 0.5, maxHeight: MAX_HEIGHT }}
        />
      </TableCell>
    </TableRow>
  )
}
