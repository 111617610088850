import { IAanvoerderOnregelmatig } from 'src/common/services/client'

export function byAanvoerderVestigingSort(
  ia1: IAanvoerderOnregelmatig,
  ia2: IAanvoerderOnregelmatig
): number {
  if (ia1.vestigingCode !== ia2.vestigingCode) {
    return ia1.vestigingCode - ia2.vestigingCode
  } else {
    return ia1.aanvoerderNummer - ia2.aanvoerderNummer
  }
}
