import { DEFAULT_TAKE } from '../constants'
import { IApiClientGetFilter, IApiClientListFilter } from '../interfaces'
import { byProductCodeSort } from '../lib'
import { IClient, IProduct } from './client'

export const getProductAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientGetFilter>,
  inclExpired = false
): Promise<Partial<IProduct>> => {
  const result = (await apiClient.producten_Product_GetProduct(
    apiClientFilter.key,
    undefined,
    undefined
  )) as any

  const cleanedResult: Partial<IProduct> = result?.cleanedResponse

  if (!cleanedResult || (!inclExpired && cleanedResult.isVervallen)) {
    return null
  }

  return {
    productCode: cleanedResult.productCode,
    productNaam: cleanedResult.productNaam,
  }
}

export const getProductsAsync = async (
  apiClient: IClient,
  apiClientFilter: Partial<IApiClientListFilter>
): Promise<Partial<IProduct>[]> => {
  const result = (await apiClient.producten_Product_ListProduct(
    DEFAULT_TAKE,
    undefined,
    undefined,
    apiClientFilter.filter,
    undefined,
    undefined,
    undefined,
    undefined
  )) as any

  const records: Partial<IProduct>[] = result?.cleanedResponse?.value

  records?.sort(byProductCodeSort)
  return records
}
