import { Stack } from '@mui/material'

type FilterStackProps = {
  justify?: 'flex-start' | 'flex-end' | 'space-between'
  children: React.ReactNode
}

export default function FilterStack({
  justify = 'space-between',
  children,
  ...rest
}: Readonly<FilterStackProps>): JSX.Element {
  return (
    <Stack
      direction={'row'}
      justifyContent={justify}
      alignItems={'start'}
      maxHeight={'4.6em'}
      {...rest}
    >
      {children}
    </Stack>
  )
}
