import type { IProductclusterVeilgroepRegelCode } from 'src/common/services/client'
import type { Item } from 'src/common/types'

export const toItem = (
  ruleCode: IProductclusterVeilgroepRegelCode
): Item<number> => ({
  code: ruleCode.code,
  name: ruleCode.omschrijving,
  selected: ruleCode.geselecteerd,
})
