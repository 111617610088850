import { Box, type SxProps } from '@mui/material'
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress'

const sx: SxProps = {
  display: 'flex',
  animation: 'fadeIn 1s ease-in',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
}

export default function LoadingIcon({
  size = 25,
  ...rest
}: Readonly<CircularProgressProps>): JSX.Element {
  return (
    <Box sx={sx} role={'alert'} aria-busy={true}>
      <CircularProgress size={size} aria-label={'loading icon'} {...rest} />
    </Box>
  )
}
