import { MouseEventHandler } from 'react'

import { TableCell, TableRow } from '@mui/material'
import theme from '@rfh-core/theme'

import { CELL_PADDING } from 'src/common/constants'
import type { Item } from 'src/common/types'

type LookupItemProps = {
  item: Item<number>
  onClick: MouseEventHandler<HTMLTableRowElement>
}

export default function LookupItem({
  item,
  onClick,
}: Readonly<LookupItemProps>): JSX.Element {
  const color = item.expired ? theme.rfhColors.grey[400] : undefined
  return (
    <TableRow hover={true} onClick={onClick} sx={{ cursor: 'pointer' }}>
      <TableCell sx={{ py: CELL_PADDING, color }}>{item.code}</TableCell>
      <TableCell sx={{ py: CELL_PADDING, color }}>{item.name}</TableCell>
    </TableRow>
  )
}
