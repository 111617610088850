import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'

import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid2 as Grid,
  NativeSelect,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'

import { Divider } from 'src/common/components'
import { ThemeConfig } from 'src/common/config/SpacingConfig'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { IClient, IVeilgroepRegel } from 'src/common/services/client'
import {
  constructFilterRuleForKVP,
  getOrderBy,
  snackbarUtils,
} from 'src/common/utils'

import { AuctionPeriod, FormInput } from '../components'
import {
  extractAuctionPeriod,
  ruleCharacteristicsToString,
  stringToLoadCarierTypes,
  stringToQuality,
  stringToSupplierTypes,
} from '../lib'
import { validateAuctionPeriod, validateDescription } from '../lib/lib.utils'
import { CharacteristicsProvider } from '../providers'
import getAllAuctionGroupRulesAsync, {
  addAuctionGroupRuleAsync,
  getAuctionGroupRuleByIdAsync,
  getByAuctionGroupRuleId,
  updateAuctionGroupRuleAsync,
} from '../services'
import { useAuctionGroupRulesStore, useAuctionGroupRuleStore } from '../stores'
import { VeilgroepRegelKenmerkView } from '../types'
import { AddRuleCharacteristic } from './AddRuleCharacteristic'

export const ManageAuctionGroupRules: FC = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const user = useUser()
  const { apiClient } = useApiClient()
  const { id } = useParams<{ id?: string }>()

  const [inProgress, setInProgress] = useState(false)
  const {
    auctionGroupRule,
    characteristics,
    auctionGroupRuleCharacteristics,
    mustRefetchAuctionGroupRule,
    quality,
    loadCarriers,
    supplier,
    auctionPeriod,
    oldAuctionGroupRule,
    setSupplier,
    setRule,
    setRuleCharacteristics,
    setMustRefetchRule,
    resetAuctionGroupRuleStoreState,
    setLoadCarriers,
    setQuality,
    setAuctionPeriod,
    setOldRule,
  } = useAuctionGroupRuleStore()
  const { setMustRefetchAuctionGroupRules } = useAuctionGroupRulesStore()
  const [open, setOpen] = useState(false)

  const checkboxStyles = {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    maxHeight: '1rem',
  }
  const comboStyles = {
    maxHeight: '3rem',
    minHeight: '3rem',
    my: '0.1rem',
  }

  const characteristicContent = useMemo(
    () =>
      ruleCharacteristicsToString(
        auctionGroupRuleCharacteristics as VeilgroepRegelKenmerkView[],
        characteristics
      ),
    [auctionGroupRuleCharacteristics, characteristics]
  )

  const getBooleanOptions = useCallback(() => {
    const booleanOptions = []
    booleanOptions.push(
      <option key='ntv' value=''>
        {t('auctionRule.notApplicable')}
      </option>
    )
    booleanOptions.push(
      <option key='true' value='1'>
        {t('common.yes')}
      </option>
    )
    booleanOptions.push(
      <option key='false' value='0'>
        {t('common.no')}
      </option>
    )
    return booleanOptions
  }, [t])

  const changeAuctionGroupRule = useCallback(
    (event: any) => {
      const { name, value } = event.target
      setRule({
        ...auctionGroupRule,
        [name]: value,
      })
    },
    [auctionGroupRule, setRule]
  )

  const changeBoolean = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target
      setRule({
        ...auctionGroupRule,
        [name]: value === '' ? null : Boolean(JSON.parse(value)),
      })
    },
    [auctionGroupRule, setRule]
  )

  const changeQuality = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setQuality({
        ...quality,
        [target.name]: checked,
      })
    },
    [quality, setQuality]
  )

  const changeSupplyType = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newSupplier = { ...supplier, [target.name]: checked }
      if (target.name === 'GP' || target.name === 'GPPlus') {
        newSupplier.GPPlus = checked
      }
      setSupplier(newSupplier)
    },
    [supplier, setSupplier]
  )

  const changeLoadCarrierType = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const newLoadCarrier = {
        ...loadCarriers,
        [target.name]: checked,
      }
      setLoadCarriers(newLoadCarrier)
    },
    [loadCarriers, setLoadCarriers]
  )

  const openCharacteristicDialog = () => setOpen(true)

  const closeCharacteristicDialog = () => setOpen(false)

  const addOrSaveRule = useCallback(async () => {
    if (inProgress) {
      return Promise.resolve()
    }
    setInProgress(true)
    const newRule: IVeilgroepRegel = {
      ...auctionGroupRule,
      ...auctionPeriod,
      aanmaakDatumTijd: new Date(),
      aanmaakGebruiker: user.sub,
      mutatieDatumTijd: new Date(),
      mutatieGebruiker: user.sub,
    }

    if (!validateAuctionPeriod(auctionPeriod)) {
      setInProgress(false)
      snackbarUtils.error(t('auctionRule.invalidAuctionPeriod'))
      return
    }
    if (!validateDescription(newRule)) {
      setInProgress(false)
      snackbarUtils.error(t('auctionRule.invalidDescription'))
      return
    }
    if (
      newRule.veilgroepRegelNummer !==
        oldAuctionGroupRule.veilgroepRegelNummer &&
      (
        await getAllAuctionGroupRulesAsync(apiClient, {
          count: false,
          filter: constructFilterRuleForKVP(
            'veilgroepRegelNummer',
            Number(newRule.veilgroepRegelNummer)
          ),
        })
      ).records.length
    ) {
      setInProgress(false)
      snackbarUtils.error(t('auctionRule.invalidRuleNumber'))
      return
    }
    setRule(newRule)

    let result: IVeilgroepRegel = null
    if (auctionGroupRule.veilgroepRegelId) {
      try {
        await updateAuctionGroupRuleAsync(
          apiClient,
          auctionGroupRule.veilgroepRegelId,
          newRule,
          user.sub
        )
      } catch (error: any) {
        snackbarUtils.error(`update rule error: ${String(error)}`)
      }
      setRule(newRule)
      setOldRule(newRule)
    } else {
      try {
        result = await addAuctionGroupRuleAsync(apiClient, newRule, user.sub)
      } catch (error: any) {
        snackbarUtils.error(`add rule error: ${String(error)}`)
      }
      setRule(result)
      setOldRule(result)
      if (result.veilgroepRegelId) {
        history.push(
          `/dashboard/auction-group-rules/${result.veilgroepRegelId}`
        )
      }
    }
    setInProgress(false)
  }, [
    inProgress,
    auctionGroupRule,
    auctionPeriod,
    user.sub,
    oldAuctionGroupRule.veilgroepRegelNummer,
    apiClient,
    setRule,
    t,
    setOldRule,
    history,
  ])

  const fetchData = useCallback(
    async (client: IClient, veilgroepRegelId: number): Promise<void> => {
      if (inProgress || !veilgroepRegelId) {
        return Promise.resolve()
      }
      try {
        const fetchedRule = await getAuctionGroupRuleByIdAsync(client, {
          key: veilgroepRegelId,
        })
        setRule(fetchedRule)
        setOldRule(fetchedRule)
        setQuality(stringToQuality(fetchedRule.kwaliteit))
        setLoadCarriers(stringToLoadCarierTypes(fetchedRule.ladingdragerTypen))
        setSupplier(stringToSupplierTypes(fetchedRule.aanvoerTypen))
        setAuctionPeriod(extractAuctionPeriod(fetchedRule))
      } catch (error: any) {
        snackbarUtils.error(String(error))
      }
    },
    [
      inProgress,
      setRule,
      setOldRule,
      setQuality,
      setLoadCarriers,
      setSupplier,
      setAuctionPeriod,
    ]
  )

  const ensureCharacteristics = useCallback(
    async (client: IClient, veilgroepRegelId: number) => {
      if (inProgress || !veilgroepRegelId) {
        return Promise.resolve()
      }

      try {
        // eslint-disable-next-line testing-library/no-await-sync-query
        const fetchedCharacteristics = await getByAuctionGroupRuleId(client, {
          key: veilgroepRegelId,
        })
        if (fetchedCharacteristics.records.length > 0) {
          setRuleCharacteristics(fetchedCharacteristics.records)
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      }
    },
    [inProgress, setRuleCharacteristics]
  )

  const goBack = useCallback(() => {
    resetAuctionGroupRuleStoreState()
    setMustRefetchAuctionGroupRules(true)
    history.push('/dashboard/auction-group-rules')
  }, [
    history,
    resetAuctionGroupRuleStoreState,
    setMustRefetchAuctionGroupRules,
  ])

  const displayBoolean = (input: boolean | undefined | null) => {
    if (input === undefined || input === null) return ''
    return input ? '1' : '0'
  }

  const fetchAuctionRuleNumber = useCallback(
    async (client: IClient) => {
      if (inProgress) {
        return Promise.resolve()
      }
      try {
        setInProgress(true)
        const latestRuleNumber = await getAllAuctionGroupRulesAsync(client, {
          top: 1,
          skip: 0,
          count: false,
          orderby: getOrderBy([
            {
              field: 'veilgroepRegelNummer',
              sort: 'desc',
            },
          ]),
        })
        if (latestRuleNumber.records.length) {
          setRule({
            veilgroepRegelNummer:
              latestRuleNumber.records[0].veilgroepRegelNummer + 1,
          })
        } else {
          setRule({
            veilgroepRegelNummer: 1,
          })
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setInProgress(false)
      }
    },
    [inProgress, setRule]
  )

  const getLabel = useCallback(
    (label: string) => (
      <Typography variant='body1' fontWeight={100}>
        {label}
      </Typography>
    ),
    []
  )

  useEffect(() => {
    if (mustRefetchAuctionGroupRule && apiClient && id) {
      fetchData(apiClient, Number(id))
      ensureCharacteristics(apiClient, Number(id))
      setMustRefetchRule(false)
    } else if (apiClient && !id) {
      fetchAuctionRuleNumber(apiClient)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient, mustRefetchAuctionGroupRule, id, setMustRefetchRule])

  return (
    <CharacteristicsProvider>
      <Container maxWidth='lg'>
        <Grid container spacing={2} sx={{ width: '100%', margin: '0' }}>
          <Typography variant='h2'>
            {t('auctionRule.auctionRuleTitle')}
          </Typography>
        </Grid>
        <Grid size={12}>
          <Divider />
        </Grid>
        <Box
          component='form'
          sx={{ width: '100%', mt: GRID_MARGIN_TOP }}
          onSubmit={addOrSaveRule}
        >
          <FormGroup sx={{ justifyContent: 'flex-start' }}>
            <FormInput
              label={t('auctionRule.rulenumber')}
              id='id-veilgroepRegelNummer'
              input={
                <TextField
                  type='number'
                  name='veilgroepRegelNummer'
                  slotProps={{
                    htmlInput: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  onChange={changeAuctionGroupRule}
                  value={auctionGroupRule?.veilgroepRegelNummer ?? ''}
                />
              }
              sx={comboStyles}
            />
            <FormInput
              label={t('auctionRule.ruleDescription')}
              id='id-veilgroepRegelOmschrijving'
              input={
                <TextField
                  name='veilgroepRegelOmschrijving'
                  value={auctionGroupRule?.veilgroepRegelOmschrijving ?? ''}
                  onChange={changeAuctionGroupRule}
                />
              }
              sx={comboStyles}
            />
            <Grid
              container
              alignItems={'center'}
              sx={{ my: ThemeConfig.spacing.s }}
            >
              <Grid size={{ xs: 12, sm: 4 }}>
                <FormLabel sx={{ fontSize: '1rem', lineHeight: '1rem' }}>
                  {t('auctionRule.characteristics')}
                </FormLabel>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <FormControl
                  sx={{
                    margin: 0,
                    width: '100%',
                  }}
                >
                  <TextareaAutosize
                    minRows={5}
                    value={characteristicContent ?? ''}
                    style={{
                      paddingTop: ThemeConfig.spacing.l,
                      paddingLeft: ThemeConfig.spacing.l,
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid size={{ xs: 12, sm: 4 }}>
                <Button
                  variant='contained'
                  disabled={!auctionGroupRule?.veilgroepRegelId}
                  onClick={openCharacteristicDialog}
                  sx={{ ml: ThemeConfig.spacing.s }}
                >
                  {t('auctionRule.change')}
                </Button>
              </Grid>
            </Grid>
            <FormInput
              label={t('auctionRule.quality')}
              id='id-kwaliteit'
              input={
                <FormGroup sx={checkboxStyles}>
                  <FormControlLabel
                    label={getLabel('A1')}
                    control={
                      <Checkbox
                        onChange={changeQuality}
                        checked={quality.A1}
                        name={'A1'}
                        size={'small'}
                      />
                    }
                  />
                  <FormControlLabel
                    label={getLabel('A2')}
                    control={
                      <Checkbox
                        onChange={changeQuality}
                        checked={quality.A2}
                        name={'A2'}
                        size={'small'}
                      />
                    }
                  />
                  <FormControlLabel
                    label={getLabel('B1')}
                    control={
                      <Checkbox
                        onChange={changeQuality}
                        checked={quality.B1}
                        name={'B1'}
                        size={'small'}
                      />
                    }
                  />
                </FormGroup>
              }
              sx={{ my: '0.4rem' }}
            />
            <FormInput
              label={t('auctionRule.supplyTypes')}
              id='id-aanvoertypen'
              input={
                <FormGroup sx={checkboxStyles}>
                  <FormControlLabel
                    label={getLabel('MP')}
                    control={
                      <Checkbox
                        onChange={changeSupplyType}
                        checked={supplier.MP}
                        name={'MP'}
                        size={'small'}
                      />
                    }
                  />
                  <FormControlLabel
                    label={getLabel('1P')}
                    control={
                      <Checkbox
                        onChange={changeSupplyType}
                        checked={supplier.oneP}
                        name={'oneP'}
                        size={'small'}
                      />
                    }
                  />
                  <FormControlLabel
                    label={getLabel('GP')}
                    control={
                      <Checkbox
                        onChange={changeSupplyType}
                        checked={supplier.GP}
                        name={'GP'}
                        size={'small'}
                      />
                    }
                  />
                  <FormControlLabel
                    label={getLabel('GP+')}
                    control={
                      <Checkbox
                        onChange={changeSupplyType}
                        checked={supplier.GPPlus || supplier.GP}
                        disabled={supplier.GP}
                        name={'GPPlus'}
                        size={'small'}
                      />
                    }
                  />
                </FormGroup>
              }
              sx={{ my: '0.4rem' }}
            />
            <FormInput
              label={t('auctionRule.loadCarrierTypes')}
              id='id-ladingdragertypen'
              input={
                <FormGroup sx={checkboxStyles}>
                  <FormControlLabel
                    label={getLabel('STW')}
                    control={
                      <Checkbox
                        onChange={changeLoadCarrierType}
                        checked={loadCarriers.STW}
                        name={'STW'}
                        size={'small'}
                      />
                    }
                  />
                  <FormControlLabel
                    label={getLabel('DC')}
                    control={
                      <Checkbox
                        onChange={changeLoadCarrierType}
                        checked={loadCarriers.DC}
                        name={'DC'}
                        size={'small'}
                      />
                    }
                  />
                </FormGroup>
              }
              sx={{ my: '0.4rem' }}
            />
            <Grid
              container
              alignItems={'center'}
              sx={{
                mb: ThemeConfig.spacing.sm,
                maxHeight: '2.5rem',
              }}
            >
              <Grid size={{ xs: 12, sm: 4 }}>
                <FormLabel sx={{ fontSize: '1rem', lineHeight: '1rem' }}>
                  {t('auctionRule.auctionperiod')}
                </FormLabel>
              </Grid>
              <Grid size={{ xs: 12, sm: 8 }}>
                <AuctionPeriod />
              </Grid>
            </Grid>
            <FormInput
              label={t('auctionRule.linkedPicture')}
              id='id-gekoppeldePartijFoto'
              input={
                <NativeSelect
                  name='gekoppeldePartijFoto'
                  onChange={changeBoolean}
                  value={displayBoolean(auctionGroupRule?.gekoppeldePartijFoto)}
                  sx={{ pl: ThemeConfig.spacing.s }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
              sx={comboStyles}
            />

            <FormInput
              label={t('auctionRule.regularity')}
              id='id-aanvoerderRegelmatig'
              input={
                <NativeSelect
                  name='aanvoerderRegelmatig'
                  onChange={changeBoolean}
                  value={displayBoolean(auctionGroupRule?.aanvoerderRegelmatig)}
                  sx={{ pl: ThemeConfig.spacing.s }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
              sx={comboStyles}
            />
            <FormInput
              label={t('auctionRule.irregularity')}
              id='id-aanvoerderOnregelmatig'
              input={
                <NativeSelect
                  name='aanvoerderOnregelmatig'
                  onChange={changeBoolean}
                  value={displayBoolean(
                    auctionGroupRule?.aanvoerderOnregelmatig
                  )}
                  sx={{ pl: ThemeConfig.spacing.s }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
              sx={comboStyles}
            />
            <FormInput
              label={t('auctionRule.noAuctionService')}
              id='id-geenDienstVeilen'
              input={
                <NativeSelect
                  name='geenDienstVeilen'
                  onChange={changeBoolean}
                  value={displayBoolean(auctionGroupRule?.geenDienstVeilen)}
                  sx={{ pl: ThemeConfig.spacing.s }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
              sx={comboStyles}
            />
            <FormInput
              label={t('auctionRule.sourceIsClaim')}
              id='id-bronReclamatie'
              input={
                <NativeSelect
                  name='bronReclamatie'
                  onChange={changeBoolean}
                  value={displayBoolean(auctionGroupRule?.bronReclamatie)}
                  sx={{ pl: ThemeConfig.spacing.s }}
                >
                  {getBooleanOptions()}
                </NativeSelect>
              }
              sx={comboStyles}
            />
            <Grid container sx={{ m: ThemeConfig.spacing.xs }}>
              <Grid
                size={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: ThemeConfig.spacing.s,
                  marginBottom: ThemeConfig.spacing.sm,
                }}
              >
                <Button
                  variant='outlined'
                  onClick={goBack}
                  sx={{ marginRight: ThemeConfig.spacing.s }}
                >
                  {t('common.back')}
                </Button>
                <Button
                  variant='contained'
                  disabled={inProgress}
                  onClick={addOrSaveRule}
                >
                  {auctionGroupRule.veilgroepRegelId
                    ? t('common.save')
                    : t('common.add')}
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
        <AddRuleCharacteristic
          open={open}
          onClose={closeCharacteristicDialog}
        />
      </Container>
    </CharacteristicsProvider>
  )
}
