import { useTranslation } from 'react-i18next'

import { Grid2 as Grid, Typography } from '@mui/material'

import { Divider } from 'src/common/components'

import { AuctionGroupCountsFilter, AuctionGroupCountsGrid } from '../components'

export default function AuctionGroupCounts(): JSX.Element {
  const { t } = useTranslation()

  return (
    <>
      <Grid size={12} mb={2}>
        <Typography variant='h2' style={{ fontWeight: 'bold' }}>
          {t('dayCounts.dayCountsTitle')}
        </Typography>
      </Grid>
      <Grid size={{ sm: 12 }}>
        <AuctionGroupCountsFilter />
      </Grid>
      <Grid size={12}>
        <Divider />
      </Grid>
      <Grid size={{ sm: 12 }}>
        <AuctionGroupCountsGrid />
      </Grid>
    </>
  )
}
