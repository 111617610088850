import { Stack } from '@mui/material'

import BooleanTag from './BooleanTag'

type BooleanTagCenteredProps = {
  value: boolean
}

export default function BooleanTagCentered({
  value,
}: Readonly<BooleanTagCenteredProps>): JSX.Element {
  return (
    <Stack flexDirection={'row'} justifyContent={'center'}>
      <BooleanTag value={value} />
    </Stack>
  )
}
