import { CSSProperties } from 'react'

import { lighten } from '@mui/material'
import theme from '@rfh-core/theme'

export type TextAlign = 'left' | 'right' | 'center'
export type ClockStatus = 'WAC' | 'VEL' | 'PAU' | 'STO' | 'END'

export type ClockStatusData = {
  vestigingCode: number
  klokNaam: string
  klokStatus?: ClockStatus
  huidigeVeilgroep: string
  teVeilen: number
  geveild: number
}

export const KLOK_STATUS: Record<
  string,
  { color: string; description: string }
> = {
  WAC: {
    color: lighten(theme.rfhColors.royalPurple, 0.85),
    description: 'Wacht Op Start Veilen',
  },
  VEL: { color: theme.rfhColors.hydroGreenShade, description: 'Veilen' },
  PAU: { color: 'lightGoldenRodYellow', description: 'Pauze' },
  STO: { color: theme.rfhColors.alertRed, description: 'Storing' },
  END: { color: theme.rfhColors.white, description: 'Einde veilen white' },
}

export const getHeaderCellStyles = (
  textAlign: TextAlign = 'left'
): CSSProperties => ({
  color: theme.rfhColors.leafGreen,
  fontWeight: 700,
  padding: '0.05em 0.5em',
  textAlign,
  width: '20%',
})

export const getCellStyles = (
  textAlign: TextAlign = 'right'
): CSSProperties => ({
  color: theme.rfhColors.leafGreen,
  fontWeight: 500,
  padding: '0.05em 0.5em',
  textAlign,
  whiteSpace: 'nowrap',
  width: '20%',
})
