import { ILocationClock } from 'src/common/interfaces'
import { IVeildagVeilgroepTellingReport } from 'src/common/services/client'

export const byLocationCode = (
  lk1: ILocationClock,
  lk2: ILocationClock
): number => lk1.locationCode - lk2.locationCode

export const byLocationCodeClockName = (
  lk1: ILocationClock,
  lk2: ILocationClock
): number =>
  lk1.locationCode === lk2.locationCode
    ? Number(lk1.clockName.slice(1)) - Number(lk2.clockName.slice(1))
    : lk1.locationCode - lk2.locationCode

export const byKlokVeilgroep =
  (clocks: ILocationClock[]) =>
  (
    rep1: IVeildagVeilgroepTellingReport,
    rep2: IVeildagVeilgroepTellingReport
  ): number => {
    const indexRep1 = clocks.findIndex(lc => lc.clockName === rep1.klokNaam)
    const indexRep2 = clocks.findIndex(lc => lc.clockName === rep2.klokNaam)

    if (indexRep1 === -1 || indexRep2 === -1) return -1

    if (indexRep1 > indexRep2) {
      return 1
    } else if (indexRep1 < indexRep2) {
      return -1
    } else if (rep1.veilgroepCode > rep2.veilgroepCode) {
      return 1
    } else if (rep1.veilgroepCode < rep2.veilgroepCode) {
      return -1
    }
    return 0
  }
