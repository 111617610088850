import { GridColDef, GridInitialState } from '@mui/x-data-grid-premium'

import { IVeilgroepRegel } from 'src/common/services/client'
import { formatDate } from 'src/common/utils/DateFunctions'
import i18n from 'src/i18n'

import { Aanvoertypen, DateInput, Kwaliteit, LadingdragerTypen } from '../types'

export const CACHE_KEY = 'auction-group-rules-datagrid'

export const initialState: GridInitialState = {
  columns: {
    columnVisibilityModel: {
      veilgroepRegelNummer: true,
      veilgroepRegelOmschrijving: true,
      mutatieDatumTijd: true,
      mutatieGebruiker: true,
      delete: true,
      edit: true,
      __check__: true,
    },
  },
}

export const columns: GridColDef[] = [
  {
    field: 'veilgroepRegelNummer',
    headerName: i18n.t('auctionRule.rulenumber'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    sortable: true,
  },
  {
    field: 'veilgroepRegelOmschrijving',
    headerName: i18n.t('auctionRule.rule'),
    headerAlign: 'left',
    align: 'left',
    flex: 3,
    sortable: true,
  },
  {
    field: 'mutatieDatumTijd',
    headerName: i18n.t('auctionRule.mutationDate'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    sortable: true,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
    groupingValueGetter: row =>
      formatDate(row, i18n.t('common.dateFormatShort')),
  },
  {
    field: 'mutatieGebruiker',
    headerName: i18n.t('auctionRule.mutationUser'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    sortable: true,
  },
]

export const defaultValues: IVeilgroepRegel = {
  aanvoerderOnregelmatig: undefined,
  aanvoerderRegelmatig: undefined,
  aanvoerTypen: undefined,
  dagTtm: undefined,
  dagVanaf: undefined,
  gekoppeldePartijFoto: undefined,
  kwaliteit: undefined,
  ladingdragerTypen: undefined,
  veilgroepRegelId: undefined,
  veilgroepRegelNummer: undefined,
  veilgroepRegelOmschrijving: undefined,
  weekTtm: undefined,
  weekVanaf: undefined,
}

export const defaultQuality: Kwaliteit = {
  A1: false,
  A2: false,
  B1: false,
}

export const defaultLoadCarrier: LadingdragerTypen = {
  DC: false,
  STW: false,
}

export const defaultSupplier: Aanvoertypen = {
  MP: false,
  oneP: false,
  GP: false,
  GPPlus: false,
}

export const defaultAuctionPeriod: DateInput = {
  dagTtm: undefined,
  dagVanaf: undefined,
  weekTtm: undefined,
  weekVanaf: undefined,
}
