import { useTranslation } from 'react-i18next'

import {
  FormControl,
  FormLabel,
  Grid2 as Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  selectClasses,
  SxProps,
} from '@mui/material'
import theme from '@rfh-core/theme'

import type { Item } from 'src/common/types'

type Props = {
  onChange?: (event: SelectChangeEvent<string>) => void
  label?: string
  disabled?: boolean
  fieldName?: string
  values?: Item<string>[]
  value?: string
  sx?: SxProps
}

export default function LabelDropdownText({
  disabled,
  fieldName,
  label,
  onChange,
  values,
  value,
  sx,
}: Readonly<Props>): JSX.Element {
  const { t } = useTranslation()
  return (
    <Grid
      container
      direction={'row'}
      justifyContent={'flex-start'}
      alignItems={'center'}
      sx={sx}
    >
      <Grid>
        <FormControl fullWidth>
          <FormLabel
            htmlFor={`id-${fieldName}`}
            sx={{
              color: theme.rfhColors.black,
              whiteSpace: 'nowrap',
              minWidth: 170,
              fontSize: '1rem',
              fontWeight: 100,
            }}
          >
            {label}
          </FormLabel>
        </FormControl>
      </Grid>
      <Grid>
        <FormControl fullWidth>
          <Select
            autoWidth
            defaultValue=''
            disabled={disabled}
            id={`id-${fieldName}`}
            name={fieldName}
            value={value ?? ''}
            onChange={onChange}
            sx={{
              minWidth: 160,
              bgcolor: theme.rfhColors.white,
              fontSize: '1rem',
              fontWeight: 100,
              [`& .${selectClasses.select}`]: { p: 0 },
            }}
          >
            <MenuItem value='' />
            {values?.length === 0 ? (
              <MenuItem value=''>{t('common.noResults')}</MenuItem>
            ) : null}
            {values?.map(item => (
              <MenuItem key={item.code} value={item.code}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
}
